const MembershipTypeCode = Object.freeze({
  EZETIMIBE: 'ezetimibe',
  ROSUVASTATIN: 'rosuvastatin',

  LONGEVITY: 'Longevity',
  LIPIDS: 'Lipids',
  LIPIDS_UPGRADE: 'lipids-upgrade',
  ULTIMATE: 'ultimate',

  HEART_HEALTH: 'heart-health',

  SUPPLEMENTS: 'supplements'
})

export default MembershipTypeCode;

import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const addAppointment = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/appointments`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addAppointmentPaymentLink = async (id, params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/appointments/${id}/payment-link`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addAppointmentCharge = async (id, params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/appointments/${id}/charges`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const updateAppointment = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/appointments/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const confirmAppointment = async (id, params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/appointments/${id}/confirm`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const getJoinableAppointment = async (id, params) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/appointments/${id}/joinable`, {
    params: ServiceHelper.getParams(params),
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const joinAppointment = async (id, params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/appointments/${id}/join`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const cancelAppointment = async (id, params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/appointments/${id}/cancel`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeAppointment = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/appointments/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const addAppointmentInvites = async (id, params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/appointments/${id}/invites`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeAppointmentInvite = async (id, inviteId, params={}) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/appointments/${id}/invites/${inviteId}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const getAppointment = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/appointments/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const listAppointments = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/appointments`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}


/* APPOINTMENT PARENTS aka Events */

export const addAppointmentParent = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/appointments/parents`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}


export const listAppointmentParents = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/appointments/parents`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })

  console.log(response)
  return response.data
}

export const updateAppointmentParent = async (id, params={}) => {
  
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/appointments/parents/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const getAppointmentParent = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/appointments/parents/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}


export const removeAppointmentParent = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/appointments/parents/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const joinAppointmentParent = async (id, params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/appointments/parents/${id}/join`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}
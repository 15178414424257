import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const getTest = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/tests/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const listAnnotatedTests = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/tests/annotated`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const listTests = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/tests`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const addTest = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/tests`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeTest = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/tests/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const updateTest = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/tests/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}
import React, { useEffect, useState } from 'react';
import { Avatar, Space } from 'antd'
import './supportSidebar.scss';
import { UserOutlined } from '@ant-design/icons'
import { useContext } from 'react';
import { UserContext } from '../../contexts/user.context';
import MembershipTypeCode from '../../enums/membershipTypeCode.enum';
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import Breakpoint from "../../enums/breakpoint.enum";
import MembershipHelper from '../../helpers/membership.helper';
import { addSlack } from "../../services/slack.service"
import FlowType from '../../enums/flowType.enum';

export const SupportSidebar = () => {
  const navigate = useNavigate()
  const { currentUser, longevityMembership } = useContext(UserContext)

  const FRONTCHAT = {
    concierge: "779ba35ec6e067f6747a99f9a69c803b",
    support: "5dd219df2035e1ccf7051fd7f6a581b8"
  }

  useEffect(() => {
    document.title = `Home | Instalab`
  }, [])


  const onChat = (team) => {
    if (team === "concierge") {
      const chatId =  FRONTCHAT.concierge 
      window.FrontChat("init", {chatId: chatId, useDefaultLauncher: false});
      setTimeout(() => {
        window.FrontChat("show");
      }, 500);  
    }

    else {
      window.FrontChat("show");
    }
  };



  const onIntroCall = () => {
    window.Calendly.initPopupWidget({ url: 'https://calendly.com/d/4kj-7q2-bnb?hide_gdpr_banner=1' });
  }

  const onBecomeMember = async() => {
    try {
      await addSlack({
      message: `${currentUser.firstName} ${currentUser.lastName}: Membership Upgrade Click - Support Sidebar`,
      channel: process.env.REACT_APP_PATIENT_SLACK
    });
    }
    catch (error) {
      console.error('Failed to log message to Slack:', error);
    }
    navigate("/patient/memberships")
  }

  const onBookSession = () => {
    navigate(`/flow/${FlowType.LONGEVITY_CONSULT}`)
  }

  const ConciergeComponent = () => {
    return (
      <div className={`support-medical-team`}>
        {!longevityMembership && (
          <div className="members-only-message" onClick={onBecomeMember}>
            Members-only feature. Learn more.
          </div>
        )}

        <div className="support-subtitle">Message Member Concierge</div>
        <div className={`support-medical-team-content ${!longevityMembership ? 'blur-effect' : ''}`}>

          <div className="support-description"><a onClick={()=>onChat("concierge")} className="secondary-link">Reach out</a> with any health-related questions or requests like scheduling, referrals, etc.</div>
        </div>
      </div>

    );
  }

  const PhysicianComponent = () => {
    return (
      <div className={`support-medical-team`}>

          <div className="support-subtitle">Talk to Longevity Physician</div>
          <div className={`support-medical-team-content`}>

          <div className="support-description"><a onClick={onBookSession} className="secondary-link">Schedule a call</a> with a longevity physician for an in-depth discussion on your long-term health.</div>
        </div>


      </div>

      
    );
  }
    
  return  currentUser && 
    <div className="support-sidebar">
    <div className={classNames('support', {'dashboard-box': window.innerWidth < Breakpoint.LG })}>
        <div clasName="support-avatars">
        <Space wrap size={13}>
          <Avatar className="support-avatar" src="/img/steven-square.png" size={55} icon={<UserOutlined />} />
          <Avatar className="support-avatar" src= "/img/adora-square.png" size={55} icon={<UserOutlined />} />
          <Avatar className="support-avatar" src= "/img/dan-square.png" size={55} icon={<UserOutlined />} />
          </Space>


        </div>

        <div className="support-title">Have a question?</div>
        <div className="support-description">Our team is here to help.</div>

        {longevityMembership  ?
          <ConciergeComponent />
          :
          <>        
          <div className="support-subtitle">Message Support</div>
          <div className="support-description"><a onClick={()=>onChat("support")} className="secondary-link">Ask a question</a> about any of Instalab's products and services or your account.</div>
          </>
        }

        <PhysicianComponent  />
        

        {/* <div className="support-subtitle">Talk to our Founders</div>
        <div className="support-description"><a onClick={onIntroCall} className="secondary-link">Schedule a call</a> with our founders. They're eager to speak with you.</div> */}

    </div>

    
    </div>
}
import React, { useState } from 'react'
import { Modal, Form, Input, message } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import "./changePasswordModal.scss"
import { updatePhlebotomist } from '../../services/phlebotomist.service';
import { updateAdmin } from '../../services/admin.service';
import { updatePatient } from '../../services/patient.service';
import { updateProvider } from '../../services/provider.service';
import { updateStaff } from '../../services/staff.service';
import ObjectType from '../../enums/objectType.enum'

const { Item } = Form

const updatePassword = {
  [ObjectType.PHLEBOTOMIST]: updatePhlebotomist,
  [ObjectType.ADMIN]: updateAdmin,
  [ObjectType.PATIENT]: updatePatient,
  [ObjectType.PROVIDER]: updateProvider,
  [ObjectType.STAFF]: updateStaff,
}

export const ChangePasswordModal = ({ open, setOpen, onSuccess, objectId, objectType, select, populate=[] }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onCancel = () => {
    setOpen(false)
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {
      const params = { 
        fields: form.getFieldsValue(),
        select,
        populate,
      }
      const response = await updatePassword[objectType](objectId, params)
      if (onSuccess) {
        onSuccess(response)
      }
      message.success('Password saved')
      setOpen(false)
      form.resetFields()
    } catch (err) {
      message.error('Failed to save password')
    }
    setIsSubmitting(false)
  }


  return (
    <Modal 
      open={open} 
      title={'Change Password'}
      onCancel={onCancel}
      onOk={onOk}
      okText='Save'
      footer={[
        <ContinueButton
          onClick={onOk}
          text='Save Password'
          isSubmitting={isSubmitting}
        />
      ]}
    >
      <Form
        form={form}
        onFinish={onOk}
        layout='vertical'
      >
        <Item 
          label="Password"
          name="password"
          rules={[{ 
            required: true, 
            message: 'Enter password'
          }]}
        >
          <Input placeholder="Password" type="password" />
        </Item>
      </Form>
    </Modal>
  )
}
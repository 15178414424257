import { Button }  from 'antd'
import LatestPosts from '../latestPosts/latestPosts.component.js'

export const ConfirmIntake = () => {

  const instructions = [{
    sections: [{
      title: 'Get Blood Draw Kit',
      description: <>You'll get your kit in the mail in one week. This contains all the materials needed for the blood draw.</>
    }, 
  ]
  }, 
  {
    sections: [{
      title: 'Schedule Blood Draw',
      description: <>Once you've received your kit, we'll email you to schedule your blood draw. Appointments are usually available within one week.</>
    }]
  },
  {
    sections: [{
      title: 'Get Blood Drawn',
      description: <>Your phlebotomist will arrive in the time window you selected. The process should take no more than 10 minutes.</>
    }]
  }, 
  {
    sections: [{
      title: 'Get Test Results',
      description: <>Your test results and comprehensive report will be available one week after your appointment.</>
    }]
  }]      
  
  return (
    <div className="prescription-approval">

{/* 
      {instructions?.length > 0 && (
        <div className="instruction-list">
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row>
                <Col
                  style={{
                    width: width > Breakpoint.SM ? 80 : '100%',
                    marginBottom: width > Breakpoint.SM ? 0 : 10
                  }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  style={{
                    width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                  }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>
      )} */}

      <Button
        className="question-btn"
        onClick={() => window.FrontChat("show")}
      >
        I have a question?
      </Button>

      <LatestPosts />
    </div>
  )
}

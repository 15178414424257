import React from "react";
import "./dehydrationAdvice.scss";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import ReportHelper from "../../../helpers/report.helper";
import { LowercaseTest, ReportSection } from "../../../enums/index.enum";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const converter = require("number-to-words");
const { Paragraph } = Typography;

export const DehydrationAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [dehydrationTests, setDehydrationTests] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    setDehydrationTests(getDehydrationTests());
  }, [report, tests]);

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report, dehydrationTests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs,
        }
      }
    })
  }, [title, description, recs])

  const getDehydrationTests = () => {
    if (!report || !tests) return null;
    return ReportHelper.getSectionTests(report, ReportSection.DEHYDRATED_ADVICE, tests);
  };

  const getTestNames = (tests) => {
    const testNames = tests.map((test, index) => (
      <>
      <span key={test._id}>{LowercaseTest[test.code]}</span>
      {tests.length > 2 && index < tests.length - 1 && ', '}
      {tests.length > 1 && index < tests.length - 1 && ' and '}
      </>
    ));

    return testNames
  }

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !dehydrationTests) return null;

    const isBetter = ReportHelper.isBetter(report, ReportSection.DEHYDRATED_ADVICE);
    const isWorse = ReportHelper.isWorse(report, ReportSection.DEHYDRATED_ADVICE);
    if (isBetter) {
      return (
        <>Your <span>BUN</span> level, a waste product created by your liver and filtered by your kidneys, is <span>decreasing</span>.</>
      );
    }
    if (isWorse) {
      return (
        <>Your <span>BUN</span> level, a waste product created by your liver and filtered by your kidneys, is <span>increasing</span>.</>
      );
    }
    return (
      <>
      {dehydrationTests?.length === 1
        ? <>Your <span>elevated {LowercaseTest[dehydrationTests[0].code]}</span> level suggests possible <span>dehydration</span> when your blood was drawn.</>
        : <>These <span>{converter.toWords(dehydrationTests?.length)} biomarkers</span> suggests possible <span>dehydration</span> when your blood was drawn.</>
      }
      </>
    );
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report || !dehydrationTests) return null;

    const isBetter = ReportHelper.isBetter(report, ReportSection.DEHYDRATED_ADVICE);
    const isWorse = ReportHelper.isWorse(report, ReportSection.DEHYDRATED_ADVICE);

    if (isBetter) {
      return (
        <>
          Since your {getTestNames(dehydrationTests)} level is approaching an optimal range, there's much less cause of concern. To ensure that this continues to improve, it's important to stay well-hydrated.
        </>
      );
    }
    if (isWorse) {
      return (
        <>
          This isolated {getTestNames(dehydrationTests)} elevation is usually due to dehydration. If you are hydrating plenty, given this is moving in the wrong direction, it's recommended you discuss with your physician about what may be the cause of this increase. 
        </>
      );
    }

    
    return <>In dehydration, your blood fluid volume can drop, which leads to higher concentrations of substances like {getTestNames(dehydrationTests)}. Given there's no indication of major liver or kidney issues, {dehydrationTests.length > 1 ? `these isolated elevations are` : `this isolated elevation is`} likely due to dehydration. Consistent water intake is essential for hydration and supporting your kidney and liver health.</>

  };

  return (
    dehydrationTests && (
      <div className="dehydration-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">{description}</Paragraph>
        <TestView 
          report={report} 
          tests={dehydrationTests}
          results={results}
          reports={reports}
          user={user}
        />
        <AdviceSection 
          report={report} 
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import annotationPlugin from "chartjs-plugin-annotation";
import "./testChart.scss";
import { useEffect, useState, useRef } from "react";
import { TestModal } from "../../testModal/testModal.component";
import Color from "../../../colors.scss";
import RiskLevel from "../../../enums/riskLevel.enum";
import moment from "moment";
import { Button } from "antd";
import ReadableRiskLevel from "../../../enums/readableRiskLevel.enum";
import ReportHelper from "../../../helpers/report.helper";
import ReportStatus from "../../../enums/reportStatus.enum";

ChartJS.register(
  annotationPlugin,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const riskColor = {
  [RiskLevel.ABNORMAL]: Color.error,
  [RiskLevel.HIGH]: Color.error,
  [RiskLevel.OPTIMAL]: Color.success,
  [RiskLevel.MODERATE]: Color.warning,
};

const riskBackground = {
  [RiskLevel.ABNORMAL]: Color.error_bg_transparent,
  [RiskLevel.HIGH]: Color.error_bg_transparent,
  [RiskLevel.OPTIMAL]: Color.success_bg_transparent,
  [RiskLevel.MODERATE]: Color.warning_bg_transparent,
  // [RiskLevel.PEAK]: Color.success_bg_transparent,

};

export const TestChart = ({
  code,
  report,
  yAxisLabel = true,
  hasReadMore = true,
  tests,
  results,
  reports,
}) => {
  const [test, setTest] = useState();
  const [values, setValues] = useState();
  const [riskLevels, setRiskLevels] = useState();
  const [chartOptions, setChartOptions] = useState();
  const [chartData, setChartData] = useState();
  const [max, setMax] = useState();
  const [tooltipPlugin, setTooltipPlugin] = useState();
  const [openModal, setOpenModal] = useState();

  const testRef = useRef();
  testRef.current = test
  const valuesRef = useRef();
  valuesRef.current = values
  const riskLevelsRef = useRef();
  riskLevelsRef.current = riskLevels

  useEffect(() => {
    setMax(getMax());
  }, [test, values]);

  useEffect(() => {
    setTest(getTest());
  }, [code, tests]);

  useEffect(() => {
    setValues(getValues());
    setRiskLevels(getRiskLevels());
  }, [test, results, report, reports]);

  useEffect(() => {
    setChartOptions(getChartOptions());
    setChartData(getChartData());
  }, [max, values, riskLevels]);

  useEffect(() => {
    setTooltipPlugin(getTooltipPlugin());
  }, [riskLevelsRef?.current, valuesRef?.current, testRef?.current]);

  const getTest = () => {
    if (!tests) return null;
    if (code === "HEALTH_SCORE") {
      return {
        code,
        unit: "points",
        risk: [
          {
            level: RiskLevel.OPTIMAL,
            greaterThanOrEqual: 90,
          },
          {
            level: RiskLevel.MODERATE,
            greaterThanOrEqual: 75,
            lessThan: 90,
          },
          {
            level: RiskLevel.HIGH,
            lessThan: 75,
          },
        ],
      };
    }
    const fetchedTest = tests.find((t) => t.code === code)

    console.log('fetchedTest: ', fetchedTest)

    return fetchedTest;
  };


  const getMax = () => {
    if (!test || !values?.length) return null;

    if (test.code === "HEALTH_SCORE") {
      // Fetch maximum Y value for health score
      return 120;
    } else {
      // Fetch maximum Y value for test
      const maxValue = values.reduce((acc, value) => {
        return value > acc ? value : acc;
      }, 0);

      const { greaterThan, greaterThanOrEqual, lessThan, lessThanOrEqual } =
        test.risk.find(({ level }) => level === RiskLevel.OPTIMAL);
      const minimum = greaterThan || greaterThanOrEqual || 0;
      let maximum = lessThan || lessThanOrEqual || minimum;
      maximum = maxValue > maximum ? maxValue : maximum;
      maximum = maximum * 1.5;
      if (maximum === 0) return 10
      return maximum
    }
  };

  const getValues = () => {
    if (!test || !results?.length || !reports) return null;

    if (test.code === "HEALTH_SCORE") {
      // Pull health score values from reports
      return getHealthScoreResults().map((result) => {
        return reports.find((r) => r.result._id === result._id)?.healthScore;
      });
    } else {
      // Pull test values from results
      const testResults = getTestResults();

      const data = testResults.map((result) => {

        const r = result?.values.find((v) => v.test === test._id)

        if (isEmpty(r)) { return null} 

        let testValue = r.value;
        
        if (typeof testValue === "string" && testValue.includes("<")) {
          testValue = testValue.replace(/</, "");
        }
        else if (typeof testValue === "string" && testValue.includes(">")) {
          testValue = testValue.replace(/>/, "");
        }

        
        if (!isNumeric(testValue)) { return null;}

        if (typeof testValue === "string") {
          testValue = Number(testValue);
        }

        return testValue;
      }).filter(value => value !== null);

      return data;
    }
  };


  const getRiskLevels = () => {
    if (!test || !results?.length || !reports) return null;

    if (test.code === "HEALTH_SCORE") {
      // Pull health score risk from reports
      return getHealthScoreResults().map((result) => {
        const report = reports.find((r) => r.result._id === result._id);
        return ReportHelper.getHealthScoreRisk(report);
      });
    } else {
      // Pull test risk values from results
      const testResults = getTestResults();
      return testResults.map((result) => {
        const r = result?.values.find((v) => v.test === test._id)
        if (!r) { return null;}
        return r.risk;
      });
    }
  };

  const isNumeric = (num) => {
    return num && (typeof num === 'number' ||  !isNaN(num.replace(/</, "")) ||  !isNaN(num.replace(/>/, "")))
  }

  const getTestResults = () => {
    return results
      .filter((result) => {
        return (
          !report ||
          new Date(report.result.collectedAt).getTime() >=
            new Date(result.collectedAt).getTime()
        );
      })
      .sort((a, b) => {
        return (
          new Date(a.collectedAt).getTime() - new Date(b.collectedAt).getTime()
        );
      })
      .filter((result) => {
        return result.values.some((v) => {
          return v.test === test._id && isNumeric(v.value)  
        });
      })
      .map((result) => {
        result.values = result.values.filter((v) => v.value !== null);
        return result;
      });

      
  };


  const getHealthScoreResults = () => {
    return results
      .filter((result) => {
        return result?.report;
      })
      .sort((a, b) => {
        return (
          new Date(a.collectedAt).getTime() - new Date(b.collectedAt).getTime()
        );
      })
      .filter((result) => {
        return reports.find(
          (r) =>
            r.result._id === result._id && r.status === ReportStatus.APPROVED
        )?.healthScore;
      });
  };

  const getRangeMin = (riskData) => {
    const { greaterThanOrEqual, greaterThan } = riskData;
    return greaterThan || greaterThanOrEqual || 0;
  };

  const getRangeMax = (riskData) => {
    const { lessThan, lessThanOrEqual } = riskData;
    return lessThan || lessThanOrEqual || max;
  };

  const getChartAnnotations = () => {
    let annotations = test.risk.map((riskData) => {
      return {
        type: "box",
        yMin: getRangeMin(riskData),
        yMax: getRangeMax(riskData),
        borderColor: "rgba(0,0,0,0)",
        backgroundColor: riskBackground[riskData.level],
      };
    });

    if (riskLevels[0] !== RiskLevel.OPTIMAL) {
      const optimalRange = test.risk.find(
        ({ level }) => level === RiskLevel.OPTIMAL
      );
      const labelAnnotation = {
        type: "label",
        yValue: (getRangeMin(optimalRange) + getRangeMax(optimalRange)) / 2,
        backgroundColor: "rgba(0,0,0,0)",
        color: Color.success,
        content: [`This is ${ReadableRiskLevel[RiskLevel.OPTIMAL]}`],
        font: {
          size: 12,
          family: "Source Sans 3",
        },
      };

      annotations.push(labelAnnotation);
    }

    return annotations;
  };

  const tooltipLabel = (context) => {
    const { dataIndex } = context;
    const value = values[dataIndex];
    const riskLevel = ReadableRiskLevel[riskLevels[dataIndex]];
    return `${riskLevel[0].toUpperCase()}${riskLevel.slice(1)}: ${value} ${
      test.unit
    }`;
  };

  const getChartOptions = () => {
    if (!riskLevels?.length || !values?.length) return null;

    const annotations = getChartAnnotations();

    return {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        annotation: {
          annotations,
        },
        tooltip: {
          filter: function (item) {
            return item.datasetIndex === 0;
          },
          callbacks: {
            label: tooltipLabel,
          },
        },
      },
      scales: {
        y: {
          min: 0,
          max,
          border: {
            dash: [2, 4],
          },
          afterTickToLabelConversion: function (scaleInstance) {
            scaleInstance.ticks[scaleInstance.ticks.length - 1].label = "";
          },
        },
        x: {
          grid: {
            color: "rgba(0,0,0,0)",
          },
          border: {
            color: Color.border,
          },
          ticks: {
            color: Color.secondary_text,
            font: {
              size: 13,
              family: "Source Sans 3",
              lineHeight: 5,
            },
          },
          offset: true,
        },
      },
    };
  };

  const getChartLabels = () => {
    if (test.code === "HEALTH_SCORE") {
      return getHealthScoreResults().map((result) => {
        return moment(result.collectedAt).format("MMM D, YYYY");
      });
    } else {
      return getTestResults().map((result) => {
        return moment(result.collectedAt).format("MMM D, YYYY");
      });
    }
  };

  const getChartData = () => {
    if (!riskLevels || !values || !riskLevels?.length || !values?.length) return null;
    return {
      labels: getChartLabels(),
      datasets: [
        {
          label: "1",
          data: values,
          backgroundColor: riskLevels.map((riskLevel) => riskColor[riskLevel]),
          tension: 0.5,
          pointRadius: 5,
          segment: {
            borderColor: (ctx) => riskColor[riskLevels[ctx.p1DataIndex]],
          },
        },
        {
          label: "2",
          data: values,
          backgroundColor: "white",
          pointRadius: 8,
          tension: 0.5,
          pointBorderColor: riskLevels.map((riskLevel) => riskColor[riskLevel]),
        },
      ],
    };
  };

  const isEmpty = (data) => {
    return data === undefined || data === null;
  };

  const getTooltipPlugin = () => {
    if (isEmpty(valuesRef?.current) || isEmpty(testRef?.current) || isEmpty(riskLevelsRef?.current) ) return null
    
    return {
      id: `tooltip-${testRef?.current?._id}`,
      afterDraw(chart) {
        const { ctx } = chart;
        ctx.save();
        chart.data.datasets.forEach((dataset, i) => {
          chart.getDatasetMeta(i).data.forEach((datapoint, index) => {
            if (index !== valuesRef?.current.length - 1) return;
            const { x, y } = datapoint.tooltipPosition();

            const riskLevel = riskLevelsRef?.current[index];
            const value = valuesRef?.current[index];
            const text = `${ReadableRiskLevel[
              riskLevel
            ][0].toUpperCase()}${ReadableRiskLevel[riskLevel].slice(
              1
            )}: ${value} ${testRef?.current.unit ? testRef?.current.unit : ""}`;
            const width = ctx.measureText(text).width;

            // triangle
            ctx.fillStyle = riskColor[riskLevel];
            ctx.beginPath();
            ctx.moveTo(x, y - 10);
            ctx.lineTo(x - 5, y - 15);
            ctx.lineTo(x + 5, y - 15);
            ctx.fill();
            ctx.restore();

            // rectangle
            ctx.fillStyle = riskColor[riskLevel];
            ctx.roundRect(x - (width + 18) / 2, y - 50, width + 18, 35, 3);
            ctx.fill();
            ctx.restore();

            // text
            ctx.font = "12px Source Sans 3";
            ctx.fillStyle = "white";
            ctx.fillText(text, x - width / 2, y - 27);
            ctx.restore();
          });
        });
      },
    };
  };

  return (
    test &&
    chartData &&
    chartOptions &&
    tooltipPlugin && (
      <div className="test-chart">
        <TestModal
          open={openModal}
          setOpen={setOpenModal}
          testId={test._id}
          report={report}
          tests={tests}
          results={results}
          reports={reports}
        />

        <div className="chart-position">
          <Line
            options={chartOptions}
            data={chartData}
            plugins={[tooltipPlugin]}
            height={250}
            className="scatter-chart"
          />

          {yAxisLabel && (
            <div className="test-name-container">
              <div className="test-name-label">
                {test.name} {test.unit && <span>({test.unit})</span>}
              </div>
            </div>
          )}

          {hasReadMore && (
            <Button
              onClick={() => setOpenModal(true)}
              size="small"
              className="read-more-btn"
            >
              Read More
            </Button>
          )}
        </div>
      </div>
    )
  );
};

import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Form, Input, Button, message } from 'antd'
import { login } from '../../services/auth.service.js'
import './login.scss'
import { ForgotPasswordModal } from '../forgotPasswordModal/forgotPasswordModal.component.js';
import { UserContext } from '../../contexts/user.context.js';
import StorageKey from '../../enums/storageKey.enum.js';

const { Item } = Form

export const Login = () => {
  const [form] = Form.useForm()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState()
  const [openModal, setOpenModal] = useState()
  const { setToken, token } = useContext(UserContext)

  useEffect(() => {
    document.title = 'Instalab | Sign in'
    document.description = 'Sign in or create a new account.'
  }, [])

  useEffect(() => {
    if (token) {
      const redirect = searchParams.get('redirect')
      navigate(redirect || '/')
    }
  }, [token])

  const onFinish = async () => {
    setIsLoading(true)
    try {
      const response = await login(form.getFieldsValue())
      localStorage.setItem(StorageKey.TOKEN, response.token)
      setToken(response.token)
      message.info('Logged in')
    } catch (err) {
      message.error(err.response?.data?.message || 'Failed to log in')
    }
    setIsLoading(false)
  };

  return <div className="login">
    <ForgotPasswordModal
      open={openModal}
      setOpen={setOpenModal}
    />

    <Form
      form={form}
      onFinish={onFinish}
      layout='vertical'
      className="login-form"
    >
      <Item 
        label="Email Address"
        name="email"
        onInput={e => e.target.value = e.target.value.toLowerCase()}
        rules={[{ 
          required: true, 
          message: 'Enter email address'
        }]}
      >
        <Input placeholder="Email Address" />
      </Item>

      <Item 
        label="Password"
        name="password"
        rules={[{ 
          required: true, 
          message: 'Enter password'
        }]}
      >
        <Input placeholder="Password" type="password" />
      </Item>

      <Item>
        <Button 
          htmlType='submit'
          type="primary" 
          loading={isLoading}
        >
          Log in
        </Button>
      </Item>

      <div className="reset-password">
        <a onClick={() => setOpenModal(true)}>Need help logging in?</a>
      </div>
    </Form>
  </div>
}
import { Button, Form, Skeleton, message } from "antd"
import "./imageUpload.scss"
import { WarningFilled } from '@ant-design/icons'
import { FlowStepFooter } from "../../flowStep/flowStepFooter.component"
import { useState, useEffect, useContext } from "react"
import { PickerDropPane } from 'filestack-react';
import { UserContext } from "../../../../contexts/user.context"

const { Item } = Form

export const ImageUpload = ({
  step,
  onSubmit,
  form,
  isSubmitting,
}) => {
  const { user } = useContext(UserContext)
  const [image, setImage] = useState(null)
  const [renderPicker, setRenderPicker] = useState()
  const [isImageLoaded, setIsImageLoaded] = useState()

  useEffect(() => {
    const timer = setTimeout(() => {
      setRenderPicker(true)
    }, 100)

    return () => clearTimeout(timer)
}, [])

  useEffect(() => {
    fetchImage()
  }, [user])

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onFormSubmit()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  const fetchImage = () => {
    if (!user) return
    if (user[step.field.name]) {
      setImage(user[step.field.name])
    } else {
      setImage(null)
    }
  }

  const onFormSubmit = async () => {
    onSubmit({ [step.field.name]: form.getFieldsValue()[step.field.name] })
  }

  const onRemoveImage = async () => {
    form.setFieldsValue({ [step.field.name]: null })
    onSubmit({ [step.field.name]: null }, false, true)
    message.info('Image removed')
  }

  return (
    <div className="image-upload">
      <Form
        form={form}
        className="image-upload-form"
        layout="vertical"
      >

        {image ? (
          <div className="uploaded-img-container">
            {!isImageLoaded && (
              <Skeleton.Avatar 
                active
                shape='square' 
                className="loading-skeleton"
              />
            )}
            <div className="image-container">
              <img 
                src={`${image}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`} 
                onLoad={() => setIsImageLoaded(true)}
              />
            </div>
            <Button
              className="remove-image-btn"
              onClick={onRemoveImage}
            >
              Remove
            </Button>
          </div>
        ) : <>
          <div id='picker-container' className="picker-container">
            <Skeleton.Avatar 
              active
              shape='square' 
              className="loading-skeleton"
            />
            {renderPicker && <>
              <PickerDropPane 
                apikey={process.env.REACT_APP_FILESTACK_API_KEY}
                onUploadDone={({ filesUploaded, filesFailed }) => {
                  if (filesUploaded?.length) {
                    const { url } = filesUploaded[0]
                    form.setFieldsValue({ [step.field.name]: url })
                    setImage(url)
                  }
                  if (filesFailed?.length) {

                  }
                }}
                onError={(error) => {
                  console.log('error', error)
                }}
                pickerOptions={{
                  container: 'picker-container',
                  accept: [
                    'image/jpg',
                    'image/jpeg',
                    'image/png',
                    'image/webp',
                  ],
                  maxFiles: 1,
                }}
                clientOptions={{
                  security: {
                    policy: process.env.REACT_APP_FILESTACK_POLICY,
                    signature: process.env.REACT_APP_FILESTACK_SIGNATURE
                  }
                }}
              />
            </>}
          </div>
        </>}

        <Item 
          name={step.field.name}
          className="image-upload-item"
          validateTrigger='onSubmit'
          rules={[{ 
            required: true, 
            message: <><WarningFilled />&nbsp; Please upload image</>,
          }]}
        >
        </Item>

        <FlowStepFooter 
          onSubmit={onFormSubmit} 
          isSubmitting={isSubmitting}
        />
      </Form>
    </div>
  )
}
import TestCode from "../enums/testCode.enum";

export const cholesterolTherapyPanel = {
  Lipoproteins: [
    TestCode.APO_B, 
  ],
  Lipids: [
    TestCode.TC,
    TestCode.DIRECT_LDL,
    TestCode.HDL,
    TestCode.VLDL,
    TestCode.TG,
  ],
  Metabolic: [
    TestCode.HBA1C
  ],
  Liver: [
    TestCode.ALT
  ],
};
const CollectionMethod = Object.freeze({
    BLOOD: "blood",
    URINE: "urine",
    CT_SCAN: "ct",
    MRI_SCAN: "mri",
    FDA_DEVICE: 'fda device',
    SHOT: "shot"
})

export default CollectionMethod


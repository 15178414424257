import { useState } from "react";
import { Typography } from 'antd';

import "./xPanel.scss";

const { Paragraph } = Typography;

export const XPanel = ({panels}) => {
  const [showAll, setShowAll] = useState(false)

  return panels?.length > 0 && (
    <div className="product-details-x-panels">
      {panels.slice(0, showAll ? panels.length : 4).map(({ title, text, image }) => (
          <div className="product-details-x-panel">
            <div className="panel-img-container">                
                <img className="panel-img" src={image}/>
            </div>

            {title && <Paragraph className="panel-title">{title}</Paragraph>}
            <Paragraph className="panel-description">{text}</Paragraph>
          </div>
      ))}
    </div>
  );
};

import { Row, Col, Typography } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import "./membershipPoints.scss";

const { Paragraph } = Typography

export const MembershipPoints = () => {
  const points = [
    {
      title: `Exclusive savings on all services`,
      description: <>Includes 10% or more off on all testing.</>
    }, 
    {
      title: `Access to longevity physicians`,
      description: `Receive expert guidance and personalized advice from longevity specialists through convenient online visits.`
    }, 
    
  {
    title: `Dedicated care concierge`,
    description: `Personalized assistance for managing your health, including finding specialists, scheduling appointments and more.`,
  }, 
  {
    title: `Pause or cancel anytime`,
    description: `We make it really easy to pause or cancel your membership.`
  },

  ]

  return (
    <div className="membership-points">
      <div className="point-list">
        <Row gutter={10}>
          {points.map((point, index) => (
            <div className="point-item">
              <Row>
                <Col className="point-col">
                  <CheckCircleOutlined className="point-icon" />
                </Col>
                <Col className="text-col">
                  <Paragraph className="point-title">
                    {point.title}
                  </Paragraph>
                  <Paragraph className="point-description">
                    {point.description}
                  </Paragraph>
                </Col>
              </Row>
            </div>
          ))}
        </Row>
      </div>
    </div>
  )
}
const ResultHelper = {
  getProgressData: (result) => {
    if (!result) return
    const { tests, values } = result
    if (!tests?.length) return
    const targetTests = tests.filter(({ isCalculated }) => !isCalculated)
    const completeTestIds = values.filter(({ value }) => value).map(({ test }) => test?._id || test)
    const incompleteTests = targetTests.filter(test => !completeTestIds.includes(test?._id || test))
    const percentageComplete = Math.round((1 - incompleteTests?.length / targetTests?.length) * 100)

    return {
      incompleteTests,
      percentageComplete,
    }
  }
}

export default ResultHelper
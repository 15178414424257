import React, { useEffect, useState } from 'react';
import { Table, Button, Dropdown, message } from 'antd'
import { listAuthors, removeAuthor } from "../../services/author.service"
import { SettingOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import './authors.scss';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { AuthorForm } from '../authorForm/authorForm.component';

const { Column } = Table

export const Authors = () => {
  const [authors, setAuthors] = useState([])
  const [authorId, setAuthorId] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isEditModalOpen, setIsEditModalOpen] = useState()

  useEffect(() => {
    document.title = 'Instalab | Authors'
    fetchAuthors()
  }, [])

  const fetchAuthors = async () => {
    setAuthors(await listAuthors())
    setIsLoading(false)
  }

  const onRemove = async (_id) => {
    try {
      await removeAuthor(_id)
      setAuthors(authors.filter(author => author._id !== _id))
      message.info('Author removed')
    } catch (err) {
      message.error('Failed to remove author')
    }
  }

  const items = (_id) => [{
    key: 'edit',
    label: (
      <a onClick={() => {
        setAuthorId(_id)
        setIsEditModalOpen(true)
      }}>
        <EditOutlined /> Edit
      </a>
    )
  }, {
    type: 'divider'
  }, {
    key: 'remove',
    label: (
      <a 
        onClick={() => onRemove(_id)}
        className="error"
      >
        <DeleteOutlined /> Remove
      </a>
    )
  }]

  return (
    <div className="authors">
      <PageHeader
        title='Author'
        count={authors.length}
        actions={(
          <Button
            type='primary'
            onClick={() => {
              setAuthorId(null)
              setIsEditModalOpen(true)
            }}
          >
            + Add New Author
          </Button>
        )}
      />

      <AuthorForm
        authorId={authorId}
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        onSuccess={author => {
          if (authorId) {
            setAuthors(authors.map(p => p._id === authorId ? author : p))
          } else {
            setAuthors([
              author,
              ...authors
            ])
          }
        }}
      />

      <Table
        loading={isLoading}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={authors}
      >
        <Column
          title='Name'
          dataIndex='name'
        />

        <Column
          title='Title'
          dataIndex='title'
        />

        <Column
          title='Twitter'
          dataIndex='twitter'
        />
        
        <Column
          dataIndex='remove'
          width={50}
          className="action-column"
          render={(_, { _id }) => (
            <Dropdown 
              menu={{ items: items(_id) }}
              placement='bottomRight'
            >
              <Button>
                <SettingOutlined />
              </Button>
            </Dropdown>
          )}
        />
      </Table>
    </div>
  )
}
import React, { useContext, useState } from "react";
import { LockOutlined, CheckOutlined, UnlockOutlined, StopOutlined } from '@ant-design/icons'
import "./tableOfContents.scss";
import { Affix, Anchor, Button, Typography, Tooltip, message } from "antd";
import ReportSection from "../../../enums/reportSection.enum";
import Role from "../../../enums/role.enum";
import { UserContext } from "../../../contexts/user.context";
import ReportStatus from "../../../enums/reportStatus.enum";
import { updateReport } from "../../../services/report.service";

const { Text } = Typography

export const TableOfContents = ({ sections, sectionMeta, report, onReportChange }) => {
  const { currentUser } = useContext(UserContext)
  const [isApproving, setIsApproving] = useState()
  const [isUnapproving, setIsUnapproving] = useState()
  const [isLocking, setIsLocking] = useState()
  const [isUnlocking, setIsUnlocking] = useState()

  const title = {
    [ReportSection.HEALTH_SCORE]: "Introduction",
    [ReportSection.SMOKING_ADVICE]: "Nicotine",
    [ReportSection.VITAMIN_B9_ADVICE]: "Vitamin B9",
    [ReportSection.VITAMIN_B12_ADVICE]: "Vitamin B12",
    [ReportSection.ANEMIA_ADVICE]: "Anemia",
    [ReportSection.TESTOSTERONE_ADVICE]: "Testosterone",
    [ReportSection.PREGNANT_ADVICE]: "Pregnancy",
    [ReportSection.METABOLIC_ADVICE]: "Metabolic Health",
    [ReportSection.CARDIO_ADVICE]: "Cardio Health",
    [ReportSection.GENETIC_CARDIO_ADVICE]: "Cardiogenetics",
    [ReportSection.HYPERTHYROIDISM_ADVICE]: "Thyroid Health",
    [ReportSection.HYPOTHYROIDISM_ADVICE]: "Thyroid Health",
    [ReportSection.BP_ADVICE]: "Blood Pressure",
    [ReportSection.GILBERTS_SYNDROME]: "Liver Health",
    [ReportSection.HYPOTENSION_ADVICE]: "Blood Pressure",
    [ReportSection.VITAMIN_D_ADVICE]: "Vitamin D",
    [ReportSection.LOW_FERRITIN_ADVICE]: "Iron Deficiency",
    [ReportSection.HOMOCYSTEINE_ADVICE]: "Homocysteine",
    [ReportSection.DEHYDRATED_ADVICE]: "Dehydration",
    [ReportSection.GENERAL_EXERCISE_ADVICE]: "Exercise",
    [ReportSection.FERRITIN_CHECK]: "Iron",
    [ReportSection.SHBG_CHECK]: "Hormones",
    [ReportSection.MULTIPLE_ABNORMAL_TESTS]: "Other Tests",
    [ReportSection.SINGLE_ABNORMAL_TEST]: "Other Tests",
    [ReportSection.FIXED_TESTS]: "Fixed Tests",
    [ReportSection.NEXT_STEPS]: "Next Steps",
    [ReportSection.LOW_RHR_ADVICE]: "Heart Rate",
    [ReportSection.URIC_ACID_ADVICE]: "Uric Acid",
    [ReportSection.LIVER_ADVICE]: "Liver Health",
    [ReportSection.STRENGTH_ADVICE]: "Strength",
    [ReportSection.CREATINE_IMPACT]: "Creatine Impact",
    [ReportSection.ISOLATED_FREE_T4_ADVICE]: "Thyroid Health",
    [ReportSection.INFLAMMATION_CHECK]: "Inflammation",
    [ReportSection.HORMONE_CHECK]: "Hormones",
    [ReportSection.HORMONE_CHECK_FEMALE]: "Hormones",
    [ReportSection.RHABDOMYOLYSIS_ADVICE]: "Injury",
    [ReportSection.MACROCYTOSIS_ADVICE]: "Macrocytosis",
    [ReportSection.ISOLATED_TSH_ADVICE]: "Thyroid Health",
    [ReportSection.VITAMIN_B_ADVICE]: "Vitamin Bs",
    [ReportSection.VIRAL_INFECTION_ADVICE]: "Viral Infection",
    [ReportSection.HRT_IMPACT]: "Testosterone",
    [ReportSection.METABOLIC_SYNDROME_ADVICE]: "Metabolic Syndrome",
    [ReportSection.SEVERE_TG_ADVICE]: "Hypertriglyceridemia",
    [ReportSection.CKD_ADVICE]: "Kidney Health",
    [ReportSection.LOW_WBC_COUNT]: "Immune Health",
    [ReportSection.HYPONATREMIA_ADVICE]: "Hyponatremia",
    [ReportSection.PROSTATE_ADVICE]: "Prostate",
    [ReportSection.PLATELET_ADVICE]: 'Platelet Count'
  };

  const onLock = async () => {
    setIsLocking(true)

    try {
      const lockedSections = report.sections.map(section => {
        if (sectionMeta[section.code]) {
          section.meta = sectionMeta[section.code]
        }
        return section
      })

      await updateReport(report._id, {
        fields: {
          sections: lockedSections,
          lockedAt: new Date()
        },
      })
      message.info('Report locked')
      onReportChange()
    } catch (err) {
      message.error('Failed to lock report')
    }

    setIsLocking(false)
  }

  const onUnlock = async () => {
    setIsUnlocking(true)

    try {
      await updateReport(report._id, {
        fields: {
          lockedAt: null
        }
      })
      message.info('Report unlocked')
      onReportChange()
    } catch (err) {
      message.error('Failed to unlock report')
    }

    setIsUnlocking(false)
  }

  const onApprove = async () => {
    setIsApproving(true);
    try {
      let fields = {}
      if (currentUser?.role === Role.PROVIDER) {
        fields.providerStatus = ReportStatus.APPROVED
      } else {
        fields.status = ReportStatus.APPROVED
      }
      await updateReport(report._id, {
        fields,
      })
      message.info('Report approved')
      onReportChange()
    } catch (err) {
      message.error('Failed to approve')
    }
    setIsApproving(false);
  }

  const onUnapprove = async () => {
    setIsUnapproving(true);
    try {
      let fields = {}
      if (currentUser?.role == Role.PROVIDER) {
        fields.providerStatus = ReportStatus.PENDING
      } else {
        fields.status = ReportStatus.PENDING
      }
      
      await updateReport(report._id, {
        fields,
      })
      message.info('Removed report approval')
      onReportChange()
    } catch (err) {
      message.error('Failed to remove report approval')
    }
    setIsUnapproving(false);
  }

  return (
    <Affix className="table-of-contents">
      <div>
        <Text className="toc-title">
          Table of Contents
        </Text>
        <Anchor
          items={sections?.map((section) => {
            return {
              key: section.code,
              href: `#${section.code}`,
              title: title[section.code],
            };
          })}
        />

        {(currentUser?.role === Role.PROVIDER || currentUser?.role === Role.ADMIN) && (
          <div className="approve-container">
            {report[currentUser.role === Role.PROVIDER ? 'providerStatus' : 'status'] === ReportStatus.APPROVED ? (
              <div className="approved-status">
                <CheckOutlined className="check-icon" /> Approved
                <Tooltip title="Remove approval">
                  <Button
                    loading={isUnapproving}
                    onClick={onUnapprove}
                    icon={<StopOutlined />}
                    className="unapprove-btn"
                  />
                </Tooltip>
              </div>
            ) : (
              <Button
                loading={isApproving}
                onClick={onApprove}
                icon={<CheckOutlined />}
                className="approve-btn"
                type='primary'
              >
                Approve Report
              </Button>
            )}
          </div>
        )}

        {currentUser?.role === Role.ADMIN && <>
          <div className="lock-container">
            {report.lockedAt ? (
              <div className="locked-status">
                <CheckOutlined className="check-icon" /> Locked Report
                <Tooltip title="Unlock">
                  <Button
                    loading={isUnlocking}
                    onClick={onUnlock}
                    icon={<UnlockOutlined />}
                    className="unlock-btn"
                  />
                </Tooltip>
              </div>
            ) : (
              <Button
                loading={isLocking}
                onClick={onLock}
                icon={<LockOutlined />}
                className="lock-btn"
              >
                Lock Report
              </Button>
            )}
          </div>
        </>}
      </div>
    </Affix>
  );
};

import React, { useEffect, useRef, useState, useCallback, useContext  } from "react";
import { Button, Tag } from "antd";
import ImageGallery from "react-image-gallery";
import "./productHero.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import { useNavigate } from "react-router-dom";
import { ProductQuestions } from "../productQuestions/productQuestions.component";
import { debounce } from 'lodash';
import { UserContext } from '../../../contexts/user.context';

export const ProductHero = ({product, isMobileOrTablet}) => {
    const { longevityMembership } = useContext(UserContext);

    const navigate = useNavigate()
    const ctaRef = useRef(null);  // Ref for the CTA button
    const [isButtonFixed, setIsButtonFixed] = useState(false);

    const handleScroll = useCallback(debounce(() => {
        if (ctaRef.current) {
            const ctaPosition = ctaRef.current.getBoundingClientRect();
            const shouldBeFixed = window.scrollY > ctaPosition.bottom + 10; // Adding a buffer of 10px to reduce sensitivity
            setIsButtonFixed(shouldBeFixed);
        }
    }, 100), []); // Debounce scroll handling by 100 milliseconds

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
            handleScroll.cancel(); // Cancel the debounce on unmount to prevent delayed execution
        };
    }, [handleScroll]);
  
    return (   
        <div className="product-detail-hero-container">
                      
            <div className="product-photos-desktop">
                {product.photos.length === 1 ? <img className="product-photos-solo" src={product.photos[0].original}/> :
                <ImageGallery 
                    items={product.photos}
                    showThumbnails={!isMobileOrTablet}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={isMobileOrTablet}
                    thumbnailClass="product-photos-thumbnail"
                    showNav={false}
                    slideOnThumbnailOver={true}
                />}
            </div>
            <div className="product-description">
                <h1 className="product-title">{product.title}</h1>
                <div className="product-price">
                    {longevityMembership ? <>
                    <span className="product-original-price">${product.originalPrice}</span>{" "}
                    <span className="product-member-price">{product.memberPrice===0 ? "FREE" : <>${product.memberPrice}</>}</span>{" "}
                    <Tag className="member-tag">member price</Tag></>
                    : <>${product.originalPrice}</>
                }
                </div>
                <p className="product-oneliner">{product.oneliner}</p>
                <div className="product-points">
                    {product.points.map(point => <div className="product-point">{point}</div>)}
                </div>

                <div className="product-photos-mobile">
                <ImageGallery 
                    items={product.photos}
                    showThumbnails={!isMobileOrTablet}
                    showFullscreenButton={false}
                    showPlayButton={false}
                    showBullets={isMobileOrTablet}
                    thumbnailClass="product-photos-thumbnail"
                    showNav={false}
                />
                </div>

                <div ref={ctaRef} className={`product-cta ${isButtonFixed ? "fixed-cta" : ""}`}>
                    <Button 
                        className="product-cta-button" 
                        size="large" 
                        type="primary" 
                        onClick={() => {
                            if (product.cta.url) {
                                navigate(product.cta.url)
                            }
                            if (product.cta.onClick) {
                                product.cta.onClick()
                            }
                        }}
                    >
                        {product.cta.text}
                    </Button>
                </div>

                {product.faqs && 
                <ul className="product-top-faq">
                    <ProductQuestions questions={product.faqs.slice(0,3)}/>
                </ul>}
            </div>    
        </div>
        
    )
}
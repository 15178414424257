import { useEffect, useState } from "react"
import { Row, Col, Button, Tag, Checkbox, Modal } from "antd"
import MedicalCondition from "../../../../enums/medicalCondition.enum"
import ProductTypeCode from "../../../../enums/productTypeCode.enum"
import './quizRecommendation.scss'
import PatientHelper from "../../../../helpers/patient.helper"
import { listProductTypes } from "../../../../services/productType.service"
import SleepQuality from "../../../../enums/sleepQuality.enum"
import RecommendedTest from "../../../../enums/recommendedTest.enum"
import {InfoCircleOutlined } from '@ant-design/icons'

export const QuizRecommendation = ({ flow }) => {
  const [recommendations, setRecommendations] = useState()
  const [productTypes, setProductTypes] = useState()
  const [checkedItems, setCheckedItems] = useState({}); // State to manage checkbox status

  useEffect(() => {
    const fetchProductTypes = async () => {
      const productTypes = await listProductTypes();
      setProductTypes(productTypes);
    }
  
    fetchProductTypes();
  }, []);

  const getProductType = (code) => {
    if (!productTypes) return;
    return productTypes?.find(productType => productType.code === code)
  }

  const cardiacConditions = [
    MedicalCondition.HEART_DISEASE,
    MedicalCondition.HEART_ATTACK,
    MedicalCondition.STROKE,
    MedicalCondition.BLOCKED_ARTERY,
    MedicalCondition.PERIPHERAL_ARTERY_DISEASE,
  ];

  useEffect(() => {
    if (!flow?.user || !productTypes) return

    const getRecommendations = () => {
      const { familyHistory, currentMedicalConditions, hasSmoking, possibleToxicExposure, possibleMoldExposure, sleepQuality } = flow.user;

      const age = PatientHelper.getAge(flow.user)
      const isFemale = PatientHelper.isFemale(flow.user)
      const hasCardiacFamilyHistory = familyHistory?.some(condition => cardiacConditions.includes(condition));
  
      let recs = [RecommendedTest.COMPREHENSIVE_BLOOD_PANEL]
  
      if (age> 30 && hasCardiacFamilyHistory) recs.push(RecommendedTest.CCTA)
      else if (age >= 40) recs.push(RecommendedTest.CAC)
      
      if (familyHistory?.includes(MedicalCondition.CANCER) || currentMedicalConditions?.includes(MedicalCondition.CANCER) || age>50) {
        recs.push(RecommendedTest.GALLERI);
      }

      if (familyHistory?.includes(MedicalCondition.CANCER_COLORECTAL) || age >= 40) {
        recs.push(RecommendedTest.FIT_STOOL)
      }

      if (hasSmoking && age >=40) {
        recs.push(RecommendedTest.LOW_DOSE_SCAN)
      }

      if (sleepQuality === SleepQuality.POOR) {
        recs.push(RecommendedTest.SLEEP_APNEA_TEST)
      }

      if (possibleToxicExposure) {
        recs.push(RecommendedTest.TOXIC_METALS)
      }

      recs.push(
        RecommendedTest.BP, 
        RecommendedTest.DEXA_BONE,
        RecommendedTest.DEXA_BODY_COMP,
        RecommendedTest.MAMMOGRAM,
        RecommendedTest.PAP_SMEAR,
        RecommendedTest.HPV,
        RecommendedTest.COLONOSCOPY,
        RecommendedTest.SKIN_CANCER,
        RecommendedTest.GUM, 
        RecommendedTest.GLAUCOMA,
        RecommendedTest.PROSTATE_PHYSICAL,
        // RecommendedTest.VO2MAX,
        
      )
  
        return recs
        .map((rec) => RecommendedTestData.find((test) => test.code === rec))
        .filter((test) => 
          test && 
          (!test.startAge || test.startAge <= age) && 
          (!test.endAge || age <= test.endAge) &&
          (!test.femaleOnly || isFemale)
        )
        .map((test) => {
          if (test.instalabProductTypeCode) {
            return {
              ...test,
              instalabProductType: getProductType(test.instalabProductTypeCode)
            };
          }
          return test;
        });

    };
  
    setRecommendations(getRecommendations());
  }, [flow, productTypes]);
  
    // Inside your component
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({ title: "", description: "", frequency: "" });

    const showModal = (title, description, frequency) => {
      setModalContent({ title, description, frequency });
      setIsModalVisible(true);
    };

    const handleOk = () => {
      setIsModalVisible(false);
    };

    const handleCancel = () => {
      setIsModalVisible(false);
    };

  const RecommendedTestData = [
    {
      code: RecommendedTest.COMPREHENSIVE_BLOOD_PANEL,
      title: "Comprehensive Blood Panel",
      reason: "Monitor overall health risks by assessing a comprehensive range of blood biomarkers, including advanced lipid profiles, metabolic markers, kidney and liver function, thyroid and sex hormones, essential nutrients, and markers of inflammation.",
      frequency: "Twice per year",
      instalabProductTypeCode: ProductTypeCode.LONGEVITY_PANEL,
      price: 299,
    },
    {
      code: RecommendedTest.BP,
      title: "Blood Pressure",
      reason: "This test measures the force exerted by circulating blood on the walls of your blood vessels as your heart contracts and relaxes. Elevated blood pressure is a significant risk factor for heart attacks, strokes, kidney disease and other complications. Blood pressure is often referred to as the “silent killer” because it can be remarkably high or low without causing any noticeable symptoms.",
      frequency: "Annually, and monitor frequently if above 120/80",
      instalabProductTypeCode: ProductTypeCode.BIOBEAT,
    },
    {
      code: RecommendedTest.GUM,
      title: "Gum / Dental Checkup",
      reason: "Maintain good oral hygiene and address gum issues early to prevent serious conditions such as periodontitis, which can lead to tooth loss, increased cardiovascular disease risk, diabetes complications, and more.",
      frequency: "Annually",
    },
    {
      code: RecommendedTest.SKIN_CANCER,
      title: "Skin Cancer Exam",
      reason: "Detect early signs of skin cancer, such as melanoma, basal cell carcinoma, or squamous cell carcinoma, increasing the chances of successful treatment.",
      frequency: "Annually",
      startAge: 21
    },
    {
      code: RecommendedTest.PAP_SMEAR,
      title: "Pap Smear",
      reason: "Detect precancerous or cancerous cells in the cervix early, preventing the development of cervical cancer.",
      frequency: "Every 3 years",
      startAge: 21,
      endAge: 30,
      femaleOnly: true
    },
    {
      code: RecommendedTest.HPV,
      title: "Pap Smear + HPV test",
      reason: "Detect high-risk types of human papillomavirus and precancerous or cancerous cells in the cervix early, preventing the development of cervical cancer.",
      frequency: "Every 5 years",
      startAge: 30,
      endAge: 65,
      femaleOnly: true
    },

    {
      code: RecommendedTest.DEXA_BODY_COMP,
      title: "DEXA Body Composition",
      reason: "This scan provides insights into your body composition, detailing fat mass, lean mass, and fat distribution. Muscle is pivotal to longevity and overall quality of life. Detecting early muscle loss is essential in achieving optimal health.",
      frequency: "Annually",
      startAge: 30,
      instalabProductTypeCode: ProductTypeCode.DEXA_BONE
    },
    {
      code: RecommendedTest.VO2MAX,
      title: "VO2Max Test",
      reason: "Assess cardiovascular fitness and detecting potential issues such as cardiovascular disease and respiratory problems early.",
      frequency: "Annually",
      startAge: 30,
    },
    {
      code: RecommendedTest.GALLERI,
      title: "Early Multi-Cancer Detection Test",
      reason: "Detect cancer in its early stages with our multi-cancer early detection blood test. This simple test screens for over 50 types of cancer—over 45 of which lack regular screening tests today. This multi-cancer early detection test is effective in finding cancers at early stages, enabling doctors to treat before cancer spreads or causes harm.",
      frequency: "Annually",
      instalabProductType: getProductType(ProductTypeCode.GALLERI),
      startAge: 40
    },
    {
      code: RecommendedTest.CAC,
      title: "Coronary Calcium Scan",
      reason: "Assess the risk of coronary artery disease by detecting calcified plaque in the arteries, helping to prevent heart attacks and stroke.",
      frequency: "Every 3-5 years depending on other cardiovascular risks",
      instalabProductType: getProductType(ProductTypeCode.CAC),
      startAge: 40,
      endAge: 70
    },
    {
      code: RecommendedTest.CCTA,
      title: "Coronary CT Angiogram",
      reason: "Coronary Computed Tomography Angiogram (CCTA) detects early warning signs of possible heart attacks and strokes. By accurately identifying the presence of soft plaque — a less dense and more vulnerable type of arterial buildup that is more likely to rupture and cause blockages — we can offer a precise assessment of your current heart disease risk. This enables early intervention and timely preventative measures reducing the likelihood of life-threatening events. ",
      frequency: "Every 3-5 years depending on other cardiovascular risks",
      instalabProductType: getProductType(ProductTypeCode.CLEERLY),
      startAge: 30,
      endAge: 70
    },
    {
      code: RecommendedTest.FIT_STOOL,
      title: "FIT Stool Test",
      reason: "Standard screening for colorectal cancer by detecting hidden blood in the stool, allowing for early detection and treatment.",
      frequency: "Annually",
      instalabProductTypeCode: ProductTypeCode.FIT_STOOL,
      endAge: 45,
    },
    {
      code: RecommendedTest.GLAUCOMA,
      title: "Glaucoma / Eye checkup",
      reason: "Detect glaucoma and other eye conditions early, preventing severe complications such as vision loss and blindness.",
      frequency: "Annually",
      startAge: 40
    },
    {
      code: RecommendedTest.DEXA_BONE,
      title: "DEXA Bone Density",
      reason: "This specialized technique offers detailed images of your bones, specifically your spine and hips. By measuring the density of your bones your risk of fractures can be assessed and a plan put in place to enhance your bone health to prevent fractures as you age.",
      frequency: "Every 5 years if normal results",
      startAge: 40,
      instalabProductTypeCode: ProductTypeCode.DEXA_BONE
    },
    {
      code: RecommendedTest.MAMMOGRAM,
      title: "Mammogram / Ultrasound",
      reason: "Standard screening to detect breast cancer early, improving treatment outcomes and increasing survival rates through timely intervention.",
      frequency: "Annually (40-54), Every 2 years (55+)",
      startAge: 40,
      femaleOnly: true,
    },
    {
      code: RecommendedTest.COLONOSCOPY,
      title: "Colonoscopy",
      reason: "Standard screening to detect colorectal cancer or polyps early, allowing for timely intervention and removal of potentially cancerous growths.",
      frequency: "Every 3 years, but depends on the findings",
      startAge: 45
    },
    {
      code: RecommendedTest.PROSTATE_PHYSICAL,
      title: "Prostate Physical Exam",
      reason: "Identify prostate abnormalities early, aiding in the detection and treatment of prostate cancer and other prostate-related issues.",
      frequency: "Annually",
      startAge: 50
    },
    {
      code: RecommendedTest.LOW_DOSE_SCAN,
      title: "Low-dose CT Scan",
      reason: "Screen for lung cancer or other abnormalities with reduced radiation exposure, aiding in early detection and diagnosis.",
      frequency: "Annually for high-risk individuals, every 2-3 years for others",
      startAge: 40
    },
    {
      code: RecommendedTest.TOXIC_METALS,
      title: "Toxic Metals Test",
      reason: "Detect potential toxic exposures that can cause serious health issues, including neurological, gastrointestinal, and cardiovascular problems.",
      frequency: "Annually if possible ongoing exposure",
      instalabProductTypeCode: ProductTypeCode.TOXIC_METALS_URINE_TEST
    },
    {
      code: RecommendedTest.MOLD_TEST,
      title: "Mold Toxicity Test",
      reason: "Detect potential mold toxicity exposure that can cause serious health issues, including chronic fatigue, neurological, skin and respiratory problems.",
      frequency: "Annually if possible ongoing exposure",
      instalabProductType: getProductType(ProductTypeCode.MYCOTOX_PROFILE)
    },
    {
      code: RecommendedTest.SLEEP_APNEA_TEST,
      title: "Sleep Apnea Test",
      reason: "Determine whether sleep apnea is causing your poor sleep.",
      frequency: "Once",
      instalabProductType: getProductType(ProductTypeCode.WATCHPAT_ONE)
    }


  ]

  const handleCheckboxChange = (index) => {
    setCheckedItems({
      ...checkedItems,
      [index]: !checkedItems[index],
    });
  };

  const onIntroCall = () => {
    window.Calendly.initPopupWidget({ url: 'https://calendly.com/d/cmhv-xmp-7h4/health-advisor-consult?hide_gdpr_banner=1' });
  }

  return productTypes && recommendations && (
    <div className="quiz-recommendation">

      

      <Modal title={modalContent.title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
        <p>{modalContent.description}</p>
        <p>Frequency: {modalContent.frequency}</p>
      </Modal>
      <div className="recommendation-header">
        Your Personal Testing Roadmap

        <div className="recommendation-subheader">
        Below are tests you should do in the next year.
      </div>
      </div>


        
      <div className="recommendation-caption">
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 19 }}
            md={{ span: 19 }}
            lg={{ span: 20 }}
            xl={{ span: 20 }}
            xxl={{ span: 20 }}
          >
            Have any questions?<br/><span>Schedule a call with with one of Instalab's testing specialists.</span>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 5 }}
            md={{ span: 5 }}
            lg={{ span: 4 }}
            xl={{ span: 4 }}
            xxl={{ span: 4 }}
          >
            <Button type="primary" onClick={onIntroCall} className="schedule-call-button">
              Schedule Call
            </Button>
          </Col>
        </Row>
      </div>

      <div className="recommendation-table">
      {recommendations.map((recommendation, index) => (
        <>
          <Row
            gutter={24}
            key={index}
            className={`recommendation-row ${
              checkedItems[index] ? "checked" : ""
            }`}
          >
            <Col span={1}>
                <Checkbox
                  checked={checkedItems[index]}
                  onChange={() => handleCheckboxChange(index)}
                />
            </Col>

          
          <Col
            xs={{ span: 17 }}
            sm={{ span: 19 }}
            md={{ span: 19 }}
            lg={{ span: 19 }}
            xl={{ span: 19 }}
            xxl={{ span: 19 }}
          >
            <div
              className="recommendation-title"
              onClick={() => showModal(recommendation.title, recommendation.reason, recommendation.frequency)}
            >
              {recommendation.title} <span className='info-icon'><InfoCircleOutlined /></span>
            </div>
          </Col>

          <Col
            xs={{ span: 4 }}
            sm={{ span: 4 }}
            md={{ span: 4 }}
            lg={{ span: 4 }}
            xl={{ span: 4 }}
            xxl={{ span: 4 }}
            className="recommendation-cta"
          >
            {checkedItems[index] ? (
                  <Tag className="coming-soon" color="green">Done</Tag>
                ) :

            recommendation.instalabProductType ? 
              <Button
                // className="recommendation-btn"
                // type="primary"

                onClick={() => window.open(recommendation.instalabProductType.path ? recommendation.instalabProductType.path : `/flow/${recommendation.instalabProductType.flowType}` , "_blank")}
              >
                &rarr;
              </Button>

              : 
              <Button
              // type="primary"

                onClick={() => alert("We help Instalab members schdeule this with a nearby partner facility. Email concierge@instalab.com to get started.")}
              >
               &rarr;
              </Button>              
            }
          </Col>
        </Row>

        </>
      ))}
      </div>
    </div>
  );
}
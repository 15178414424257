import { Typography, Row, Col }  from 'antd'
import { useState } from "react";
import Breakpoint from "../../../../enums/breakpoint.enum";
import { TassoModal } from "../../../tassoModal/tassoModal.component";
import useWidth from '../../../../hooks/useWidth.hook';

const { Text, Paragraph } = Typography

const PrescriptionIntro = ({ footer }) => {
  const width = useWidth()
  const [openTasso, setOpenTasso] = useState()

  const instructions = [{
    sections: [{
      title: 'Get Custom Medication',
      description: <>Your initial medication will be prescribed based on your medical history and conveniently delivered to your doorstep.</>
    }, 
  ]
  }, {
    sections: [{
      title: 'See How Much ApoB + LDL Cholesterol Lowers',
      description: <>Test your blood 6 weeks after starting medication with a painless <a onClick={() => setOpenTasso(true)} className="instruction-link">at-home blood test</a>. We'll adjust your flow based on results as needed so you can meet your goals faster.</>
    }]
  }, {
    sections: [{
      title: 'Repeat and Refine',
      description: 'Repeat the process as we progress towards your ideal medication and hit your desired cholesterol levels.'
    }]
  }]
  
  return (
    <div 
      className="flow-intro"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >
      <TassoModal
        open={openTasso}
        setOpen={setOpenTasso}
      />

      <div 
        className="flow-intro-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
        <Paragraph 
          className="flow-title"
          style={{
            fontSize: width > Breakpoint.SM ? 32 : 28,
          }}
        >
          Cholesterol Treatment Program
        </Paragraph>
        <Paragraph 
          className="flow-description"
          style={{
            fontSize: width > Breakpoint.SM ? 16 : 14,
          }}
        >
          Instalab makes it easy to get and fine-tune your medication so you reach your desired cholesterol levels ASAP. 
        </Paragraph>

        <div 
          className="instruction-list"
          style={{
            marginTop: width > Breakpoint.SM ? 50 : 30,
            marginBottom: width > Breakpoint.SM ? -15 : -25,
            paddingTop: width > Breakpoint.SM ? 25 : 15,
          }}
        >
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row>
                <Col
                  style={{
                    width: width > Breakpoint.SM ? 80 : '100%',
                    marginBottom: width > Breakpoint.SM ? 0 : 10
                  }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  style={{
                    width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                  }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>
        
        <div className="flow-intro-btn-container">
          <div className="flow-intro-btn">
            {footer({ 
              buttonText: "Let's go",
              buttonCheck: false,
              buttonStyle: {
                height: 50,
                fontSize: 18,
                width: '100%',
                display: 'block',
              },
              enterStyle: {
                display: 'none',
              }
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrescriptionIntro
import { useContext, useRef, useState, useEffect } from "react"
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { ConfirmSupplements } from "./confirmSupplements.component"
import { UserContext } from "../../contexts/user.context"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { completeFlow } from "../../services/flow.service"
import { listMembershipTypes } from '../../services/membershipType.service'
import DISCOUNT from "../../enums/discount.enum"

export const Step = {
  SELECT: 'select',
  PAY: 'pay',
  DELIVERY: 'delivery',
  ACCOUNT: 'account',
  CONFIRM: 'confirm',
}

export const SupplementFlow = () => {
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const { longevityMembership } = useContext(UserContext)
  const [hasPass, setHasPass] = useState()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  useEffect(() => {
    fetchMembershipType()
  }, [])

  useEffect(() => {
    fetchSkeleton()
  }, [membershipTypes, flow, initialUserRef, hasPass])

  const fetchMembershipType = async () => {
    setMembershipTypes(await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.SUPPLEMENTS,
            MembershipTypeCode.LONGEVITY,
          ]
        }
      }
    }))
  }

  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.SELECT]: {
        type: StepType.PRODUCT_SELECT,
        title: `Which supplements do you want in your supplement daily pack?`,
        description: `Below are recomended based on your latest lab results. For each one you select, you'll get a 3 month supply. They'll be packed into daily pouches.`,
        buildFlow: true,
        multiple: true,
        nextStep: Step.PAY,
        field: {
          name: 'selectedSupplements',
          required: true,
          options: [{
            title: `${ProductTypeCode.CALCIFEDIOL} by D.velop`,
            value: ProductTypeCode.CALCIFEDIOL,
            description: `3x faster at increasing your vitamin D levels than conventional vitamin D3.`,
            tag: null
          }, 
          {
            title: `${ProductTypeCode.VITAMIN_B9_B12} by Jarrows Formula`,
            value: ProductTypeCode.VITAMIN_B9_B12,
            description: `Will help reduce your homocysteine levels. Also great for energy metabolism.`,
            tag: null
          }
        ]
        },
        isLocked: () => {
          return flow?.products?.length
        }
      },
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.DELIVERY,
        productType: ProductTypeCode.SUPPLEMENT_PACK,
        addSubscription: true,
        addLongevityMembership: true,
        subscriptionType: MembershipTypeCode.SUPPLEMENTS,
        title: (flow?.addLongevityMembership || longevityMembership) ?
          <>Pay <span className="true-price">${getMembershipType(MembershipTypeCode.SUPPLEMENTS)?.cost * DISCOUNT}/month</span> <span className="original-price">${getMembershipType(MembershipTypeCode.SUPPLEMENTS)?.cost}/month</span> for your supplement daily packs{longevityMembership ? '.' : <> and <span className="true-price">${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span> for your Instalab membership.</>}</> :
          <>Pay <span className="true-price">${getMembershipType(MembershipTypeCode.SUPPLEMENTS)?.cost}/month</span> for your supplement daily packs.</>,
        description: <>We make it simple by packaging them into daily pouches. Just open one per day and take with food. You'll get a 3 month supply, refilled quarterly. Cancel anytime.</>,
      },
      [Step.DELIVERY]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.ACCOUNT,
        title: `What's your delivery address?`,
        description: `We’ll send your supplements to this location.`,
        field: {
          name: 'location'
        },
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.CONFIRM,
        buildUser: true,
        title: `Create your Instalab acccount.`,
        description: `Create your account to manage your supplements.`,
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `Your Supplement Packs Are On The Way!!`,
        description: `You should receive your supplement packs within 1 week. We'll update your account with tracking info soon.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmSupplements />,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  return <>
    {skeleton && (
      <Flow 
        skeleton={skeleton} 
        flow={flow}
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
      />
    )}
  </>
}
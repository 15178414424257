
import { useRef, useEffect, useState } from "react";
import { EventCode } from "../../enums/index.enum";
import { ProductDetail } from '../productDetail/productDetail.component';
import { ReactComponent as BloodSVG} from '../../assets/svg/blood.svg'; 
import { ReactComponent as CardSVG} from '../../assets/svg/card.svg'; 
import { ReactComponent as HomeSVG} from '../../assets/svg/house.svg'; 
import ProductTypeCode from "../../enums/productTypeCode.enum"
import ProductHelper from "../../helpers/product.helper";
import ProductDetailSection from "../../enums/productDetailSection.enum";
import { getProductTypeByCode } from "../../services/productType.service";

export const FertilityTest = () => {

  const [productType, setProductType] = useState()
  const [sections, setSections] = useState(null)
  const panelRef = useRef(null);

  // init + get prices
  useEffect(() => {
    fetchProductType(); 
  }, []);

  const fetchProductType = async () => {
    setProductType(await getProductTypeByCode(ProductTypeCode.FERTILITY))
  };

  const fertilityPanel = [
  
      {
        name: "Anti-Müllerian Hormone",
        description: "AMH is released by cells around your follicles and is the most important hormone for estimating ovarian reserve, indicating your remaining egg supply and fertility lifespan."
      },
      {
        name: "Thyroid-Stimulating Hormone",
        description: "TSH, produced by the pituitary gland, regulates thyroid function, which is essential for maintaining hormonal balance and supporting reproductive health."
      },
      {
        name: "Follicle-Stimulating Hormone",
        description: "FSH is responsible for growing follicles growth and is crucial for evaluating ovarian reserve and the ovaries’ ability to produce mature eggs."
      },
      {
        name: "Estradiol",
        description: "Estradiol is provided by the ovaries helps assess ovarian function and follicular development, ensuring accurate interpretation of other hormone levels."
      },
      {
        name: "Prolactin",
        description: "Prolactin stimulates milk production and can affect ovulation and menstrual regularity. Elevated levels may interfere with fertility."
      },
      {
        name: "Luteinizing Hormone",
        description: "LH, produced by the pituitary gland, regulates ovulation timing and luteal phase function, impacting overall fertility."
      },

    ]

  
  // set product attributes
  useEffect(() => {
    if (!productType) return
    const scrollToPanel = () => {panelRef.current?.scrollIntoView({ behavior: 'smooth' });}
    const faqs = [
      {
          question: "What biomarkers are included?",
          answer: <>The Fertility Hormone Test is carefully curated by leading fertility experts, providing a comprehensive assessment of your ever-evolving fertility health. It includes AMH, TSH, FSH, LH, Estradiol and Prolactin. <a onClick={scrollToPanel}  className="cta-link">Learn More</a></>
      },
      {
        question: "How is the Tasso+ virtually painless?",
        answer: "The Tasso+ device offers a more comfortable and convenient alternative to traditional finger pricks for blood collection. Unlike finger pricks, which can cause sharp pain by puncturing deeper into the skin, the Tasso+ uses microneedles and a gentle suction mechanism to draw blood from the skin’s surface with minimal penetration. This reduces the discomfort and potential bruising associated with finger pricks."
      },
      {
        question: `How long does it take to get my results?`,
        answer: <>It typically takes about 2 business days to get results after your sample arrives at our lab. We’ll send you an email when your results and recommendations are ready to go!</>
      }, 
      {
        question: "How do I test hormone levels at home?",
        answer: <>We’ll send you everything you need to test, along with instructions (and tips!) to support you throughout the process. After you collect your blood sample with the painless Tasso+ collection device, you’ll just pop it in the mail and our lab will handle the rest.
        <br/><br/>Please note you'll want to wait til day three if your menstrual cycle to do the collection. This is considered the baseline of the menstrual cycle when hormone levels are stable and least influenced by the developing follicle. It allows for accurate assessment of hormone levels without the fluctuations that occur later in the cycle. </>,
      },
      {
        question: "Can I do this test when I'm on birth control?",
        answer: "Yes! Testing on birth control can help you get proactive with fertility insights even before you’re ready for kids. If you’re on hormonal birth control you’ll want to test two key fertility hormones: AMH, the most reliable marker for ovarian reserve (aka your “egg count”), and TSH, the main indicator of thyroid health."
      },
      {
          question: `Where is this available?`,
          answer: `Anywhere in the United States.`
      },
      {
          question: `Do I need to fast before I collect my blood?`,
          answer: `No fasting is needed.`,
      }, 
      {
          question: `Where is my blood being sent?`,
          answer: `Your sample is sent to one of our CLIA + CAP certified labs for analysis. After the lab is done, any remaining sample is disposed.`,
      }, 
      {
          question: `Can I talk to an actual human physician to review my results with me?`,
          answer: `Yes. Once you receive your results, you have the option to schedule a video consultation with a longevity physician. They'll be ready to address any of your questions.`,
      }, 
      {
          question: `Does Instalab help with any followup needs?`,
          answer: `Yes. Instalab helps members with any additional testing and referrals to fertility specialists.`
      },
      {
          question: "How often should I get this panel done?",
          answer: `If you're looking to have kids, it's wise to have a check-up at least once a year to monitor your reproductive health.`,
      },             
      {
          question: `Does my health insurance cover this?`,
          answer: `For price transparency reasons, Instalab does not accept insurance. However, if you have an HSA/FSA, you may be eligible for reimbursement. We recommend using your HSA/FSA card when purchasing anything at Instalab. If you don’t have one, we can generate an itemized receipt for you to submit.`,
      }, 

    ]

    setSections([
      { type: ProductDetailSection.HERO,
        data: {
          title: productType.title,
          memberPrice: ProductHelper.getMemberPrice(productType.cost),
          originalPrice: productType.cost,
          oneliner: "A comprehensive at-home test that empowers you with the knowledge you need to be proactive about your fertility.",
          panelRef: panelRef,
          cta: {
            text: "Buy Now",
            url: `/flow/${productType.flowType}`
          },
          photos: [
              {
                original: "/img/fertility-hero-1.png", 
                thumbnail: "/img/fertility-hero-1.png"
              },

              {
                original: "/img/tasso.png",
                thumbnail:  "/img/tasso.png"
              },

              {
                original: "/img/fertility-tasso-grid.png",
                thumbnail:  "/img/fertility-tasso-grid.png"
              },

              {
                original: "/img/fertility-results.png",
                thumbnail:  "/img/fertility-results.png"
              },
          ],
          points: [
              <><BloodSVG className="point-icon"/>Test for six key fertility biomarkers</>,
              <><HomeSVG className="point-icon"/>Easy and painless sample collection with Tasso+</>,
              <><CardSVG className="point-icon" />HSA / FSA cards accepted</>,
          ],
          faqs: faqs.slice(0,3)
        },
      },
      {
        type: ProductDetailSection.SMALLPANEL,
        title: "Tests Included",
        data: {
          panel: fertilityPanel,
          panelRef: panelRef
        }
      },
      { type: ProductDetailSection.DIRECTIONS,
        data: [
            {
                title: "Order test",
                description: "After you purchase your test, you'll receive your sample collection kit in the mail. This includes the Tasso+ collection unit.",
                image: "/img/tasso-box.png"
            },
            {
                title: "Collect & mail sample",
                description: "On day three of your menustrual cycle, complete your sample collection with the Tasso+, then just pop it the mail.",
                image: "/img/tasso-grid.png"
            },
            {
                title: "Receive your results",
                description: "Results are reviewed by a physician and available within 1 week. A report and game plan will be available as well.",
                image: "/img/receive-results-woman.png"
            },

        ],

      },
      { type: ProductDetailSection.FAQ,
        title: 'Frequently Asked Questions',
        data: faqs.slice(3),
      },

    ])
  
  }, [productType])


  return (
    sections && <div className="longevity-panel">

      <ProductDetail 
        sections={sections}
        trackCode={EventCode.VISIT_FERTILITY_TEST}
      />

    </div>
  )

}

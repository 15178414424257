import Role from '../enums/role.enum.js'
import ProductTypeCode from "../enums/productTypeCode.enum.js"
import PlanCode from "../enums/planCode.enum.js"

const UserHelper = {
  isAdmin: (user) => {
    return user?.role === Role.ADMIN
  },
  isPatient: (user) => {
    return user?.role === Role.PATIENT
  },
  isPhlebotomist: (user) => {
    return user?.role === Role.PHLEBOTOMIST
  },
  isProvider: (user) => {
    return user?.role === Role.PROVIDER
  },
  isStaff: (user) => {
    return user?.role === Role.STAFF
  },
  hasCredit: (user, productType) => {
    if (!user?.credits) return false 

    const creditDict = {
        [ProductTypeCode.LONGEVITY_PANEL]: PlanCode.ADVANCED,
        [ProductTypeCode.ATHLETE_PANEL]: PlanCode.ATHLETE,
        [ProductTypeCode.CONSULT_LONGEVITY]: ProductTypeCode.CONSULT_LONGEVITY,
        [ProductTypeCode.CONSULT_HEART_HEALTH]: ProductTypeCode.CONSULT_HEART_HEALTH,
        [ProductTypeCode.CONSULT_METABOLIC_HEALTH]: ProductTypeCode.CONSULT_METABOLIC_HEALTH,
        [ProductTypeCode.WATCHPAT_ONE]: ProductTypeCode.WATCHPAT_ONE,
        [ProductTypeCode.OMEGA_3_ADDON]: ProductTypeCode.OMEGA_3_ADDON

    }

    return creditDict[productType] ? user.credits?.some(credit => credit === creditDict[productType]) : false
  }
}

export default UserHelper
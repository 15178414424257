import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listBlogs = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/blogs`, {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const getBlogBySlug = async (slug) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/blogs/${slug}`, {
    params: {
      idType: 'slug'
    },
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const getBlog = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/blogs/${id}`, {
    params: ServiceHelper.getParams({
      idType: '_id'
    }),
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const subscribeBlog = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/blogs/subscribe`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addBlog = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/blogs`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const publishBlog = async (id) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/blogs/${id}/publish`, ServiceHelper.getParams(), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const updateBlog = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/blogs/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeBlog = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/blogs/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}
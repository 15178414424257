import axios from "axios";
import { posthog } from "posthog-js";

export const login = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/auth/login`, params)
  //set in posthog
  if (window.location.hostname !== "localhost") {
    posthog.people.set({ email: response.data.user.email })
  }
  return response.data
}

import { useContext, useEffect, useState } from "react"
import { Form, Input, Button } from "antd"
import { WarningFilled } from '@ant-design/icons'
import FormHelper from "../flow/helpers/form.helper"
import { isUserUnique, buildUser } from "../../services/user.service"
import './emailForm.scss'
import { UserContext } from "../../contexts/user.context"
import Breakpoint from "../../enums/breakpoint.enum"
import ValidateHelper from "../../helpers/validate.helper"
import useWidth from "../../hooks/useWidth.hook"
import StorageKey from "../../enums/storageKey.enum"

const { Item } = Form

export const EmailForm = ({ 
  buttonText, 
  onSuccess,
  shouldBuildPatient=false,
  inputBackground="white"
}) => {
  const [rules, setRules] = useState()
  const [form] = Form.useForm()
  const [hasAttempt, setHasAttempt] = useState()
  const [isSubmitting, setIsSubmitting] = useState()
  const { setCurrentUser } = useContext(UserContext)
  const width = useWidth()

  useEffect(() => {
    fetchRules()
  }, [shouldBuildPatient])

  const fetchRules = () => {
    let r = [{ 
      required: true, 
      message: <><WarningFilled />&nbsp; Please fill this in</>,
    }, {
      message: <><WarningFilled />&nbsp; Hmm... that email doesn't look right</>,
      validator: (_, value) => {
        if (!value ||  ValidateHelper.email(value)) {
          return Promise.resolve();
        } else {
          return Promise.reject('Enter valid email address');
        }
      }
    }]

    if (shouldBuildPatient) {
      r.push({
        message: <><WarningFilled />&nbsp; Email already taken.</>,
        validator: async (_, value) => {
          if (!value?.length) return true

          const isUnique = await isUserUnique({
            tracker: localStorage.getItem(StorageKey.TRACKER),
            field: 'email',
            value
          })

          if (isUnique) {
            return Promise.resolve();
          } else {
            return Promise.reject('Email already taken');
          }
        }
      })
    }

    setRules(r)
  }

  const onFinish = async () => {
    setIsSubmitting(true)
    setHasAttempt(true)

    try {
      const error = await FormHelper.fetchHasError(form)
      if (error) {
        setIsSubmitting(false)
        return
      }
      const { email } = form.getFieldsValue()

      if (shouldBuildPatient) {
        const response = await buildUser({ 
          fields: {
            email 
          },
          select: '_id email',
          populate: []
        })
        setCurrentUser(response.user)
      }
      onSuccess(email)
      form.resetFields()
    } catch (err) {

    }

    setIsSubmitting(false)
  }

  return (
    <Form
      form={form}
      onFinish={onFinish}
      className="email-form"
    >
      <Item
        name="email"
        validateTrigger='onSubmit'
        rules={rules}
        style={{
          width: width > Breakpoint.SM ? 400 : '100%',
          marginRight: width > Breakpoint.SM ? 10 : 0,
        }}
      >
        <Input
          onChange={() => {
            if (hasAttempt) FormHelper.fetchHasError(form)
          }}
          style={{ 
            backgroundColor: inputBackground,
          }}
          placeholder="Enter email address..."
          className="email-input"
        />
      </Item>

      <Button
        loading={isSubmitting}
        onClick={onFinish}
        type="primary"
        className="email-btn"
      >
        {buttonText}
      </Button>
    </Form>
  )
}
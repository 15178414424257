
// TODO: speed refactor - replace with reusable components
import { Modal, Typography, Input, Form, Descriptions, Button, message, Select, Tooltip, Row, Col, Table } from 'antd'
import moment from 'moment'
import './taskModal.scss'
import { Checkbox } from 'antd-mobile'
import parse from 'html-react-parser';
import { CopyOutlined, EditOutlined } from '@ant-design/icons'
import { useEffect, useState, useRef, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { addTaskNote, completeTask, getTask, openTask, removeTaskNote } from '../../services/task.service'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import TaskStatus from '../../enums/taskStatus.enum';
import PrescriptionStatus from '../../enums/prescriptionStatus.enum';
import TrackingHelper from '../../helpers/tracking.helper';
import { listAssignees } from '../../services/user.service';
// import { getData } from '../../services/data.service';
import TaskCategory from '../../enums/taskCategory.enum';
import ProductTypeCode from '../../enums/productTypeCode.enum';
import FlowType from '../../enums/flowType.enum';
import { listPrescriptions } from '../../services/prescription.service';
import { PrescriptionForm } from '../prescriptionForm/prescriptionForm.component';
import { listAppointments } from '../../services/appointment.service';
import classNames from 'classnames';
import { listProducts } from '../../services/product.service';
import { listMemberships } from '../../services/membership.service';
import MembershipStatus from '../../enums/membershipStatus.enum';
import MmebershipTypeCode from '../../enums/membershipTypeCode.enum';
import { ResultsTable } from '../resultsTable/resultsTable.component';
import AbsoluteCategoryCode from '../../enums/absoluteCategoryCode.enum';
import TestCode from '../../enums/testCode.enum';
import { AdminProductModal } from '../adminProductModal/adminProductModal.component';
import Role from '../../enums/role.enum';
import { UserContext } from '../../contexts/user.context';

const { Text, Paragraph } = Typography
const { TextArea } = Input
const { Column } = Table

export const TaskModal = ({ open, setOpen, taskId, setTaskId, onSuccess }) => {
  const [task, setTask] = useState()
  const [patientFields, setPatientFields] = useState()
  const [form] = Form.useForm()
  const [users, setUsers] = useState()
  const [searchParams, setSearchParams] = useSearchParams()
  const [productId, setProductId] = useState()
  const { currentUser } = useContext(UserContext)
  const [isAddingNote, setIsAddingNote] = useState()
  const [openTag, setOpenTag] = useState()
  const [openAddPrescription, setOpenAddPrescription] = useState()
  const [membershipFields, setMembershipFields] = useState()
  const [appointmentFields, setAppointmentFields] = useState()
  const [productFields, setProductFields] = useState()
  const [resultFields, setResultFields] = useState()
  const [openAddTestKit, setOpenAddTestKit] = useState()

  const [prescriptions, setPrescriptions] = useState([])
  const prescriptionsRef = useRef()
  prescriptionsRef.current = prescriptions

  useEffect(() => {
    fetchUsers()
  }, [])

  useEffect(() => {
    fetchTask()
  }, [taskId])

  useEffect(() => {
    fetchPatientFields()
    fetchProductFields()
    fetchAppointmentFields()
    fetchMembershipFields()
    fetchPrescriptions()
  }, [task])

  useEffect(() => {
    if (!open) {
      setTaskId(null)
      setTask(null)
      setMembershipFields(null)
      setAppointmentFields(null)
      setResultFields(null)
      setProductFields(null)
      setPrescriptions(null)
    }
  }, [open])

  const fetchPrescriptions = async () => {
    if (!task?.patient) return
    if (task.category === TaskCategory.CHOLESTEROL_TREATMENT) {
      setPrescriptions(await listPrescriptions({ patient: task.patient._id }))
    }
  }

  const fetchUsers = async () => {
    const response = await listAssignees()
    const whitelistEmails = [
      'alex@instalab.com',
      'adora@instalab.com',
      'layla@instalab.com',
      'jasha@instalab.com',
    ]
    setUsers(response.filter(({ email }) => whitelistEmails.includes(email)))
  }

  const fetchTask = async () => {
    if (taskId) {
      setTask(sortNotes(await getTask(taskId)))
    } else {
      setTask(null)
    }
  }

  const sortNotes = (response) => {
    return {
      ...response,
      notes: response.notes?.length ? response.notes.reverse() : []
    }
  }

  const onAddNote = async () => {
    setIsAddingNote(true)
    try {
      const { content, taggedUsers } = form.getFieldsValue()
      setTask(sortNotes(await addTaskNote(taskId, { content, taggedUsers })))
      message.info('Note added')
      form.resetFields()
      setOpenTag(false)
    } catch (err) {
      message.error('Failed to add note')
    }
    setIsAddingNote(false)
  }

  const onRemoveNote = async (noteId) => {
    try {
      setTask(sortNotes(await removeTaskNote(taskId, noteId)))
      message.info('Note removed')
    } catch (err) {
      message.error('Failed to remove note')
    }
  }

  const onCancel = () => {
    setOpen(false)
    searchParams.delete('id')
    setSearchParams(searchParams)
  }

  const onNewPrescription = (newPrescription) => {
    setPrescriptions([newPrescription, ...prescriptionsRef.current])
  }

  const onUpdatePrescription = (updatedPrescription) => {
    setPrescriptions(prescriptionsRef.current.map(p => p._id === updatedPrescription._id ? updatedPrescription : p))
  }

  const onRemovePrescription = (removedPrescription) => {
    setPrescriptions(prescriptionsRef.current.filter(p => p._id !== removedPrescription._id))
  }

  const onNewTestKit = () => {
    setOpenAddTestKit(true)
  }

  const onEditTestKit = (productId) => {
    setProductId(productId)
    setOpenAddTestKit(true)
  }


  const onProductUpdate = async () => {
    await fetchProductFields()
  }

  const onPrescriptionSuccess = (prescription) => {
    setPrescriptions([
      prescription,
      ...prescriptions
    ])
  }

  const fetchProductFields = async () => {
    if (!task?.patient) return
    if (task.category === TaskCategory.CHOLESTEROL_TREATMENT) {
      let products = await listProducts({ patient: task.patient._id })
      const productTypeCodes = [
        ProductTypeCode.CHOLESTEROL_TREATMENT_TEST_KIT,
        ProductTypeCode.HEART_HEALTH_TEST_KIT,
      ]
      const AbbreviatedProductType = {
        [ProductTypeCode.CHOLESTEROL_TREATMENT_TEST_KIT]: 'Cholesterol',
        [ProductTypeCode.HEART_HEALTH_TEST_KIT]: 'Heart Health',
      }
      products = products.filter(({ productType }) => productTypeCodes.includes(productType.code))
      setProductFields([{
        label: 'Test Kits',
        value: (
          <Table
            size='small'
            pagination={false}
            rowKey='_id'
            dataSource={products}
            className="product-table"
          >
            <Column
              title='Status'
              dataIndex='status'
              render={status => <Text className={classNames(`${status}-appointment-status`, "appointment-status")}>{status}</Text>}
            />

            <Column
              title='Type'
              dataIndex='productType'
              render={productType => AbbreviatedProductType[productType.code]}
            />

            <Column 
              title='Ordered' 
              dataIndex='createdAt' 
              render={createdAt => (
                <Tooltip title={`${Math.round(moment().diff(moment(createdAt), 'weeks', true)*10)/10} weeks ago`}>
                  {moment(createdAt).format('MM/DD/YYYY')}
                </Tooltip>
              )} 
            />

            <Column
              title='Tracking'
              dataIndex='tracking'
              render={(tracking, { trackingNumber, _id }) => {
                if (tracking?.trackingEvents?.length > 0) {
                  return (
                    <Tooltip 
                      overlayStyle={{ maxWidth: '390px'}}
                      title={(
                        <ul className="tracking-list">
                          {tracking.trackingEvents.map(({ eventTimestamp, eventType }, index) => (
                            <li key={`tracking-${_id}-${index}`}>
                              <strong>{moment(eventTimestamp).format('MM/DD/YYYY')}</strong>: {eventType}
                            </li>
                          ))}
                        </ul>
                      )}
                    >
                      <a className="link" onClick={(e) => {
                        e.stopPropagation(); 
                        window.open(`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${tracking.trackingNumber}`, '_blank');
                      }}>{tracking.trackingNumber}</a>
                    </Tooltip>
                  )
                } else if (tracking) {
                  return <a className="link" href={TrackingHelper.getDeliveryService(tracking.trackingNumber).url} target="_blank">{tracking.trackingNumber}</a>
                } else if (trackingNumber) {
                  return trackingNumber
                } else {
                  return <Text className="no-value">Not Shipped</Text>
                }
              }}
            />

            <Column
              dataIndex='_id'
              width={50}
              render={_id => (
                <Tooltip title="Edit">
                  <Button
                    className="edit-btn"
                    onClick={() => onEditTestKit(_id)}
                    icon={<EditOutlined />}
                  />
                </Tooltip>
              )}
            />
          </Table>
        )
      }])
    }
  }

  const fetchAppointmentFields = async () => {
    if (!task?.patient) return
    if (task.category === TaskCategory.CHOLESTEROL_TREATMENT) {
      let appointments = await listAppointments({ patients: { $in: [task.patient._id] }})
      const flowTypes = [
        FlowType.LONGEVITY_TEST,
        FlowType.ATHLETE_TEST,
      ]
      appointments = appointments.filter(({ flow }) => flowTypes.includes(flow?.type) || !flow)
      setAppointmentFields([{
        label: 'Appointments',
        value: (
          <Table
            size='small'
            pagination={false}
            rowKey='_id'
            dataSource={appointments}
            className="appointment-table"
          >
            <Column
              title='Status'
              dataIndex='status'
              render={status => <Text className={classNames(`${status}-appointment-status`, "appointment-status")}>{status}</Text>}
            />

            <Column
              title='Type'
              dataIndex='flow'
              render={flow => flow?.type || <Text className="no-value">N/A</Text>}
            />

            <Column 
              title='Start' 
              dataIndex='start' 
              render={start => (
                <Tooltip title={moment() > moment(start) ? `${Math.round(moment().diff(moment(start), 'weeks', true)*10)/10} weeks ago` : `${Math.round(moment().diff(moment(start), 'weeks', true)*10)/10} weeks later`}>
                  {moment(start).format('MM/DD/YYYY')}
                </Tooltip>
              )} 
            />
          </Table>
        )
      }])
    }
  }

  const fetchMembershipFields = async () => {
    if (!task?.patient) return
    if (task.category === TaskCategory.CHOLESTEROL_TREATMENT) {
      let memberships = await listMemberships({ patient: task.patient._id })
      const membershipTypeCodes = [
        MmebershipTypeCode.LONGEVITY,
        MmebershipTypeCode.LIPIDS,
        MmebershipTypeCode.EZETIMIBE,
        MmebershipTypeCode.ROSUVASTATIN,
        MmebershipTypeCode.LIPIDS_UPGRADE,
      ]
      memberships = memberships.filter(({ membershipType }) => membershipTypeCodes.includes(membershipType.code))
      setMembershipFields([{
        label: 'Memberships',
        value: (
          <Table
            size='small'
            pagination={false}
            rowKey='_id'
            dataSource={memberships}
            className="membership-table"
          >
            <Column
              title='Status'
              dataIndex='status'
              render={(status, { trialEnd }) => {
                return (trialEnd && status !== MembershipStatus.CANCELED) ? (
                  <Text className={classNames('status-text', 'membership-pending-status')}>
                    Pending Approval
                  </Text>
                ) : (
                  <Text className={classNames('status-text', `membership-${status}-status`)}>
                    {status}
                  </Text>
                )
              }}
            />

            <Column
              title='Type'
              dataIndex='membershipType'
              render={membershipType => membershipType?.code}
            />

            <Column
              title='Total'
              dataIndex='total'
              render={total => total ? `$${total}` : null}
            />

            <Column
              title='Invoices'
              dataIndex='invoices'
              render={invoices => invoices?.length ? `${invoices?.length}x` : null}
            />

            <Column
              title='Created'
              dataIndex='createdAt'
              render={createdAt => (
                <Tooltip title={`${Math.round(moment().diff(moment(createdAt), 'months', true)*10)/10} months ago`}>
                  {moment(createdAt).format('MM/DD/YYYY')}
                </Tooltip>
              )}
            />
          </Table>
        )
      }])
    }
  }

  const fetchPatientFields = async () => {
    if (!task?.patient) return
    const { patient } = task

    setPatientFields([{
      label: 'First Name',
      value: patient.firstName
    }, {
      label: 'Last Name',
      value: patient.lastName
    }, {
      label: 'Email',
      value: patient.email
    }, {
      label: 'Phone',
      value: patient.phone
    }, {
      label: 'Gender',
      value: patient.gender
    }, {
      label: 'DOB',
      value: patient.dob
    }, {
      label: 'Ethnicity',
      value: patient.ethnicity?.length ? patient.ethnicity : <Text className="no-value">N/A</Text>
    }, {
      label: 'Blood Draw',
      value: getLocation(patient.location)
    }, {
      label: 'Shipping',
      value: getLocation(patient.shippingLocation)
    }])
  }

  const getLocation = (location) => {
    if (!location) return null
    const {
      streetAddress,
      streetAddress2,
      city,
      state,
      postalCode,

    } = location
    let address = streetAddress
    if (streetAddress2) address += ` ${streetAddress2}`
    return `${address}, ${city}, ${state} ${postalCode}`
  }

  const CopyButton = ({ label, value }) => {
    return (
      <Tooltip title={`Copy: ${label}`}>
        <CopyToClipboard 
          text={value}
          onCopy={() => message.info(`Copied: ${label}`)}
        >
          <Button
            icon={<CopyOutlined className="copy-btn" />}
          />
        </CopyToClipboard>
      </Tooltip>
    )
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className="task-modal"
      title={task?.title ? <>
        <Tooltip 
          title={task.status === TaskStatus.COMPLETE ? `Reopen task` : `Complete task`}
          placement='left'
        >
          <div className="complete-checkbox-container">
            <Checkbox 
              checked={task?.status === TaskStatus.COMPLETE} 
              className="complete-checkbox"
              onChange={async value => {
                setTask(prevTask => {
                  return {
                    ...prevTask,
                    status: value ? TaskStatus.COMPLETE : TaskStatus.ACTIVE
                  }
                })
                let updatedTask
                if (value) {
                  updatedTask = await completeTask(task._id)
                  message.success(`Task completed: ${task.title}`)
                } else {
                  updatedTask = await openTask(task._id)
                  message.info(`Task reopened: ${task.title}`)
                }
                setTask(updatedTask)
                onSuccess(updatedTask)
              }}
            />
          </div>
        </Tooltip>
        {parse(task.title)}
      </> : null}
      footer={null}
      width={1200}
    >
      <PrescriptionForm
        open={openAddPrescription}
        setOpen={setOpenAddPrescription}
        onSuccess={onPrescriptionSuccess}
        patientId={task?.patient?._id}
      />

      <AdminProductModal
        open={openAddTestKit}
        setOpen={setOpenAddTestKit}
        onSuccess={onProductUpdate}
        patientId={task?.patient?._id}
        productId={productId}
      />

      {task?.description && (
        <Paragraph className="task-description">
          {parse(task.description)}
        </Paragraph>
      )}

      <div className="task-modal-body">
        <Row gutter={12}>
          <Col span={16}>
            {task?.subtasks?.length > 0 && (
              <ul className="subtask-list">
                {task.subtasks.map(({ title }, index) => (
                  <li
                    key={`subtask-${index}`}
                    className="subtask-item"
                  >
                    {title}
                  </li>
                ))}
              </ul>
            )}

            {patientFields?.length > 0 && (
              <Descriptions 
                layout='horizontal'
                column={1}
                bordered={true}
              >
                {patientFields.map(({ label, value, hasCopy=true }, index) => (
                  <Descriptions.Item 
                    key={`patient-field-${index}`}
                    label={label}
                  >
                    {value ? (
                      <div className="patient-value-container">
                        <span>{value}</span>
                        {hasCopy && <CopyButton label={label} value={value} />}
                      </div>
                    ) : (
                      <Text className="no-value">
                        N/A
                      </Text>
                    )}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            )}

            {task?.category === TaskCategory.CHOLESTEROL_TREATMENT && (
              <Descriptions 
                layout='horizontal'
                column={1}
                bordered={true}
              >
                <Descriptions.Item 
                  label={'Prescriptions'}
                >
                  <Table
                    size='small'
                    pagination={false}
                    rowKey='_id'
                    dataSource={prescriptions}
                    className="prescription-table"
                  >
                    <Column
                      title='Status'
                      dataIndex='status'
                      render={status => <Text className={classNames(`${status}-prescription-status`, "prescription-status")}>{status}</Text>}
                    />

                    <Column
                      title='Type'
                      dataIndex='type'
                    />

                    <Column 
                      title='Ordered' 
                      dataIndex='createdAt' 
                      render={createdAt => (
                        <Tooltip title={`${Math.round(moment().diff(moment(createdAt), 'weeks', true)*10)/10} weeks ago`}>
                          {moment(createdAt).format('MM/DD/YYYY')}
                        </Tooltip>
                      )} 
                    />

                    <Column
                      title='Tracking'
                      dataIndex='tracking'
                      render={(tracking, { status, _id, trackingNumber }) => {
                        if (tracking?.trackingEvents?.length > 0) {
                          return (
                            <Tooltip 
                              overlayStyle={{ maxWidth: '390px'}}
                              title={(
                                <ul className="tracking-list">
                                  {tracking.trackingEvents.map(({ eventTimestamp, eventType }, index) => (
                                    <li key={`tracking-${_id}-${index}`}>
                                      <strong>{moment(eventTimestamp).format('MM/DD/YYYY')}</strong>: {eventType}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            >
                              <a className="link" onClick={(e) => {
                                e.stopPropagation(); 
                                window.open(`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${tracking.trackingNumber}`, '_blank');
                              }}>{tracking.trackingNumber}</a>
                            </Tooltip>
                          )
                        } else if (tracking) {
                          return <a className="link" href={TrackingHelper.getDeliveryService(tracking.trackingNumber).url} target="_blank">{tracking.trackingNumber}</a>
                        } else if (trackingNumber) {
                          return trackingNumber
                        } else if (status === PrescriptionStatus.SENT) {
                          return 'Pharmacy Fulfilling'
                        } else {
                          return 'Pending Physician Approval'
                        }
                      }}
                    />
                  </Table>
                </Descriptions.Item>

                <Descriptions.Item>
                  <Button
                    className="add-btn"
                    onClick={() => setOpenAddPrescription(true)}
                  >
                    + New Prescription
                  </Button>
                </Descriptions.Item>
              </Descriptions>
            )}

            {productFields?.length > 0 && (
              <Descriptions 
                layout='horizontal'
                column={1}
                bordered={true}
              >
                {productFields.map(({ label, value }, index) => (
                  <Descriptions.Item 
                    key={`product-field-${index}`}
                    label={label}
                  >
                    {value}
                  </Descriptions.Item>
                ))}
                <Descriptions.Item>
                  <Button
                    className="add-btn"
                    onClick={onNewTestKit}
                  >
                    + New Test Kit
                  </Button>
                </Descriptions.Item>
              </Descriptions>
            )}

            {appointmentFields?.length > 0 && (
              <Descriptions 
                layout='horizontal'
                column={1}
                bordered={true}
              >
                {appointmentFields.map(({ label, value }, index) => (
                  <Descriptions.Item 
                    key={`appointment-field-${index}`}
                    label={label}
                  >
                    {value}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            )}

            {membershipFields?.length > 0 && (
              <Descriptions 
                layout='horizontal'
                column={1}
                bordered={true}
              >
                {membershipFields.map(({ label, value }, index) => (
                  <Descriptions.Item 
                    key={`membership-field-${index}`}
                    label={label}
                  >
                    {value}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            )}

            {resultFields?.length > 0 && (
              <Descriptions 
                layout='horizontal'
                column={1}
                bordered={true}
              >
                {resultFields.map(({ label, value }, index) => (
                  <Descriptions.Item 
                    key={`result-field-${index}`}
                    label={label}
                  >
                    {value}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            )}
          </Col>

          <Col span={8}>
            <Form
              form={form}
              onFinish={onAddNote}
              layout='vertical'
            >
              <Row gutter={12}>
                <Col span={18}>
                  <Form.Item 
                    name="content"
                  >
                    <TextArea 
                      placeholder="Type note here..." 
                      autoSize
                    />
                  </Form.Item>

                  {openTag ? (
                    <Form.Item 
                      name="taggedUsers"
                    >
                        <Select
                          mode="multiple"
                          placeholder="Select teammates..."
                          className="teammate-select"
                          showSearch={false}
                          options={users.map(({ firstName, lastName, _id }) => {
                            return {
                              label: `${firstName} ${lastName}`,
                              value: _id
                            }
                          })}
                        />
                    </Form.Item>
                  ) : (
                    <a 
                      onClick={() => setOpenTag(true)}
                      className="tag-link" 
                    >
                      + Tag Teammates 
                    </a>
                  )}
                </Col>

                <Col span={6}>
                  <Form.Item>
                    <Button
                      type="primary"
                      className="submit-btn"
                      htmlType='submit'
                      loading={isAddingNote}
                    >
                      Save
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              {task?.notes?.length ? (
                <div className="note-list">
                  {task.notes.map(note => (
                    <div 
                      key={`note-${note._id}`}
                      className="note-item"
                    >
                      <Paragraph className="note-content">
                        {note.content}
                      </Paragraph>
                      <Text className="note-user">
                        {note.user.firstName} {note.user.lastName}
                      </Text>
                      <span className="note-bullet">
                        •
                      </span>
                      <Text className="note-created-at">
                        {moment(note.createdAt).format('MMM D, YYYY')} at {moment(note.createdAt).format('h:mm a')}
                      </Text>
                      {(currentUser?.role === Role.ADMIN || currentUser?._id === note.user._id) && (
                        <a onClick={() => onRemoveNote(note._id)} className="remove-link">
                          Remove
                        </a>
                      )}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no-notes">
                  No notes yet...
                </div>
              )}
            </Form>
          </Col>
        </Row>
      </div>
    </Modal>
  )
}
import { Typography, Button, Row, Col }  from 'antd'
import { useState } from 'react'
import Breakpoint from '../../enums/breakpoint.enum.js'
import LatestPosts from '../latestPosts/latestPosts.component.js'
import useWidth from '../../hooks/useWidth.hook.js'
import { TassoModal } from '../tassoModal/tassoModal.component';

const { Text, Paragraph } = Typography

const TotalTestosteroneTestConfirm = () => {
  const [openTasso, setOpenTasso] = useState()
  const width = useWidth()

  const instructions = [{
    sections: [
      {
      title: 'Receive Test Kit',
      description: <>It'll come within one week of purchase. This includes everything you'll need to collect your blood and send to lab.</>
    }, 
  ]}, 
  {
    sections: [{
      title: 'Self Collect Blood + Mail Back',
      description: <>You'll use a <a className="cta-link" onClick={(e) => {
        e.stopPropagation(); 
        setOpenTasso(true);
      }} >Tasso device</a> vs the usual fingerprick, which ensures a painless process. Just drop in mail when done.</>
    }]
  }, 
  {
    sections: [{
      title: 'Review Test Results',
      description: <>Test results will be ready 1 week after your sample arrives at the lab.</>
    }]
  }]

  return (
    <div className="prescription-approval">
      <TassoModal
        open={openTasso}
        setOpen={setOpenTasso}
      />

      {instructions?.length > 0 && (
        <div className="instruction-list">
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row>
                <Col
                  style={{
                    width: width > Breakpoint.SM ? 80 : '100%',
                    marginBottom: width > Breakpoint.SM ? 0 : 10
                  }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  style={{
                    width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                  }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>
      )}

      <Button
        className="question-btn"
        onClick={() => window.FrontChat("show")}
      >
        I have a question?
      </Button>

      <LatestPosts />
    </div>
  )
}

export default TotalTestosteroneTestConfirm
import {
  CalendarOutlined,
  VideoCameraAddOutlined,
  UserOutlined,
  DollarCircleOutlined,
  TableOutlined,
  FileSearchOutlined,
  ExperimentOutlined,
  ReadOutlined,
  StarOutlined,
  LoginOutlined,
  GiftOutlined,
  LogoutOutlined,
  EditOutlined,
  ShopOutlined,
  ProfileOutlined,
  HomeOutlined,
  MessageOutlined,
  FileDoneOutlined,
  KeyOutlined,
  DownloadOutlined, 
  TagsOutlined,
  IdcardOutlined,
  CheckSquareOutlined,
  FileOutlined,
  BankOutlined,
  UsergroupAddOutlined,
  SendOutlined
} from '@ant-design/icons'
import { ReactComponent as TubeOutlined } from '../assets/svg/blood-vial.svg'
import { ReactComponent as PrescriptionOutlined} from '../assets/svg/prescription-2.svg'; 
import { ReactComponent as ScienceOutlined } from "../assets/svg/science.svg"
import FlowType from '../enums/flowType.enum'
import Role from '../enums/role.enum';
import Permission from '../enums/permission.enum';

const LayoutHelper = {

  getMenuItems: (counts, currentUser, membership, navigate) => {
    if (!counts) return

    const testList = [
      {
        key: 'truage',
        label: 'Biological Age Test',
        urls: ['/truage'],
        onClick: () => navigate(`/truage`)
      },
      {
        key: 'cgm',
        label: "CGM",
        urls: [`/flow/${FlowType.CGM}`],
        onClick: () => navigate(`/flow/${FlowType.CGM}`)
      },
      {
        key: 'ccta',
        label: "CT Coronary Angiogram",
        urls: [`/cleerly`],
        onClick: () => navigate(`/cleerly`)
      },
      {
        key: 'biobeat',
        label: "Continuous BP Monitor",
        urls: [ `/biobeat`],
        onClick: () => navigate(`/biobeat`)
      },
      {
        key: 'dexa',
        label: "DEXA Bone Density",
        urls: [`/flow/${FlowType.DEXA}`],
        onClick: () => navigate(`/flow/${FlowType.DEXA}`)
      },
      {
        key: 'sleep',
        label: "Sleep Apnea Test",
        urls: [`/flow/${FlowType.SLEEP_APNEA}`],
        onClick: () => navigate(`/flow/${FlowType.SLEEP_APNEA}`)
      },
      {
        key: '5',
        label: "Toxic Metals Test",
        urls: [ `/flow/${FlowType.TOXIC_METALS_TEST}`],
        onClick: () => navigate(`/flow/${FlowType.TOXIC_METALS_TEST}`)
      },
      {
        key: 'prenuvo',
        label: "Whole Body MRI Scan",
        urls: [ `/flow/${FlowType.PRENUVO}`],
        onClick: () => navigate(`/flow/${FlowType.PRENUVO}`)
      },
    ]

    const popularTestList = [
      {
        key: 'longevity',
        label: "Longevity Blood Panel",
        urls: [ "/longevity-panel"],
        onClick: () => navigate("/longevity-panel")
      },
      {
        key: 'cac',
        label: "Coronary Calcium Scan",
        urls: [`/cac`],
        onClick: () => navigate(`/cac`)
      },
      {
        key: 'fertility',
        label: "Fertility Hormone Test",
        urls: [`/fertility`],
        onClick: () => navigate(`/fertility`)
      },
      {
        key: 'galleri',
        label: "Multi-Cancer Detection Test",
        urls: [ `/galleri-test`],
        onClick: () => navigate(`/galleri-test`)
      },
      {
        key: 'heart-health',
        label: "Heart Health Test",
        urls: [ `/heart-health-test`],
        onClick: () => navigate(`/heart-health-test`)
      },
      {
        key: 'testosterone',
        label: "Testosterone Panel",
        urls: [ `/testosterone`],
        onClick: () => navigate(`/testosterone`)
      },
    ]

    if (currentUser?.role === Role.ADMIN) {
      return [{
        key: 'Appointment-Calendar',
        label: (
          <>
            Appointment Calendar
            <div className="menu-item-count">{counts.futureAppointments?.toLocaleString()}</div>
          </>
        ),
        icon: <CalendarOutlined />,
        urls: ['/'],
        onClick:() => navigate('/')
      }, {
        key: 'Blood-Draws',
        label: (
          <>
            Blood Draws
            <div className="menu-item-count">{counts.draws?.toLocaleString()}</div>
          </>
        ),
        icon: <TubeOutlined />,
        urls: ['/admin/appointments'],
        onClick:() => navigate('/admin/appointments')
      }, {
        key: 'Lab Orders',
        label: (
          <>
            Lab Orders
            <div className="menu-item-count">{counts.orders?.toLocaleString()}</div>
          </>
        ),
        icon: <ProfileOutlined />,
        urls: ['/admin/orders'],
        onClick:() => navigate('/admin/orders')
      }, 
      {
        key: 'Longevity-Consults',
        label: (
          <>
            Consultations
            <div className="menu-item-count">{counts.consults?.toLocaleString()}</div>
          </>
        ),
        icon: <VideoCameraAddOutlined />,
        urls: ['/admin/consults'],
        onClick:() => navigate('/admin/consults')
      }, 
      {
        key: 'Tasks',
        label: (
          <>
            Concierge Tasks
            <div className="menu-item-count">{counts.tasks?.toLocaleString()}</div>
          </>
        ),
        icon: <CheckSquareOutlined />,
        urls: ['/admin/tasks'],
        onClick:() => navigate('/admin/tasks')
      },   
      {
        key: 'Events',
        label: (
          <>
            Events
            <div className="menu-item-count">{counts.events?.toLocaleString()}</div>
          </>
        ),
        icon: <UsergroupAddOutlined />,
        urls: ['/admin/events'],
        onClick:() => navigate('/admin/events')
      }, {
        key: 'Users',
        label: 'Users',
        icon: <UserOutlined />,
        children: [
          { 
            key: 'Patients', 
            urls: ['/admin/patients'],
            onClick: () => navigate('/admin/patients'),
            label: (
            <>
              - Patients
              <div className="menu-item-count">{counts.patients?.toLocaleString()}</div>
            </>
            )
          },
          { 
            key: 'Members', 
            urls: ['/admin/members'],
            onClick: () => navigate('/admin/members'),
            label: (
            <>
              - Members
              <div className="menu-item-count">{counts.members?.toLocaleString()}</div>
            </>
            )
          },
          { 
            key: 'HH Members', 
            urls: ['/admin/hhmembers'],
            onClick: () => navigate('/admin/hhmembers'),
            label: (
            <>
              - Heart Health
              <div className="menu-item-count">{counts.hhMembers?.toLocaleString()}</div>
            </>
            )
          },
          { 
            key: 'Phlebotomists', 
            urls: ['/admin/phlebotomists'],
            onClick: () => navigate('/admin/phlebotomists'),
            label: (
            <>
              - Phlebotomists
              <div className="menu-item-count">{counts.phlebotomists?.toLocaleString()}</div>
            </>
            )
          },
          { 
            key: 'Providers', 
            urls: ['/admin/providers'],
            onClick: () => navigate('/admin/providers'),
            label: (
            <>
              - Providers
              <div className="menu-item-count">{counts.providers?.toLocaleString()}</div>
            </>
            )
          },
          { 
            key: 'Staff', 
            urls: ['/admin/staffs'],
            onClick: () => navigate('/admin/staffs'),
            label: (
            <>
              - Staff
              <div className="menu-item-count">{counts.staff?.toLocaleString()}</div>
            </>
            )
          },
          { 
            key: 'Admins', 
            urls: ['/admin/admins'],
            onClick: () => navigate('/admin/admins'),
            label: (
            <>
              - Admins
              <div className="menu-item-count">{counts.admins?.toLocaleString()}</div>
            </>
            )
          },
        ],
      }, {
        key: 'Billing',
        label: (
          <>
            Billing
            <div className="menu-item-count">{counts.charges?.toLocaleString()}</div>
          </>
        ),
        icon: <DollarCircleOutlined />,
        urls: ['/billing'],
        onClick:() => navigate('/billing')
      }, {
        key: 'Pricing',
        label: 'Pricing',
        icon: <TagsOutlined />,
        children: [
          { 
            key: 'Membership-Types', 
            urls: ['/admin/membership-types'],
            onClick: () => navigate('/admin/membership-types'),
            label: (
            <>
              - Membership Types
              <div className="menu-item-count">{counts.membershipTypes?.toLocaleString()}</div>
            </>
            )
          },
          { 
            key: 'Product-Types', 
            urls: ['/admin/product-types'],
            onClick: () => navigate('/admin/product-types'),
            label: (
            <>
              - Product Types
              <div className="menu-item-count">{counts.productTypes?.toLocaleString()}</div>
            </>
            )
          }]
      }, {
        key: 'Facilities',
        label: (
          <>
            Facilities
            <div className="menu-item-count">{counts.facilities?.toLocaleString()}</div>
          </>
        ),
        icon: <BankOutlined />,
        urls: ['/admin/facilities'],
        onClick:() => navigate('/admin/facilities')
      }, 
      {
        key: 'Referrals',
        label: (
          <>
           Referrals
            <div className="menu-item-count">{counts.referrals?.toLocaleString()}</div>
          </>
        ),
        icon: <SendOutlined/>,
        urls: ['/admin/referrals'],
        onClick:() => navigate('/admin/referrals')
      }, 
      {
        key: 'Memberships',
        label: (
          <>
            Memberships
            <div className="menu-item-count">{counts.memberships?.toLocaleString()}</div>
          </>
        ),
        icon: <KeyOutlined />,
        urls: ['/admin/memberships'],
        onClick:() => navigate('/admin/memberships')
      }, {
        key: 'Prescriptions',
        label: (
          <>
            Prescriptions
            <div className="menu-item-count">{counts.prescriptions?.toLocaleString()}</div>
          </>
        ),
        icon: <PrescriptionOutlined/>,
        urls: ['/admin/prescriptions'],
        onClick:() => navigate('/admin/prescriptions')
      }, {
        key: 'Products',
        label: (
          <>
            Products
            <div className="menu-item-count">{counts.products?.toLocaleString()}</div>
          </>
        ),
        icon: <ShopOutlined />,
        urls: ['/admin/products'],
        onClick:() => navigate('/admin/products')
      }, {
        key: 'Test-Results',
        label: (
          <>
            Test Results
            <div className="menu-item-count">{counts.results?.toLocaleString()}</div>
          </>
        ),
        icon: <TableOutlined />,
        urls: ['/admin/results'],
        onClick:() => navigate('/admin/results')
      }, {
        key: 'reports',
        label: (
          <>
            Reports
            <div className="menu-item-count">{counts.reports?.toLocaleString()}</div>
          </>
        ),
        icon: <FileSearchOutlined />,
        urls: ['/admin/reports'],
        onClick:() => navigate('/admin/reports')
      }, {
        key: 'Biomarkers',
        label: (
          <>
            Biomarkers
            <div className="menu-item-count">{counts.tests?.toLocaleString()}</div>
          </>
        ),
        icon: <ExperimentOutlined />,
        urls: ['/admin/tests'],
        onClick:() => navigate('/admin/tests')
      }, 
      {
        key: 'Logout',
        label: 'Logout',
        icon: <LogoutOutlined />,
        urls: ['/logout'],
        onClick:() => navigate('/logout')
      }]
    } 
    else if (currentUser?.role === Role.PROVIDER) {
      let providerItems = [{
        key: 'Patients',
        label: (
          <>
            Patients
            <div className="menu-item-count">{counts.patients?.toLocaleString()}</div>
        </>
        ),
        icon: <UserOutlined />,
        urls: ['/', '/provider/patients'],
        onClick:() => navigate('/provider/patients')
      }, {
        key: 'Blood-Draws',
        label: (
          <>
            Blood Draws
            <div className="menu-item-count">{counts.draws?.toLocaleString()}</div>
          </>
        ),
        icon: <TubeOutlined />,
        urls: ['/provider/appointments'],
        onClick:() => navigate('/provider/appointments')
      }, {
        key: 'Longevity-Consults',
        label: (
          <>
            Consultations
            <div className="menu-item-count">{counts.consults?.toLocaleString()}</div>
          </>
        ),
        icon: <VideoCameraAddOutlined />,
        urls: ['/provider/consults'],
        onClick:() => navigate('/provider/consults')
      }, {
        key: 'Test-Results',
        label: (
          <>
            Test Results
            <div className="menu-item-count">{counts.results?.toLocaleString()}</div>
          </>
        ),
        icon: <TableOutlined />,
        urls: ['/provider/results'],
        onClick:() => navigate('/provider/results')
      }, {
        key: 'reports',
        label: (
          <>
            Reports
            <div className="menu-item-count">{counts.reports?.toLocaleString()}</div>
          </>
        ),
        icon: <FileSearchOutlined />,
        urls: ['/provider/reports'],
        onClick:() => navigate('/provider/reports')
      },
      {
        key: 'referrals',
        label: (
          <>
            Referrals 
            <div className="menu-item-count">{counts.referrals?.toLocaleString()}</div>
          </>
        ),
        icon: <SendOutlined />,
        urls: ['/provider/referrals'],
        onClick:() => navigate('/provider/referrals')
      }]

      if (currentUser?.chargePersonalCard) {
        providerItems.push({
          key: 'Billing',
          label: (
            <>
              Billing
              <div className="menu-item-count">{counts.charges?.toLocaleString()}</div>
            </>
          ),
          icon: <DollarCircleOutlined />,
          urls: ['/billing'],
          onClick:() => navigate('/billing')
        })
      }

      if (currentUser?.permissions?.includes(Permission.PRESCRIPTIONS)) {
        providerItems.push({
          key: 'Prescriptions',
          label: (
            <>
              Prescriptions
              <div className="menu-item-count">{counts.prescriptions?.toLocaleString()}</div>
            </>
          ),
          icon: <EditOutlined />,
          urls: ['/provider/prescriptions'],
          onClick:() => navigate('/provider/prescriptions')
        })
      }
    
      providerItems = [
        ...providerItems,
        {
          type: 'divider'
        },
        {
          key: 'Contact',
          label: (
            <>
              Concierge
            </>
          ),
          icon:  <MessageOutlined />,
          onClick:() => {
            window.FrontChat("show")
          }
        }, 
        {
          key: 'Logout',
          label: 'Logout',
          icon: <LogoutOutlined />,
          urls: ['/logout'],
          onClick:() => navigate('/logout')
        }
      ]

      return providerItems
    } 
    else if (currentUser?.role === Role.PHLEBOTOMIST) {
      return [{
        key: 'Tasks',
        label: (
          <>
            Concierge Tasks
            <div className="menu-item-count">{counts.tasks?.toLocaleString()}</div>
          </>
        ),
        icon: <CheckSquareOutlined />,
        urls: ['/phlebotomist/tasks'],
        onClick:() => navigate('/phlebotomist/tasks')
      }]
    }
    else if (currentUser?.role === Role.STAFF) {
      return [{
        key: 'Tasks',
        label: (
          <>
            Concierge Tasks
            <div className="menu-item-count">{counts.tasks?.toLocaleString()}</div>
          </>
        ),
        icon: <CheckSquareOutlined />,
        urls: ['/'],
        onClick:() => navigate('/')
      }, {
        key: 'Appointment-Calendar',
        label: (
          <>
            Upcoming Appointments
            <div className="menu-item-count">{counts.futureAppointments?.toLocaleString()}</div>
          </>
        ),
        icon: <CalendarOutlined />,
        urls: ['/staff/calendar'],
        onClick:() => navigate('/staff/calendar')
      }, {
        key: 'Lab Orders',
        label: (
          <>
            Lab Order Queue
            <div className="menu-item-count">{counts.orders?.toLocaleString()}</div>
          </>
        ),
        icon: <ProfileOutlined />,
        urls: ['/staff/orders'],
        onClick:() => navigate('/staff/orders')
      }, {
        type: 'divider'
      }, {
        key: 'Logout',
        label: 'Logout',
        icon: <LogoutOutlined />,
        urls: ['/logout'],
        onClick:() => navigate('/logout')
      }]
    }
    else if (currentUser?.role === Role.PATIENT && currentUser?.email) {

      let orderItems = [
        {
          key: 'blood-draws',
          label: "Appointments",
          urls: [ "/patient/appointments"],
          onClick: () => navigate("/patient/appointments")
        },
        {
          key: 'test-kits',
          label: "Test Kits",
          urls: [ "/patient/kits"],
          onClick: () => navigate("/patient/kits")
        }
      ]

      if (membership) {
        orderItems = [
          ...orderItems,
          {
            key: 'consultations',
            label: 'Consultations',
            urls: ["/patient/consults"],
            onClick: () => navigate("/patient/consults"),
          },
          {
            key: 'Treatments',
            label: (
              <>
                Prescriptions
              </>
            ),
            urls: ['/patient/treatments'],
            onClick:() => navigate('/patient/treatments')
          }
  
        ]
      }



      let items = [{
        key: 'Home',
        label: <>Home</>,
        icon: <HomeOutlined/>,
        urls: ['/', "/patient/home"],
        onClick:() => navigate('/patient/home'),
      },
      {
        key: 'Membership',
        label: (
          <>
            Membership

            {membership && <div className="active-membership">
                  <StarOutlined className="active-icon" /> Active
                </div> 
            }
          </>
        ),
        icon: <IdcardOutlined />,
        urls: ['/patient/memberships'],
        onClick:() => navigate('/patient/memberships')
      },
      {
        key: 'orders',
        label: 'Orders',
        icon: <TubeOutlined/>,
        children: [...orderItems]},
      ]

      if (counts.results?.toLocaleString() > 0) {
        items = [...items, {
          key: 'Test-Results',
          label: (
            <>
              Test Results
            </>
          ),
          icon: <TableOutlined />,
          urls: ['/patient/results'],
          onClick:() => navigate('/patient/results')
        }]
      }

      if (counts?.reports>0) {
        items = [...items, 
          {
            key: 'Latest-Report',
            label: 'Latest Report',
            icon: <FileSearchOutlined />,
            urls: ['/patient/report'],
            onClick:() => navigate('/patient/report')
          },
          {
            key: 'Game-Plan',
            label: 'Game Plan',
            icon: <CheckSquareOutlined />,
            urls: ['/patient/plan'],
            onClick:() => navigate('/patient/plan')
          },
        ]
      }

      items.push(
        {
        key: 'Medical-Information',
        label: 'Medical Info',
        icon: <ProfileOutlined />,
        urls: ['/patient/medical'],
        onClick:() => navigate('/patient/medical')
      },
      {
        key: 'Files',
        label: (
          <>
            Files
          </>
        ),
        icon: <FileOutlined />,
        urls: ['/patient/files'],
        onClick:() => navigate('/patient/files')
      },
      counts.charges > 0 && {
        key: 'Billing',
        label: (
          <>
            Billing
          </>
        ),
        icon: <DollarCircleOutlined />,
        urls: ['/billing'],
        onClick:() => navigate('/billing')
      },
      {
        type: 'divider'
      })


      if (counts?.results > 0) {
        items = [...items, {
          key: 'Sharing',
          label: (
            <>
              Sharing
            </>
          ),
          icon: <DownloadOutlined />,
          urls: ['/patient/share'],
          onClick:() => navigate('/patient/share')
        }, 
      ]
      }

      if (membership) {
        items = [...items, 
          {
          key: 'Contact',
          label: (
            <>
              Concierge
            </>
          ),
          icon:  <MessageOutlined />,
          onClick:() => {
            window.FrontChat("show");
          }
        }, 
        ]        
      }
      else {
        items = [...items, {
          key: 'Contact',
          label: (
            <>
              Support
            </>
          ),
          icon:  <MessageOutlined />,
          onClick:() => window.FrontChat("show")
        }, 
        ] 
      }

      items = [...items, {
        key: 'Gift-Cards',
        label: 'Gift Instalab',
        icon: <GiftOutlined/>,
        urls: ['/gift'],
        onClick:() => navigate('/gift')
      },
      {
        key: 'Newsletter',
        label: 'Newsletter',
        icon: <ReadOutlined />,
        urls: ['/newsletter'],
        onClick:() => window.open('/newsletter', '_blank')
      }, {
        key: 'Logout',
        label: 'Logout',
        icon: <LogoutOutlined />,
        urls: ['/logout'],
        onClick:() => navigate('/logout')
      }]

      return items
    } 
    else {
      return [{ 
        key: 'Home',
        label: 'Home',
        icon: <HomeOutlined />,
        urls: ['/'],
        onClick:() => navigate('/')
      }, 

      // {
      //   key: 'Membership',
      //   label: 'Join Instalab Membership',
      //   icon: <IdcardOutlined/>,
      //   onClick: () => navigate(`/flow/${FlowType.MEMBER}`)
      // },  

      {
        key: 'popular-tests',
        label: 'Most Popular Tests',
        icon: <TubeOutlined/>,
        children:popularTestList
      }, 

      {
        key: 'testing',
        label: 'Other Popular Tests',
        icon: <ScienceOutlined/>,
        children:testList
      }, 
      // {
      //   key: 'Treatment',
      //   label: 'Cholesterol Medication',
      //   icon: <PrescriptionOutlined/>,
      //   urls: ['/cholesterol'],
      //   onClick:() => navigate('/cholesterol')
      // },

      // {
      //   key: 'consultations',
      //   label: 'Consultations',
      //   icon: <PhysicianIcon/>,
      //   children: [{
      //     key: FlowType.LONGEVITY_CONSULT,
      //     label: 'Longevity Physician',
      //     urls: [`/${FlowType.LONGEVITY_CONSULT}/intro`],
      //     onClick: () => navigate(`/flow/${FlowType.LONGEVITY_CONSULT}`)
      //   }, {
      //     key: FlowType.HEART_HEALTH_CONSULT,
      //     label: 'Clinical Lipidologist',
      //     urls: [`/${FlowType.HEART_HEALTH_CONSULT}/intro`],
      //     onClick: () => navigate(`/flow/${FlowType.HEART_HEALTH_CONSULT}`)
      //   }, {
      //     key: FlowType.METABOLIC_HEALTH_CONSULT,
      //     label: 'Metabolic Specialist',
      //     urls: [`/${FlowType.METABOLIC_HEALTH_CONSULT}/intro`],
      //     onClick: () => navigate(`/flow/${FlowType.METABOLIC_HEALTH_CONSULT}`)
      //   }]
      // },

      {
        type: 'divider'
      }, 
      {
        key: 'Login',
        label: 'Login',
        icon: <LoginOutlined />,
        urls: ['/login'],
        onClick:() => navigate('/login')
      }, {
        type: 'divider'
      }, 
      { 
        key: 'Contact',
        label: 'Contact',
        icon: <MessageOutlined />,
        onClick:() => window.FrontChat("show")
      }, 
      {
        key: 'Gift-Cards',
        label: 'Gift Instalab',
        icon: <GiftOutlined/>,
        urls: ['/gift'],
        onClick:() => navigate('/gift')
      },

            {
        key: 'Newsletter',
        label: 'Newsletter',
        icon: <ReadOutlined />,
        urls: ['/newsletter'],
        onClick:() => window.open('/newsletter', '_blank')
      }, {
        key: 'Terms',
        label: 'Terms',
        icon: <FileDoneOutlined />,
        urls: ['/terms'],
        onClick:() => navigate('/terms')
      }, {
        key: 'Privacy',
        label: 'Privacy',
        icon: <FileDoneOutlined />,
        urls: ['/privacy'],
        onClick:() => navigate('/privacy')
      }]
    }
  }
}

export default LayoutHelper

import { Modal, Form, message, Input, Button, Select, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { updateReferral, addReferral, getReferral } from "../../services/referral.service.js";
import { ContinueButton } from '../continueButton/continueButton.component';
import { ReferralType } from "../../enums/index.enum.js";

const select = '_id firstName lastName location title scheduleLink type author image';
const { Item } = Form;

export const ReferralForm = ({ open, setOpen, referralId, onSuccess }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (open && referralId) {
      setReferralFields();
    } else {
      form.resetFields();
    }
  }, [open, referralId]);

  const setReferralFields = async () => {
    const { 
      firstName,
      lastName,
      title,
      scheduleLink,
      type,
      image
     } = await getReferral(referralId, { select });
    form.setFieldsValue({ 
      firstName, 
      lastName, 
      title,
      scheduleLink,
      type,
      image  
    });
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onOk = async () => {
    setIsSubmitting(true);
    try {
      const { 
        firstName,
        lastName,
        title,
        scheduleLink,
        type,
        image 
      } = form.getFieldsValue();
      const params = { 
        fields: {
          firstName,
          lastName,
          title,
          scheduleLink,
          type,
          image
        },
        select
      };
      const response = referralId ? await updateReferral(referralId, params) : await addReferral(params);
      if (onSuccess) {
        onSuccess(response);
      }
      message.success(referralId ? 'Referral updated' : 'Referral added');
      setOpen(false);
      form.resetFields(); // Resetting form fields in both cases
    } catch (err) {
      let msg = referralId ? 'Failed to update Referral' : 'Failed to add Referral';
      if (err.response?.data?.code === 11000) {
        msg = 'Account already exists with this email';
      }
      message.error(msg);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal 
      open={open}
      title={referralId ? 'Edit Referral' : 'Add Referral'}
      onCancel={onCancel}
      onOk={onOk}
      okText='Save'
      footer={[
        <ContinueButton
          key="submit"
          onClick={onOk}
          text='Save'
          isSubmitting={isSubmitting}
        />
      ]}
    >
      <Form
        form={form}
        onFinish={onOk}
        layout='vertical'
        className="admin-referrals-form"
      >

        <Item
          name="type"
          label="Referral Type"
          rules={[{
            required: true,
            message: 'Select referral type'
          }]}
        >
          <Select
            placeholder="Select referral type"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => 
              option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
            }
            options={Object.values(ReferralType).sort().map(referralType => {
              return {
                label: referralType,
                value: referralType,
              }
            })}
          />
        </Item>
        <Item
          label="Title"
          name="title"
        >
          <Input placeholder="Title, e.g. Dr." />
        </Item>
        <Item
          label="First Name"
          name="firstName"
          rules={[{
            required: true,
            message: 'Enter first name',
          }]}
        >
          <Input placeholder="First Name" />
        </Item>

        <Item
          label="Last Name"
          name="lastName"
          rules={[{
            required: true,
            message: 'Enter last name',
          }]}
        >
          <Input placeholder="Last Name" />
        </Item>

        <Item
          label="Schedule Link"
          name="scheduleLink"
          rules={[{
            required: true,
            message: 'Enter schedule link',
          }]}
        >
          <Input placeholder="Schedule link, e.g. calendly" />
        </Item>

        <Item
          label="Image Link"
          name="image"
        >
          <Input placeholder="Image link" />
        </Item>
      </Form>
    </Modal>
  );
};
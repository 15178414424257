import React, { useEffect, useState } from 'react';
import { Table, Button, Tooltip } from 'antd'
import { listPrescriptions } from "../../services/prescription.service"
import moment from 'moment'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { useNavigate } from "react-router-dom";
import { TrackingHelper } from "../../helpers/tracking.helper.js"

import "./prescriptions.scss"
import PrescriptionStatus from '../../enums/prescriptionStatus.enum.js';

const { Column } = Table

export const Prescriptions = () => {
  const [prescriptions, setPrescriptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchPrescriptions = async () => {
      setPrescriptions(await listPrescriptions())
      setIsLoading(false)
    }
    document.title = 'Prescriptions | Instalab'
    fetchPrescriptions()
  }, [])


  const onGetTreatment = async () => {
    navigate('/cholesterol')    
  };

  return (
    <div className="prescriptions">
      <PageHeader
        title='Prescriptions'
        actions={(<Button type='primary' onClick={onGetTreatment}>+ Get Treatment</Button>)}  
      />

      <Table
        loading={isLoading}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={prescriptions}
      >

        <Column
          title='Type'
          dataIndex='type'
        />

        <Column
          title='Ordered'
          dataIndex='createdAt'
          render={createdAt => moment(createdAt).format('MM/DD/YYYY')}
        />

        <Column
          title='Next Refill'
          dataIndex='createdAt'
          render={(createdAt, { gogo, status, hasRefill, daysToRefill }) => { 
            if (status === PrescriptionStatus.PAUSED) {
              return 'Paused'
            } 
            else if (!hasRefill) {
              return 'None'
            }
            else if (daysToRefill) {
              return moment(createdAt).add(daysToRefill, 'days').format('MM/DD/YYYY')
            }
            else {
              return gogo ? moment(createdAt).add(gogo.units/(gogo.doseQty ? gogo.doseQty : 1), 'days').format('MM/DD/YYYY') :  moment(createdAt).add(90, 'days').format('MM/DD/YYYY') 
            }
          }}
        />

        <Column
          title='Tracking'
          dataIndex='status'
          render={(status, { tracking, _id, isPickupOrder }) => {
            if (isPickupOrder) 
              return 'n/a'

            if (tracking?.trackingEvents?.length > 0) {
              return <Tooltip 
              overlayStyle={{ maxWidth: '390px'}}
              title={(
                <ul className="tracking-list">
                  {tracking.trackingEvents.map(({ eventTimestamp, eventType }, index) => (
                    <li key={`tracking-${_id}-${index}`}>
                      <strong>{moment(eventTimestamp).format('MM/DD/YYYY')}</strong>: {eventType}
                    </li>
                  ))}
                </ul>
              )}
            >
              <a className="link" onClick={(e) => {
                e.stopPropagation(); 
                window.open(`https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1=${tracking.trackingNumber}`, '_blank');
              }}>{tracking.trackingNumber}</a>
            </Tooltip>
          }
          else if (tracking) {
            return <a className="link" href={TrackingHelper.getDeliveryService(tracking.trackingNumber).url} target="_blank">{tracking.trackingNumber}</a>
          
            } else if (status === PrescriptionStatus.SENT) {
              return 'Pharmacy Fulfilling'
            } else {
              return 'Pending Physician Approval'
            }
          }}
        />


      </Table>
    </div>
  )
}
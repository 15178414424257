import { posthog } from "posthog-js";

const PosthogHelper = {
  track: async (user, eventCode) => {
    if (!eventCode || window.location.hostname === "localhost") { 
      console.log("posthog track: ", eventCode, user)
      return
    }
    posthog.capture(
      eventCode, 
      { 
        $set: { 
          email: user?.email,
          _id: user?._id,
          tracker: user?.tracker,
        },
      }
    )
  }
}

export default PosthogHelper

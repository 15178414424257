const PlanCode = Object.freeze({
  // Standard
  BASIC: 'Basic',
  ADVANCED: 'Advanced',
  ELITE: 'Elite',

  // Jeff Tang
  TESTOSTERONE: 'Testosterone',

  LONGEVITY: 'Longevity Program',
  CUSTOM: 'Custom',
  ATHLETE: 'Athlete',
  ALLERGY: 'Allergy',
})

export default PlanCode
const TaskCategory = Object.freeze({
  SPOT: 'spot',
  LONGEVITY_PANEL: 'longevity panel',
  MEMBER: 'member',
  GALLERI: 'galleri',
  HEAVY_METALS_TEST: 'heavy metals test',
  CORONARY_CALCIUM_SCAN: 'coronary calcium scan',
  CHOLESTEROL_TREATMENT: 'cholesterol treatment',
})

export default TaskCategory
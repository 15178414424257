
import { Button, Progress, Affix, Typography } from 'antd'
import Breakpoint from '../../enums/breakpoint.enum'
import Color from '../../colors.scss'
import {
  UpOutlined,
  DownOutlined,
  MessageFilled,
  CheckOutlined,
  ArrowRightOutlined
} from '@ant-design/icons'
import './flowSample.scss'
import {
  setDefaults,
} from "react-geocode";
import useWidth from '../../hooks/useWidth.hook'
import { Colors } from 'chart.js'

const { Text, Paragraph } = Typography

setDefaults({
  key: process.env.REACT_APP_GOOGLE_MAP_KEY,
  language: "en", // Default language for responses.
  region: "es", // Default region for responses.
})

export const FlowSample = ({ 

}) => {
  const width = useWidth()

  return <>
    <Affix offsetTop={0} className="flow-progress-affix">
      <Progress 
        percent={0} 
        showInfo={false}
        className="flow-progress"
        strokeColor={Color.success}
      />
    </Affix>
    
    <div className="flow">
      <div 
        className="flow-step-dynamic"
        style={{
          margin: width > Breakpoint.SM ? '100px auto' : '40px auto'
        }}
      >
        <div className="flow-step">
          <div className="flow-step-header">
            <div className="flow-step-count">
              <Text className="flow-step-index">
                1
              </Text>

              <ArrowRightOutlined className="flow-step-arrow" />
            </div>

            <Paragraph className="flow-step-title">
              Before you sign up, please review and agree to the following:
            </Paragraph>

            <Paragraph className="flow-step-description">
              <strong>Client Relationship</strong>: By registering, you acknowledge that you are a client of Newman HP. This means you receive services and support from Thomas Newman and other professionals at Newman HP.
              <br/><br/>
              <strong>Data Access</strong>: Your Instalab data will be accessible to NewmanHP and Dr. Steven Winiarski, an independent physician. This access is essential to provide you with personalized and high-quality services. All data will be managed with the highest level of care and in compliance with applicable privacy regulations.
              <br/><br/>
              By approving, you confirm that you understand and agree to the above terms.

            </Paragraph>
          </div>
        </div>

        <div className="flow-step-footer">
          <Button
            className="flow-step-submit"
            type='primary'
          >
            Approve <CheckOutlined />
          </Button>
          <Button
            className="flow-step-submit"
            style={{ background: Color.error, marginLeft: 10 }}
            type='primary'
          >
            Decline
          </Button>
        </div>
      </div>

      {width > Breakpoint.SM && (
        <div className="flow-footer">
          <div className="flow-nav">
            <Button
              icon={<UpOutlined />}
              type='primary'
              className="flow-nav-up"
            />
            <Button
              icon={<DownOutlined />}
              type='primary'
              className="flow-nav-down"
            />
          </div>

          <Button 
            className="flow-contact"
            type='primary'
            onClick={() => window.FrontChat("show")}
            icon={<MessageFilled />}
          >
            Contact Us
          </Button>
        </div>
      )}
    </div>
  </>
}
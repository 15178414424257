import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { PanelModal } from "../panelModal/panelModal.component"
import { advancedPanel } from "../../data/advancedPanel.data"
import { listMemberships } from "../../services/membership.service"
import MembershipHelper from "../../helpers/membership.helper"
import ConfirmAppointment from "./static/confirmAppointment/confirmAppointment.component"
import { WarningFilled } from '@ant-design/icons'
import { getAvailability } from "../../services/schedule.service"
import PhlebType from "../../enums/phlebType.enum"
import { getProductTypeByCode } from '../../services/productType.service'
import { completeFlow } from "../../services/flow.service"
import Gender from "../../enums/gender.enum"
import './proDrawFlow.scss';
import ProductHelper from "../../helpers/product.helper"
import { athletePanel } from "../../data/athletePanel.data"
import { invitePatient } from "../../services/patient.service"
import { listUserCounts } from "../../services/user.service"
import Role from "../../enums/role.enum"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"

export const Step = {
  LOCATION: 'location',
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  GENDER: 'gender',
  DOB: 'dob',
  PAY: 'pay',
  INTEREST: 'interest',
  SCHEDULE: 'schedule',
  APPOINTMENT_CONFIRMATION: 'appointment-confirmation',
  SHIPPING: 'shipping',
}

export const ProDrawFlow = () => {
  const { currentUser, setCounts } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [openPanel, setOpenPanel] = useState()
  const [phlebType, setPhlebType] = useState()
  const [productType, setProductType] = useState()
  const [payDescription, setPayDescription] = useState()
  
  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  const [longevityMembership, setLongevityMembereship] = useState()

  const panels = {
    [ProductTypeCode.LONGEVITY_PANEL]: advancedPanel,
    [ProductTypeCode.ATHLETE_PANEL]: athletePanel,
  }

  const panelTitles = {
    [ProductTypeCode.LONGEVITY_PANEL]: 'The Lonevity Panel',
    [ProductTypeCode.ATHLETE_PANEL]: 'The Athlete Panel',
  }

  useEffect(() => {
    fetchSkeleton()
  }, [productType, longevityMembership, flow, phlebType, payDescription])

  useEffect(() => {
    fetchPhlebType()
  }, [flow])

  useEffect(() => {
    fetchProductType()
    fetchLongevityMembership()
  }, [flow])

  useEffect(() => {
    fetchPayDescription()
  }, [productType, currentUser])

  const fetchLongevityMembership = async () => {
    if (!flow?.user) return
    const memberships = await listMemberships({
      filter: {
        patient: flow.user._id
      },
      select: 'status endAt',
      populate: {
        path: 'membershipType',
        satus: 'code'
      }
    })
    setLongevityMembereship(MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.LONGEVITY))
  }

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };
  
  const fetchPayDescription = () => {
    if (!productType || !currentUser) return

    let panelDescription, payDescription
    switch (productType.code) {
      case ProductTypeCode.LONGEVITY_PANEL:
        panelDescription = <>This includes tests for <a onClick={() => setOpenPanel(true)} target="panel-link">60+ biomarkers</a> at once to proactively detect health risks well before symptoms appear and discover ways to optimize for long-term health.</>
        break
      case ProductTypeCode.ATHLETE_PANEL:
        panelDescription = <>This includes tests for <a className="panel-link" onClick={() => setOpenPanel(true)}>70+ biomarkers</a> to proactively detect your health risks well before symptoms appear and to discover ways to optimize your athletic and long-term health.</>
        break
    }
    if (currentUser.chargePersonalCard) {
      payDescription = <>Note: use your own personal card for this purchase.</>
    } else {
      payDescription = <>Note: use your client's card for this purchase.</>
    }

    setPayDescription(<>{panelDescription}<br/><br/>{payDescription}</>)
  }

  const fetchPhlebType = async () => {
    if (!flow?.user?.location) return
    const response = await getAvailability({
      startDate: new Date(),
      location: flow.user.location,
      flowId: flow?._id
    })
    setPhlebType(response.phlebType)
  }

  const fetchProductType = async () => {
    if (!flow) return
    if (flow?.products?.length) {
      setProductType(flow.products[0].productType)
    } else if (flow?.proxy?.defaultProductType) {
      setProductType(flow.proxy.defaultProductType)
    } else {
      setProductType(await getProductTypeByCode(ProductTypeCode.LONGEVITY_PANEL))
    }
  }

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        onNextStep: async (patient) => {
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType || flow?.user?.phlebType
          return phlebType ? Step.NAME : Step.INTEREST
        },
        title: `Where is your client located?`,
        description: `The phlebotomist will meet your client here to draw their blood.`,
        field: {
          name: 'location'
        },
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.EMAIL,
        buildUser: true,
        title: `What is your client's full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.EMAIL]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What is ${flow?.user?.firstName}'s email address?`,
        field: {
          name: 'email',
          placeholder: 'Type email address here...',
          email: true,
          required: true,
          uniqueError: <>
            <WarningFilled />&nbsp; Email already taken.
            &nbsp;
            <a className="error-link" onClick={onChat}>Message us</a> to add this client to your account.
          </>
        },
        skipIf: () => {
          return initialUserRef?.current?.email
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What is ${flow?.user?.firstName}'s phone number?`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type phone number here...',
          required: true,
          unique: true,
          uniqueError: <>
            <WarningFilled />&nbsp; Phone number already taken.
            &nbsp;
            <a className="error-link" onClick={onChat}>Message us</a> to add this client to your account.
          </>
        },
        skipIf: () => {
          return initialUserRef?.current?.phone
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What is ${flow?.user?.firstName}'s biological sex?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUserRef?.current?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PAY,
        buildUser: true,
        title: `What's ${flow?.user?.gender === Gender.MALE ? 'his' : 'her'} date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.dob
        }
      },
      [Step.INTEREST]: {
        type: StepType.STATIC,
        buildUser: true,
        title: `Unfortunately, we don't service this area yet...`,
        description: <>If you would like us to find a lab close to {flow?.user.firstName} for {flow?.user?.gender === Gender.MALE ? 'his' : 'her'} blood draw, email us at <a href="mailto:concierge@instalab.com">concierge@instalab.com</a>.</>,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
      },
      [Step.PAY]: {
        type: StepType.PAY,
        onNextStep: (patient) => {
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType || flow?.user?.phlebType
          if (phlebType === PhlebType.INSTALAB) {
            return Step.SCHEDULE
          }
          return Step.SHIPPING
        },
        addProduct: true,
        productType: productType?.code,
        title: (currentUser?.role === Role.PROVIDER || longevityMembership) ? 
          <>Pay <span className="true-price">{ProductHelper.formatDiscountCost(productType)}</span> <span className="original-price">${productType?.cost}</span> for the {productType?.title}.</> :
          <>Pay <span className="true-price">${productType?.cost}</span> for the {productType?.title}.</>,
        description: payDescription,
        onSuccess: async () => {
          setCounts(await listUserCounts())
        }
      },
      [Step.SHIPPING]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.SCHEDULE,
        title: `Where should we ship your client's blood test kit?`,
        description: `${flow?.user?.firstName} will receive a test kit in the mail containing all the supplies needed for our phlebotomist to complete ${flow?.user?.gender === Gender.MALE ? 'his' : 'her'} blood draw appointment.`,
        field: {
          name: 'shippingLocation'
        },
      },
      [Step.SCHEDULE]: {
        type: StepType.SCHEDULE,
        nextStep: Step.APPOINTMENT_CONFIRMATION,
        addAppointment: true,
        title: `Schedule your client's blood draw appointment.`,
        description: phlebType === PhlebType.INSTALAB ? 
                      `The appointment takes about 15 minutes. ${flow?.user?.firstName} must fast 8 hours before ${flow?.user?.gender === Gender.MALE ? 'his' : 'her'} appointment. Water and black coffee are fine.` :
                      `A phlebotomist from our partner (Getlabs) will arrive during the 2-hour time window you select. The appointment takes about 10 minutes. ${flow?.user?.firstName} must fast 8 hours before ${flow?.user?.gender === Gender.MALE ? 'his' : 'her'} appointment. Water and black coffee are fine. `,
        onSuccess: async () => {
          await invitePatient(flow?.user?._id)
          setCounts(await listUserCounts())
        }
      },
      [Step.APPOINTMENT_CONFIRMATION]: {
        type: StepType.STATIC,
        title: `Your client's blood draw appointment is confirmed!`,
        description: flow?.appointment?.phlebotomist ? 
                        <>We have sent a text confirmation and calendar invite to {flow?.user?.firstName} to confirm {flow?.user?.gender === Gender.MALE ? 'his' : 'her'} appointment.</> :
                        <>We have sent a text confirmation and calendar invite to {flow?.user?.firstName} to confirm {flow?.user?.gender === Gender.MALE ? 'his' : 'her'} appointment. A test kit will be mailed with all the supplies needed to complete {flow?.user?.gender === Gender.MALE ? 'his' : 'her'} draw. A phlebotomist from our partner (Getlabs) will call or text {flow?.user?.gender === Gender.MALE ? 'him' : 'her'} upon arrival on the day of the appointment.</>,
        buttonText: 'Back to Patient Profile',
        path: `/patients/${flow?.user?._id}?key=Appointments`,
        content: <ConfirmAppointment flow={flow} />,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  return <>
    {skeleton && <>
      {productType && (
        <PanelModal
          panel={panels[productType.code]}
          title={panelTitles[productType.code]}
          open={openPanel}
          setOpen={setOpenPanel}
        />
      )}
      <Flow 
        skeleton={skeleton} 
        flow={flow} 
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        startIndex={1}
      />
    </>}
  </>
}
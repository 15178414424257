
import { useRef, useEffect, useState } from "react";
import { EventCode } from "../../enums/index.enum";
import { ProductDetail } from '../productDetail/productDetail.component';
import { ReactComponent as BloodSVG} from '../../assets/svg/blood.svg'; 
import { ReactComponent as CardSVG} from '../../assets/svg/card.svg'; 
import { ReactComponent as HomeSVG} from '../../assets/svg/house.svg'; 
import ProductTypeCode from "../../enums/productTypeCode.enum"
import ProductHelper from "../../helpers/product.helper";
import ProductDetailSection from "../../enums/productDetailSection.enum";
import { getProductTypeByCode } from "../../services/productType.service";

export const CholesterolTreatmentTest = () => {

  const [productType, setProductType] = useState()
  const [sections, setSections] = useState(null)
  const panelRef = useRef(null);

  // init + get prices
  useEffect(() => {
    fetchProductType(); 
  }, []);

  const fetchProductType = async () => {
    setProductType(await getProductTypeByCode(ProductTypeCode.CHOLESTEROL_TREATMENT_TEST_KIT))
  };


  const cholesterolTreatmentPanel = [
    {
      name: "Apolipoprotein B",
      description: "ApoB is a protein found on all lipid particles that have the potential to contribute to atherosclerosis, making it one of the most powerful predictors of heart disease risk."
    },
    {
      name: "Hemoglobin A1c",
      description: "HbA1c reflects your average blood sugar levels over the past 2 to 3 months. A common side effect of cholesterol medications is insulin resistance."
    },
    {
      name: "Alanine Aminotransferase",
      description: "ALT is a liver enzyme that helps convert proteins into energy. A common side effect of cholesterol medications is increased liver enzymes."
    },
    {
      name: "Direct LDL Cholesterol",
      description: "The amount of cholesterol in harmful, atherogenic particles called LDL (low-density lipoproteins). Higher levels increase your risk for heart attack and stroke.",
    },
    {
      name: "HDL Cholesterol",
      description: "The amount of cholesterol in particles called HDL, which helps remove cholesterol from the bloodstream and artery walls. Higher levels are associated with a reduced risk of heart disease."
    },
    {
      name: "VLDL Cholesterol",
      description: "The amount of cholesterol in particles called VLDL, which also transports triglycerides from the liver to tissues. High level are asociated with increased risk of heart disease."
    },
    {
      name: "Triglycerides",
      description: "Triglycerides are a type of fat found in the blood and are an important source of energy. But elevated levels can contribute to an increased risk of heart disease."
    },
    {
      name: "Total Cholesterol",
      description: "The amount of cholesterol in your blood. It's a lipid crucial for proper functioning of cells and carried throughout your body in particles called lipoproteins."
    }
  ]
  
  // set product attributes
  useEffect(() => {
    if (!productType) return
    const scrollToPanel = () => {panelRef.current?.scrollIntoView({ behavior: 'smooth' });}
    const faqs = [
      {
          question: "What biomarkers are included?",
          answer: <>The Cholesterol Treatment Test is carefully curated by leading cardiovascular experts, providing a comprehensive assessment of how well your cholesterol treatment is going. It includes ApoB, HbA1c, ALT, Direct LDL Cholesterol, and more. <a onClick={scrollToPanel}  className="cta-link">Learn More</a></>
         
      },
      {
        question: "How is the Tasso+ virtually painless?",
        answer: "The Tasso+ device offers a more comfortable and convenient alternative to traditional finger pricks for blood collection. Unlike finger pricks, which can cause sharp pain by puncturing deeper into the skin, the Tasso+ uses microneedles and a gentle suction mechanism to draw blood from the skin’s surface with minimal penetration. This reduces the discomfort and potential bruising associated with finger pricks."
      },
      {
        question: `How long does it take to get my results?`,
        answer: <>It typically takes about 5 days to get results after your sample arrives at our lab. We’ll send you an email when your results and recommendations are ready to go!</>
      }, 
      {
          question: `Where is this available?`,
          answer: `Anywhere in the United States.`
      },
      {
          question: `Do I need to fast before I collect my blood?`,
          answer: `Yes, please fast 8 hours before your collection.`,
      }, 
      {
          question: `Where is my blood being sent?`,
          answer: `Your sample is sent to one of our CLIA + CAP certified labs for analysis. After the lab is done, any remaining sample is disposed.`,
      }, 
      {
          question: `Can I talk to an actual human physician to review my results with me?`,
          answer: `Yes. Once you receive your results, you have the option to schedule a video consultation with a clinical lipidologist. They'll be ready to address any of your questions.`,
      }, 
      {
          question: `Does Instalab help with any followup needs?`,
          answer: `Yes. Instalab helps members with any additional testing and referrals to heart health specialists.`
      },
      {
          question: "How often should I get this panel done?",
          answer: `If you're currently taking medications or making any changes to your diet or lifestyle, it's good to check every 6 to 12 weeks to make sure those changes are helping you. This will let you figure out`,
      },             
      {
          question: `Does my health insurance cover this?`,
          answer: `For price transparency reasons, Instalab does not accept insurance. However, if you have an HSA/FSA, you may be eligible for reimbursement. We recommend using your HSA/FSA card when purchasing anything at Instalab. If you don’t have one, we can generate an itemized receipt for you to submit.`,
      }, 

    ]

    setSections([
      { type: ProductDetailSection.HERO,
        data: {
          title: productType.title,
          memberPrice: ProductHelper.getMemberPrice(productType.cost),
          originalPrice: productType.cost,
          oneliner: "An advanced at-home test that lets you easily track the progress and any side effects of your cholesterol treatment plan.",
          panelRef: panelRef,
          cta: {
            text: "Buy Now",
            url: `/flow/${productType.flowType}`
          },
          photos: [
              {
                original: "/img/cholesterol-treatment-test.png", 
                thumbnail: "/img/cholesterol-treatment-test.png"
              },

              {
                original: "/img/tasso.png",
                thumbnail:  "/img/tasso.png"
              },

              {
                original: "/img/fertility-tasso-grid.png",
                thumbnail:  "/img/fertility-tasso-grid.png"
              },

              // {
              //   original: "/img/fertility-results.png",
              //   thumbnail:  "/img/fertility-results.png"
              // },
          ],
          points: [
              <><BloodSVG className="point-icon"/>Test for 8 key cholesterol treatment related biomarkers</>,
              <><HomeSVG className="point-icon"/>Easy and painless sample collection with Tasso+</>,
              <><CardSVG className="point-icon" />HSA / FSA cards accepted</>,
          ],
          faqs: faqs.slice(0,3)
        },
      },
      {
        type: ProductDetailSection.SMALLPANEL,
        title: "Tests Included",
        data: {
          panel: cholesterolTreatmentPanel,
          panelRef: panelRef
        }
      },
      { type: ProductDetailSection.DIRECTIONS,
        data: [
            {
                title: "Order test",
                description: "After you purchase your test, you'll receive your sample collection kit in the mail. This includes the Tasso+ collection unit.",
                image: "/img/tasso-box.png"
            },
            {
                title: "Collect & mail sample",
                description: "Complete your sample collection with the Tasso+, then just pop it the mail. It just takes 5 minute sor less.",
                image: "/img/tasso-grid.png"
            },
            {
                title: "Receive your results",
                description: "Results are reviewed by a physician and available within 1 week. A report and game plan will be available as well.",
                image: "/img/receive-results-woman.png"
            },

        ],
      },

      { type: ProductDetailSection.FAQ,
        title: 'Frequently Asked Questions',
        data: faqs.slice(3),
      },

    ])
  
  }, [productType])


  return (
    sections && <div className="longevity-panel">

      <ProductDetail 
        sections={sections}
        trackCode={EventCode.VISIT_HEART_HEALTH_TEST}
      />

    </div>
  )

}

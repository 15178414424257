import { Modal } from 'antd'
import "./pricingTableModal.scss"
import { PricingTable } from '../pricingTable/pricingTable.component'

export const PricingTableModal = ({ open, setOpen }) => {
  const onCancel = () => {
    setOpen(false)
  }

  return (
    <Modal 
      open={open} 
      onCancel={onCancel}
      okText='Save'
      className="pricing-table-modal"
      width={800}
      footer={null}
    >
      <PricingTable />
    </Modal>
  )
}

import { Button }  from 'antd'
import './testConfirmed.scss'
import LatestPosts from '../../../latestPosts/latestPosts.component.js'

const TestConfirmed = () => {
  return (
    <div 
      className="test-confirmed"
    >
      <Button
        className="question-btn"
        onClick={() => window.FrontChat("show")}
      >
        I have a question?
      </Button>

      <LatestPosts />
    </div>
  )
}

export default TestConfirmed
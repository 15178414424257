import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import { TestCode, ReportSection, Injury, QuestionTemplateCode, RiskLevel } from "../../../enums/index.enum";
import { Reference } from "../reference/reference.component";
import ReportHelper from "../../../helpers/report.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { TestView } from "../testView/testView.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';
import "./strengthAdvice.scss";

const { Paragraph } = Typography;

export const StrengthAdvice = ({ report, section, setSectionMeta, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [strengthTests, setStrengthTests] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const isFixed = ReportHelper.isFixed(report, ReportSection.STRENGTH_ADVICE);
  const isBetter = ReportHelper.isBetter(report, ReportSection.STRENGTH_ADVICE);
  const isWorse = ReportHelper.isWorse(report, ReportSection.STRENGTH_ADVICE);
  const isStatic = ReportHelper.isStatic(report, ReportSection.STRENGTH_ADVICE);

  useEffect(() => {
    setStrengthTests(getStrengthTests());
  }, [report, tests, isFixed]);

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [user, report, strengthTests, isFixed, isBetter, isWorse, isStatic]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])


  const getStrengthTests = () => {
    if (!report || !tests) return null;
    if (isFixed) {
      return [tests.find(({code}) => code === TestCode.GRIP)]
    }
    // if GRIP BOTH exist, use that - else use the one that exists
    const gripTests =  ReportHelper.getSectionTests(report, ReportSection.STRENGTH_ADVICE, tests);
    const GRIP = gripTests.find((test) => test.code === TestCode.GRIP)
    if (GRIP) return [GRIP] 

    const injuries = ReportHelper.getAnswer(report, QuestionTemplateCode.RECENT_INJURY)

    if (!injuries?.includes(Injury.RIGHT_HAND) && injuries?.includes(Injury.LEFT_HAND)) {
      const GRIP_RIGHT = gripTests.find((test) => test.code === TestCode.GRIP_RIGHT)
      if (GRIP_RIGHT) return [GRIP_RIGHT]
    }

    else if (!injuries?.includes(Injury.LEFT_HAND) && injuries?.includes(Injury.RIGHT_HAND)) {
      const GRIP_LEFT = gripTests.find((test) => test.code === TestCode.GRIP_LEFT)
      if (GRIP_LEFT) return [GRIP_LEFT]
    }
        
    return ReportHelper.getSectionTests(report, ReportSection.STRENGTH_ADVICE, tests);
  };
  



  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!user || !report || !strengthTests) return null;

    if (isFixed && ReportHelper.getSectionSuboptimalTests(report, ReportSection.STRENGTH_ADVICE, tests)?.length===0) {
      return (
        <>
          Your <span>grip strength</span> is now{" "}
          <span>optimal</span>, which decreases your risk
          of age-related muscle loss, dementia, and cardiovascular disease.
        </>
      );
  
    }

    else if (isBetter || (isFixed &&  ReportHelper.getSectionSuboptimalTests(report, ReportSection.STRENGTH_ADVICE, tests)?.length>0)) {
      return (
        <>
          Your <span>grip strength</span> is{" "}<span>improving</span>, which decreases your risk
        for age-related muscle loss, dementia, and cardiovascular disease.
        </>
      );
  
    }

    if (isStatic) {
      return (
        <>
          Your <span>grip strength</span> is <span> {ReportHelper.getRiskEval(TestCode.GRIP, report, results, tests)}</span>, which increases your risk
        of age-related muscle loss, dementia, and cardiovascular disease.
        </>
      );
  
    }

    if (ReportHelper.getSectionIndex(report, ReportSection.STRENGTH_ADVICE)===1)
      return (
        <>
          Let's focus on the area where you can make the most progress:{" "}
          <span>your strength</span>.
        </>
      );

    // const strengthTrains = ReportHelper.getAnswer(report, QuestionTemplateCode.STRENGTH)
    // if ( strengthTrains === true ||  strengthTrains === "true") {
    //   return (
    //     <>
    //     Your <span>grip strength</span> is{" "}
    //     <span>{ReportHelper.getRiskEval(strengthTests[0].code, report, results, tests)}</span>, despite regular strength training.
    //   </>)
    // }
    
    return (
      <>
        Your <span>grip strength</span> is{" "}
        <span>{ReportHelper.getRiskEval(strengthTests[0].code, report, results, tests)}</span>, which increases your risk
        of age-related muscle loss, dementia, and cardiovascular disease.
      </>
    );
  };


  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!user || !report) return null;

    const strengthRisk = ReportHelper.getTestRisk(TestCode.GRIP, report, results, tests);
    const strengthTrains = ReportHelper.getAnswer(report, QuestionTemplateCode.STRENGTH)
    
    return (
      <>
        Strong muscles are crucial for maintaining balance, mobility, and overall functionality, especially as you age. It's also linked to better outcomes in combating age-related diseases.
        <br/><br/>
        { isFixed ? <>Great job on improving your grip strength! Stick to your game plan so your overall muscle strength continues to improve and keep your grip strength at optimal levels.</>

        : <>Improving grip strength goes beyond enhancing hand strength;{" "}<Reference
        content="it's one indicator of overall muscle health"
        url="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7297622/"
      />. Strength training and exercises targeting various muscle groups is essential. These not only improve grip strength but also boost overall muscle strength and endurance.
          </>
        }

        {strengthRisk=== RiskLevel.MODERATE &&  (strengthTrains === true ||  strengthTrains === "true") &&
            <><br/><br/>You're already close to an optimal level so stick with your routine and you'll get there soon!</>
        }

      </>
    );
  };


  return (
    strengthTests && (
      <div className="strength-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">
          {description}
        </Paragraph>

        <TestView 
          tests={strengthTests} 
          report={report} 
          results={results}
          reports={reports}
          user={user}
        />

        <AdviceSection 
          report={report}
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};

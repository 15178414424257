import { useState, useEffect } from 'react'
import { Table, Typography, Popconfirm } from 'antd'
import { RightOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import './pricingTable.scss';
import MembershipTypeCode from '../../enums/membershipTypeCode.enum';
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { listProductTypes } from '../../services/productType.service'
import { listMembershipTypes } from '../../services/membershipType.service'
import FlowType from '../../enums/flowType.enum';
import DISCOUNT from '../../enums/discount.enum';
import ProductHelper from '../../helpers/product.helper';

const { Text, Paragraph } = Typography
const { Column } = Table

export const PricingTable = () => {
  const navigate = useNavigate()
  const [membershipTypes, setMembershipTypes] = useState()
  const [productTypes, setProductTypes] = useState()

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipTypes()
  }, [])

  const fetchMembershipTypes = async () => {
    setMembershipTypes(await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.LIPIDS
          ]
        }
      }
    }))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }
  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }
  
  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.LONGEVITY_PANEL,
            ProductTypeCode.HEART_HEALTH_TEST_KIT,
            ProductTypeCode.GALLERI,
            ProductTypeCode.PRENUVO,
            ProductTypeCode.TRUAGE_COMPLETE,
            ProductTypeCode.TRUAGE_PACE,
            ProductTypeCode.CONSULT_LONGEVITY,
            ProductTypeCode.TOXIC_METALS_URINE_TEST,
            ProductTypeCode.OMEGAQUANT_COMPLETE,
            ProductTypeCode.CTCALCIUM_REFERRAL,
            ProductTypeCode.FERTILITY,
            ProductTypeCode.MYCOTOX_PROFILE,
            ProductTypeCode.WATCHPAT_ONE,
            ProductTypeCode.BIOBEAT
          ]
        }
      }
    }))
  }

  const items = [{
    title: "Longevity Panel (60+ biomarkers)",
    membershipPrice: ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.LONGEVITY_PANEL)),
    normalPrice: `$${getProductType(ProductTypeCode.LONGEVITY_PANEL)?.cost.toLocaleString()}`,
    explanation: <>Test 60+ biomarkers to proactively detect your health risks well before symptoms appear and discover ways to optimize your long-term health.<br/><br/>Instalab transforms your test results into an engaging, clear, and user-friendly experience, making the process of understanding your health data not just simple, but enjoyable.</>,
    button: {
      text: "Learn More",
      path: "/longevity-panel",
    }
  }, 
  {
    title: "Multi-Cancer Detection Test",
    membershipPrice: ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.GALLERI)),
    normalPrice: `$${getProductType(ProductTypeCode.GALLERI)?.cost.toLocaleString()}`,
    explanation: "Improve your chances of finding cancer early with the Galleri test, which detects over 50 types of cancer through a simple blood draw.",
    button: {
      text: "Learn More",
      path: `/${FlowType.GALLERI_TEST}`,
    }
  }, 
  {
    title: "Whole Body MRI Scan",
    membershipPrice: ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.PRENUVO)),
    normalPrice: `$${getProductType(ProductTypeCode.PRENUVO)?.cost.toLocaleString()}`,
    explanation: "Prenuvo helps detect cancers and other conditions blood tests cannot. 1 in 20 scans result in a potentially life-saving diagnosis.",
    button: {
      text: "Order Now",
      path: `/flow/${FlowType.PRENUVO}`,
    }
  }, 
  {
    title: "Sleep Apnea Test",
    membershipPrice: ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.WATCHPAT_ONE)),
    normalPrice: `$${getProductType(ProductTypeCode.WATCHPAT_ONE)?.cost.toLocaleString()}`,
    explanation: "Accurately measures for sleep apnea based on up to 7 different factors: PAT signal (peripheral arterial tone), heart rate, oximetry (blood oxygen level), actigraphy (gross motor activity), body position, snoring, and chest motion.",
    button: {
      text: "Order Now",
      path: `/flow/${FlowType.SLEEP_APNEA}`,
    }
  }, 
  {
    title: "Cardiac Function Test",
    membershipPrice: ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.BIOBEAT)),
    normalPrice: `$${getProductType(ProductTypeCode.BIOBEAT)?.cost.toLocaleString()}`,
    explanation: "Continuously measure over 24 hours your blood pressure, heart rate, mean arterial pressure, cardiac output, and oxygen saturation",
    button: {
      text: "Order Now",
      path: `/flow/${FlowType.BIOBEAT}`,
    }
  }, 
  {
    title: "Toxic Metals Test",
    membershipPrice: ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.TOXIC_METALS_URINE_TEST)),
    normalPrice: `$${getProductType(ProductTypeCode.TOXIC_METALS_URINE_TEST)?.cost.toLocaleString()}`,
    explanation: "A urine test to detect current and long-term exposure to 20 toxic metals, including heavy metals like arsenic, lead, mercury and more.",
    button: {
      text: "Order Now",
      path: `/flow/${FlowType.TOXIC_METALS_TEST}`,
    }
  }, 
  {
    title: "Mold Toxins Test",
    membershipPrice: ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.MYCOTOX_PROFILE)),
    normalPrice: `$${getProductType(ProductTypeCode.MYCOTOX_PROFILE)?.cost.toLocaleString()}`,
    explanation: "A urine test to detect exposure to 11 commonly encountered toxins from mold organisms.",
    button: {
      text: "Order Now",
      path: `/flow/${FlowType.MOLD_TOXINS}`,
    }
  }, 
  {
    title: "Omega-3 Index Panel",
    membershipPrice: ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.OMEGAQUANT_COMPLETE)),
    normalPrice: `$${getProductType(ProductTypeCode.OMEGAQUANT_COMPLETE)?.cost.toLocaleString()}`,
    explanation: "A test that reports levels of all 24 fatty acids in the blood as well as the Omega-3 index, Ratios, and the Trans Fat Index.",
    button: {
      text: "Order Now",
      path: `/flow/${FlowType.OMEGAQUANT_COMPLETE}`,
    }
  },
  {
    title: "Fertility Hormone Panel",
    membershipPrice: ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.FERTILITY)),
    normalPrice: `$${getProductType(ProductTypeCode.FERTILITY)?.cost.toLocaleString()}`,
    explanation: "Test six key biomarkers to assess your hormonal balance and overall reproductive health. Get ahead of possible infertility issues now.",
    button: {
      text: "Order Now",
      path: `/flow/${FlowType.FERTILITY}`,
    }
  }, 
  // {
  //   title: "Biological Age Test",
  //   membershipPrice: ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.TRUAGE_COMPLETE)),
  //   normalPrice: `$${getProductType(ProductTypeCode.TRUAGE_COMPLETE)?.cost.toLocaleString()}`,
  //   explanation: "An at-home epigenetic test that tells you how old you really are from a biological standpoint and analyzes how fast or slow your body is aging on a cellular level.",
  //   button: {
  //     text: "Order Now",
  //     path: `/flow/${FlowType.TRUAGE_COMPLETE}`,
  //   }
  // }, 
  {
    title: "Advanced Heart Health Test",
    membershipPrice: ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.HEART_HEALTH_TEST_KIT)),
    normalPrice: `$${getProductType(ProductTypeCode.HEART_HEALTH_TEST_KIT)?.cost.toLocaleString()}`,
    explanation: "Test eight key biomarkers to get a baseline of your cardiovascular system's health and your risk for heart attack, stroke, and more.",
    button: {
      text: "Order Now",
      path: `/flow/${FlowType.HEART_HEALTH_TEST}`,
    }
  }, 

  {
    title: "Physician Consultations",
    membershipPrice: ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.CONSULT_LONGEVITY)),
    normalPrice: `$${getProductType(ProductTypeCode.CONSULT_LONGEVITY)?.cost.toLocaleString()}`,
    explanation: "Meet with a physician who specializes in your area of interest for a video consultation. Leveraging your medical data, lifestyle and preferences, your Instalab physician will create a custom game plan to optimize your health.",
    button: {
      text: "Book Consultation",
      path: `/flow/${FlowType.LONGEVITY_CONSULT}`,
    }
  }, 
  {
    title: "Cholesterol Treatment Program",
    membershipPrice: `$${(getMembershipType(MembershipTypeCode.LIPIDS)?.cost * DISCOUNT).toLocaleString()}/month`,
    normalPrice: `$${getMembershipType(MembershipTypeCode.LIPIDS)?.cost.toLocaleString()}/month`,
    explanation: <>The perfect cholesterol medication for you depends on how your body uniquely reacts to different drugs and dosages.<br/><br/>Instalab prescribes you medication and monitors its efficacy by frequently testing for seven key biomarkers using an at-home blood test kit.<br/><br/>Adjustments to your prescription are guided by your test outcomes and personal feedback.</>,
    button: {
      text: "Learn More",
      path: `/${FlowType.CHOLESTEROL}`,
    }
  }, 
  {
    title: "Supplements",
    membershipPrice: "30% off retail",
    normalPrice: null,
    explanation: "Get your supplements shipped to your doorstep at a 30% discount compared to what they cost you outside Instalab.",
  }, 
  {
    title: "Coronary Calcium Scan Referral",
    membershipPrice: "Free",
    normalPrice: null,
    explanation: "Get recommendation to nearest scan facility with reasonable pricing and the required physician referral for your coronary calcium scan.",
    button: {
      text: "Get Started",
      path: `/flow/${FlowType.CAC_REFERRAL}`,
    }
  }, 
  {
    title: "Personal Health Concierge",
    membershipPrice: "Free",
    normalPrice: null,
    explanation: "A dedicated person that helps you with the logistics of implementing your game plan to a longer, healthier life.",
  },
  {
    title: "Specialist Referrals",
    membershipPrice: "Free",
    normalPrice: null,
    explanation: "Your concierge will find the best specialist in the field you want to talk to and book the appointment for you.",
  }, 
  {
    title: "Medical Team Messaging",
    membershipPrice: "Free",
    normalPrice: null,
    explanation: "Whether you have questions about the latest research, need clarification on your test results, or seek advice, our longevity medical team is available over email.",
  }, 

]

  return (
    <div className="pricing-table">
      <Table
        size="small"
        pagination={false}
        rowKey="title"
        dataSource={items}
        tableLayout='auto'
        scroll={{ x: 'max-content' }}
      >
        <Column
          dataIndex="title"
          render={(title, { explanation, button }) => (
            <>
              <Text className="pricing-title">
                {title}
              </Text>

              <Popconfirm
                title={<>
                  <div className="pop-content">
                    <Paragraph className="pop-title">
                      {title}
                    </Paragraph>
                    <Paragraph className="pop-description">
                      {explanation}
                    </Paragraph>
                  </div>
                </>}
                okText={<>{button?.text || 'Start Now'} <RightOutlined className="pop-btn-icon" /></>}
                okButtonProps={{ 
                  className: 'pop-btn' ,
                  style: { display: button ? 'inline' : 'none' }
                }}
                onConfirm={() => navigate(button.path)}
                icon={null}
                showCancel={false}
              >
                <InfoCircleOutlined className="pricing-icon" />
              </Popconfirm>
            </>
          )}
        />

        <Column
          dataIndex="membershipPrice"
          title="Member Price"
          className="price-col"
          render={price => (
            <Text className="membership-price">
              {price}
            </Text>
          )}
        />

        <Column
          dataIndex="normalPrice"
          title="Regular Price"
          className="price-col"
          render={price => {
            return price ? (
              <Text className="regular-price">
                {price}
              </Text>
            ) : (
              <Text className="no-price">
                Not Available
              </Text>
            )
          }}
        />
      </Table>
    </div>
  )
}
import React, { useContext, useEffect, useState } from 'react';
import { Button, Input, Row, Col, message, Typography, Tooltip, Switch } from 'antd'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { CopyOutlined, DownloadOutlined, LoadingOutlined, SendOutlined } from '@ant-design/icons'
import "./sharing.scss";
import copy from 'copy-to-clipboard';
import { UserContext } from '../../contexts/user.context';
import { updateMe } from '../../services/user.service';
import { addReportForPatient } from '../../services/patient.service';
import fileDownload from 'js-file-download'
import axios from 'axios'
import { SendFileModal } from '../sendFileModal/sendFileModal.component';

const { Paragraph, Text } = Typography

export const Sharing = () => {
  const { currentUser, setCurrentUser } = useContext(UserContext)
  const [isDownloading, setIsDownloading] = useState()
  const [isSendModalVisible, setIsSendModalVisible] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  useEffect(() => {
    document.title = 'Instalab | Sharing'
  }, [])

  const onCopy = () => {
    copy(`${process.env.REACT_APP_CLIENT_URL}/share/${currentUser._id}`)
    message.info(`URL copied`)
  }

  const onChange = async (isShareable) => {
    try {
      const updatedUser = await updateMe({ 
        fields: {
          isShareable 
        },
        select: '_id role credits email firstName lastName tracker location isShareable',
        populate: [{
          path: 'memberships',
          select: 'status endAt',
          populate: {
            path: 'membershipType',
            select: 'code'
          }
        }]
      })
      setCurrentUser(updatedUser)
      message.info(isShareable ? 'Link activated' : 'Link deactivated')
    } catch (err) {
      message.error(isShareable ? 'Failed to activate link' : 'Failed to deactivate link')
    }
  }

  // TO DO: protect download to just the patient
  const onDownload = async (_id) => {
    setIsDownloading(_id)
    const location = await addReportForPatient(_id)
    const filename = location.split('?')[0]

    axios
      .get(location, { responseType: 'blob' })
      .then((res) => {
        fileDownload(res.data, filename)
        message.success('PDF downloaded')
        setIsDownloading(null)
    })    
  }

  const handleSendFileClick = (file) => {
    setSelectedFile(file);
    setIsSendModalVisible(true); // Open the send modal
  };

  return currentUser && (
    <div className="sharing">
      <PageHeader title='Sharing' />

      <SendFileModal 
          selectedFile={selectedFile}
          isSendModalVisible={isSendModalVisible}
          setIsSendModalVisible={setIsSendModalVisible}
      />
      <div className="share-content">
        <h3 style={{fontWeight:600}}>Share your Instalab data with your family, friends, or doctor using the following options:</h3>
        
        <Paragraph className="share-directions">          
          <Button 
            type='primary' 
            className="download-btn"
            onClick={async (e) => {
              e.stopPropagation()
              if (isDownloading !== currentUser?._id) {
                await onDownload(currentUser?._id)
              }
            }}
          >
            {isDownloading === currentUser?._id ? (
              <Text className="downloading-text">
              <LoadingOutlined /> Downloading...
            </Text>
          ) : <>
            <DownloadOutlined /> Download Results
          </>}
          </Button>

          <Button 
            type='primary' 
            className="send-btn"
            onClick={async (e) => {
              e.stopPropagation()
              handleSendFileClick({name: "Instalab Lab Results", id: `${currentUser.email}`, folderName: currentUser?._id})
            }}
          >
            <SendOutlined /> Email/Fax Results
          
          </Button>
        </Paragraph>

        <Paragraph className="share-directions">
          Activate private link to allowed shared access to your:
          <ul>
            <li>test results</li>
            <li>latest report</li>
          </ul>

          <label className="shareable-container">
            <Switch 
              defaultChecked={currentUser.isShareable}
              onChange={onChange}
              size='small'
              className="shareable-switch"
            />
            <span className="shareable-text">
              {currentUser.isShareable ? <span className="activated-text">Activated</span> : <span className='deactivated-text'>Deactivated</span>} - {currentUser.isShareable ? 'your link is shareable' : 'your link is not currently shareable'}
            </span>
          </label>

          <Tooltip title={currentUser.isShareable ? '' : 'Activate your link above to make it shareable'}>
            <Row 
              className="share-row"
              gutter={6}
            >
              <Col span={19}>
                <Input
                  value={`${process.env.REACT_APP_CLIENT_URL}/share/${currentUser._id}`}
                  disabled={!currentUser.isShareable}
                />
              </Col>
              <Col span={5}>
                <Button
                  type='primary'
                  onClick={onCopy}
                  className="share-btn"
                  icon={<CopyOutlined />}
                  disabled={!currentUser.isShareable}
                >
                  Copy
                </Button>
              </Col>
            </Row>
          </Tooltip>


        </Paragraph>


      </div>
    </div>
  )
}
import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import "./isolatedTshAdvice.scss";
import ReportHelper from "../../../helpers/report.helper";
import { TestView } from "../testView/testView.component"
import { RiskLevel, ReportSection, TestCode } from "../../../enums/index.enum"
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography;

const primaryCodes = [TestCode.TSH, TestCode.FREET4, TestCode.FREET3]

export const IsolatedTshAdvice = ({ report, section, setSectionMeta, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [riskLevel, setRiskLevel] = useState();
  const [title, setTitle] = useState();
  const [primaryDescription, setPrimaryDescription] = useState();
  const [secondaryDescription, setSecondaryDescription] = useState();
  const [thyroidTests, setThyroidTests] = useState();

  // progress
  const [isFixed, setIsFixed] = useState();
  const [isBetter, setIsBetter] = useState();
  const [isWorse, setIsWorse] = useState();
  const [isStatic, setIsStatic] = useState();

  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.ISOLATED_TSH_ADVICE));
    setIsBetter(ReportHelper.isBetter(report, ReportSection.ISOLATED_TSH_ADVICE));
    setIsWorse(ReportHelper.isWorse(report, ReportSection.ISOLATED_TSH_ADVICE));
    setIsStatic(ReportHelper.isStatic(report, ReportSection.ISOLATED_TSH_ADVICE));
  }, [report]);


  useEffect(() => {
    setThyroidTests(getThyroidTests())
  }, [report, tests])

  useEffect(() => {
    setTitle(getTitle());
    setPrimaryDescription(getPrimaryDescription())
    setSecondaryDescription(getSecondaryDescription())
  }, [riskLevel, report, thyroidTests])

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          primaryDescription: renderToString(primaryDescription),
          secondaryDescription: renderToString(secondaryDescription),
          recs
        }
      }
    })
  }, [title, primaryDescription, secondaryDescription, recs])

  useEffect(() => {
    setRiskLevel(getRiskLevel())
  }, [tests, results, report])


  const getThyroidTests = () => {
    if (!report || !tests) return null
    const t = ReportHelper.getSectionTests(report, ReportSection.ISOLATED_TSH_ADVICE, tests)

    return t
  }

  const getRiskLevel = () => {
    if (!tests || !results || !report) return null;
    return ReportHelper.getTestRisk(TestCode.TSH, report, results, tests);
  };



  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)
      
    if (!report || !riskLevel || !tests || !results) return null;

    const highTSH = ReportHelper.isGreaterThan(TestCode.TSH, RiskLevel.OPTIMAL, report, results, tests);

    if (isBetter) {
      return <>Your <span>TSH</span> is <span>improving</span>, which decreases your risk for <span>{highTSH ? "clinical hypothyroidism" : "clinical hyperthyroidism"}</span></>
    }

    return <>
      Your <span>TSH</span> is{" "}
      <span>{ReportHelper.getRiskEval(TestCode.TSH, report, results, tests)}</span>, which can be an indicator of <span>{highTSH ? "hypothyroidism" : "hyperthyroidism"}</span>.
    </>
  };

  const getPrimaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.primaryDescription)

    if (!report || !riskLevel || !tests || !results || !thyroidTests) return null;

    const highTSH = ReportHelper.isGreaterThan(TestCode.TSH, RiskLevel.OPTIMAL, report, results, tests);
    const hasFreeT3 = ReportHelper.getTestValue(TestCode.FREET3, report, results, tests);

    return (
      <>
        TSH stimulates the production of {hasFreeT3 && <>T3 and</>} T4 hormones, which are essential in regulating the body's metabolism, energy levels, and overall metabolic processes. 
        <br/><br/>
        {highTSH 
          ? <>Given your {hasFreeT3 ? <>free T3 and free T4 are</> : <>free T4 is</>} in the normal range, you may have subclinical hypothyroidism. This is an early, milder form of hypothyroidism, a condition where the thyroid gland does not produce enough thyroid hormones. Symptoms may include fatigue, weight gain, and cold sensitivity.</>
          : <>Given your {hasFreeT3 ? <>free T3 and free T4 are</> : <>free T4 is</>} in the normal range, you may have subclinical hyperthyroidism. This is an early, milder form of hyperthyroidism, a condition where the thyroid gland produces too much thyroid hormones. Symptoms may include weight loss, increased heart rate, anxiety/irritability although some people do not show any symptoms at all.</>
        }
      </>
    )
  }

  const getSecondaryDescription = () => {
    if (report?.lockedAt) return parse(section.meta.secondaryDescription)
    if (!report) return null;

    if (isBetter || isFixed || isWorse || isStatic) return null

    return <>Note that TSH can sometimes vary day to day so it's advised to repeat this thyroid testing within the next 3 months to confirm whether this is a transiet or persistent issue.</>
  }

  return (
    thyroidTests && <div className="isolated-tsh-advice">
      <Paragraph className="report-template-title">{title}</Paragraph>
      <Paragraph className="report-template-description">{primaryDescription}</Paragraph>
      <TestView  
        report={report} 
        tests={thyroidTests.filter(({code})=> primaryCodes.includes(code))} 
        results={results}
        reports={reports}
        user={user}
      />
      {secondaryDescription && <Paragraph className="report-template-description">{secondaryDescription}</Paragraph>}
      <AdviceSection 
        report={report} 
        results={results}
        tests={tests}
        section={section}
        recs={recs}
        setRecs={setRecs}
        reports={reports}
      />
    </div>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { Modal, Form, message, Input, Button, Select } from 'antd';
import { listReferrals, addReferralForPatient } from "../../services/referral.service.js";
import { UserContext } from '../../contexts/user.context.js';

const { Item } = Form;

export const AddReferralForm = ({ open, setOpen, patientId, onSuccess }) => {
  const [form] = Form.useForm();
  const [referrals, setReferrals] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (open) {
      fetchReferrals();
      form.resetFields();
    }
  }, [open]);

  const fetchReferrals = async () => {
    try {
      const data = await listReferrals();
      setReferrals(data);
    } catch (error) {
      message.error('Failed to fetch referrals');
    }
  };

  const onCancel = () => {
    setOpen(false);
  };

  const onOk = async () => {
    setIsSubmitting(true);
    try {
      const { referralId } = form.getFieldsValue();
      const updatedReferral = await addReferralForPatient({ patientId, referralId });
      if (onSuccess) {
        onSuccess(updatedReferral);
      }
      message.success('Referral added');
      setOpen(false);
      form.resetFields();
    } catch (err) {
      message.error('Failed to add referral');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal
      open={open}
      title='Add Referral For Patient'
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isSubmitting}
          onClick={onOk}
        >
          Add
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout='vertical'
        className="admin-referrals-form"
      >
        <Item
          name="referralId"
          label="Select Referral"
          rules={[{
            required: true,
            message: 'Select a referral'
          }]}
        >
          <Select
            placeholder="Select referral"
            options={referrals.map(referral => ({
              label: `${referral.firstName} ${referral.lastName}`,
              value: referral._id
            }))}
          />
        </Item>
      </Form>
    </Modal>
  );
};

import { Modal, Typography } from 'antd'
import ReactPlayer from "react-player";
import './tassoModal.scss'
import { useRef, useCallback } from 'react';

const { Paragraph } = Typography

export const TassoModal = ({ open, setOpen }) => {
  const playerRef = useRef()

  const onCancel = () => {
    setOpen(false)
  }

  const onReady = useCallback(() => {
    if (open) {
      playerRef.current.seekTo(6, "seconds");
    }
  }, [open]);

  return open && (
    <Modal
      open={open}
      onCancel={onCancel}
      className="tasso-modal"
      footer={null}
      width={500}
    >
      <Paragraph className="tasso-title">
        Tasso: A Painless Blood Test
      </Paragraph>
      <Paragraph className="tasso-description">
        A convenient at-home blood test that doesn't hurt.
      </Paragraph>

      <div className="tasso-video">
        <ReactPlayer
          ref={playerRef}
          url={'https://player.vimeo.com/video/409226805#t=1m'}
          controls={true}
          width="100%"
          onReady={onReady}
          playing={true}
          playbackRate={1.25}
        />
      </div>
    </Modal>
  )
}
import React, { useState, useEffect } from "react";
import { Typography } from "antd"
import './lowFerritinAdvice.scss'
import { TestView } from "../testView/testView.component";
import PatientHelper from "../../../helpers/patient.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import ReportHelper from "../../../helpers/report.helper";
import { TestCode, Diet, QuestionTemplateCode, Supplement, ReportSection, RiskLevel } from "../../../enums/index.enum";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography

// TO DO: transform this into an iron deficiency section where ferritin is the focus but the the only thing

export const LowFerritinAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()

  const [isFemale, setIsFemale] = useState();
  const [riskLevel, setRiskLevel] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [ironTests, setIronTests] = useState([])

  // progress
  const [isFixed, setIsFixed] = useState();
  const [isBetter, setIsBetter] = useState();
  const [isWorse, setIsWorse] = useState();
  const [isStatic, setIsStatic] = useState();

  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.LOW_FERRITIN_ADVICE))
    setIsBetter(ReportHelper.isBetter(report, ReportSection.LOW_FERRITIN_ADVICE))
    setIsWorse(ReportHelper.isWorse(report, ReportSection.LOW_FERRITIN_ADVICE))
    setIsStatic(ReportHelper.isStatic(report, ReportSection.LOW_FERRITIN_ADVICE))
    setIsFemale(PatientHelper.isFemale(report.patient));
  }, [report]);

  useEffect(() => {
    setRiskLevel(getRiskLevel());

    if (isFixed) {
      setIronTests(ReportHelper.getSectionFixedTests(report, ReportSection.LOW_FERRITIN_ADVICE, tests)|| [])
      console.log(ReportHelper.getSectionFixedTests(report, ReportSection.LOW_FERRITIN_ADVICE, tests))
    }
    else {
      setIronTests(ReportHelper.getSectionTests(report, ReportSection.LOW_FERRITIN_ADVICE, tests)|| []);

    } 

  }, [isFixed, tests, results, report]);

  useEffect(() => { 
    setTitle(getTitle());
    setDescription(getDescription());
  }, [user, report, riskLevel, isFixed, isBetter, isWorse]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])


  const getRiskLevel = () => {
    if (!tests || !results || !report) return null;
    return ReportHelper.getTestRisk(TestCode.FERRITIN, report, results, tests);
  };


  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!user || !riskLevel || !report) return null;
    const answer = ReportHelper.getAnswer(report,QuestionTemplateCode.CURRENT_SUPPLEMENTS);

    if (isFixed) {
      return (
        <>
          <>Your <span>ferritin</span> is now <span>optimal</span>, which decreases your risk for anemia.</>
        </>
      );
    } else if (isBetter) {
      return (
        <>
          {isFemale ? <>Your <span>ferritin</span> is <span>improving</span>, which decreases your risk for anemia.</>
                    : <>Your <span>ferritin</span> is <span>improving</span>{riskLevel!==RiskLevel.OPTIMAL && <> but still <span>low</span>, which suggests you're iron deficient and at risk for anemia</>}.</>}
        </>
      );
    } else if (isWorse) {
      return (
        <>
          {isFemale ? <>Your <span>ferritin</span> is <span>decreasing</span>, which increases your risk for anemia.</>
                    : <>Your <span>ferritin</span> is <span>decreasing</span>, which increases your risk for anemia.</>}
        </>
      );
    }

    else if (answer?.includes(Supplement.IRON)) {
      return (
        <>
          Your <span>ferritin</span> is{" "}
          <span>{ReportHelper.getRiskEval(TestCode.FERRITIN, report, results, tests)}</span>, despite taking an iron supplement.
        </>
      );
    }
    return (
      <>Your <span>ferritin</span> is{" "}<span>{ReportHelper.getRiskEval(TestCode.FERRITIN, report, results, tests)}</span>, which can cause anemia, a condition where you don't have enough healthy red blood cells to carry oxygen.
      {/* {isFemale ? <>Your <span>ferritin</span> is{" "}<span>{ReportHelper.getRiskEval(TestCode.FERRITIN, report, results, tests)}</span>, which can eventually cause anemia, a condition where you don't have enough healthy red blood cells to carry oxygen.</>
                : <>Your <span>ferritin</span> is <span>{ReportHelper.getRiskEval(TestCode.FERRITIN, report, results, tests)}</span> - a protein that stores iron in your cells and plays a vital role in maintaining healthy cells.</>
      } */}
      </>
    )
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!user || !riskLevel || !report) return null;

    const supplements = ReportHelper.getAnswer(report, QuestionTemplateCode.CURRENT_SUPPLEMENTS);
    const isTakingIron = supplements?.includes(Supplement.IRON)
    const diet = ReportHelper.getAnswer(report, QuestionTemplateCode.DIET)
    const isMeatEater = !(diet?.includes(Diet.VEGETARIAN) || diet?.includes(Diet.VEGAN)) 


    if (isFixed) {
      return <>Your ferritin, a key marker of iron storage, is now in a healthier place. Great job on fixing your iron deficiency. Let's keep monitoring this to make sure it remains optimal.
      </>
    }


    let content = <>Low ferritin, a key marker of iron storage, indicates you have iron deficiency and can lead to anemia, causing symptoms like weakness, shortness of breath and cognitive decline. </>

    if (isFemale) {

      if (isWorse) {
        if (isTakingIron)
          content = <>{content} <br/><br/>Usually taking iron supplements can quickly restore your ferritin to optimal levels. If you're consistently taking your iron supplements, consider speaking with your physician on what the underlying issue is to your low ferritin.</>
        else 
          content = <>{content} <br/><br/>Taking iron supplements can quickly restore your ferritin to optimal levels, preventing these issues. Monitor your progress in 3 months to assure you’re meeting your goals.</>
      }


      else {
        content = <>{content}
          {isTakingIron 
            ? <><br/><br/>It's good that you're already taking iron supplements already to help prevent these issues. Repeat this test in 3 months to make sure you're make the right progress.</> 
            : <>To reduce this risk, taking an iron supplement can quickly boost you to an optimal level.</> 
          }</>          
      }
    }

    else {

      if  (isFixed || isBetter || isStatic || (ReportHelper.hasWorseningTrend(TestCode.FERRITIN, report, results, tests))) {
        if (riskLevel === RiskLevel.OPTIMAL)
          content = <>{content} While your ferritin levels are moving in the right direction, it's important to continue to monitor this.</>
        else 
          content = <>{content} 
          
          <br/><br/>Men are rarely iron deficient but when they are it's generally due to blood loss from the digestive tract, diseases that affect iron absorption, or in some cases, alcohol abuse. 
          
          {!isMeatEater ? <><br/><br/>But given your {diet?.includes(Diet.VEGAN) ? <>vegan</> : <>vegetarian</>} diet, your iron deficiency could be due to a combination of inadequate iron intake and the lower bioavailability of iron from plant-based foods. If you've already increased your iron intake, it's worth a discussion with your physician to identify the persistently low levels.</>
          
          : <> Given your persistently low readings, it's worth a discussion with your physician to identify the cause of this.</>}

          </>
      }

      else {
        content = <>{content} 
        <br/><br/>Men are rarely iron deficient but when they are it's generally due to blood loss from the digestive tract, diseases that affect iron absorption, or in some cases, alcohol abuse. 
        
        {isMeatEater 
          ? <><br/><br/>It's recommended you repeat this test to confirm the low levels and/or talk with your primary care provider about what could be the cause of this.</>
          : <><br/><br/>But given your {diet?.includes(Diet.VEGAN) ? <>vegan</> : <>vegetarian</>} diet, your iron deficiency could be due to a combination of inadequate iron intake and the lower bioavailability of iron from plant-based foods. Supplementing with iron and keeping track of your ferritin levels can help determine if this is the cause.</>
        }
        </>
      }
    }

    return content

  }



  return  user && report && ironTests  && (
    <div className="low-ferritin-advice">
      <Paragraph className="report-template-title">{title}</Paragraph>
      <Paragraph className="report-template-description">{description}</Paragraph>
      <TestView
        user={user}
        report={report} 
        tests={ironTests}
        results={results}
        reports={reports}
      />

      <AdviceSection 
        report={report} 
        results={results}
        tests={tests}
        section={section}
        recs={recs}
        setRecs={setRecs}
        reports={reports}
      />
    </div>
  )
}
import React, { useEffect, useState } from 'react';
import { Tooltip } from 'antd'
import { listTests } from "../../services/test.service"
import './tests.scss'
import { TestForm } from '../testForm/testForm.component';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';

export const Tests = () => {
  const [tests, setTests] = useState([])
  const [testId, setTestId] = useState()
  const [isEditModalOpen, setIsEditModalOpen] = useState()
  const [isLoading, setIsLoading] = useState()
  const [filteredCount, setFilteredCount] = useState();
  const [absoluteCategoryNames, setAbsoluteCategoryNames] = useState([])

  useEffect(() => {
    document.title = '[Admin] Biomarkers'
    fetchTests()
  }, [])

  const fetchTests = async () => {
    setIsLoading(true)
    let params = {
      select: '_id name code tagline absoluteCategory',
      filter: {},
      populate: [{
        path: 'absoluteCategory',
        select: 'name',
      }],
      sort: 'name',
      collation: { locale: 'en', strength: 2 }
    }
    const fetchedTests = await listTests(params)
    setAbsoluteCategoryNames([...new Set(fetchedTests.filter(({ absoluteCategory }) => absoluteCategory).map(({ absoluteCategory }) => absoluteCategory.name))].sort())
    setTests(fetchedTests)
    setFilteredCount(fetchedTests.length)
    setIsLoading(false)
  }
  const getCustomFilter = (test, value) => {
    return {
      absoluteCategory: () => test.absoluteCategory?.name === value,
    }
  }

  return (
    <div className="tests">
      {/* <PatientSpreadModal 
        open={isSpreadModalOpen}
        setOpen={setIsSpreadModalOpen}
        data={spreadData}
        setData={setSpreadData}
        tests={tests}
        tableData={tableData}
      /> */}

      <TestForm
        testId={testId}
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        onSuccess={test => {
          if (tests.some(t => t._id === test._id)) {
            setTests(tests.map(t => t._id === test._id ? test : t))
          } else {
            setTests([
              test,
              ...tests
            ])
          }
        }}
      />

      <PageHeader 
        title='Biomarkers' 
        count={filteredCount}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={tests}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        columns={[{
          title: 'Name',
          dataIndex: 'name',
          filterDropdownType: FilterDropdownType.INPUT,
        }, {
          title: 'Code',
          dataIndex: 'code',
          width: 90,
          filterDropdownType: FilterDropdownType.INPUT,
        }, {
          title: 'Category',
          dataIndex: 'absoluteCategory',
          width: 150,
          render: absoluteCategory => absoluteCategory?.name,
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: absoluteCategoryNames
        }, {
          title: 'Tagline',
          dataIndex: 'tagline',
          ellipsis: true,
          render: tagline => (
            <Tooltip title={tagline}>
              {tagline}
            </Tooltip>
          )
        }]}
      />
    </div>
  )
}
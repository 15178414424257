import { Typography } from "antd";
import "./shbgCheck.scss";
import { TestChart } from "../testChart/testChart.component";
import { TestItemList } from "../testItemLlist/testItemList.component";
import { useState, useEffect } from "react";
import ReportHelper from "../../../helpers/report.helper";
import ReportSection from "../../../enums/reportSection.enum";
import { TestModal } from "../../testModal/testModal.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const converter = require("number-to-words");

const { Paragraph } = Typography;

export const SHBGCheck = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [birthControlTests, setBirthControlTests] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [testId, setTestId] = useState();
  const [openModal, setOpenModal] = useState();
  const [birthControl, setBirthControl] = useState();

  useEffect(() => {
    setBirthControlTests(getBirthControlTests());
  }, [report, tests]);

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report, birthControlTests]);

  useEffect(() => {
    setBirthControl(getBirthControl());
  }, [user]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
        }
      }
    })
  }, [title, description])

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !birthControlTests) return null;
    const isFixed = ReportHelper.isFixed(report, ReportSection.SHBG_CHECK);
    const isBetter = ReportHelper.isBetter(report, ReportSection.SHBG_CHECK);
    if ((isFixed || isBetter) && birthControlTests?.length === 1) {
      return (
        <>
          Your <span>SHGB</span>, a protein that stores testosterone and
          estrogen until it's required by your body, has <span>improved</span>.
        </>
      );
    } else if (isFixed || isBetter) {
      return (
        <>
          These{" "}
          <span>{converter.toWords(birthControlTests?.length)} hormones</span>{" "}
          have <span>improved</span>.
        </>
      );
    } else if (birthControlTests?.length === 1) {
      return (
        <>
          Your <span>SHGB</span> is <span>abnormal</span>, a protein that stores
          testosterone and estrogen until it's required by your body.
        </>
      );
    } else {
      return (
        <>
          These{" "}
          <span>{converter.toWords(birthControlTests?.length)} hormones</span>{" "}
          are <span>abnormal</span>.
        </>
      );
    }
  };

  const getBirthControl = () => {
    if (!user) return null;
    return user?.birthControl;
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report || !birthControlTests) return null;

    const isFixed = ReportHelper.isFixed(report, ReportSection.SHBG_CHECK);
    const isBetter = ReportHelper.isBetter(report, ReportSection.SHBG_CHECK);
    if (isFixed) {
      return (
        <>
          Since{" "}
          {birthControlTests?.length === 1
            ? "this biomarker"
            : "these biomarkers"}{" "}
          returned to an optimal level since your last blood draw, you have
          nothing to worry about.
        </>
      );
    } else if (isBetter) {
      return (
        <>
          Since{" "}
          {birthControlTests?.length === 1
            ? "this biomarker has"
            : "these biomarkers have collectively"}{" "}
          moved towards an optimal level since your last blood draw, you have
          nothing to worry about for now. A follow-up blood test will track how{" "}
          {birthControlTests?.length === 1 ? "it trends" : "they trend"} over
          time.
        </>
      );
    } else if (birthControl === undefined) {
      return (
        <>
          If you're taking hormonal birth control, your SHBG levels will rise
          and your LH, FSH and testosterone levels may drop as a result.
          <br />
          <br />
          If this is the case, there's no reason for concern. If you ever stop
          taking birth control, it's a good idea to get your hormone levels
          re-checked.
        </>
      );
    } else if (birthControl?.length === 0) {
      return (
        <>
          If you've used hormonal birth control in the last 6 months, it could
          be the cause of your elevated SHBG levels. It's advisable to recheck
          within 3 months to ensure a decline towards optimal.
          <br />
          <br />
          If birth control isn't a factor for you, you may want to consult your
          primary care provider for further evaluation and potential management
          options.
        </>
      );
    } else if (birthControl?.length > 0) {
      return `Because you're on hormonal birth control, there's no reason for concern. Birth control increases your SHBG levels and can decrease your testosterone, LH and FSH levels. If you ever go off of birth control, it's a good idea to get this re-checked.`;
    }
  };

  const getBirthControlTests = () => {
    if (!report || !tests) return null;
    return ReportHelper.getSectionTests(
      report,
      ReportSection.SHBG_CHECK,
      tests
    );
  };

  return (
    report &&
    birthControlTests && (
      <div className="shgb-check">
        <TestModal
          open={openModal}
          setOpen={setOpenModal}
          testId={testId}
          setTestId={setTestId}
          report={report}
          tests={tests}
          results={results}
          reports={reports}
        />

        <Paragraph className="report-template-title">{title}</Paragraph>

        <Paragraph className="report-template-description">
          {description}
        </Paragraph>

        {birthControlTests?.length === 1 ? (
          <TestChart 
            report={report} 
            code={birthControlTests[0].code} 
            tests={tests}
            results={results}
            reports={reports}
          />
        ) : (
          <TestItemList
            report={report}
            testIds={birthControlTests.map(({ _id }) => _id)}
            tests={tests}
            results={results}
            user={user}
            reports={reports}
          />
        )}
      </div>
    )
  );
};

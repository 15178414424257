const Supplement = Object.freeze({
  CALCIFEDIOL: "Calcifediol",
  CALCIUM: "Calcium",
  CREATINE: "Creatine",
  IRON: "Iron",
  MAGNESIUM: "Magnesium",
  OMEGA_3S: "Omega-3s",
  POTASSIUM: "Potassium",
  SELENIUM: "Selenium",
  SOLUBLE_FIBER: "Soluble Fiber",
  VITAMIN_B6: "Vitamin B6",
  BIOTIN: "Vitamin B7 (Biotin)", //"Biotin (Vitamin B7)",
  VITAMIN_B9: "Vitamin B9 (Folate)", // "Vitamin B9", 
  VITAMIN_B12: "Vitamin B12",
  VITAMIN_C: "Vitamin C",
  VITAMIN_D_OR_CALCIFEDIOL: "Vitamin D or Calcifediol",
  VITAMIN_D3: "Vitamin D3",
  VISCOUS_FIBER: "Viscous Fiber",
  ZINC: "Zinc",
});

export default Supplement;
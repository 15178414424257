import { Typography, Button, Row, Col }  from 'antd'
import './prescriptioApproval.scss'
import LatestPosts from '../../../latestPosts/latestPosts.component.js'
import { useContext, useState, useEffect } from 'react'
import { UserContext } from '../../../../contexts/user.context.js'
import Breakpoint from '../../../../enums/breakpoint.enum.js'
import { cholesterolTherapyPanel } from '../../../../data/cholesterolTherapyPanel.data.js'
import { TassoModal } from '../../../tassoModal/tassoModal.component.js'
import { PanelModal } from '../../../panelModal/panelModal.component.js'
import moment from 'moment'
import TestCode from '../../../../enums/testCode.enum.js'
import useWidth from '../../../../hooks/useWidth.hook.js'
import { getMe } from '../../../../services/user.service.js'
import { listAnnotatedTests } from '../../../../services/test.service.js'
import { listAnnotatedResults } from '../../../../services/result.service.js'

const { Text, Paragraph } = Typography

const PrescriptionApproval = ({ prescriptionTitle }) => {
  const [instructions, setInstructions] = useState()
  const width = useWidth()
  const { token } = useContext(UserContext)
  const [openPanel, setOpenPanel] = useState()
  const [openTasso, setOpenTasso] = useState()
  const [results, setResults] = useState()
  const [tests, setTests] = useState()
  const [currentUser, setCurrentUser] = useState()
  const biomarkerCount = Object.values(cholesterolTherapyPanel).reduce((acc, biomarkers) => {
    return acc += biomarkers.length
  }, 0)

  useEffect(() => {
    fetchPatientData()
  }, [token])

  useEffect(() => {
    fetchInstructions()
  }, [currentUser, tests, results])

  const fetchPatientData = async () => {
    if (token) {
      setCurrentUser(await getMe({
        select: 'hasLipidTest lipidTestDate lipidPrescription',
        populate: []
      }))
      setTests(await listAnnotatedTests())
      setResults(await listAnnotatedResults())
    } else {
      setCurrentUser(null)
      setTests(null)
      setResults(null)
    }
  }

  const getLatestResult = testId => {
    return results?.find(result => {
      const { values } = result
      return values?.some(({ value, test }) => {
        return value && test === testId
      })
    })
  }

  const getTestIdByCode = (testCode) => {
    if (!tests?.length) return
    return tests.find(({ code }) => code === testCode)?._id
  }

  const hasRecentLipidTest = () => {
    if (currentUser?.hasLipidTest && currentUser?.lipidTestDate) {
      return moment(currentUser?.lipidTestDate) > moment().subtract(1,'months')
    }

    const apobId = getTestIdByCode(TestCode.APO_B)
    const apobResult = getLatestResult(apobId)
    if (apobResult) {
      const isApobRecent = moment(apobResult.collectedAt) > moment().subtract(1,'months')
      if (isApobRecent) return true
    }

    const ldlId = getTestIdByCode(TestCode.DIRECT_LDL)
    const ldlResult = getLatestResult(ldlId)
    if (ldlResult) {
      const isLdlRecent = moment(ldlResult.collectedAt) > moment().subtract(1,'months')
      if (isLdlRecent) return true
    }

    return false
  }

  const fetchInstructions = () => {
    if (!results || !tests || !currentUser) return
    const baselineSection = hasRecentLipidTest() ? [] : [{
      title: 'Take Baseline Blood Test',
      description: <>We'll send you a painless <a onClick={() => setOpenTasso(true)} className="instruction-link">at-home blood test</a> for <a onClick={() => setOpenPanel(true)} className="instruction-link">{biomarkerCount} biomarkers</a> so we can get a baseline of your pre-medication levels. You'll receive it in 2 business days</>,
    }]
    setInstructions([{
      sections: [
        ...baselineSection,
        {
          title: `Get ${prescriptionTitle[currentUser?.lipidPrescription]}`,
          description: `We'll send your medication upon physician approval. You'll receive it within 5 business days.`
        }

      ]
    }, {
      sections: [{
        title: 'Measure Success',
        description: <>Re-test your blood 6 weeks after starting medication. And we'll adjust your prescription based on these results as needed so you can meet your goals faster.</>
      }]
    }, {
      sections: [{
        title: 'Repeat and Refine',
        description: 'Repeat the process as we progress towards your ideal medication and hit your desired cholesterol levels.'
      }]
    }])
  }
  return (
    <div className="prescription-approval">
      <TassoModal
        open={openTasso}
        setOpen={setOpenTasso}
      />
      <PanelModal
        panel={cholesterolTherapyPanel}
        title="Cholesterol Treatment Panel"
        open={openPanel}
        setOpen={setOpenPanel}
      />

      {instructions?.length > 0 && (
        <div className="instruction-list">
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row>
                <Col
                  style={{
                    width: width > Breakpoint.SM ? 80 : '100%',
                    marginBottom: width > Breakpoint.SM ? 0 : 10
                  }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  style={{
                    width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                  }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>
      )}

      <Button
        className="question-btn"
        onClick={() => window.FrontChat("show")}
      >
        I have a question?
      </Button>

      <LatestPosts />
    </div>
  )
}

export default PrescriptionApproval
import { Panel, TestCode } from '../enums/index.enum.js'

// Note: if you're updating this file, you probably want to update advancedPanel.data.js
// This should really move to backend - with service file

const PanelData = [
  {
    code: Panel.ADVANCED_BASELINE,
    name: "Instalab Panel (Baseline)",
    price: 499,
    tubes: {SST: 2, EDTA: 2},
    testCodes: [
      TestCode.APO_E,
      TestCode.APO_B,
      TestCode.LIPOPROTEIN_A,
      TestCode.TC,
      TestCode.DIRECT_LDL,
      TestCode.HDL,
      TestCode.VLDL,
      TestCode.TG,
      TestCode.INSULIN,
      TestCode.GLUCOSE,
      TestCode.HBA1C,
      TestCode.HOMAIR,
      TestCode.TSH,
      TestCode.FREET4,
      TestCode.LP_PLA2,
      TestCode.HS_CRP,
      TestCode.HOMOCYSTEINE,
      TestCode.URIC_ACID,
      TestCode.FOLATE,
      TestCode.VITAMIN_D,
      TestCode.CALCIUM,
      TestCode.FERRITIN,
      TestCode.VITAMIN_B12,
      TestCode.TOTAL_TESTOSTERONE,
      TestCode.FREE_TESTOSTERONE,
      TestCode.SHBG,
      TestCode.LH,
      TestCode.FSH,
      TestCode.ALBUMIN,
      TestCode.ALP,
      TestCode.ALT,
      TestCode.AST,
      TestCode.TOTAL_BILIRUBIN,
      TestCode.DIRECT_BILIRUBIN,
      TestCode.TOTAL_PROTEIN,
      TestCode.BUN,
      TestCode.CREATININE,
      TestCode.CYSTATIN_C,
      TestCode.EGFR,
      TestCode.CHLORIDE,
      TestCode.SODIUM,
      TestCode.POTASSIUM,
      TestCode.BICARBONATE,
      TestCode.RBC,
      TestCode.HEMOGLOBIN,
      TestCode.HEMATOCRIT,
      TestCode.MCV,
      TestCode.MCH,
      TestCode.MCHC,
      TestCode.RDW,
      TestCode.WBC,
      TestCode.NEUTROPHILS,
      TestCode.MONOCYTES,
      TestCode.LYMPHOCYTES,
      TestCode.EOSINOPHILS,
      TestCode.BASOPHILS,
      TestCode.PLATELET,
      TestCode.GRIP,
      TestCode.GRIP_LEFT,
      TestCode.GRIP_RIGHT,
      TestCode.BMI,
      TestCode.BP_D,
      TestCode.BP_S,
      TestCode.WAIST,
      TestCode.PULSE,
      TestCode.PSA_TOTAL
    ],
  },
  {
    code: Panel.ADVANCED_FOLLOWUP,
    name: "Instalab Panel (Followup)",
    price: 499,
    testCodes: [
      TestCode.APO_B,
      TestCode.LIPOPROTEIN_A,
      TestCode.TC,
      TestCode.DIRECT_LDL,
      TestCode.HDL,
      TestCode.VLDL,
      TestCode.TG,
      TestCode.INSULIN,
      TestCode.GLUCOSE,
      TestCode.HBA1C,
      TestCode.HOMAIR,
      TestCode.TSH,
      TestCode.FREET4,
      TestCode.LP_PLA2,
      TestCode.HS_CRP,
      TestCode.HOMOCYSTEINE,
      TestCode.URIC_ACID,
      TestCode.VITAMIN_D,
      TestCode.CALCIUM,
      TestCode.FERRITIN,
      TestCode.FOLATE,
      TestCode.VITAMIN_B12,
      TestCode.TOTAL_TESTOSTERONE,
      TestCode.FREE_TESTOSTERONE,
      TestCode.SHBG,
      TestCode.LH,
      TestCode.FSH,
      TestCode.ALBUMIN,
      TestCode.ALP,
      TestCode.ALT,
      TestCode.AST,
      TestCode.TOTAL_BILIRUBIN,
      TestCode.DIRECT_BILIRUBIN,
      TestCode.TOTAL_PROTEIN,
      TestCode.BUN,
      TestCode.CREATININE,
      TestCode.CYSTATIN_C,
      TestCode.EGFR,
      TestCode.CHLORIDE,
      TestCode.SODIUM,
      TestCode.POTASSIUM,
      TestCode.BICARBONATE,
      TestCode.RBC,
      TestCode.HEMOGLOBIN,
      TestCode.HEMATOCRIT,
      TestCode.MCV,
      TestCode.MCH,
      TestCode.MCHC,
      TestCode.RDW,
      TestCode.WBC,
      TestCode.NEUTROPHILS,
      TestCode.MONOCYTES,
      TestCode.LYMPHOCYTES,
      TestCode.EOSINOPHILS,
      TestCode.BASOPHILS,
      TestCode.PLATELET,
      TestCode.GRIP,
      TestCode.GRIP_LEFT,
      TestCode.GRIP_RIGHT,
      TestCode.BMI,
      TestCode.BP_D,
      TestCode.BP_S,
      TestCode.WAIST,
      TestCode.PULSE,
      TestCode.PSA_TOTAL

    ],
  },
  {
    code: Panel.THYROID,
    name: "Thyroid Panel",
    price: 99,
    testCodes: [
      TestCode.TSH,
      TestCode.FREET3,
      TestCode.FREET4,
      TestCode.TPO_ANTIBODY,
    ],
  },
  {
    code: Panel.GALLERI,
    name: "Galleri",
    price: 949,
  },
  {
    code: Panel.CHOLESTEROL_THERAPY,
    name: "Cholesterol Therapy",
    price: 99,
    testCodes: [
      TestCode.APO_B,
      TestCode.DIRECT_LDL,
      TestCode.TG,
      TestCode.HDL,
      TestCode.VLDL,
      TestCode.ALT
    ]

  },
  {
    code: Panel.INSIDETRACKER_ULTIMATE,
    name: "InsideTracker Ultimate",
    price: 399,
    testCodes: [
        TestCode.APO_B,
        TestCode.HDL,
        TestCode.DIRECT_LDL,
        TestCode.TG,
        TestCode.TC,
        TestCode.ESTRADIOL,
        TestCode.PROGESTERONE,
        TestCode.TSH,
        TestCode.CALCIUM,
        TestCode.CORTISOL,
        TestCode.DHEA_S,
        TestCode.MAGNESIUM,
        TestCode.RBC_MAGNESIUM,
        TestCode.VITAMIN_D,
        TestCode.HS_CRP,
        TestCode.WBC,
        TestCode.NEUTROPHILS,
        TestCode.BASOPHILS,
        TestCode.EOSINOPHILS,
        TestCode.LYMPHOCYTES,
        TestCode.MONOCYTES,
        TestCode.INSULIN,
        TestCode.GLUCOSE,
        TestCode.HBA1C,
        TestCode.ALT,
        TestCode.ALBUMIN,
        TestCode.AST,
        TestCode.CREATINE_KINASE,
        TestCode.GGT,
        TestCode.POTASSIUM,
        TestCode.SODIUM,
        TestCode.VITAMIN_B12,
        TestCode.FOLATE,
        TestCode.FERRITIN,
        TestCode.HEMATOCRIT,
        TestCode.HEMOGLOBIN,
        TestCode.IRON_TOTAL,
        TestCode.TIBC,
        TestCode.IRON_SATURATION,
        TestCode.MCH,
        TestCode.MCHC,
        TestCode.MCV,
        TestCode.RBC,
        TestCode.RDW,
        TestCode.MPV,
        TestCode.PLATELET,
        TestCode.TOTAL_TESTOSTERONE,
        TestCode.FREE_TESTOSTERONE,
        TestCode.SHBG
    ]
  }
]

export default PanelData
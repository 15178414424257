import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listAnnotatedResults = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/results/annotated`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const listResults = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/results`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const removeResult = async (resultId) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/results/${resultId}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const getResult = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/results/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const updateResult = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/results/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addResult = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/results`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}
import { Modal, Form, Input, Typography, Button, message } from 'antd'
import './subscribeModal.scss'
import { useState } from 'react'
import { subscribeBlog } from '../../services/blog.service';
import { useLocation, useParams } from 'react-router-dom';
import StorageKey from '../../enums/storageKey.enum';

const { Paragraph } = Typography
const { Item } = Form;
const { TextArea } = Input;

export const SubscribeModal = ({ open, setOpen, setIsSubscribed }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState()
  const { id } = useParams()
  const location = useLocation()

  const onCancel = () => {
    setOpen(false)
  }

  const onFinish = async () => {
    setIsSubmitting(true)
    const formError = await hasFormError()
    if (formError) {
      message.error('Enter a valid email address')
      setIsSubmitting(false)
      return
    }

    try {
      const {
        email,
        city,
        topics
      } = form.getFieldsValue()

      const subscription = await subscribeBlog({
        email,
        city,
        topics,
        url: location.pathname,
        blog: id
      })

      localStorage.setItem(StorageKey.SUBSCRIBED, true)
      setIsSubscribed(true)
      setOpen(false)
      message.success('Subscribed to newsletter')
      form.resetFields()
      localStorage.setItem('subscribed', subscription._id)
    } catch (err) {
      console.log('err', err)
      message.error('Failed to subscribe to newsletter')
    }
    setIsSubmitting(false)
  }

  const hasFormError = async () => {
    try {
      await form.validateFields();
    } catch (errors) {
      return true
    }
    return false
  }

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      className="subscribe-modal"
      footer={null}
      width={400}
    >
      <Paragraph className="subscribe-title">
        Subscribe to <span>Peak Health</span>
      </Paragraph>
      <Paragraph className="subscribe-subtitle">
        Insights for high-achieving people, striving to keep up with the demands of your busy life while prioritizing your health.
      </Paragraph>

      <Form 
        form={form} 
        onFinish={onFinish} 
        layout="vertical"
        className="subscribe-form"
      >
        <Item
          label="Email address"
          name="email"
          onInput={e => e.target.value = e.target.value.toLowerCase()}
          rules={[
            {
              required: true,
              message: "Enter email address",
            },
            {
              type: "email",
              message: "Enter a valid email address",
            },
          ]}
        >
          <Input placeholder="Enter email" />
        </Item>

        <Item
          label={<>
            What city do you reside in?
            <Paragraph className="item-subtitle">
              We'll invite you to private events in your area.
            </Paragraph>
          </>}
          className="city-item"
          name="city"
        >
          <Input placeholder="Enter city" />
        </Item>

        <Item
          label="What topics would you like us to dig into?"
          name="topics"
        >
          <TextArea placeholder="Enter topics" autoSize />
        </Item>

        <Item>
          <Button
            type="primary"
            className="subscribe-btn"
            htmlType='submit'
            loading={isSubmitting}
          >
            Subscribe
          </Button>
        </Item>
      </Form>
    </Modal>
  )
}
import React from "react";
import "./fixedTests.scss";
import { Typography } from "antd";
import { TestView } from "../testView/testView.component";
import { useState } from "react";
import { useEffect } from "react";
import ReportHelper from "../../../helpers/report.helper";
import { ReportSection, LowercaseTest } from "../../../enums/index.enum";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const converter = require("number-to-words");

const { Paragraph } = Typography;

export const FixedTests = ({ report, section, setSectionMeta, user, tests, results, reports }) => {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState()
  const [fixedTests, setFixedTests] = useState();

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
  }, [report, fixedTests]);

  useEffect(() => {
    setFixedTests(getFixedTests());
  }, [report, tests]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
        }
      }
    })
  }, [title, description])


  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!fixedTests) return null;
    return (
      <>
        {fixedTests?.length === 1 ? (
          <>
            Your <span>{LowercaseTest[fixedTests[0].code]}</span> has
          </>
        ) : (
          <>
            These{" "}
            <span>{converter.toWords(fixedTests?.length)} biomarkers</span> have
          </>
        )}{" "}
        become <span>optimal</span> since your last blood draw.
      </>
    );
  };

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!fixedTests) return null;
    return (
      <>
        Great work! Since{" "}
        {fixedTests?.length === 1
          ? "this biomarker has"
          : "these biomarkers have"}{" "}
        moved into a healthy range, you don't need to worry about the
        previous reading.
      </>
    );
  };


  const getFixedTests = () => {
    if (!report || !tests) return null;

    return ReportHelper.getSectionTests(
      report,
      ReportSection.FIXED_TESTS,
      tests
    );
  };

  return (
    fixedTests &&
    title && (
      <div className="fixed-tests">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">{description}</Paragraph>
        <br />
        <br />
        <TestView 
          report={report} 
          tests={fixedTests}
          results={results}
          reports={reports}
          user={user}
        />
      </div>
    )
  );
};

import { Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { EventType, QuestionTemplateCode, Role, MembershipTypeCode } from "../../../enums/index.enum";
import { addEvent } from "../../../services/event.service";
import "./nextSteps.scss";
import ReportHelper from "../../../helpers/report.helper";
import UrlHelper from "../../../helpers/url.helper";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import FlowType from "../../../enums/flowType.enum";
import { UserContext } from "../../../contexts/user.context";
import MembershipHelper from "../../../helpers/membership.helper";

const { Paragraph } = Typography;

export const NextSteps = ({ scheduleFollowup, report, user, tests, results }) => {
  const navigate = useNavigate()
  const { currentUser, memberships } = useContext(UserContext)
  const [products, setProducts] = useState();
  const [isPregnant, setIsPregnant] = useState();
  const [hasMembership, setHasMembership] = useState()
  const isAtheletePanel = ReportHelper.isAthletePanel(report)

  useEffect(() => {
    setHasMembership(MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.LONGEVITY))
  }, [memberships])

  useEffect(() => {
    if (!user || !report || !tests || !results) return;

    const fetchProducts = () => {
      const p = ReportHelper.getProducts(report, results, tests);
      
      // Remove duplicates based on product.code
      const uniqueProducts = p.filter(
        (product, index, self) =>
          index === self.findIndex((p) => p.code === product.code)
      );
      
      return uniqueProducts;
    };
  
    setProducts(fetchProducts())
  }, [user, report, results, tests]);

  useEffect(() => {
    if (!report) return 
    setIsPregnant(getIsPregnant());
  }, [report]);


  const getIsPregnant = () => {
    if (!report) return null;
    return ReportHelper.getAnswer(report, QuestionTemplateCode.PREGNANCY);
  };

  const onViewResults = async () => {
    await addEvent({ eventType: EventType.CLICK_RESULTS });
  };

  const onShare = async () => {
    await addEvent({ eventType: EventType.CLICK_SHARE });
  };

  const onClickAmazon = async () => {
    await addEvent({ eventType: EventType.CLICK_AMAZON });
  };

  const onScheduleConsultation = async () => {

    if (isAtheletePanel) {
      window.open(`https://calendly.com/d/cp32-v2b-dsx/athlete-consult?utm_content=${user._id}&email=${user.email}&name=${user.firstName}+${user.lastName}`)
    }

    else {
      let url = "/flow/longevity-consult"
      navigate(url)  
    }

    
    await addEvent({ eventType: EventType.CLICK_CONSULTATION_REPORT });
  };

  const onScheduleDraw = async () => {
    let url = isAtheletePanel ? `/flow/${FlowType.ATHLETE_TEST}` : `/flow/${FlowType.LONGEVITY_TEST}`
    navigate(url)  
  }


  return (
    user && (
      <div className="next-steps">
        <div className={classNames("advice-section", "next-steps-section")}>
          <Paragraph className="advice-title">Next Steps</Paragraph>
          <ol className="advice-list">

            <li>
              <Paragraph className="advice-item-description">
                <a
                  href={
                    currentUser?.role === Role.ADMIN
                      ? UrlHelper.getPatientProfile(user?._id, 'Results')
                      : `/patient/results`
                  }
                  target="_blank"
                  onClick={onViewResults}
                >
                  <u>View all test results</u>
                </a>
                , including your optimal levels.
              </Paragraph>
            </li>

            {isAtheletePanel ?
            <li>
            <Paragraph className="advice-item-description">
            <a onClick={onScheduleConsultation}>
              <u>Schedule a consultation</u>
            </a>{" "}
            with Dr. Steven Winiarski to discuss your results and answer any questions.
          </Paragraph>
          </li>
: hasMembership && <li>
              <Paragraph className="advice-item-description">
              <a onClick={onScheduleConsultation}>
                <u>Schedule a consultation</u>
              </a>{" "}
              with an Instalab longevity physician to discuss your results and answer any questions.
            </Paragraph>
            </li>}



            {products && products?.length > 0 && (
              <li>
                <Paragraph className="advice-item-description">
                  If you haven't already, get your &nbsp;
                  {products.map((supplement, index) => (
                    <span key={`supplement-${index}`}>
                      {products.length > 1 &&
                        index === products.length - 1 &&
                        " and "}
                      <a
                        href={supplement?.buyUrl}
                        target="_blank"
                        onClick={onClickAmazon}
                      >
                        <u>{supplement?.genericName}</u>
                      </a>
                      {index < products.length - 1 &&
                        products.length > 2 &&
                        ", "}
                    </span>
                  ))}
                  .
                </Paragraph>
              </li>
            )}


            {/* <li>
              <Paragraph className="advice-item-description">
                <a href={`/patient/share`} onClick={onShare}>
                  <u>Share your Instalab data</u>
                </a>{" "}
                with your family, friends, or doctor.
              </Paragraph>
            </li>
 */}
            <li>
              <Paragraph className="advice-item-description">
                <a onClick={onScheduleDraw}>
                  <u>Schedule a follow-up blood draw</u>
                </a>{" "}
                {scheduleFollowup
                  ? scheduleFollowup
                  : isPregnant
                  ? "after you give birth to ensure your lipids and hormones return to normal levels."
                  : `for ${
                      ReportHelper.isSuperHealthy(report)
                        ? "3 months from now to ensure your biomarker levels are aligned"
                        : "3 months from now to ensure your suboptimal levels are improving"
                    }.`}
              </Paragraph>
            </li>
          </ol>
        </div>
      </div>
    )
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { Table, Typography } from 'antd'
import { listInvoices } from "../../services/invoice.service"
import './invoices.scss';
import { PageHeader } from '../pageHeader/pageHeader.component';
import moment from 'moment'

const { Column } = Table
const { Text } = Typography

export const Invoices = () => {
  const [invoices, setInvoices] = useState([])
  const [isInvoicesLoading, setIsInvoicesLoading] = useState(true)

  useEffect(() => {
    fetchInvoices()
  }, [])

  const fetchInvoices = async () => {
    setInvoices(await listInvoices())
    setIsInvoicesLoading(false)
  }

  return (
    <div className="invoices">
      <PageHeader
        title='Payments'
        count={invoices.length}
      />

      <Table
        loading={isInvoicesLoading}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={invoices}
      >
        <Column
          title='Panel'
          dataIndex='plans'
          render={plans => {
            const counts = plans.reduce((acc, plan) => {
              if (acc[plan]) {
                acc[plan] = acc[plan] + 1
              } else {
                acc[plan] = 1
              }
              return acc
            }, {})

            return Object.keys(counts)?.length > 0 ? Object.keys(counts).map(plan => `${plan} x ${counts[plan]}`).join(', ') : 'Supplements'
          }}
        />

        <Column
          title='Total'
          dataIndex='total'
          render={total => (
            <Text className={"invoice-total"}>
              ${total.toLocaleString()}
            </Text>
          )}
        />

        <Column
          title='Created'
          dataIndex='createdAt'
          render={createdAt => moment(createdAt).format('MMM D, YYYY')}
        />
      </Table>
    </div>
  )
}
import React, { useEffect, useState } from "react";
import { Button, Table, Modal, Input, Form } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import PatientHelper from "../../helpers/patient.helper";
import "./profile.scss";
import { PageHeader } from "../pageHeader/pageHeader.component";
import { AdminLocationModal } from "../adminLocationModal/adminLocationModal.component";
import ObjectType from "../../enums/objectType.enum";
import { updatePatient } from "../../services/patient.service";
import MaskedInput from 'antd-mask-input'

const { Column } = Table;


export const PhoneEditModal = ({ open, setOpen, patient, onSuccess }) => {
  const [form] = Form.useForm(); // Create form instance

  useEffect(() => {
    // Reset phone number in form when the modal opens
    if (open) {
      form.setFieldsValue({ phone: patient?.phone || "" });
    }
  }, [open, patient, form]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      const updatedPatient = await updatePatient(patient._id, {
        fields: { phone: values.phone },
      });
      setOpen(false);
      onSuccess(updatedPatient); // Ensure onSuccess is passed the updated patient
    } catch (error) {
      console.error("Failed to update phone number:", error);
    }
  };

  return (
    <Modal
      title="Edit Phone Number"
      visible={open}
      onCancel={() => setOpen(false)}
      onOk={handleSave}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="phone"
          label="Phone Number"
          rules={[
            {
              required: true,
              message: "Please enter a phone number",
            },
            {
              pattern: /^\(\d{3}\) \d{3}-\d{4}$/,
              message:
                "Please enter a valid phone number format: (###) ###-####",
            },
          ]}
        >
          <MaskedInput mask="(000) 000-0000" placeholder="Enter new phone number" />
        </Form.Item>
      </Form>
    </Modal>
  );

};

export const Profile = ({ patient, setPatient }) => {
  const [fieldName, setFieldName] = useState(null);
  const [isLocationModalOpen, setIsLocationModalOpen] = useState(false);
  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false); // New state for phone modal
  const [profile, setProfile] = useState([]);

  useEffect(() => {
    if (!patient) return;
    document.title = `${patient.firstName} ${patient.lastName} - Profile | Instalab`;

    const fetchProfileData = async () => {
      const weight = await PatientHelper.getWeight(patient);
      const profileData = [
        { key: 'Name', value: `${patient.firstName} ${patient.lastName}` },
        { key: 'Biological sex', value: patient.gender },
        { key: 'Date of birth', value: patient.dob },
        { key: 'Email', value: patient.email },
        { 
          key: 'Phone', 
          value: ( 
            <>{patient.phone}

              <Button
                icon={<EditOutlined />}
                className="edit-btn"
                onClick={() => {
                  setFieldName('phone');
                  setIsPhoneModalOpen(true);
                }}
              />
            
            </>
          ) 
        },
        { 
          key: 'Physical Address', 
          value: (
            <>
              {getLocation(patient.location)}
              <Button
                icon={<EditOutlined />}
                className="edit-btn"
                onClick={() => {
                  setFieldName('location');
                  setIsLocationModalOpen(true);
                }}
              />
            </>
          )
        },
        { 
          key: 'Shipping Address', 
          value: (
            <>
              {getLocation(patient.shippingLocation)}
              <Button
                icon={<EditOutlined />}
                className="edit-btn"
                onClick={() => {
                  setFieldName('shippingLocation');
                  setIsLocationModalOpen(true);
                }}
              />
            </>
          )
        },
        { key: 'Height', value: patient.height ? `${patient.height} in` : '-' },
        { key: 'Weight', value: patient.weight ? `${weight} lbs` : '-' },
        { key: 'Instalab Member', value: await PatientHelper.isInstalabMember(patient) ? "Yes" : "No" },
        { key: 'Professional Athlete', value: patient.isAthlete ? "Yes" : "No"}
      ];
      setProfile(profileData);
    };


    fetchProfileData();
  }, [patient]);

  const getLocation = (location) => {
    if (!location) return '';
    const { streetAddress, streetAddress2, city, state, postalCode } = location;
    let address = streetAddress;
    if (streetAddress2) address += ` ${streetAddress2}`;
    return `${address}, ${city}, ${state} ${postalCode}`;
  };

  return (
    <div className="profile">
      <PageHeader title='Profile' />

      <AdminLocationModal
        objectId={patient?._id}
        objectType={ObjectType.PATIENT}
        fieldName={fieldName}
        open={isLocationModalOpen}
        setOpen={setIsLocationModalOpen}
        onSuccess={setPatient}
      />

      <PhoneEditModal // Integrate the new PhoneEditModal
        open={isPhoneModalOpen}
        setOpen={setIsPhoneModalOpen}
        patient={patient}
        onSuccess={setPatient}
      />

      {profile.length > 0 && (
        <div className="profile-section">
          <Table
            loading={!profile.length}
            className='primary-table'
            pagination={false}
            rowKey='key'
            dataSource={profile}
            showHeader={false}
          >
            <Column dataIndex='key' width={150} />
            <Column dataIndex='value' render={(value) => value} />
          </Table>
        </div>
      )}
    </div>
  );
};

import "./results.scss";
import { ResultsTable } from '../resultsTable/resultsTable.component';
import { useState, useEffect } from "react";
import { PageHeader } from "../pageHeader/pageHeader.component";
import { Select } from "antd";
import { useSearchParams } from "react-router-dom";

const { Option } = Select;

export const Results = () => {
  const [filterValue, setFilterValue] = useState('all');
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const filter = searchParams.get('filter') || 'all';
    setFilterValue(filter);
  }, [searchParams]);

  const handleFilterChange = value => {
    setFilterValue(value);
    let params = {};
    for (const [key, value] of searchParams.entries()) {
      params[key] = value;
    }
    setSearchParams({ ...params, filter: value });
  };

  return (
    <div className="results">
      <PageHeader 
        title='Test Results' 
        actions={(
          <div className="filter">
            <Select 
              className="select-filter"
              value={filterValue} 
              onChange={handleFilterChange} 
            >
              <Option value="all">Show All Results</Option>
              <Option value="last">Last Blood Draw Only</Option>
              <Option value="high-risk">High Risk Only</Option>
              <Option value="high-moderate-risk">High+Moderate Risk Only</Option>
              <Option value="low-risk">Low Risk Only</Option>
              <Option value="instalab-panel">Longevity Panel Only</Option>
            </Select>
          </div>
        )}
      />

      <ResultsTable 
        filterValue={filterValue} 
      />
    </div>
  );
};

import React, { useContext, useEffect } from 'react';
import './gift.scss';
import EventCode from "../../enums/eventCode.enum";
import PosthogHelper from '../../helpers/posthog.helper';
import { FilloutStandardEmbed } from "@fillout/react";
import { UserContext } from '../../contexts/user.context';


export const Gift = () => {
  const { currentUser } = useContext(UserContext)

  useEffect(() => {
    document.title = 'Give the Gift of Instalab | Instalab'
    document.description = 'Peak health is the most personal gift you can give. Choose the service that’s right for your friends and loved ones.'
  }, [])

  useEffect(() => {
    trackPosthog()
  }, [currentUser])

  const trackPosthog = () => {
    PosthogHelper.track(currentUser, EventCode.VISIT_GIFT)
  }


  return (
  <div className="gift">
    <FilloutStandardEmbed filloutId="2DFQRebLNyus" dynamicResize/>
  </div>)
}
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { getMembershipTypeByCode } from '../../services/membershipType.service'
import Color from '../../colors.scss'
import FlowType from "../../enums/flowType.enum"
import ConfirmInterest from "./static/confirmInterest/confirmInterest.component"
import { PanelModal } from "../panelModal/panelModal.component"
import { advancedPanel } from "../../data/advancedPanel.data"
import { Step as IntakeStep } from '../intakeFlow/intakeFlow.component'
import { addSlack } from "../../services/slack.service"
import ConfirmAppointment from "./static/confirmAppointment/confirmAppointment.component"
import { getAvailability } from "../../services/schedule.service"
import PhlebType from "../../enums/phlebType.enum"
import { addProduct } from "../../services/product.service"
import { listProductTypes } from '../../services/productType.service'
import { completeFlow } from "../../services/flow.service"
import ProductHelper from "../../helpers/product.helper"
import { useNavigate } from "react-router-dom"
import { updateProduct } from "../../services/product.service"

export const Step = {
  LOCATION: 'location',
  ACCOUNT: 'account',
  PAY: 'pay',
  ADDON: 'add-ons',
  NAME: 'name',
  PHONE: 'phone',
  GENDER: 'gender',
  DOB: 'dob',
  INTEREST: 'interest',
  INTEREST_CONFIRMATION: 'interest-confirmation',
  SCHEDULE: 'schedule',
  INVITE: 'invite',
  APPOINTMENT_CONFIRMATION: 'appointment-confirmation',
  INTAKE: 'intake',
  SHIPPING: 'shipping',
}

export const LongevityTestFlow = () => {
  const navigate = useNavigate()
  const { longevityMembership, currentUser } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [openPanel, setOpenPanel] = useState()
  const [productTypes, setProductTypes] = useState()
  const [membershipType, setMembershipType] = useState()
  const [phlebType, setPhlebType] = useState()
  const [hasPass, setHasPass] = useState()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  useEffect(() => {
    fetchSkeleton()
  }, [productTypes, longevityMembership, flow, currentUser, phlebType, hasPass])

  useEffect(() => {
    fetchPhlebType()
  }, [currentUser, flow])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipType()
  }, [])

  const fetchPhlebType = async () => {
    if (!currentUser?.location || !flow) return
    const response = await getAvailability({
      startDate: new Date(),
      location: currentUser.location,
      flowId: flow._id
    })
    setPhlebType(response.phlebType)
  }

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.LONGEVITY_PANEL,
            ProductTypeCode.OMEGA_3_ADDON,
          ]
        }
      }
    }))
  }

  const fetchMembershipType = async () => {
    setMembershipType(await getMembershipTypeByCode(MembershipTypeCode.LONGEVITY))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        onNextStep: async (patient) => {
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType || flow?.user?.phlebType
          return phlebType ? Step.ACCOUNT : Step.INTEREST
        },
        title: `Where are you located?`,
        description: `Your phlebotomist will meet you here to draw your blood.`,
        field: {
          name: 'location'
        },
      },
      [Step.INTEREST]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.INTEREST_CONFIRMATION,
        buildUser: true,
        title: `Unfortunately, we're not in your area yet...`,
        description: <>Leave your email and we'll let you know when we open in your area. If you would like us to find a lab close to you to draw your blood, email us at <a href="mailto:support@instalab.com">support@instalab.com</a>.</>,
        field: {
          name: 'email',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
        },
        onSuccess: async (patient) => {
          await addSlack({
            message: `${patient.email} interested: longevity test`,
            channel: process.env.REACT_APP_PATIENT_SLACK
          })
        },
      },
      [Step.INTEREST_CONFIRMATION]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `You joined the waitlist.`,
        description: `When we launch in your area, we'll send you an email.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <ConfirmInterest />
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PAY,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>This will help you manage orders and test results. Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.LONGEVITY_TEST}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.ADDON,
        addProduct: true,
        productType: ProductTypeCode.LONGEVITY_PANEL,
        addLongevityMembership: true,
        title: (longevityMembership) ?
          <>Pay <span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.LONGEVITY_PANEL))}</span> <span className="original-price">${getProductType(ProductTypeCode.LONGEVITY_PANEL)?.cost}</span> for the Longevity Blood Panel.</> 
          : flow?.addLongevityMembership
          ? <>Pay <span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.LONGEVITY_PANEL))}</span> <span className="original-price">${getProductType(ProductTypeCode.LONGEVITY_PANEL)?.cost}</span> for the Longevity Blood Panel and <span className="true-price">${membershipType?.cost}/month</span> for your Instalab membership.</>
          : <>Pay <span className="true-price">${getProductType(ProductTypeCode.LONGEVITY_PANEL)?.cost}</span> for the Longevity Blood Panel.</>, 
        description: (!hasPass) ? <>If you already have an Instalab account, <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.LONGEVITY_TEST}`)}>log in here</a> first.</> : 
        <>Includes testing for over 60 biomarkers, a detailed report explaining your health risks, and a personalized action plan to optimize your long-term health.</>,
      },
      [Step.ADDON]: {
        type: StepType.UPSELL,
        onNextStep: (patient) => {
          const phlebType = flow?.appointment?.phlebType || patient?.phlebType || flow?.user?.phlebType
          return phlebType === PhlebType.GETLABS ? Step.SHIPPING : Step.GENDER
        },
        title: (flow?.addLongevityMembership || longevityMembership) ? <>Add the Omega-3 Index Panel for <span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.OMEGA_3_ADDON))}</span>.</> :<>Add the Omega-3 Index Panel for <span className="true-price">${getProductType(ProductTypeCode.OMEGA_3_ADDON)?.cost}</span>.</> ,
        points: [
          <>Included in the same blood draw appointment.</>,
          <>Measures all fatty acid levels and includes Omega-3 Index, Ratios, and Trans Fat Index.</>,
          <>Helps further stratify cardiovascular disease risk and identify preventive measures.</>,
        ],
        hasPurchased: flow?.products?.some(product => ProductTypeCode.OMEGA_3_ADDON === product.productType?.code),
        buttonText: 'Add Panel',
        upsellProductType: ProductTypeCode.OMEGA_3_ADDON,
        onUpsell: async () => {
          const response = await addProduct({
            fields: {
              type: ProductTypeCode.OMEGA_3_ADDON,
              flowId: flow?._id
            }
          })
          setFlow(response.flow)
        },
      },

      [Step.SHIPPING]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.GENDER,
        title: `Where should we ship your blood test kit?`,
        description: `You will receive a test kit in the mail containing all the supplies needed for our phlebotomist to complete your blood draw appointment.`,
        field: {
          name: 'shippingLocation'
        },
        onSuccess: async (patient) => {
          try {
            for (const product of flow?.products) {
              await updateProduct(product._id, { 
                fields: {shippingLocation: patient.shippingLocation} 
              });
            }
          } 
          catch (error) {
            console.log(error)
          }

          return true
        }
      },

      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your sex assigned at birth?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUserRef?.current?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.dob
        }
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this for your lab order for the test.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.SCHEDULE,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.phone
        }
      },
      [Step.SCHEDULE]: {
        type: StepType.SCHEDULE,
        nextStep: Step.INVITE,
        addAppointment: true,
        title: `Schedule your blood draw appointment.`,
        description: phlebType === PhlebType.INSTALAB ? 
                      `The appointment takes about 15 minutes. You must fast 8 hours before your appointment. Water and black coffee are fine.` :
                      `A phlebotomist from our partner (Getlabs) will arrive during the 2-hour time window you select. The appointment takes about 10 minutes. You must fast 8 hours before your appointment. Water and black coffee are fine. `,
      },
      [Step.INVITE]: {
        type: StepType.INVITE,
        nextStep: Step.APPOINTMENT_CONFIRMATION,
        addInvite: true,
        title: `Add friends and family to your appointment.`,
        description: <>Add additional people to your appointment to get the same tests done for {flow?.appointment?.invitePrice < getProductType(ProductTypeCode.LONGEVITY_PANEL)?.cost 
          ? 'the discounted price of' 
          : ''} <span style={{ color: Color.success }}>{ProductHelper.formatPrice(flow?.appointment?.invitePrice)} per invite</span>{flow?.appointment?.invitePrice < getProductType(ProductTypeCode.LONGEVITY_PANEL)?.cost 
            ? <span> <span className="original-price">(${getProductType(ProductTypeCode.LONGEVITY_PANEL)?.cost})</span></span> 
            : ''}. You won't be charged until your appointment is complete.</>,
      },
      [Step.APPOINTMENT_CONFIRMATION]: {
        type: StepType.STATIC,
        path: `/flow/${FlowType.INTAKE}/${IntakeStep.MEDICAL_CONDITIONS_CATEGORIES}`,
        title: `Your blood draw appointment is confirmed!`,
        description: flow?.appointment?.phlebType === PhlebType.INSTALAB ? 
                        <>Now we just need a little more information to conduct your blood draw appointment and interpret your test results correctly.</> :
                        <><u>You will receive a test kit in the mail</u> containing all the supplies needed to complete your draw. A phlebotomist from our partner (Getlabs) will call or text you upon arrival on the day of your appointment.<br/><br/>Now we just need a little more information to conduct your blood draw appointment and interpret your test results correctly.</>,
        buttonText: 'Continue',
        content: <ConfirmAppointment flow={flow} />,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }


  return skeleton && <>
    <PanelModal
      panel={advancedPanel}
      title="Longevity Blood Panel"
      open={openPanel}
      setOpen={setOpenPanel}
    />
    <Flow 
      skeleton={skeleton} 
      flow={flow} 
      setFlow={setFlow}
      initialUser={initialUser}
      setInitialUser={setInitialUser}
      setHasPass={setHasPass}
      startIndex={1}
    />
  </>
}
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { useState, useRef, useEffect } from "react"
import MemberConfirmed from "./custom/memberConfirmed/memberConfirmed.component"
import MemberIntro from "./custom/memberIntro/memberIntro.component"
import { getMembershipTypeByCode } from "../../services/membershipType.service"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { completeFlow } from "../../services/flow.service"
import FlowType from "../../enums/flowType.enum"
import { useNavigate } from "react-router-dom"

export const Step = {
  INTRO: 'intro',
  ACCOUNT: 'account',
  PAY: 'pay',
  NAME: 'name',
  PHONE: 'phone',
  PASSWORD: 'password',
  CONFIRM: 'confirm',
}

export const MemberFlow = () => {
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipType, setMembershipType] = useState()
  const [hasPass, setHasPass] = useState()
  const navigate = useNavigate()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  useEffect(() => {
    fetchMembershipType()
  }, [])

  useEffect(() => {
    fetchSkeleton()
  }, [membershipType, flow, hasPass])

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.ACCOUNT,
        content: ({ footer }) => <MemberIntro membershipType={membershipType} footer={footer} />
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PAY,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>This will help you manage orders and test results. Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.MEMBER}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.NAME,
        addSubscription: true,
        subscriptionType: MembershipTypeCode.LONGEVITY,
        title: <>Pay <span className="true-price">${membershipType?.cost}/month</span> for your Instalab membership.</>,
        description: <>Get the tools and support to stay on top of your health. Includes exclusive savings on all tests, dedicated care concierge, access to longevity physicians and more. Easy to cancel anytime.</>,
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this to set up your account.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.CONFIRM,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.phone
        }
      },
      [Step.CONFIRM]: {
        type: StepType.CUSTOM,
        content: ({ footer }) => <MemberConfirmed footer={footer} />,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  const fetchMembershipType = async () => {
    setMembershipType(await getMembershipTypeByCode(MembershipTypeCode.LONGEVITY))
  }

  return <>
    {skeleton && (
      <Flow 
        skeleton={skeleton} 
        flow={flow} 
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
      />
    )}
  </>
}
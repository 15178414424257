import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'
import QueryStringHelper from '../helpers/queryString.helper'

export const listPatients = async (params={}) => {
  const queryString = QueryStringHelper.format(ServiceHelper.getParams(params))
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/patients?${queryString}`, {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const listMembers = async (params={}) => {
  const queryString = QueryStringHelper.format(ServiceHelper.getParams(params))
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/patients/members?${queryString}`, {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const listHHMembers = async (params={}) => {
  const queryString = QueryStringHelper.format(ServiceHelper.getParams(params))
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/patients/hhmembers?${queryString}`, {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const acceptInvite = async (id, params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}/accept-invite`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addReportForPatient = async (id) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}/reports`, ServiceHelper.getParams(), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const getPatient = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const addPatient = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/patients`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const invitePatient = async (id, params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}/invite`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const updatePatient = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  console.log(response)
  return response.data
}

export const removePatient = async (id, params={}) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/patients/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}
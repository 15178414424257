import TestCode from "../enums/testCode.enum";

// this is a repetitive file with panel.data.js - the differene is this is categorizing it for display onto the marketing page

export const addOnTests = {
    Adrenal: [
        TestCode.CORTISOL,
        TestCode.DHEA_S
    ],
    "Blood Cells": [
        TestCode.DDIMER,
        TestCode.HEMOGLOBIN_A,
        TestCode.HEMOGLOBIN_A2,
        TestCode.HEMOGLOBIN_F,
    ],
    Cancer: [
        TestCode.AFP
    ],
    ELectrolyes: [
        TestCode.RBC_MAGNESIUM,
        TestCode.PHOSPHOROUS
    ],
    Immunologic: [
        TestCode.ANA_SCREEN,
        TestCode.ANA_PATTERN,
        TestCode.ANA_TITER,
        TestCode.DOUBLE_STRAND_DNA_AB,
        TestCode.SS_A_AB,
        TestCode.SS_B_AB,
        TestCode.RHEUMATOID_FACTOR,
        // TestCode.CCP_AB
    ],
    Inflammation: [
        TestCode.SED_RATE,
        TestCode.CREATINE_KINASE
    ],
    Lipids: [
        TestCode.CAMPESTEROL,
        TestCode.SITOSTEROL,
        TestCode.LATHOSTEROL,
        TestCode.DESMOSTEROL,
    ],
    Lipoproteins:[
        TestCode.NMR_LDL_P,
        TestCode.NMR_LDL_SIZE,
        TestCode.NMR_SMALL_LDL_P,
        TestCode.NMR_HDL_P,
        TestCode.NMR_HDL_SIZE,
        TestCode.NMR_LARGE_HDL_P,
        TestCode.NMR_LARGE_VLDL_P,
        TestCode.NMR_LP_IR_SCORE
    ],
    Metabolism: [
        TestCode.CPEPTIDE,
        TestCode.GLYCOMARK
    ],
    Nutrients: [
        TestCode.MAGNESIUM,
        TestCode.IRON_TOTAL,
        TestCode.IRON_SATURATION,
        TestCode.TIBC,
        TestCode.OMEGA_3_INDEX,
        TestCode.OMEGA_6_3,
        TestCode.ARACHIDONIC_EPA,
        TestCode.TRANSFAT_INDEX,
        TestCode.ZINC,
        TestCode.MMA
    ],
    Pituitary: [
        TestCode.IGFI,
        TestCode.PROLACTIN,
    ],
    "Sex Hormones": [
        TestCode.PROGESTERONE,
        TestCode.TOTAL_HCG,
        TestCode.ESTRADIOL
    ],
    Prostate: [
        TestCode.PSA_FREE
    ],
    Thyroid: [
        TestCode.FREET3, 
        TestCode.TPO_ANTIBODY,
        TestCode.THYROGLOBULIN_ANTIBODY,
    ],
    Toxins: [
        TestCode.MERCURY
    ]
};
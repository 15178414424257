import { useState, useEffect } from 'react'
import { Typography, Row, Col, Skeleton, Avatar } from 'antd'
import { listBlogs } from "../../services/blog.service"
import { useNavigate } from 'react-router-dom'
import "./latestPosts.scss"
import Breakpoint from '../../enums/breakpoint.enum'
import useWidth from '../../hooks/useWidth.hook'

const { Text, Paragraph } = Typography

const LatestPosts = () => {
  const navigate = useNavigate()
  const width = useWidth()
  const [blogs, setBlogs] = useState()

  useEffect(() => {
    fetchBlogs()
  }, [])

  const fetchBlogs = async () => {
    let blogList = await listBlogs()
    blogList = blogList.filter(({ image }) => image).slice(0,4)
    setBlogs(blogList)
  }

  return (
    <div className="latest-posts">
      <div className="posts-header">
        <Paragraph className="posts-header-text">
          Read Latest Posts
        </Paragraph>
      </div>

      <div className="post-list">
        <Row gutter={20}>
          {blogs?.map(blog => (
            <Col 
              key={`blog-${blog._id}`}
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <div 
                className="post-item"
                onClick={() => navigate(`/newsletter/${blog.slug}`)}
                style={{
                  marginTop: width > Breakpoint.SM ? 50 : 35
                }}
              >

                <div className="post-image-container">
                  <img 
                    src={`${blog.image}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`} 
                    className="post-image"
                  />

                  <Skeleton.Avatar 
                    active
                    shape='square' 
                    className="image-skeleton"
                  />
                </div>

                <Paragraph 
                  className="post-title"
                  ellipsis={{
                    rows: 1
                  }}
                >
                  {blog.title}
                </Paragraph>

                <Paragraph 
                  className="post-content" 
                  ellipsis={{
                    rows: 3
                  }}
                >
                  {blog.content.replace(/<table[^*]+?<\/table>/g, ' ').replace(/<strong[^*]+?<\/strong>/g,' ').replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, ' ')}
                </Paragraph>
                <div className="post-author">
                  <Avatar 
                    src={`${blog.author.image}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`} 
                    className="post-avatar"
                  />
                  <Text className="author-name">
                    {blog.author.name}
                  </Text>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  )
}

export default LatestPosts
import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listAuthors = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/authors`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const getAuthor = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/authors/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const addAuthor = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/authors`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const updateAuthor = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/authors/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeAuthor = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/authors/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}
import { useContext, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { UserContext } from "../../contexts/user.context"

export const Auth = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { token } = useContext(UserContext)

  useEffect(() => {
    if (!token) {
      navigate(`/login?redirect=${location.pathname}`)
    }
  }, [token])

  return token ? children : null
}
import { Typography, Button, Row, Col }  from 'antd'
import Breakpoint from '../../enums/breakpoint.enum.js'
import LatestPosts from '../latestPosts/latestPosts.component.js'
import useWidth from '../../hooks/useWidth.hook.js'


const { Text, Paragraph } = Typography

export const ToxicHairTestConfirm = () => {
  const width = useWidth()

  const instructions = [{
    sections: [
      {
      title: 'Receive Hair Collection Kit',
      description: <>It'll come within one week of purchase. This includes everything you'll need to collect your hair for testing.</>
    }, 
  ]}, 
  {
    sections: [{
      title: 'Self Collect Hair + Mail Back',
      description: <>It just requires a few strands of hair. This takes two minutes or less. Just drop in mail when done.</>
    }]
  }, 
  {
    sections: [{
      title: 'Get Test Results',
      description: <>Test results are usually ready 1 week after your sample arrives at the lab.</>
    }]
  }]

  return (
    <div className="prescription-approval">


      {instructions?.length > 0 && (
        <div className="instruction-list">
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row>
                <Col
                  style={{
                    width: width > Breakpoint.SM ? 80 : '100%',
                    marginBottom: width > Breakpoint.SM ? 0 : 10
                  }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  style={{
                    width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                  }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>
      )}

      <Button
        className="question-btn"
        onClick={() => window.FrontChat("show")}
      >
        I have a question?
      </Button>

      <LatestPosts />
    </div>
  )
}


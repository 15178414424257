import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const removeCard = async (cardId, params={}) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/cards/${cardId}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}

export const addCard = async (params={}) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/cards`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const listCards = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/cards`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(params),
  })
  return response.data
}
import { Typography } from "antd";
import "./ckdAdvice.scss";
import { TestView } from "../testView/testView.component";
import { useState, useEffect } from "react";
import { TestCode, LowercaseTest, ReportSection, MedicalCondition, QuestionTemplateCode, Medication, Condition } from "../../../enums/index.enum";
import ReportHelper from "../../../helpers/report.helper";
import { AdviceSection } from "../adviceSection/adviceSection.component";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const converter = require("number-to-words");

const { Paragraph } = Typography;

export const CkdAdvice = ({ report, setSectionMeta, section, user, tests, results, reports }) => {
  const [recs, setRecs] = useState()
  const [kidneyTests, setKidneyTests] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [recParams, setRecParams] = useState();

  // baseline or followup?
  const [isFixed, setIsFixed] = useState();
  const [isBetter, setIsBetter] = useState();
  const [isWorse, setIsWorse] = useState();

  useEffect(() => {
    setIsFixed(ReportHelper.isFixed(report, ReportSection.CKD_ADVICE));
    setIsBetter(ReportHelper.isBetter(report, ReportSection.CKD_ADVICE));
    setIsWorse(ReportHelper.isWorse(report, ReportSection.CKD_ADVICE));
  }, [report]);

  useEffect(() => {
    setKidneyTests(getKidneyTests());
  }, [report, tests, isFixed]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
          recs
        }
      }
    })
  }, [title, description, recs])

  useEffect(() => {
    setTitle(getTitle());
    setDescription(getDescription());
    setRecParams(getRecParams());
  }, [report, kidneyTests, isFixed, isBetter, isWorse]);


  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)

    if (!report || !tests || !kidneyTests) return null

    if (isWorse) {
      return <>Your <span>kidney health</span> appears to have <span>declined</span> since your last blood draw.</>
    }

    else if (isBetter || isFixed) {
      return <>Your <span>kidney health</span> has <span>improved</span> since your last blood draw.</>
    }


    return <>         
      {kidneyTests.length === 1 
        ? <>Your <span>{kidneyTests[0].code === TestCode.EGFR ? "low"  : "elevated"} {LowercaseTest[kidneyTests[0].code]}</span> suggests</>
        : <>These{" "}<span>{converter.toWords(kidneyTests.length)} biomarkers</span>{" "}suggest</>
      }{" "}you have possible <span>kidney dysfunction</span>.
    </>
  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    if (!report || !tests || !kidneyTests) return null

    const diagnosedConditions = ReportHelper.getAnswer(report, QuestionTemplateCode.CURRENT_MEDICAL_CONDITIONS)
    const currentMedications = ReportHelper.getAnswer(report, QuestionTemplateCode.CURRENT_MEDICATIONS)

    const hasCKD = diagnosedConditions?.includes(MedicalCondition.CHRONIC_KIDNEY_DISEASE)
    const hasMedications = currentMedications?.includes(Medication.STATIN) || currentMedications?.includes(Medication.LOWER_GLUCOSE)
    const hasMetabolicSyndrome = report?.result?.conditions?.includes(Condition.METABOLIC_SYNDROME)
    const hasCorticosteroids = currentMedications?.includes(Medication.CORTICOSTEROIDS)
    const isSmoker = ReportHelper.getAnswer(report, QuestionTemplateCode.SMOKING)

    if (isFixed) {
      return <>Good work on improving this. {hasMetabolicSyndrome && <>That said, it's important to focus on optimizing your blood pressure, glucose levels and weight to prevent kidney disease.</>}</>
    }
    
    return <>
    
    Your {getTestNames(kidneyTests)} {kidneyTests.length>1 ? `suggest ` : `suggests `} that your kidneys are not working as efficiently as they should. 

    {hasCKD ? <> There are several reasons why your eGFR might be low including your current chronic kidney disease condition {hasMedications && <>and <a href="https://www.kidney.org/atoz/content/5-drugs-you-may-need-to-avoid-or-adjust-if-you-have-kidney-disease" target="_blank">certain medications</a></>}.</>
            : hasMetabolicSyndrome ? <> This could be due to high blood pressure and metabolic health issues. If not managed, this can eventually lead to chronic kidney disease.</>
            : <> While this could suggest early kidney dysfunction, it's important to consider that transient factors like dehydration or recent illness could also influence these results. </>} 

    {hasCorticosteroids && isSmoker && kidneyTests?.find(test=> test.code === TestCode.CYSTATIN_C) && <> Additionally, there is some evidence that smoking and corticosteroid use can increase cystatin C and underestimate your GFR, independent of actual kidney health.</> }
    {/* {kidneyTests?.length === 2 && kidneyTests?.find(test=> test.code === TestCode.CYSTATIN_C) && <> Moreover, while these are reliable markers for assessing kidney function, their accuracy can be affected by conditions like chronic inflammation, corticosteroid use, smoking, and thyroid disorders.</>} */}
    </>

  }

  const getKidneyTests = () => {
    if (!report || !tests) return null;
    if (isFixed) return ReportHelper.getSectionFixedTests(report, ReportSection.CKD_ADVICE, tests);
    return ReportHelper.getSectionTests(report, ReportSection.CKD_ADVICE, tests);
  };

  const getTestNames = (kidneyTests) => {

    const testNames = kidneyTests.map((test, index) => (
      <>
      <span key={test._id}>{LowercaseTest[test.code]}</span>
      {kidneyTests.length > 2 && index < kidneyTests.length - 1 && ', '}
      {kidneyTests.length > 1 && index < kidneyTests.length - 1 && ' and '}
      </>
    ));

    return testNames
  }

  const getRecParams = () => {
    if (!report || !kidneyTests) return null;
    let params = {
      hasHighCreatinine: kidneyTests?.some(({ code }) => code === TestCode.CREATININE)
    }
    
    return params
  };



  return (
    kidneyTests && recParams && (
      <div className="ckd-advice">
        <Paragraph className="report-template-title">{title}</Paragraph>
        <Paragraph className="report-template-description">{description}</Paragraph>
        <TestView 
          report={report} 
          tests={kidneyTests} 
          results={results}
          reports={reports}
          user={user}
        />
        <AdviceSection 
          report={report} 
          results={results}
          tests={tests}
          section={section}
          recs={recs}
          setRecs={setRecs}
          reports={reports}
        />
      </div>
    )
  );
};

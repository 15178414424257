import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Form, Input, Typography, Button, Select, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import "./joinEvent.scss";
import { posthog } from 'posthog-js';
import { ArrowRightOutlined } from '@ant-design/icons';
import MaskedInput from 'antd-mask-input';
import { UserContext } from '../../contexts/user.context';
import { getAppointmentParent, joinAppointmentParent } from "../../services/appointment.service";
import ValidateHelper from "../../helpers/validate.helper";
import dayjs from 'dayjs';
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import classNames from 'classnames';
import Breakpoint from "../../enums/breakpoint.enum";
import Gender from '../../enums/gender.enum';
import tzLookup from 'tz-lookup';
import useWidth from '../../hooks/useWidth.hook';
import StorageKey from '../../enums/storageKey.enum';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(localeData);

const { Item } = Form;
const { Paragraph } = Typography;

export const JoinEvent = () => {
  const navigate = useNavigate();
  const { appointmentParentId } = useParams();
  const { currentUser, token, setToken } = useContext(UserContext);
  const width = useWidth()
  const [form] = Form.useForm();
  const [appointmentParent, setAppointmentParent] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locationTimezone, setLocationTimezone] = useState('UTC'); 

  useEffect(() => {
    fetchAppointmentParent();
  }, [appointmentParentId]);

  useEffect(() => {
    if (appointmentParent && appointmentParent.location) {
      fetchTimezoneForLocation(appointmentParent.location);
    }
  }, [appointmentParent]);

  const fetchAppointmentParent = async () => {
    if (!appointmentParentId) return;
    const parent = await getAppointmentParent(appointmentParentId);
    setAppointmentParent(parent);
  };

  const fetchTimezoneForLocation = (location) => {
    if (location && location.latitude && location.longitude) {
      try {
        const timezone = tzLookup(location.latitude, location.longitude);
        setLocationTimezone(timezone);
      } catch (error) {
        console.log('Failed to fetch timezone information.');
      }
    }
  };

  const convertTimeZone = (time) => {
    const formattedDate = dayjs(time).tz(locationTimezone);
    return formattedDate;
  };

  const hasFormError = async () => {
    try {
      await form.validateFields();
    } catch (errors) {
      return true;
    }
    return false;
  };

  const onAccept = async () => {
    setIsSubmitting(true);
    try {
      if (await hasFormError()) {
        message.error('Enter valid account information');
        setIsSubmitting(false);
        return;
      }

      if (token) {
        const params = { userId: currentUser._id}
        const response = await joinAppointmentParent(appointmentParentId, params);
        setAppointmentParent(response.appointmentParent);
        message.success('Joined event!');
      }

      else {
        const { email, phone, firstName, lastName, password, gender, dob } = form.getFieldsValue();
        const params = { firstName, lastName, email, phone, password, gender, dob };
  
        const response = await joinAppointmentParent(appointmentParentId, params);
  
        localStorage.setItem(StorageKey.TOKEN, response.token);
        setToken(response.token)
        setAppointmentParent(response.appointmentParent);
  
        message.success('Instalab account created. Joined event!');
      }


      navigate(`/flow/intake`);
    } catch (err) {
      let msg = 'Failed to join event';
      if (err.response?.data?.code === 11000) {
        msg = 'Account already exists with this email';
      } else if (err.response?.data?.message) {
        msg = err.response?.data?.message;
      }
      message.error(msg);
    }
    setIsSubmitting(false);
  };

  const onLogin = () => {
    navigate(`/login?redirect=/events/${appointmentParent._id}/join`);
  };

  return appointmentParent && (
    <div 
      className={classNames("checkout-v2", "join")}
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100svh - 120px)' : 'calc(100svh - 94px)'
      }}
    >
      <div className="grey-bg" />

      <Paragraph className="join-title">
      {appointmentParent.title}
      </Paragraph>
      <Paragraph className="join-description">
        You're invited to a blood draw at {appointmentParent.location.streetAddress} from {convertTimeZone(appointmentParent.start).format('MMM D, YYYY h:mm a z')} to {convertTimeZone(appointmentParent.end).format('MMM D, YYYY h:mm a z')}. 
        
        {!token && <> Create your Instalab account to confirm you will join. If you already have an account, <a onClick={onLogin}>log in</a>.</>}
      </Paragraph>

      {!token && 
        <Form
          form={form}
          layout='vertical'
          className='account-form'
        >
          <Row gutter={12}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="First Name"
                name="firstName"
                rules={[{ 
                  required: true, 
                  message: 'Enter first name'
                }]}
              >
                <Input placeholder="Enter first name" />
              </Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Last Name"
                name="lastName"
                rules={[{ 
                  required: true, 
                  message: 'Enter last name'
                }]}
              >
                <Input placeholder="Enter last name" />
              </Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Email Address"
                name="email"
                rules={[{ 
                  required: true, 
                  message: 'Enter email address'
                }, {
                  message: 'Enter valid email address',
                  validator: (_, value) => {
                    if (!value || ValidateHelper.email(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject('Enter valid email address');
                    }
                  }
                }]}
              >
                <Input placeholder="Enter email address" />
              </Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Phone Number"
                name="phone"
                rules={[{ 
                  required: true, 
                  message: 'Enter phone number'
                }]}
              >
                <MaskedInput 
                  placeholder={'(XXX) XXX-XXXX'}
                  mask="(000) 000-0000"
                  inputMode="numeric"
                />
              </Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Sex at Birth"
                name="gender"
                rules={[{ 
                  required: true, 
                  message: 'Enter sex'
                }]}
              >
                <Select
                  placeholder="Select sex"
                  showSearch
                  options={Object.values(Gender).map(gender => {
                    return {
                      label: gender,
                      value: gender,
                    }
                  })}
                />
              </Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span:24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Date of Birth"
                name="dob"
                rules={[{ 
                  required: true, 
                  message: 'Enter date of birth'
                }]}
              >
                <MaskedInput 
                  placeholder={'MM/DD/YYYY'}
                  mask="00/00/0000"
                  inputMode="decimal"
                />
              </Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Password"
                name="password"
                rules={[{ 
                  required: true, 
                  message: 'Enter password'
                }]}
              >
                <Input 
                  placeholder="Enter password" 
                  type="password"
                />
              </Item>
            </Col>
          </Row>
        </Form>}

      <div className="checkout-btn-container">
        <div className="checkout-btn-list">
          <Button 
            className="join-btn"
            type='primary'
            onClick={onAccept}
            loading={isSubmitting}
          >
            Join Event
            {!isSubmitting && <ArrowRightOutlined />}
          </Button>
        </div>
      </div>
    </div>
  );
};

import TestCode from "../enums/testCode.enum";

export const athletePanel = {
    Lipoproteins: [TestCode.APO_B, TestCode.LIPOPROTEIN_A],
    Lipids: [
        TestCode.TC,
        TestCode.DIRECT_LDL,
        TestCode.HDL,
        TestCode.VLDL,
        TestCode.TG,
    ],
    Inflammation: [
        TestCode.HS_CRP,
        TestCode.HOMOCYSTEINE,
        TestCode.URIC_ACID,
    ],
    Adrenal: [
        TestCode.CORTISOL,
        TestCode.DHEA_S
    ],
    Pituitary: [
        TestCode.IGFI
    ],
    "Glucose Metabolism": [
        TestCode.INSULIN,
        TestCode.GLUCOSE,
        TestCode.HBA1C,
        TestCode.HOMAIR,
    ],
    Thyroid: [TestCode.TSH, TestCode.FREET4],
    Nutrients: [
        TestCode.VITAMIN_D,
        TestCode.VITAMIN_B12,
        TestCode.FOLATE,
        TestCode.ZINC,
        TestCode.IRON_TOTAL,
        TestCode.IRON_SATURATION,
        TestCode.TIBC,
        TestCode.FERRITIN,
        TestCode.OMEGA_3_INDEX,
        TestCode.OMEGA_6_3,
        TestCode.ARACHIDONIC_EPA,
        TestCode.TRANSFAT_INDEX
    ],
    "Sex Hormones": [
        TestCode.TOTAL_TESTOSTERONE,
        TestCode.FREE_TESTOSTERONE,
        TestCode.SHBG,
        TestCode.LH,
        TestCode.FSH,
        TestCode.ESTRADIOL
    ],
    Liver: [
        TestCode.ALBUMIN,
        TestCode.ALP,
        TestCode.ALT,
        TestCode.AST,
        TestCode.TOTAL_BILIRUBIN,
        TestCode.DIRECT_BILIRUBIN,
        TestCode.TOTAL_PROTEIN,
        TestCode.GGT
    ],
    Renal: [
        TestCode.BUN,
        TestCode.CREATININE,
        TestCode.CYSTATIN_C,
        TestCode.EGFR,
    ],
    Electrolytes: [
        TestCode.CALCIUM,
        TestCode.CHLORIDE,
        TestCode.SODIUM,
        TestCode.POTASSIUM,
        TestCode.BICARBONATE,
    ],
    "Blood Cells": [
        TestCode.WBC,
        TestCode.NEUTROPHILS,
        TestCode.LYMPHOCYTES,
        TestCode.BASOPHILS,
        TestCode.MONOCYTES,
        TestCode.EOSINOPHILS,
        TestCode.RBC,
        TestCode.HEMOGLOBIN,
        TestCode.HEMATOCRIT,
        TestCode.MCV,
        TestCode.MCH,
        TestCode.MCHC,
        TestCode.RDW,
        TestCode.PLATELET,
        TestCode.MPV
    ],
    Prostate: [
        TestCode.PSA_TOTAL
    ],
    Toxins: [
        TestCode.MERCURY
    ]
};
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { OmegaCompleteApproval } from "./omegaCompleteApproval.component"
import { OmegaCompleteIntro } from "./omegaCompleteIntro.component"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { listProductTypes } from '../../services/productType.service'
import { listMembershipTypes } from '../../services/membershipType.service'
import { completeFlow } from "../../services/flow.service"
import ProductHelper from "../../helpers/product.helper"

export const Step = {
  INTRO: 'intro',
  SELECT: 'select',
  PAY_BASIC: 'pay-basic',
  PAY_COMPLETE: 'pay-complete',
  ACCOUNT: 'account',
  NAME: 'name',
  DOB: 'dob',
  GENDER: 'gender',
  PHONE: 'phone',
  DELIVERY: 'delivery',
  CONFIRM: 'confirm',
}

export const OmegaCompleteFlow = () => {
  const { longevityMembership } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [productTypes, setProductTypes] = useState()
  const [hasPass, setHasPass] = useState()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  useEffect(() => {
    fetchSkeleton()
  }, [membershipTypes, productTypes, longevityMembership, flow, hasPass])

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipTypes()
  }, [])

  const fetchMembershipTypes = async () => {
    setMembershipTypes(await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.LONGEVITY,
          ]
        }
      }
    }))
  }
  
  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.OMEGAQUANT_COMPLETE,
            ProductTypeCode.OMEGAQUANT_BASIC
          ]
        }
      }
    }))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }
  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.SELECT,
        content: ({ footer }) => <OmegaCompleteIntro footer={footer} />
      },  
      [Step.SELECT]: {
        type: StepType.PRODUCT_SELECT,
        buildUser: true,
        onNextStep: (patient) => {
          switch (patient?.omegaQuantTestPreference) {
            case ProductTypeCode.OMEGAQUANT_BASIC:
              return Step.PAY_BASIC
            case ProductTypeCode.OMEGAQUANT_COMPLETE:
              return Step.PAY_COMPLETE
            default:
              return Step.PAY_BASIC
          }
        },
        title: `Which Omega-3 Index test would you like?`,
        description: `Tests are run by OmegaQuant, a CLIA-certified lab. The lower prices listed are for Instalab members.`,
        field: {
          name: 'omegaQuantTestPreference',
          required: true,
          options: [{
            title: `Basic Test`,
            value: ProductTypeCode.OMEGAQUANT_BASIC,
            description: <>Recommended if you just want to know your omega-3 index or this is a follow-up test to monitor your status. <a className="link" target="_blank" href="https://omegaquant.com/wp-content/uploads/2020/12/OmegaQuant_O3basic_v15Dec2020.pdf">View sample report</a>.</>,
            price:  <><span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.OMEGAQUANT_BASIC), false)}</span> <span className="original-price">${getProductType(ProductTypeCode.OMEGAQUANT_BASIC)?.cost}</span></>,
          }, 
          {
            title: `Complete Test`,
            value: ProductTypeCode.OMEGAQUANT_COMPLETE,
            description: <>This includes levels of all 24 fatty acids as well as the Omega-3 index, Ratios, and the Trans Fat Index. <a className="link" target="_blank" href="https://omegaquant.com/wp-content/uploads/2020/12/OmegaQuant_O3complete_v15Dec2020.pdf">View sample report</a>.</>,
            price: <><span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.OMEGAQUANT_COMPLETE), false)}</span> <span className="original-price">${getProductType(ProductTypeCode.OMEGAQUANT_COMPLETE)?.cost}</span></>,
          }],
        },
      },
      [Step.PAY_BASIC]: {
        type: StepType.PAY,
        nextStep: Step.ACCOUNT,
        addProduct: true,
        addLongevityMembership: true,
        productType: ProductTypeCode.OMEGAQUANT_BASIC,
        title: (flow?.addLongevityMembership || longevityMembership) ?
        <>Pay <span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.OMEGAQUANT_BASIC))}</span> <span className="original-price">${getProductType(ProductTypeCode.OMEGAQUANT_BASIC)?.cost}</span> for the Omega-3 Index Basic test{longevityMembership ? '.' : <> and <span className="true-price">${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span> for your Instalab membership.</>}</> :
        <>Pay <span className="true-price">${getProductType(ProductTypeCode.OMEGAQUANT_BASIC)?.cost}</span> for the Omega-3 Index Basic test.</>,
        description: `This includes a Omega-3 Index Basic test kit, results and reports`,
      },
      [Step.PAY_COMPLETE]: {
        type: StepType.PAY,
        nextStep: Step.ACCOUNT,
        addProduct: true,
        addLongevityMembership: true,
        productType: ProductTypeCode.OMEGAQUANT_COMPLETE,
        title: (flow?.addLongevityMembership || longevityMembership) ?
        <>Pay <span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.OMEGAQUANT_COMPLETE))}</span> <span className="original-price">${getProductType(ProductTypeCode.OMEGAQUANT_COMPLETE)?.cost}</span> for the Omega-3 Index Complete test{longevityMembership ? '.' : <> and <span className="true-price">${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span> for your Instalab membership.</>}</> :
        <>Pay <span className="true-price">${getProductType(ProductTypeCode.OMEGAQUANT_COMPLETE)?.cost}</span> for the Omega-3 Index Complete test.</>,
        description: `This includes a Omega-3 Index Complete test kit, results and reports`,
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.NAME,
        buildUser: true,
        title: `Create your Instalab acccount.`,
        description: `Create your account to manage your orders and results.`,
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What is your date of birth?`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.dob
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What was your sex assigned at birth?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUserRef?.current?.gender
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.DELIVERY,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your account or results`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.phone
        }
      },
      [Step.DELIVERY]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.CONFIRM,
        title: `What's your delivery address?`,
        description: `This is where your Omega-3 Index test kit will be sent.`,
        field: {
          name: 'shippingLocation'
        },
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `Confirmed!`,
        description: `We'll send you the Omega-3 Index test kit soon. Here's the summary of the rest of the process.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <OmegaCompleteApproval/>,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  return <>
    {skeleton && (
      <Flow 
        skeleton={skeleton} 
        flow={flow} 
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
      />
    )}
  </>
}
import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const listContents = async () => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/contents`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}

export const getContent = async (id) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/contents/${id}`, {
    params: ServiceHelper.getParams({ idType: '_id' }),
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const addContent = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/contents`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const publishContent = async (id) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/contents/${id}/publish`, ServiceHelper.getParams(), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const updateContent = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/contents/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const removeContent = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/contents/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}
import { useEffect, useState, useRef } from "react";
import { Tooltip } from "antd";
import { ProductDetail } from '../productDetail/productDetail.component';
import { ReactComponent as RxSVG} from '../../assets/svg/prescription-1.svg'; 
import { ReactComponent as CardSVG} from '../../assets/svg/card.svg'; 
import { ReactComponent as BloodSVG} from '../../assets/svg/blood.svg'; 
import ProductDetailSection from "../../enums/productDetailSection.enum";
import MembershipHelper from "../../helpers/membership.helper.js"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum.js";
import { ReactComponent as DoctorSVG } from '../../assets/svg/doctor-2.svg'
import { SyncOutlined } from "@ant-design/icons"
import DISCOUNT from "../../enums/discount.enum.js";
import './heartHealth.scss';
import TestCode from "../../enums/testCode.enum.js";
import { listTests } from "../../services/test.service.js";
import { cholesterolTherapyPanel } from "../../data/cholesterolTherapyPanel.data.js";
import { TassoModal } from "../tassoModal/tassoModal.component.js";
import { useNavigate } from "react-router-dom";
import FlowType from "../../enums/flowType.enum.js";

const converter = require("number-to-words");

export const HeartHealth = () => {
  const navigate = useNavigate()
  const panelRef = useRef(null);
  const [cost, setCost] = useState(null)
  const [sections, setSections] = useState(null)
  const [openTasso, setOpenTasso] = useState()
  const biomarkerCount = Object.values(cholesterolTherapyPanel).reduce((acc, biomarkers) => {
    return acc += biomarkers.length
  }, 0)
  const [smallPanel, setSmallPanel] = useState([])

  useEffect(() => {
    fetchCost(); 
    fetchSmallPanel()
  }, []);

  useEffect(() => {
    fetchSections()
  }, [cost, smallPanel])

  const onStart = () => {
    navigate(`/flow/${FlowType.HEART_HEALTH_INTAKE}`) 
  }

  const fetchSmallPanel = async () => {
    const testCodes = Object.values(cholesterolTherapyPanel).reduce((acc, codes) => {
      return [
        ...acc,
        ...codes.map(Number)
      ]
    }, [TestCode.BP_S])

    const tests = await listTests({
      select: 'name tagline code',
      filter: {
        code: {
          $in: testCodes
        }
      }
    })
    const TestName = {
      [TestCode.BP_S]: "Blood Pressure",
      [TestCode.DIRECT_LDL]: "LDL Cholesterol"
    }
    setSmallPanel(tests.map(test => {
      return {
        name: TestName[test.code] || test.name,
        description: test.tagline,
      }
    }))
  }

  const fetchCost = async () => {
    setCost(await MembershipHelper.getCost(MembershipTypeCode.HEART_HEALTH));
  };

  const fetchSections = () => {
    if (!cost) return

    const faqs = [{
      question: `Do I qualify for this treatment program?`,
      answer: <>If you are here, most likely yes. It takes just 2 minutes or less to find out.</>
    },  
    {
      question: 'Which medications do you prescribe?',
      answer: <>The heart health program prescribes medication to lower high cholesterol, insulin resistance, and high blood pressure.<br/><br/>This class of medications includes metformin, statins (e.g. rosuvastatin), cholesterol absorption inhibitors (e.g. ezetimibe), PCSK9 inhibitors (e.g. alirocumab), inclisiran and more.<br/><br/>We recommend which one is best for you based on your medical history and preferences.</>
    },
    {
      question: <>What's included in the monthly price?</>,
      answer: <>The program covers all generic medications, testing and guidance from our medical team. If you choose to take a brand medication (i.e. in most cases it'll be because it's a newer drug and a generic is not available yet), we'll work with you to find the cheapest option available.</>
    },
    {
      question: `How is this different from my primary care physician?`,
      answer: <>Traditional medicine often relies on a generalized approach, frequently prescribing the exact same solution for everyone without thoroughly testing its efficacy. In contrast, our method is personalized to each individual. We prioritize frequent evaluations of your treatment's performance, ensuring you receive the most effective care and avoiding the pitfalls of one-size-fits-all solutions.</>
    }, 
    {
      question: `Will my health insurance cover this program?`,
      answer: <>Currently, the program is not covered by health insurance. However, participants can utilize their HSA/FSA cards. We can provide itemized receipt for reimbursement purposes.<br/><br/>If your preferred medication is not generic, we can help you explore options to cover it through insurance.</>
    }, 
    {
      question: `How long does it take to find a patient's optimal prescription?`,
      answer: <>Achieving the ideal prescription typically occurs within 3 to 6 months, though this can vary based on testing frequency and any side effects encountered. Our goal is to find the best treatment plan for you as efficiently as possible.</>
    }, 
    {
      question: `If I already take medication, should I still sign up for this program?`,
      answer: <>Absolutely. Whether you're looking to optimize or manage your medication, our medical team is ready to support you and address any concerns.</>
    }, 
    {
      question: `How often should I get my blood work done?`,
      answer: <>We recommend getting your blood work done every 6 weeks. This frequent testing allows us to monitor the efficacy of your current medication closely and make quick adjustments to your prescription based results.</>
    }, 
    {
      question: `Are the at-home blood tests actually painless?`,
      answer: <>Yes, our blood collection method avoids the discomfort associated with finger-pricks. Instead of puncturing the fingertip, which is rich in nerve endings, we use a Tasso device that employs a vacuum to draw blood gently from the upper arm, an area that experiences much less sensitivity, significantly reducing pain and making the process virtually painless for almost all individuals.</>
    }, 
    {
      question: `Is this program available in my state?`,
      answer: <>Yes, this program is availabe everywhere in the United States.</>
    },]

    setSections([
      { type: ProductDetailSection.HERO,
        data: {
          title: "Proven medication to improve your heart health",
          memberPrice: `${cost * DISCOUNT} / month`,
          originalPrice: `${cost} / month`,
          oneliner: "A treatment program that tailors medication for you through at-home testing and physician support. Prevent major risks to your longevity like heart attack, stroke, diabetes, and more.",
          points: [
              <><RxSVG className="point-icon"/><span><Tooltip title="Medications prescribed for heart health include metformin, statins, cholesterol absorption inhibitors, PCSK9 inhibitors, inclisiran and much more. We recommend which ones are best for you based on your medical history and preferences. All generics come with your heart health membership, and if you choose to take any brand medications, we'll help you find best pricing and get insurance coverage where possible."><span style={{borderBottom: "1px dotted gray"}}>Custom medication</span></Tooltip> prescribed and delivered to you</span></>,
              <><BloodSVG className="point-icon"/><span><Tooltip title="Membership includes frequent testing to ensure efficacy of any treatments and guide adjustments as needed."><span style={{borderBottom: "1px dotted gray"}}>Regular testing</span></Tooltip> to ensure efficacy and adjust as needed</span></>,
              <><DoctorSVG className="point-icon"/><span>Unlimited messaging with <Tooltip title="Gain constant access to our physician team for any questions, concerns, or advice through our unlimited messaging service, ensuring continuous support for your heart health journey."><span style={{borderBottom: "1px dotted gray"}}>medical team</span></Tooltip></span></>,
              <><CardSVG className="point-icon" /><span>HSA / FSA cards accepted</span></>,
          ],
          cta: {
            text: "Get Started Now",
            onClick: onStart
          },
          photos: [
            {
                original: "/img/treatment-0.png", 
                thumbnail: "/img/treatment-0.png"
            },
            {
                original: "/img/treatment-2.png", 
                thumbnail: "/img/treatment-2.png"
            },
            {
                original: "/img/treatment-3.png",
                thumbnail:  "/img/treatment-3.png"
            },
            {
              original: "/img/treatment-1.png",
              thumbnail:  "/img/treatment-1.png"
          },        
          ],
          faqs: faqs.slice(0,3)
        }
      },
      { type: ProductDetailSection.TESTIMONIALS,
        title: "Hear from real patients",
        data: [
          {
            name: "Jason G.",
            title: "Film Director",
            image: "/img/jason-goldman.jpg",
            text: "Thanks to this program, my doctor friends were amazed by my improved cholesterol levels. The straightforward steps made it easy to follow, and I feel healthier and more energetic. Knowing that I've also lowered my risk of heart attack gives me great peace of mind."
          },
          {
            name: "Ben B.",
            title: "Marketing Director",
            image: "/img/ben.png",
            text: "Joining this program was the best decision I've made for my health. The personalized approach and easy-to-follow guidelines helped me achieve my goals faster than I expected. The support from the team made the whole process enjoyable and stress-free."
          },
          {
            name: "Aaron C.",
            title: "Founder",
            image: "/img/aaron-cheung.jpg",
            text: "I was skeptical at first, but the results speak for themselves. Now, my cholesterol and blood pressure are under control, and I couldn't be happier. Thanks to Instalab, I've seen significant improvements in my health that I didn't think were possible without a lot of effort."
          },

          {
            name: "Chloe S.",
            title: "Financial Analyst",
            image: "/img/chloe.png",
            text: "I was struggling with insulin resistance for years and tried various methods with little success. This program finally gave me the breakthrough I needed. Now, my metabolic biomarkers are in a healthy range, and I feel more confident about my long-term health."
          },      ],
      },
      { type: ProductDetailSection.XPANEL,
        title: "Improve your heart health the right way",
        description: "Before Instalab, many experienced the same story of their doctors not being open to exploring different medications to figure out what's best for them. Let's end that now.",
        data: [
          {
            image: "/img/treatment-panel-1.png",
            title: "Wide medication selection",
            text: "You shouldn't have to argue with or be afraid to ask your own doctor to access effective medications like ezetimibe to statins to PCSK9 inhibitors to inclisran."
          },
          {
            image: "/img/treatment-panel-2.png",
            title: "An iterative approach",
            text: "Monitor the efficacy of your medication by testing often using an at-home tests. Adjustments to prescription are guided by your test outcomes and feedback."
          },
          {
            image: "/img/treatment-panel-3.png",
            title: "Personalized guidance",
            text: "Engage directly with our medical team, benefiting from their experience in helping patients lower high cholesterol, insulin resistance, and high blood pressure."
          }
        ]
      },
      { type: ProductDetailSection.TIMELINE,
        title: "How it works",
        description: "Instalab makes it super easy to get and adjust your medication. It just takes 2 minutes to get started.",
        data: [
          {
            title: <div className="timeline-header">Getting Started:</div>,
            header: true,
            dot: <div style={{paddingTop:12}}><svg xmlns="http://www.w3.org/2000/svg" fill="none" width="24" height="24" viewBox="0 0 24 24" role="presentation" class="IconSvgBase__SvgStyled-sc-1jwsfy0-0 efedNf"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.25 1.86261L12.2954 2.31066L19.2954 5.31066L20.6798 5.90395L19.3721 6.6512L12.75 10.4353V16H11.25V3.00001V1.86261ZM12.75 8.70763L17.3202 6.09608L12.75 4.13742V8.70763ZM5.5 10.75C4.5335 10.75 3.75 11.5335 3.75 12.5C3.75 13.4665 4.5335 14.25 5.5 14.25H9.52221V15.75H5.5C3.70508 15.75 2.25 14.2949 2.25 12.5C2.25 10.7051 3.70508 9.25001 5.5 9.25001H8.49999V10.75H5.5ZM17.46 14.25H14.4778V15.75H17.46C18.4599 15.75 19.25 16.5447 19.25 17.5C19.25 18.4553 18.4599 19.25 17.46 19.25H14.9199V20.75H17.46C19.2657 20.75 20.75 19.3061 20.75 17.5C20.75 15.6939 19.2657 14.25 17.46 14.25Z" fill="var(--color-text-primary, #1a1a1a)"></path></svg></div>,
          },
          {
              title: "Introduction call",
              description: <><a onClick={onStart}>Schedule a quick 15-minute call</a> with an Instalab team member to evaluate if the heart health program is right for you.</>,
          },
          {
            title: "Measure your baseline",
            description: <>Receive a blood pressure monitor and a blood test kit to evaluate how we can improve your heart health. Your test kit uses an innovate technology (<a onClick={() => setOpenTasso(true)}>Tasso</a>) to painlessly draw your blood.</>
          },
          {
              title: "Initial Rx written",
              description: "Based on your medical history, we'll instantly recommend the medication you should start with and write the prescription for it.",
          },
          {
            title: <div className="timeline-header">Treatment Begins:</div>,
            header: true,
            dot: <SyncOutlined/>,
            color: "black"
          },
          {
              title: "Get medication delivered",
              description: "Our partner pharmacy will ship your medication and you'll receive it within 1 week. You'll get a 90-day supply to start.",
          },

          {
            title: "Test every 6 weeks to fine-tune",
            description: <>We monitor the efficacy of your medication by frequently testing for blood pressure and <a onClick={() => panelRef.current?.scrollIntoView({ behavior: 'smooth' })}>{converter.toWords(biomarkerCount+1)} key biomarkers</a>, using a complimentary at-home blood test kit (or lab visit if you prefer). Adjustments to your prescription are guided by your test results and personal feedback.</>,
          },
          {
            title: "Ongoing care and support",
            description: "You'll have everything you need during treatment, including on-demand provider access via video or messaging, medication management, and ongoing refill support."
          }
      ],
      },
      {
        type: ProductDetailSection.SMALLPANEL,
        title: "Tests Included",
        data: {
          panel: smallPanel,
          panelRef
        }
      },
      {
        type: ProductDetailSection.FAQ,
        data: faqs.slice(3)
      }
    ])
  }
  
  return sections && (
    <div className="heart-health">
      <TassoModal
        open={openTasso}
        setOpen={setOpenTasso}
      />

      <ProductDetail
        sections={sections}
      />
    </div>
  )
}
import React, { useState, useEffect } from 'react';
import { Form, Button, Upload as AntUpload, message, Dropdown, Table, Spin } from 'antd';
import { UploadOutlined, DeleteOutlined, EditOutlined, SettingOutlined } from '@ant-design/icons';
import { FlowStepFooter } from '../../flowStep/flowStepFooter.component';
import { uploadFile, listFilesByPatient, deleteFile, renameFile, getSignedUrl } from '../../../../services/gdrive.service';
import './upload.scss';

const { Column } = Table;
const { Item } = Form;

export const Upload = ({
  onSubmit,
  form,
  isSubmitting,
  flow,
}) => {
  const [fileList, setFileList] = useState([]);
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    fetchFiles();
  }, [flow]);

  const fetchFiles = async () => {
    if (!flow?.user) return
    setIsLoading(true);
    try {
      const fetchedFiles = await listFilesByPatient(flow.user._id);
      setFiles(fetchedFiles);
    } catch (error) {
      console.error('Error fetching files:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpload = async ({ file }) => {
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('userId', flow.user._id);

      await uploadFile(formData);
      message.success('File uploaded successfully');
      fetchFiles();
    } catch (error) {
      console.error('Upload failed:', error);
      message.error('Upload failed');
    } finally {
      setIsUploading(false);
    }
  };

  const handleBeforeUpload = (file) => {
    handleUpload({ file });
    return false; // Prevent automatic upload
  };

  const handleDeleteFile = async (id) => {
    try {
      await deleteFile(id);
      message.success('File deleted successfully');
      fetchFiles();
    } catch (error) {
      console.error('Failed to delete file:', error);
      message.error('Failed to delete file');
    }
  };

  const handleRenameFile = async (file) => {
    const newName = prompt('Enter the new name:', file.name);
    if (newName && newName.trim() !== '') {
      try {
        await renameFile(file.id, newName);
        message.info('File renamed successfully');
        fetchFiles();
      } catch (error) {
        console.error('Failed to rename file:', error);
        message.error('Failed to rename file');
      }
    }
  };

  const viewFile = async (fileId) => {
    try {
      const signedUrl = await getSignedUrl(fileId);
      window.open(signedUrl, '_blank');
    } catch (error) {
      console.error("Error viewing file:", error);
      alert("Failed to view file");
    }
  };

  const FileListItem = ({ file }) => (
    <div>
      <img src={file.iconLink} style={{ width: 12 }} />
      <a className="link" onClick={() => viewFile(file.id)} target="_blank" rel="noopener noreferrer">{file.name}</a>
    </div>
  );

  const items = (file) => [
    {
      key: 'rename',
      label: (
        <a onClick={() => handleRenameFile(file)}>
          <EditOutlined /> Rename File
        </a>
      ),
    },
    {
      key: 'delete',
      label: (
        <a onClick={() => handleDeleteFile(file.id)} className="error">
          <DeleteOutlined /> Delete
        </a>
      ),
    },
  ];

  return (
    <div className="upload">
      <Form
        form={form}
        className="upload-form"
        layout="vertical"
        onFinish={() => form.submit()}
      >
        <Item>
          <AntUpload
            beforeUpload={handleBeforeUpload}
            fileList={fileList}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />} disabled={isUploading}>
              {isUploading ? <Spin /> : 'Select File'}
            </Button>
          </AntUpload>
        </Item>

        {files.length > 0 && (
          <Table
            loading={isLoading}
            size="small"
            className="primary-table"
            pagination={false}
            rowKey="id"
            dataSource={files}
          >
            <Column
              title="Filename"
              key="action"
              render={(text, file) => <FileListItem file={file} />}
            />
            <Column
              dataIndex='remove'
              width={50}
              className="action-column"
              render={(_, file) => (
                <Dropdown 
                  menu={{ items: items(file) }}
                  placement='bottomRight'
                >
                  <Button>
                    <SettingOutlined />
                  </Button>
                </Dropdown>
              )}
            />
          </Table>
        )}

        <FlowStepFooter 
          onSubmit={() => onSubmit()}
          isSubmitting={isSubmitting}
        />
      </Form>
    </div>
  );
};

const ReferralType = Object.freeze({
    LIPIDOLOGIST: 'lipidologist',
    NUTRIONIST: 'nutrionist',
    PERFORMANCE_COACH: 'performance coach',
    THERAPIST: 'therapist',
    CARDIOLOGIST: 'cardiologist',
    GASTROENTEROLOGIST: 'gastroenterologist',
    ENDOCRINOLOGIST: 'endocrinologist',
    DERMATOLOGIST: 'dermatologist',
    ONCOLOGIST: 'oncologist',
    PSYCHIATRIST: 'psychiatrist',
    UROLOGIST: 'urologist',
    NEPHROLOGIST: 'nephrologist',
    ALLERGIST: 'allergist',
    HEPATOLOGIST: 'hepatologist',
    HEMATOLOGIST: 'hematologist'
})

export default ReferralType
import LatestPosts from "../latestPosts/latestPosts.component.js"
import { Typography, Row, Col }  from 'antd'
import Breakpoint from '../../enums/breakpoint.enum.js'
import useWidth from "../../hooks/useWidth.hook.js"

const { Text, Paragraph } = Typography

export const ConfirmDexa = () => {
  const width = useWidth()

  const instructions = [
    {
      sections: [{
        title: 'Schedule Your Scan',
        description: <>Instalab's concierge team will reach out to you to schedule your appointment with a nearby imaging center.</>
      }, 
    ]
    }, 
    {
      sections: [{
        title: 'Get Scan Done',
        description: <>Wear comfortable clothing. The scan should take about 10 minutes.</>
      }]
    },
    {
      sections: [{
        title: 'Review Test Results',
        description: <>You can expect to receive your results within 24 hours. Instalab physicians are available to review your results with you and provide recommendations.</>
      }]
    }]
  return (
    <div className="prescription-approval">


      {instructions?.length > 0 && (
        <div className="instruction-list">
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row>
                <Col
                  style={{
                    width: width > Breakpoint.SM ? 80 : '100%',
                    marginBottom: width > Breakpoint.SM ? 0 : 10
                  }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  style={{
                    width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                  }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>
      )}

      {/* <Button className="main-cta-btn" onClick={() => window.open('https://app.acuityscheduling.com/schedule.php?owner=21646125&appointmentType=category:DEXA%20%26%20VO2%20Max', '_blank')}>Schedule Now</Button> */}

      {/* <Button
        className="question-btn"
        onClick={() => window.FrontChat("show")}
      >
        I have a question?
      </Button> */}

      <LatestPosts />
    </div>
  )
}
import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  message,
  Radio,
  Space,
  Form,
  Typography,
  Select,
  Skeleton,
} from "antd";
import { addResult, getResult } from "../../services/result.service";
import MaskedInput from "antd-mask-input";
import "./testResultsModal.scss";
import moment from "moment";
import { ContinueButton } from "../continueButton/continueButton.component";
import { updateResult } from "../../services/result.service";
import { listTests } from "../../services/test.service";
import { listPatients } from "../../services/patient.service";
import { listLabs } from "../../services/lab.service";
import { listAbsoluteCategories } from "../../services/absoluteCategory.service";
import { Plan, Panel } from "../../enums/index.enum";
import Org from "../../enums/org.enum";

const { Text } = Typography;
const { Item } = Form;

export const TestResultsModal = ({
  open,
  setOpen,
  resultId,
  setResultId,
  onSuccess,
  select,
  populate,
}) => {
  const [form] = Form.useForm();
  const [result, setResult] = useState();
  const [tests, setTests] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [absoluteCategories, setAbsoluteCategories] = useState();
  const [patients, setPatients] = useState();
  const [labs, setLabs] = useState();

  useEffect(() => {
    if (resultId) {
      fetchResult();
    }
  }, [resultId]);

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setResultId(null);
      setResult(null);
    }
  }, [open]);

  useEffect(() => {
    fetchAbsoluteCategories();
    fetchTests();
    fetchPatients();
    fetchLabs();
  }, []);

  useEffect(() => {
    if (result) {
      fetchFormValues();
    }
  }, [result]);

  useEffect(() => {
    const keyDownHandler = event => {
      if (open && event.key === 'Enter') {
        event.preventDefault();
        onOk()
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };  
  }, [open]);

  const fetchAbsoluteCategories = async () => {
    setAbsoluteCategories(await listAbsoluteCategories());
  };
  

  const fetchFormValues = () => {
    if (!resultId) return;

    let values = {
      patient: result.patient,
      collectedAt: moment(result.collectedAt).format("MM/DD/YYYY HH:mm Z"),
      hasRedraw: result.hasRedraw,
      panel: !result?.order || !result?.order.panel ? null : result?.order.panel,
      plan: !result?.order || !result?.order.plan ? null : result?.order.plan,
      reqId: !result?.order || !result?.order.reqId ? null : result?.order.reqId,
      reqLink: !result?.order || !result?.order.reqLink ? null : result?.order.reqLink,
      lab: !result?.order || !result?.order.lab ? null : result?.order.lab,
      fasting: result.fasting,
    };


    for (const { value, test } of result.values) {
      values[test] = value;
    }
    form.setFieldsValue(values);
  };

  const fetchTests = async () => {
    setTests(await listTests());
  };

  const fetchPatients = async () => {
    try {
      const patients = await listPatients({
        filter: {
          org: Org.INSTALAB,
          firstName: {
            $ne: null
          },
          email: {
            $ne: null
          }
        },
        select: '_id firstName lastName',
        populate: []
      });
      setPatients(sortPatients(patients));
    } catch (error) {
      console.error('Failed to fetch patients:', error);
    }
  }

  const sortPatients = (unsortedPatients) => {
    return unsortedPatients.sort((a, b) => {
      const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
      const nameb = `${b.firstName} ${b.lastName}`.toLowerCase();
  
      if (nameA < nameb) {
          return -1;
      }
      if (nameA > nameb) {
          return 1;
      }
      return 0;
    })
  }

  const fetchLabs = async () => {
    setLabs(await listLabs());
  };

  const onCancel = () => {
    setOpen(false);
  };

  const fetchResult = async () => {
    setIsLoading(true);
    setResult(await getResult(resultId, {
      select: 'patient collectedAt hasRedraw fasting values',
      populate: [{
        path: 'order',
        select: 'panel plan reqId reeqLink lab',
      }]
    }));
    setIsLoading(false);
  };


  const onOk = async () => {
    setIsSubmitting(true);
    try {
      const formValues = form.getFieldsValue();
      const { patient, collectedAt, hasRedraw, panel, plan, reqId, reqLink, lab, fasting } = formValues;
      const blacklist = ["patient", "collectedAt", "hasRedraw", "panel", "plan", "lab", "reqId", "reqLink", "fasting"];
      let values = resultId ? [...result.values] : [];
      for (const testId of Object.keys(formValues).filter(
        (key) => !blacklist.includes(key)
      )) {
        if (formValues[testId] !== undefined) {
          if (values.some((value) => value.test === testId)) {
            values = values.map((value) => {
              return value.test === testId
                ? { test: testId, value: formValues[testId] }
                : value;
            });
          } else {
            values.push({
              test: testId,
              value: formValues[testId],
            });
          }
        }
      }

      const params = {
        fields: {
          values,
          patient,
          collectedAt: moment(collectedAt, "MM/DD/YYYY HH:mm A Z").toDate(),
          hasRedraw,
          panel, 
          plan,
          lab,
          reqId,
          reqLink,
          fasting,
        },
        select,
        populate
      };


      const response = resultId
        ? await updateResult(resultId, params)
        : await addResult(params);
      onSuccess(response)
      setOpen(false);
      form.resetFields();
      message.info("Result saved");
    } catch (err) {
      message.error("Failed to save result");
    }
    setIsSubmitting(false);
  };

  return tests && absoluteCategories && (
    <Modal 
      open={open} 
      title='Test Results'
      onCancel={onCancel}
      okText='Save'
      width={700}
      className="test-results-modal"
      footer={[
        <ContinueButton
          onClick={onOk}
          text='Save'
          isSubmitting={isSubmitting}
        />
      ]}
    >
      {isLoading ? (
        <Skeleton active />
      ) : (      
        <Form
          form={form}
          onFinish={onOk}
          layout='vertical'
        >
          <div className="category-item">
            <Text className="category-name">
              Result Info
            </Text>
            <div className="test-row">
              <Text className="test-name">Patient</Text>
              <div className="field-container">
                <Item name={'patient'}>
                  <Select 
                    placeholder={'Patient'}
                    filterOption={(input, option) => 
                      option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
                    }
                    options={patients?.map(patient => {
                      return {
                        label: `${patient.firstName} ${patient.lastName}`,
                        value: patient._id
                      }
                    })}
                    className="patient-select"
                    showSearch
                  />
                </Item>
                </div>
                <Text className="test-unit"></Text>
            </div>
            <div className="test-row">
                <Text className="test-name">Panel</Text>
                <div className="field-container">
                  <Item name={"panel"}>
                    <Select
                      placeholder={"Panel"}
                      options={Object.entries(Panel).map(([key, value]) => ({
                        label: key,
                        value: value,
                      }))}
                      className="panel-select"
                      optionFilterProp="children"
                      showSearch
                    />
                  </Item>
                </div>
                <Text className="test-unit"></Text>
            </div>
            <div className="test-row">
                <Text className="test-name">Plan</Text>
                <div className="field-container">
                  <Item name={"plan"}>
                    <Select
                      placeholder={"Plan"}
                      options={Object.entries(Plan).map(([key, value]) => ({
                        label: key,
                        value: value,
                      }))}
                      className="plan-select"
                      optionFilterProp="children"
                      showSearch
                    />
                  </Item>
                </div>
                <Text className="test-unit"></Text>
            </div>
            <div className="test-row">
              <Text className="test-name">Lab</Text>
              <div className="field-container">
                <Item name={'lab'}>
                  <Select 
                    placeholder={'Lab'}
                    options={labs?.map(lab => {
                      return {
                        label: lab.name,
                        value: lab._id
                      }
                    })}
                    className="lab-select"
                    showSearch
                  />
                </Item>
                </div>
                <Text className="test-unit"></Text>
            </div>

            <div className="test-row">
              <Text className="test-name">Req Id</Text>
              <div className="field-container">
                <Item name={"reqId"}>
                  <Input
                    placeholder={"Requisition Id"}
                    className="test-value-input"
                  />
                </Item>
              </div>
              <Text className="test-unit"></Text>
            </div>      


            <div className="test-row">
              <Text className="test-name">Req Link</Text>
              <div className="field-container">
                <Item name={"reqLink"}>
                  <Input
                    placeholder={"Requisition Link"}
                    className="test-value-input"
                  />
                </Item>
              </div>
              <Text className="test-unit"></Text>
            </div>   

            <div className="test-row">
              <Text className="test-name">Collected</Text>
              <div className="field-container">
                <Item name={"collectedAt"}>
                  <MaskedInput
                    placeholder={"MM/DD/YYYY HH:MM Z"}
                    mask="00/00/0000 00:00 -00:00"
                    inputMode="decimal"
                    className="test-value-input"
                  />
                </Item>
              </div>
              <Text className="test-unit"></Text>
            </div>
            <div className="test-row">
              <Text className="test-name">Redraw Required</Text>
              <div className="field-container">
                <Item name="hasRedraw">
                  <Radio.Group>
                    <Space direction="horizontal">
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Space>
                  </Radio.Group>
                </Item>
              </div>
              <Text className="test-unit"></Text>
            </div>

            <div className="test-row">
              <Text className="test-name">Fasting</Text>
              <div className="field-container">
                <Item name="fasting">
                  <Radio.Group>
                    <Space direction="horizontal">
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Space>
                  </Radio.Group>
                </Item>
              </div>
              <Text className="test-unit"></Text>
            </div>

          </div>

          <div className="category-list">
            {absoluteCategories.map((category) => (
              <div key={category._id} className="category-item">
                <Text className="category-name">{category.name}</Text>
                {tests
                  .filter(
                    (test) =>
                      !test.isCalculated &&
                      test.absoluteCategory === category._id
                  )
                  .map((test) => (
                    <div key={`test-${test._id}`} className="test-row">
                      <Text className="test-name">{test.name}</Text>
                      <div className="field-container">
                        <Item name={test._id}>
                          <Input
                            placeholder={test.name}
                            className="test-value-input"
                          />
                        </Item>
                      </div>
                      <Text className="test-unit">{test.unit}</Text>
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </Form>
      )}
    </Modal>
  )
};

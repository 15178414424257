import React, { useState, useContext, useEffect } from 'react';
import { Button, Tooltip, Typography, Dropdown } from 'antd'
import { PageHeader } from '../pageHeader/pageHeader.component';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { Step as LongevityStep } from '../longevityConsultFlow/longevityConsultFlow.component'
import "./consults.scss";
import { CalendarOutlined, VideoCameraOutlined } from '@ant-design/icons'
import { UserContext } from '../../contexts/user.context.js';
import advanced from 'dayjs/plugin/advancedFormat'
import { useNavigate } from "react-router-dom";
import FlowType from '../../enums/flowType.enum.js';
import ProductTypeCode from '../../enums/productTypeCode.enum.js';
import { listConsults } from '../../services/consult.service.js';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import AppointmentStatus from '../../enums/appointmentStatus.enum.js';
import classNames from 'classnames';

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

const { Text } = Typography

export const Consults = () => {
  const navigate = useNavigate()
  const { currentUser, token } = useContext(UserContext)
  const [consults, setConsults] = useState()
  const [filteredCount, setFilteredCount] = useState();
  const [isLoading, setIsLoading] = useState()
  const [consultTypes, setConsultTypes] = useState([])

  useEffect(() => {
    document.title = 'Instalab | Consultations'
  }, [])

  useEffect(() => {
    fetchConsults()
  }, [token])


  const credits = currentUser?.credits?.filter(credit => (credit === ProductTypeCode.CONSULT_LONGEVITY))

  const fetchConsults = async () => {
    setIsLoading(true)
    if (token) {
      const fetchedConsults = await listConsults({
        select: '_id name start status remoteLocation reason',
        filter: {},
        populate: [{
          path: 'provider',
          select: 'firstName lastName photo npi'
        }, {
          path: 'flow',
          select: '_id products type',
          populate: {
            path: 'products',
            select: 'productType',
            populate: {
              path: 'productType',
              select: 'code',
            }
          }
        }],
        sort: '-start'
      })

      setConsultTypes([...new Set(fetchedConsults.map(({ name }) => name))].sort())
      setConsults(fetchedConsults)
      setFilteredCount(fetchedConsults.length)
    } else {
      setConsultTypes([])
      setConsults(null)
      setFilteredCount(0)
    }
    setIsLoading(false)
  }

  

  const getActionItems = (consult) => {
    let menuItems = []

    const isFuture = new Date(consult.start) > new Date()
    
    if (consult.flow && isFuture) {
      const flowStep = {
        [ProductTypeCode.CONSULT_LONGEVITY]: LongevityStep.CONSULT,
        [ProductTypeCode.CONSULT_LONGEVITY_60]: LongevityStep.CONSULT_60,
      }

      if (consult.remoteLocation) {
        menuItems.push( {
          key: 'meeting',
          label: (
            <Button onClick={() => {
              window.open(consult.remoteLocation, '_blank');
            }}>
              <VideoCameraOutlined /> Go To Meeting
            </Button>
          )
  
        })
      }
      menuItems.push(
        {  
        key: 'reschedule',
        label: (
          <Button onClick={() => {
            navigate(`/flow/${consult.flow.type}/${flowStep[consult.flow.products[0].productType.code]}/${consult.flow._id}`)
          }}>
            <CalendarOutlined /> Reschedule
          </Button>
        )
      });
    }
    return menuItems;
  }

  const getCustomFilter = (consult, value) => {
    return {
      provider: () => consult.provider && `${consult.provider.firstName} ${consult.provider.lastName}`.toLowerCase().includes(value.toLowerCase())
    }
  }

  const convertTimeZone = (time) => {
    const currentUserTimeZone = currentUser?.location?.timeZoneId || 'UTC'; // Default to UTC if timezone is not provided
    const formattedDate = dayjs(time).tz(currentUserTimeZone);
    return formattedDate
  }

  return (
    <div className="consults">
      <PageHeader 
        title='Consultations' 
        count={filteredCount}
        actions={(
          // <Dropdown menu={{ items: bookItems }} trigger={['click']}>
          <Tooltip 
            title={credits?.length > 0 ? `${credits.length} ${credits.length === 1 ? 'credit available' : 'credits available'}` : null}
            placement='left'
          >
            <Button  
              type='primary'
              onClick={() => navigate(`/flow/${FlowType.LONGEVITY_CONSULT}`)}
            >
              + Book Consultation

              {credits?.length > 0 && (
                <span className="credit-count">
                  {credits.length}
                </span>
              )}
            </Button>
            </Tooltip>
          // </Dropdown>
        )}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={consults}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={[
        // {
        //   title: 'Status',
        //   dataIndex: 'status',
        //   width: 100,
        //   render: status => <Text className={classNames(`${status}-status`, "consult-status")}>{status}</Text>,
        //   // filterDropdownType: FilterDropdownType.CHECKBOX,
        //   // filterOptions: Object.values(AppointmentStatus)
        // }, 
        // {
        //   title: 'Type',
        //   dataIndex: 'name',
        //   // filterDropdownType: FilterDropdownType.CHECKBOX,
        //   // filterOptions: consultTypes
        // }, 
        {
          title: 'Provider',
          dataIndex: 'provider',
          render: provider => <>{provider.npi && <>Dr. </>}{provider.firstName} {provider.lastName}</>,
          // filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: 'Start',
          dataIndex: 'start',
          render: (start, { provider }) => start && convertTimeZone(start, provider).format('MMM D, YYYY')
        }, {
          dataIndex: 'startTime',
          render: (_, { start, provider }) => start && convertTimeZone(start, provider).format('h:mma z')
        },
        // {
        //   dataIndex:'remoteLocation',
        //   render: remoteLocation => remoteLocation && <Button onClick={()=>remoteLocation} type="primary">Go To Meeting</Button>
        // }
      ]}
      />
    </div>
  )
}
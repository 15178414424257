import { Form, message, Button, Typography } from "antd"
import "./cardForm.scss"
import { DeleteOutlined } from '@ant-design/icons'
import { useState, useEffect } from "react"
import Color from '../../colors.scss'
import { ReactComponent as AmexIcon } from '../../assets/svg/amex.svg'
import { ReactComponent as DinersClubIcon } from '../../assets/svg/diners_club.svg'
import { ReactComponent as DiscoverIcon } from '../../assets/svg/discover.svg'
import { ReactComponent as JCBIcon } from '../../assets/svg/jcb.svg'
import { ReactComponent as MastercardIcon } from '../../assets/svg/mastercard.svg'
import { ReactComponent as VisaIcon } from '../../assets/svg/visa.svg'
import { ReactComponent as UnionPayIcon } from '../../assets/svg/unionpay.svg'
import { ReactComponent as CartesBancairesIcon } from '../../assets/svg/cartes_bancaires.svg'
import { addCard, removeCard } from '../../services/card.service';
import { useContext } from "react"
import { UserContext } from "../../contexts/user.context"
import { listCards } from "../../services/card.service"
import { Elements, ElementsConsumer, CardElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure'
import ReadableCardBrand from "../../enums/readableCardBrand.enum"

const { Item } = Form
const { Text } = Typography

const cardLogos = {
  american_express: <AmexIcon />,
  amex: <AmexIcon />,
  diners_club: <DinersClubIcon />,
  discover: <DiscoverIcon />,
  jcb: <JCBIcon />,
  mastercard: <MastercardIcon />,
  visa: <VisaIcon />,
  unionpay: <UnionPayIcon />,
  cartes_bancaires: <CartesBancairesIcon  />,
}

export const CardForm = (props={}) => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

  return (
    <Elements stripe={stripePromise}>
      <ElementsConsumer>
        {({stripe, elements}) => <>
          <CardFormChild
            stripe={stripe}
            elements={elements}
            {...props}
          />
        </>}
      </ElementsConsumer>
    </Elements>
  )
}

const CardFormChild = ({ stripe, elements, onSuccess, isLoading, setIsLoading }) => {
  const { token } = useContext(UserContext)
  const [activeCard, setActiveCard] = useState()
  const [cards, setCards] = useState([])
  const [form] = Form.useForm()

  useEffect(() => {
    fetchActiveCard()
  }, [cards])

  useEffect(() => {
    fetchCards()
  }, [token])

  const fetchCards = async () => {
    if (token) {
      setCards(await listCards({
        filter: {
          isRemoved: {
            $ne: true
          }
        },
        select: '_id brand last4'
      }))
    } else {
      setCards([])
    }
  }

  const fetchActiveCard = () => {
    if (cards?.length) {
      setActiveCard(cards[0])
    } else {
      setActiveCard(null)
    }
  }

  const onRemoveCard = async (cardId) => {
    try {
      await removeCard(cardId)
      setActiveCard(null)
      if (cards?.length) {
        setCards(cards.filter(({ _id }) => _id !== cardId))
      }
      message.info('Card removed')
    } catch (err) {
      message.error('Failed to remove card')
    }
  }

  const getCardParams = async () => {
    const cardElement = elements.getElement(CardElement)

    if (!cardElement) return {}

    const stripeResponse = await stripe.createSource(cardElement, { type: 'card' })
    const { error, source } = stripeResponse

    if (error) {
      message.error(error.message)
      throw new Error(error.message)
    }

    const {
      exp_month: expMonth,
      exp_year: expYear,
      country,
      last4,
      brand
    } = source.card

    return {
      stripeId: source.id,
      expMonth,
      expYear,
      country,
      last4,
      brand,
    }
  }

  const onConfirm = async () => {
    setIsLoading(true)

    if (cards?.length) {
      console.log('existing cards')
      onSuccess(cards[0])
    } else {
      console.log('add new card')
      const fields = await getCardParams()
      onSuccess(await addCard({
        fields,
        seelct: '_id last4 brand'
      }))
    }
  }

  return (
    <div className="card-form">
      <Form
        form={form}
        className="pay-form"
        layout="vertical"
      >
        <div className="pay-fields">
          {activeCard ? (
            <div className="saved-card-details">
              <span className="saved-card-logo">
                {cardLogos[activeCard.brand?.toLowerCase().replace(/ /g, '_')]}
              </span>

              <div className="saved-card-content">
                <Text className="saved-card-label">
                  Confirming payment will bill
                </Text>
                <Text className="saved-card-description">
                  {ReadableCardBrand[activeCard.brand] || activeCard.brand} ending in {activeCard.last4}
                </Text>
              </div>

              <Button
                className="remove-btn"
                icon={<DeleteOutlined />}
                onClick={() => onRemoveCard(activeCard._id)}
              />
            </div>
          ) : ( 
            <Item
              label='Enter Payment Details'
              className="pay-item"
            >
              <CardElement 
                options={{
                  disableLink: true,
                  style: {
                    base: {
                      iconColor: Color.primary_text,
                      color: Color.primary_text,
                      fontFamily: 'Lato, sans-serif',
                      margin: 30,
                      fontSize: '18px',
                      fontWeight: '400',
                      fontSmoothing: 'antialiased',
                      ':-webkit-autofill': {
                        color: Color.secondary_text,
                      },
                      '::placeholder': {
                        color: Color.secondary_text,
                      },
                    },
                    invalid: {
                      iconColor: Color.error,
                      color: Color.error,
                    },
                  }
                }}
              />
            </Item>
          )}
        </div>

        <Button
          type='primary'
          className='confirm-btn'
          onClick={onConfirm}
          loading={isLoading}
        >
          Confirm Payment
        </Button>
      </Form>
    </div>
  )
}
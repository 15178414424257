import React, { useContext, useEffect, useState } from 'react';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { EditOutlined,DeleteOutlined } from '@ant-design/icons';
import { message, Button, Popconfirm } from 'antd';
import { listReferrals, removeReferral, listReferralsByPatient, removeReferralFromPatient } from "../../services/referral.service.js";
import { PageHeader } from '../pageHeader/pageHeader.component';
import { AdminLocationModal } from '../adminLocationModal/adminLocationModal.component';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import { UserContext } from '../../contexts/user.context.js';
import { Role, ObjectType } from "../../enums/index.enum.js";
import { useParams } from 'react-router-dom'
import { ReferralForm } from './referralForm.component.js';
import { AddReferralForm } from './addReferralForm.component.js';
import './adminReferrals.scss';

const select = '_id firstName lastName location title scheduleLink type author';

export const AdminReferrals = ({ hasPatient=false }) => {
  const { patientId } = useParams()
  const [referrals, setReferrals] = useState([]);
  const [referralId, setReferralId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); 
  const [isAddReferralModalOpen, setIsAddReferralModalOpen] = useState(false); 
  const [isAdminLocationModalOpen, setIsAdminLocationModalOpen] = useState();
  const [filteredCount, setFilteredCount] = useState();
  const { setCounts, currentUser } = useContext(UserContext); // Get the user object from the context

  useEffect(() => {
    fetchReferrals();
  }, [hasPatient, patientId]);

  const fetchReferrals = async () => {
    setIsLoading(true);
    let fetchedReferrals

    if (hasPatient && !patientId) return

    else if (hasPatient && patientId) {
      fetchedReferrals = await listReferralsByPatient(patientId);
    }

    else if (!hasPatient) {
      let params = {
        select,
        filter: {},
        populate: [],
        sort: 'firstName'
      };
  
      fetchedReferrals = await listReferrals(params);
  
    }
    setReferrals(fetchedReferrals);
    setFilteredCount(fetchedReferrals.length);
    setIsLoading(false);
  };

  const stripLocation = location => {
    return location.replace(/[^a-zA-Z0-9]/g, '');
  };

  const handleRemoveReferral = async (id) => {
    try {
      await removeReferral(id);
      setReferrals(referrals.filter(referral => referral._id !== id));
      setFilteredCount(cachedFilterCount => cachedFilterCount - 1);
      setCounts(cachedCounts => ({
        ...cachedCounts,
        Referrals: cachedCounts.Referrals - 1
      }));
      message.success('Referral removed');
    } catch (error) {
      message.error('Failed to remove referral');
    }
  };

  const handleRemoveReferralFromPatient = async (referralId) => {
    try {
      const updatedPatient = await removeReferralFromPatient({ patientId, referralId });
      setReferrals(updatedPatient.referrals);
      message.success('Referral removed from patient');
    } catch (error) {
      message.error('Failed to remove referral from patient');
    }
  };

  let getActionItems;
  if (hasPatient && patientId) {

    getActionItems = ({ _id }) => [
      {
        key: 'remove-from-patient',
        label: (
          <Popconfirm
            title="Are you sure to remove this referral from the patient?"
            onConfirm={() => handleRemoveReferralFromPatient(_id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>
              <DeleteOutlined /> Remove
            </Button>
          </Popconfirm>
        )
      }
    ];

  }

  else if (!hasPatient) {
    getActionItems = ({ _id }) => [
      {
        key: '1',
        label: (
          <Button onClick={() => {
            setReferralId(_id);
            setIsEditModalOpen(true);
          }}>
            <EditOutlined /> Edit
          </Button>
        )
      },
      // {
      //   key: '2',
      //   label: (
      //     <Button onClick={() => {
      //       setReferralId(_id);
      //       setIsAdminLocationModalOpen(true);
      //     }}>
      //       <EnvironmentOutlined /> Set Location
      //     </Button>
      //   )
      // },
      {
        key: '3',
        label: (
          <Popconfirm
            title="Are you sure to delete this referral?"
            onConfirm={() => handleRemoveReferral(_id)}
            okText="Yes"
            cancelText="No"
          >
            <Button danger>
              <DeleteOutlined /> Delete
            </Button>
          </Popconfirm>
        )
      }
    ];

  }


  const getCustomFilter = (Referral, value) => {
    const searchValue = value.replace(/\s/g, '').toLowerCase();
    const firstName = Referral.firstName ? Referral.firstName.toString().toLowerCase() : '';
    const lastName = Referral.lastName ? Referral.lastName.toString().toLowerCase() : '';
    const fullName = (firstName + lastName).replace(/\s/g, '');
    
    return {
      name: () => fullName.includes(searchValue),
    };
  };

  const formatLocation = location => {
    return `${location.streetAddress}${location.streetAddress2 ? ' ' + location.streetAddress2 : ''}, ${location.city}, ${location.state} ${location.postalCode}`;
  };

  // Determine columns to display based on user role
  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      filterDropdownType: FilterDropdownType.INPUT,
      render: (firstName, { title, lastName }) => (
        <>{title && <>{title} </>}{firstName} {lastName}</>
      )
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Schedule Link',
      dataIndex: 'scheduleLink',
      render: scheduleLink => scheduleLink && <a href={scheduleLink} target="_blank" rel="noopener noreferrer">Schedule</a>
    }
  ];

  if (currentUser?.role === Role.ADMIN) {
    columns.push({
      title: "Referred By",
      dataIndex: 'author',
      render: author => author ? <>{author.firstName} {author.lastName}</> : 'N/A'
    });
  }

  return (
    <div className="admin-referrals">
      <PageHeader
        title='Referrals'
        count={filteredCount}
        actions={(
          hasPatient && patientId ? (
            <Button
              type='primary'
              onClick={() => {
                setIsAddReferralModalOpen(true); // Open the new Add Referral modal
              }}
            >
              + Add Referral
            </Button>
          ) : (
            <Button
              type='primary'
              onClick={() => {
                setReferralId(null);
                setIsEditModalOpen(true);
              }}
            >
              + Add New Referral
            </Button>
          )
        )}
      />

      <AdminLocationModal
        objectId={referralId}
        objectType={ObjectType.Referral}
        open={isAdminLocationModalOpen}
        setOpen={setIsAdminLocationModalOpen}
        select={select}
        onSuccess={referral => {
          setReferrals(referrals.map(p => p._id === referralId ? referral : p));
        }}
      />


      <AddReferralForm
        open={isAddReferralModalOpen}
        setOpen={setIsAddReferralModalOpen}
        patientId={patientId}
        onSuccess={referral => {
          setReferrals([referral, ...referrals]);
          setFilteredCount(cachedFilterCount => cachedFilterCount + 1);
          setCounts(cachedCounts => ({
            ...cachedCounts,
            Referrals: cachedCounts.Referrals + 1
          }));
        }}
      />

      <ReferralForm
        referralId={referralId}
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        onSuccess={referral => {
          if (referralId) {
            setReferrals(referrals.map(p => p._id === referralId ? referral : p));
          } else {
            setReferrals([referral, ...referrals]);
            setFilteredCount(cachedFilterCount => cachedFilterCount + 1);
            setCounts(cachedCounts => ({
              ...cachedCounts,
              Referrals: cachedCounts.Referrals + 1
            }));
          }
        }}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={referrals}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={columns}
      />
    </div>
  );
};

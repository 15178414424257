import React, { useState, useEffect, useContext } from "react";
import { Table, Tooltip, Spin, Typography } from "antd";
import ContentHelper from "../../helpers/content.helper";
import moment from 'moment'
import { UserContext } from "../../contexts/user.context.js";
import classNames from "classnames";
import { Condition, Panel, TestCode, ReadableRiskLevel, RiskLevel, AbsoluteCategoryCode, Role } from "../../enums/index.enum";
import "./resultsTable.scss";
import { SyncOutlined, CheckCircleOutlined, FlagOutlined } from "@ant-design/icons"
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import TestHelper from "../../helpers/test.helper";
import { TestModal } from "../testModal/testModal.component";
import ReportHelper from "../../helpers/report.helper";
import ReportStatus from "../../enums/reportStatus.enum";
import Breakpoint from "../../enums/breakpoint.enum";
import PanelData from "../../data/panel.data.js";
import PatientHelper from "../../helpers/patient.helper.js";
import useWidth from "../../hooks/useWidth.hook.js";
import { listAbsoluteCategories } from "../../services/absoluteCategory.service.js";
import { useParams } from "react-router-dom";
import { getPatient } from "../../services/patient.service.js";
import { getMe } from "../../services/user.service.js";
import { listAnnotatedTests } from "../../services/test.service.js";
import { listAnnotatedResults } from "../../services/result.service.js";
import { listReports } from "../../services/report.service.js";
import ResultHelper from "../../helpers/result.helper.js";
import { IncompleteTestTooltip } from "../incompleteTestTooltip/incompleteTestTooltip.component.js";
import { listGoals } from "../../services/goal.service.js";

const { Text } = Typography

dayjs.extend(utc);
dayjs.extend(timezone);

const PENDING = "pending";
const OUTDATED = "outdated";
const { Column } = Table;

const RowType = {
  ABSOLUTE_CATEGORY: "absolute-category",
  TEST: "test",
  SCORE_HEADER: "score-header",
  SCORE_VALUE: "score-value",
};

const BLACKLIST = [
  TestCode.WEIGHT,
  TestCode.ANION_GAP,
  TestCode.TESTOSTERONE_TOTAL,
  TestCode.MPV,
  TestCode.SD_LDL,
  TestCode.TG_HDL,
  TestCode.BUN_CREATINE,
  TestCode.GRIP,
  TestCode.DEXA_TOTAL_LEAN_MASS,
  TestCode.DEXA_LEAN_MASS_ARMS,
  TestCode.DEXA_LEAN_MASS_LEGS,
  TestCode.ARACHIDONIC,
  TestCode.EPA,
  TestCode.DHA,
  TestCode.PSA_FREE,
  TestCode.FREE_TESTOSTERONE_DIRECT
];

const TESTORDER = [
  {
    absoluteCategory: AbsoluteCategoryCode.NUTRIENTS,
    order: [
      TestCode.VITAMIN_D,
      TestCode.VITAMIN_B1,
      TestCode.VITAMIN_B2,
      TestCode.VITAMIN_B6,
      TestCode.FOLATE,
      TestCode.VITAMIN_B12,
      TestCode.FERRITIN,
      TestCode.IRON_TOTAL,
      TestCode.TIBC,
      TestCode.IRON_SATURATION,
      TestCode.ZINC,
      TestCode.OMEGA_3_INDEX,
      TestCode.OMEGA_6_3,
      TestCode.ARACHIDONIC_EPA,
      TestCode.TRANSFAT_INDEX
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.KIDNEY,
    order:[
      TestCode.EGFR,
      TestCode.CYSTATIN_C,
      TestCode.CREATININE,
      TestCode.BUN,
      TestCode.UACR,
      TestCode.URINE_CREATININE,
      TestCode.URINE_ALBUMIN
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.THYROID,
    order: [
      TestCode.TSH,
      TestCode.FREET4,
      TestCode.FREET3,
      TestCode.TPO_ANTIBODY,
      TestCode.THYROGLOBULIN_ANTIBODY
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.BLOOD_CELLS,
    order: [
      TestCode.RBC,
      TestCode.HEMOGLOBIN,
      TestCode.HEMATOCRIT,
      TestCode.MCV,
      TestCode.MCH,
      TestCode.MCHC,
      TestCode.RDW,
      TestCode.PLATELET,
      TestCode.WBC,
      TestCode.NEUTROPHILS,
      TestCode.MONOCYTES,
      TestCode.LYMPHOCYTES,
      TestCode.EOSINOPHILS,
      TestCode.BASOPHILS
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.LIPOPROTEINS,
    order: [
      TestCode.APO_B
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.LIPIDS,
    order: [
      TestCode.TC,
      TestCode.DIRECT_LDL,
      TestCode.LDL_CHOLESTEROL_CALC,
      TestCode.HDL,
      TestCode.VLDL,
      TestCode.TG
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.INFLAMMATION,
    order: [
      TestCode.HS_CRP,
      TestCode.HOMOCYSTEINE,
      TestCode.URIC_ACID,
      TestCode.LP_PLA2
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.HORMONES,
    order: [
      TestCode.TOTAL_TESTOSTERONE,
      TestCode.FREE_TESTOSTERONE,
      TestCode.SHBG,
      TestCode.LH,
      TestCode.FSH,
    ]
  },
  {
    absoluteCategory: AbsoluteCategoryCode.LIVER,
    order: [
      TestCode.ALBUMIN,
      TestCode.ALP,
      TestCode.ALT,
      TestCode.AST,
      TestCode.GGT,
      TestCode.TOTAL_BILIRUBIN,
      TestCode.DIRECT_BILIRUBIN,
      TestCode.INDIRECT_BILIRUBIN,
      TestCode.GLOBULIN,
      TestCode.TOTAL_PROTEIN,
      TestCode.ALBUMIN_GLOBULIN
    ]
  },
  {
  absoluteCategory: AbsoluteCategoryCode.IMMUNOLOGIC,
    order: [
      TestCode.ANA_SCREEN,
      TestCode.ANA_TITER,
      TestCode.ANA_PATTERN,
      TestCode.COMPLEMENT_TOTAL,
      TestCode.COMPLEMENT_C3,
      TestCode.COMPLEMENT_C4
    ]
  }
];

const TESTORDER_LOOKUP = TESTORDER.reduce((acc, item) => {
  acc[item.absoluteCategory] = item.order.reduce((orderMap, code, index) => {
    orderMap[code] = index;
    return orderMap;
  }, {});
  return acc;
}, {});

export const ResultsTable = ({
  hasCurrentRisk = true,
  resultIds,
  filterValue,
  showCurrentRisk=true,
  showUnit=true,
  absoluteCategoryCodes=null,
  testCodes=null,
  showGoal=true,
  maxResults=null
}) => {
  const { patientId } = useParams()
  const [tableData, setTableData] = useState();
  const [testId, setTestId] = useState();
  const [openModal, setOpenModal] = useState();
  const [sortedResults, setSortedResults] = useState();
  const [permanentValues, setPermanentValues] = useState();
  const [absoluteCategories, setAbsoluteCategories] = useState();
  const width = useWidth()
  const [isLoading, setIsLoading] = useState()
  const { currentUser } = useContext(UserContext)
  
  const [goal, setGoal] = useState()
  const [user, setUser] = useState()
  const [tests, setTests] = useState()
  const [results, setResults] = useState()
  const [reports, setReports] = useState()
  

  useEffect(() => {
    fetchTableData();
  }, [
    patientId,
    permanentValues,
    absoluteCategories,
    width,
    reports,
    filterValue,
    goal
  ]);

  useEffect(() => {
    document.title = 'Instalab | Test Results'
    fetchAbsoluteCategories()
  }, [])

  useEffect(() => {
    fetchUser()
  }, [patientId, currentUser])

  useEffect(() => {
    fetchPatientData()
  }, [user])

  useEffect(() => {
    fetchPermanentValues();
  }, [results, tests]);

  const fetchPatientData = async () => {
    if (!user) return
    setIsLoading(true)
    setTests(await listAnnotatedTests({ patient: user._id }))
    setResults(await listAnnotatedResults({ patient: user._id }))
    const goals = await listGoals({ patient: user._id })
    if (goals?.length) {
      setGoal(goals[0])
    }
    setReports(await listReports({
      filter: {
        patient: user._id,
      },
      select: 'status healthScore healthPercentile',
      populate: [{
        path: 'result',
        select: 'collectedAt',
        populate: {
          path: 'order',
          select: 'panel'
        }
      }],
      sort: '-createdAt'
    }))
  }

  const fetchUser = async () => {
    if (!currentUser) return

    let params = {
      select: 'location isAthlete height',
      populate: []
    }

    if (currentUser.role === Role.PATIENT) {
      setUser(await getMe(params))
    }  else if (patientId) {
      setUser(await getPatient(patientId, params))
    }
  }

  const fetchAbsoluteCategories = async () => {
    setAbsoluteCategories(await listAbsoluteCategories())
  }

  const fetchPermanentValues = () => {
    if (!results?.length || !tests?.length) return;
    const permanentTests = tests.filter(({ isPermanent }) => isPermanent);
    let values = {};
    for (const permanentTest of permanentTests) {
      const resultValue = results
        .find(({ values }) => {
          return values.some(
            ({ value, test }) => test === permanentTest._id && value
          );
        })
        ?.values.find(({ test }) => {
          return test === permanentTest._id;
        });
      if (resultValue) {
        values[permanentTest.code] = {
          value: resultValue.value,
          risk: resultValue.risk,
        };
      }
    }
    setPermanentValues(values);
  };

  const hasValues = (code) => {
    const test = tests.find((t) => t.code === code);
    const hasPermanentValue =
      code.toString() in permanentValues && permanentValues[test.code].value;

    return (
      hasPermanentValue  ||
      results.some(({ values }) => {
        return values.some((value) => value.test === test._id && (value.value || value.value===0));
      })
    );
  };

  const GoalHeader = ({ goal }) => {
    return (
      <div className="percent-complete-container">
        <div className="percent-complete-position">
          <Text className="percent-complete">
            <FlagOutlined className={classNames("percent-complete-icon", "all-complete-icon")} /> Next Targets
          </Text>
        </div>
      </div>
    )
  }
  
  const PercentComplete = ({ result, show=true }) => {
    if (!show || moment(result.collectedAt).add(2, 'weeks') < moment()) return
    const progressData = ResultHelper.getProgressData(result)
    if (!progressData) return

    const { percentageComplete } = progressData
    if (percentageComplete === 100) return

    return (
      <div className="percent-complete-container">
        <div className="percent-complete-position">
          <IncompleteTestTooltip
            result={result}
            label={(
              <Text className="percent-complete">
                {percentageComplete === 100 ? 
                  <CheckCircleOutlined className={classNames("percent-complete-icon", "all-complete-icon")} /> :
                  <SyncOutlined className="percent-complete-icon" /> 
                }
                {percentageComplete}% Complete
              </Text>
            )}
          />
        </div>
      </div>
    )
  }

  const getScoreHeaderRow = (activeResults) => {
    let row = {
      rowType: RowType.SCORE_HEADER,
      name: "Longevity Score",
      _id: "health_score",
    };
    if (goal) {
      row[goal._id] = <span className="collected-at">
        <GoalHeader goal={goal} />
        {formatAchieveBy(goal.achieveBy, user)}
      </span>
    }
    for (const result of activeResults) {
      row[result._id] = <span className="collected-at">
        <PercentComplete result={result} />
        {formatCollectedAt(result.collectedAt, user)}
      </span>
    }
    return row;
  };

  const getCurrentHealthScoreRisk = (activeResults) => {

    for (const result of activeResults) {
      const report = reports.find((report) => {
        if (!report.result || !result) return false
        return report.result._id === result._id;
      });

      if (report?.healthScore) return ReportHelper.getHealthScoreRisk(report)

    }
  }

  const getScoreValueRow = (activeResults) => {
    let row = {
      rowType: RowType.SCORE_VALUE,
      name: "Longevity Score",
      unit: "points",
    };
    for (const result of activeResults) {
      const report = reports.find((report) => {
        if (!report.result || !result) return false
        return report.result._id === result._id;
      });

      row[result._id] = {
        risk: report?.healthScore
          ? ReportHelper.getHealthScoreRisk(report)
          : null,
        value: report?.healthScore ? report?.healthScore : "-",
      };
    }

    if (activeResults?.length) {
      row.currentRisk = row[activeResults[0]._id].risk;
    }

    return row;
  };

  const hasInstalabScore = reports?.some(
    (report) =>
      [
        Panel.ADVANCED_BASELINE,
        Panel.ADVANCED_FOLLOWUP,
      ].includes(report?.result?.order?.panel) && (report.status === ReportStatus.APPROVED)
  );

  const getCurrentRiskByCode = (code, results) => {
    const test = tests.find(t => t.code === code);
    if (!test) return null;
    
    return getCurrentRisk(test, results);
  };
  const fetchTableData = async () => {
    if (
      (!user && !patientId) ||
      !permanentValues ||
      !reports ||
      !absoluteCategories?.length ||
      !results?.length ||
      !tests?.length ||
      !width ||
      !filterValue
    ) {
      setTableData(null);
      return;
    }
  
    let activeTests = tests.filter(({ code }) => {
      if (BLACKLIST.includes(code) || !hasValues(code)) return false;

      if (testCodes?.length && !testCodes.includes(code)) return false
  
      // FILTERS
      const currentRisk = getCurrentRiskByCode(code, results);
      if (filterValue === "instalab-panel" 
        && !PanelData.find(panel => panel.code === Panel.ADVANCED_BASELINE)?.testCodes.includes(code)) 
        return false;
      else if (filterValue === "high-risk" 
        && ![RiskLevel.HIGH, RiskLevel.ABNORMAL].includes(currentRisk)) 
        return false;
      else if (filterValue === "high-moderate-risk" 
        && ![RiskLevel.HIGH, RiskLevel.MODERATE, RiskLevel.ABNORMAL].includes(currentRisk)) 
        return false;
      else if (filterValue === "low-risk" 
        && (currentRisk !== RiskLevel.OPTIMAL)) 
        return false;
  
      // HACK: if person is very muscular, don't show BMI
      if ([TestCode.BMI, TestCode.WAIST].includes(code) && (PatientHelper.isAthlete(user) || PatientHelper.isShort(user) || results[0]?.conditions.includes(Condition.VERY_MUSCULAR))) return false;
      return true;
    });

    // Calculate recency score for absoluteCategories
    let categoryScores = absoluteCategories.map(category => {
      let recentDate = new Date(0); // Very old date
      activeTests.forEach(test => {
        if (test.absoluteCategory === category._id) {
          const testResultDates = results.filter(result => result.values.find(value => value.test === test._id)).map(res => new Date(res.collectedAt));

          if (testResultDates.length > 0) {
            const maxDate = new Date(Math.max.apply(null, testResultDates));
            if (maxDate > recentDate) {
              recentDate = maxDate;
            }
          }
        }
      });
      return {
        category: category,
        recentDate: recentDate // convert date to timestamp for easy comparison
      };
    });

    // Sort categories by most recent result date
    categoryScores.sort((a, b) => b.recentDate - a.recentDate);

    // Update the order of absoluteCategories based on recent results
    const sortedCategories = categoryScores.map(item => item.category).filter(({ _id, code }) => {
      if (absoluteCategoryCodes?.length && !absoluteCategoryCodes.includes(code)) {
        return false
      }
      return activeTests.some(test => test.absoluteCategory === _id)
    })
  
    let activeResults = results
      .sort((a, b) => new Date(b.collectedAt).getTime() - new Date(a.collectedAt).getTime())
      .filter(({ collectedAt, _id }) => {
        return collectedAt && (!resultIds || resultIds.includes(_id));
      })
      
    if (maxResults) {
      activeResults = activeResults.slice(0, maxResults)
    }
  
    if (filterValue === "last") {
      activeResults = activeResults.slice(0, 1);
    } else {
      activeResults = activeResults.slice(0, width >= Breakpoint.LG ? results?.length : 1);
    }
  
    setSortedResults(activeResults);
    let data = [];
  
    if (hasInstalabScore) {
      const currentScoreRisk = getCurrentHealthScoreRisk(activeResults);
      if (filterValue === "all" 
            || filterValue === "instalab-panel" 
            || (filterValue === "high-risk" && [RiskLevel.HIGH, RiskLevel.ABNORMAL].includes(currentScoreRisk))
            || (filterValue === "high-moderate-risk" && [RiskLevel.HIGH, RiskLevel.ABNORMAL, RiskLevel.MODERATE].includes(currentScoreRisk))
            || (filterValue === "low-risk" && [RiskLevel.OPTIMAL].includes(currentScoreRisk))) {
        
        data.push(getScoreHeaderRow(activeResults));
        data.push(getScoreValueRow(activeResults));
      }
    }
  
    let index = 0

    // Iterate through each category
    for (const absoluteCategory of sortedCategories) {
      let absoluteCategoryRow = {
        rowType: RowType.ABSOLUTE_CATEGORY,
        name: absoluteCategory.name,
        _id: absoluteCategory._id,
        code: absoluteCategory.code
      };
  
      // Add date
      if (goal) {
        absoluteCategoryRow[goal._id] = <span className="collected-at">
          {formatAchieveBy(goal.achieveBy, user)}
        </span>
      }

      for (const result of activeResults) {
        absoluteCategoryRow[result._id] = <span className="collected-at">
          <PercentComplete result={result} show={!hasInstalabScore && index === 0} />
          {formatCollectedAt(result.collectedAt, user)}
        </span>
      }
  
      // Fetch the order map for the current category if exists
      const orderMap = TESTORDER_LOOKUP[absoluteCategory.code] || {};
  
      // Fetch and sort the tests for this category
      const absoluteCategoryTests = activeTests
        .filter(test => test.absoluteCategory === absoluteCategory._id)
        .sort((a, b) => {
          const orderA = orderMap[a.code] !== undefined ? orderMap[a.code] : 999;
          const orderB = orderMap[b.code] !== undefined ? orderMap[b.code] : 999;
          return orderA - orderB;
        });
  
      let testRows;


      if (filterValue === "last") {
        testRows = absoluteCategoryTests.map(test => {
          let testRow = {
            rowType: RowType.TEST,
            _id: test._id,
            name: test.name,
            code: test.code,
            description: test.description,
            unit: test.unit,
            optimalRange: getOptimalRange(test),
            currentRisk: getCurrentRisk(test, activeResults)
          };
  
          if (activeResults.length) {
            testRow.currentRisk = getCurrentRisk(test, activeResults);
          }
  
          let hasValues = false;

          if (goal) {
            testRow[goal._id] = {
              value: getTestValue(test, goal),
              risk: getTestValueRisk(test, goal),
            }
          }
          
          for (const result of activeResults) {
            const testValue = getTestValue(test, result);
            if (testValue !== null && testValue !== undefined && testValue !== "undefined") {
              hasValues = true;
              testRow[result._id] = {
                value: testValue,
                risk: getTestValueRisk(test, result),
                footnote: result.fasting === false && test.mustFast === true && 'did not fast'
              };
            }
          }
  
          return hasValues ? testRow : null;
        }).filter(testRow => testRow !== null);
      } else {
        testRows = absoluteCategoryTests.map(test => {
          let testRow = {
            rowType: RowType.TEST,
            _id: test._id,
            name: test.name,
            code: test.code,
            description: test.description,
            unit: test.unit,
            optimalRange: getOptimalRange(test),
            currentRisk: getCurrentRisk(test, activeResults)
          };
  
          if (activeResults.length) {
            testRow.currentRisk = getCurrentRisk(test, activeResults);
          }

          if (goal) {
            testRow[goal._id] = {
              value: getTestValue(test, goal),
              risk: getTestValueRisk(test, goal),
            }
          }
  
          for (const result of activeResults) {
            testRow[result._id] = {
              value: getTestValue(test, result),
              risk: getTestValueRisk(test, result),
              footnote: result.fasting === false && test.mustFast === true && 'did not fast'
            };
          }
  
          return testRow;
        });
      }
  
      if (testRows.length > 0) {
        data.push(absoluteCategoryRow);
        data.push(...testRows);
      }

      index += 1
    }
    setTableData(data);
    setIsLoading(false)
  };
  
  const getCurrentRisk = (test, activeResults) => {
    // TO DO: if test is from more than 1 year out, label as "outdated"
    if (test.isPermanent) {
      return test.code.toString() in permanentValues
        ? permanentValues[test.code].risk
        : PENDING;
    } else {
      for (const result of activeResults) {
        const risk = getTestValueRisk(test, result);
        if (risk) {
          if (isOutdated(result)) return OUTDATED;
          if (risk === RiskLevel.UNKNOWN) return null;
          return risk;
        }
      }
      return PENDING;
    }
  };

  const getTestValue = (test, result) => {
    const testResult = result.values?.find((value) => value.test === test._id)
    const testValue = testResult?.value

    return (test.mustFast === true && result.fasting === false) ? <>{testValue}<sup>*</sup></> : `${testValue}`
  };

  const getTestValueRisk = (test, result) => {
    return result.values.find((value) => value.test === test._id)?.risk;
  };
  
  const isOutdated = (result) => {
    return (new Date() - new Date(result.collectedAt)) > (365 * 24 * 60 * 60 * 1000);
  };

  const getOptimalRange = (test) => {
    if (test.code === TestCode.APO_E) return "e3/e3, e2/e3";
    const optimalRange = test.risk.find(
      ({ level }) => level === RiskLevel.OPTIMAL
    );
    return TestHelper.formatRange(optimalRange);
  };

  const formatCollectedAt = (collectedAt, user) => {
    return (
      <Tooltip title={`${Math.round(moment().diff(moment(collectedAt), 'months', true)*10)/10} months ago`}>
        {dayjs(collectedAt).tz(user.location?.timeZoneId).format("MM/DD/YY")}
      </Tooltip>
    )
  };

  const formatAchieveBy = (achieveBy, user) => {
    return (
      <Tooltip title={`${Math.round(moment(achieveBy).diff(moment(), 'weeks', true)*10)/10} weeks away`}>
        {dayjs(achieveBy).tz(user.location?.timeZoneId).format("MM/DD/YY")}
      </Tooltip>
    )
  };


  return isLoading ? <>
    <div className="loading">
      <div className="loading-text">
        <Spin /> &nbsp;&nbsp;Loading
      </div>
    </div>
  </> : <>
    {tableData && (
      <div className="results-table">
        <TestModal
          open={openModal}
          setOpen={setOpenModal}
          testId={testId}
          setTestId={setTestId}
          tests={tests}
          results={results}
          reports={reports}
        />

        <Table
          size="small"
          pagination={false}
          rowKey="_id"
          rowClassName={({ rowType }) => `${rowType}-row`}
          dataSource={tableData}
          className="primary-table"
          showHeader={false}
          scroll={{ x: 95 * sortedResults.length }}
          onRow={({ rowType, _id }) => {
            return {
              onClick: () => {
                switch (rowType) {
                  case RowType.TEST:
                    setTestId(_id);
                    setOpenModal(true);
                    break;
                  case RowType.SCORE_VALUE:
                    setTestId("HEALTH_SCORE");
                    setOpenModal(true);
                    break;
                  default: return null;
                }
              },
            };
          }}
        >
          {showCurrentRisk && width >= Breakpoint.LG && hasCurrentRisk && (
            <Column
              fixed="left"
              dataIndex="currentRisk"
              width={110}
              className="current-risk-col"
              render={(currentRisk, { rowType }) => {
                switch (rowType) {
                  case RowType.SCORE_VALUE:
                    <div
                      className={classNames("risk-tag", `${currentRisk}-risk`)}
                    >
                      {currentRisk in ReadableRiskLevel
                        ? ReadableRiskLevel[currentRisk]
                        : currentRisk}
                    </div>;
                  case RowType.TEST:
                    return (
                      <Tooltip
                        title={
                          width >= Breakpoint.LG && currentRisk === OUTDATED &&
                          "This test was last done over a year ago. Please re-test before drawing any conclusions from these results."
                        }
                      >
                        <div
                          className={classNames(
                            "risk-tag", `${currentRisk}-risk`
                          )}
                        >
                          {currentRisk in ReadableRiskLevel
                            ? ReadableRiskLevel[currentRisk]
                            : currentRisk}
                        </div>
                      </Tooltip>
                    );
                  default: return null;
                }
              }}
            />
          )}

          <Column
            dataIndex="name"
            fixed="left"
            width={width >= Breakpoint.LG ? 200 : 150}
            ellipsis
            render={(name, { rowType, description }) => {
              switch (rowType) {
                case RowType.SCORE_HEADER:
                case RowType.SCORE_VALUE:
                case RowType.ABSOLUTE_CATEGORY:
                  return name;
                case RowType.TEST:
                  return (
                    <Tooltip
                      title={
                        width >= Breakpoint.LG &&
                        ContentHelper.format(
                          description || "",
                          tests,
                          setTestId,
                          setOpenModal
                        )
                      }
                    >
                      {name}
                    </Tooltip>
                  );
                default: 
                  return null;
              }
            }}
          />

          {showGoal && width >= Breakpoint.LG && (
            <Column
              dataIndex="optimalRange"
              width={130}
              render={(optimalRange, { rowType }) => {
                switch (rowType) {
                  case RowType.SCORE_VALUE:
                    return "≥90";
                  case RowType.SCORE_HEADER:
                  case RowType.ABSOLUTE_CATEGORY:
                    return "Goal";
                  case RowType.TEST:
                    return optimalRange;
                  default:
                    return null
                }
              }}
            />
          )}

          {goal && (
            <Column
              dataIndex={goal._id}
              width={95}
              key={goal._id}
              className="test-value-col"
              render={(cell, { rowType }) => {
                if (cell?.risk) {
                  return (
                    <Tooltip
                      title={
                        width >= Breakpoint.LG &&
                        cell.risk &&
                        cell.risk !== RiskLevel.UNKNOWN &&
                        `${ReadableRiskLevel[cell.risk]} ${cell.footnote ? `// note: ${cell.footnote}` : ""}`
                      }
                    >
                      <div
                        className={classNames(
                          "risk-tag",
                          `${cell.risk || "pending"}-risk`
                        )}
                      >
                        {cell.risk ? cell.value : "-"}
                      </div>
                    </Tooltip>
                  )
                } else {
                  return cell
                }
              }}
            />
          )}

          {sortedResults.map((result) => (
            <Column
              dataIndex={result._id}
              width={95}
              key={result._id}
              className="test-value-col"
              render={(cell, { rowType }) => {
                switch (rowType) {
                  case RowType.ABSOLUTE_CATEGORY:
                  case RowType.SCORE_HEADER:
                    return cell;
                  case RowType.SCORE_VALUE:
                    return (
                      <Tooltip
                        title={
                          width >= Breakpoint.LG &&
                          cell.risk &&
                          `${ReadableRiskLevel[cell.risk]}`
                        }
                      >
                        <div
                          className={classNames(
                            "risk-tag",
                            `${cell.risk || "pending"}-risk`
                          )}
                        >
                          {cell.risk ?  cell.value : "-"}
                        </div>
                      </Tooltip>
                    );
                  case RowType.TEST:
                    return (
                      <>
                        { (
                          <Tooltip
                            title={
                              width >= Breakpoint.LG &&
                              cell.risk &&
                              cell.risk !== RiskLevel.UNKNOWN &&
                              `${ReadableRiskLevel[cell.risk]} ${cell.footnote ? `// note: ${cell.footnote}` : ""}`
                            }
                          >
                            <div
                              className={classNames(
                                "risk-tag",
                                `${cell.risk || "pending"}-risk`
                              )}
                            >
                              {cell.risk ? cell.value : "-"}
                            </div>
                          </Tooltip>
                        )}
                      </>
                    );
                  default: return null
                }
              }}
            />
          ))}

          {showUnit && (
            <Column
              dataIndex="unit"
              fixed="right"
              width={100}
              render={(unit, { rowType }) => {
                switch (rowType) {
                  case RowType.SCORE_HEADER:
                  case RowType.ABSOLUTE_CATEGORY:
                    return "Unit";
                  case RowType.TEST:
                  case RowType.SCORE_VALUE:
                    return unit;
                  default:
                    return null
                }
                
              }}
            />
          )}
        </Table>

      </div>
    )}
  </>
};

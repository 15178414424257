import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import Gender from "../../enums/gender.enum"
import { cholesterolTherapyPanel } from "../../data/cholesterolTherapyPanel.data"
import LiverDisqualification from "./static/liverDisqualification/liverDisqualification.component"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import PrescriptionIntro from "./custom/prescriptionIntro/prescriptionIntro.component"
import PregnancyDisqualification from "./static/pregnancyDisqualification/pregnancyDisqualification.component"
import PrescriptionApproval from "./static/prescriptionApproval/prescriptionApproval.component"
import TestConfirmed from "./static/testConfirmed/testConfirmed.component"
import LabVisitConfirmed from "./static/labVisitConfirmed/labVisitConfirmed.component"
import { PanelModal } from "../panelModal/panelModal.component"
import { TassoModal } from "../tassoModal/tassoModal.component"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import TestCode from "../../enums/testCode.enum"
import LipidPrescription from "../../enums/lipidPrescription.enum"
import ConfirnmConsult from "./static/confirmConsult/confirmConsult.component"
import { dosages } from "../../data/dosage.data"
import { listProductTypes } from '../../services/productType.service'
import { listMembershipTypes } from '../../services/membershipType.service'
import MedicalCondition from "../../enums/medicalCondition.enum"
import { completeFlow } from "../../services/flow.service"
import DISCOUNT from "../../enums/discount.enum"
import { heartHealthPanel } from "../../data/heartHealthPanel.data"
import ProductHelper from "../../helpers/product.helper"
import { listAnnotatedResults } from "../../services/result.service"
import { listAnnotatedTests } from "../../services/test.service"

const prescriptionTitle = {
  [LipidPrescription.EZETIMIBE_10MG]: 'Ezetimibe (10mg)',
  [LipidPrescription.ROSUVASTATIN_2500MCG]: 'Rosuvastatin (2.5mg)',
  [LipidPrescription.EZETIMIBE_10MG_AND_ROSUVASTATIN_2500MCG]: 'Ezetimibe (10mg) + Rosuvastatin (2.5mg)',
}

const pastConditions = [
  MedicalCondition.ANGINA,
  MedicalCondition.HEART_ATTACK,
  MedicalCondition.AORTIC_ANUERISM,
  MedicalCondition.HEART_DISEASE_REQUIRING_STENT_OR_SURGERY,
  MedicalCondition.TIA,
  MedicalCondition.BLOCKED_ARTERY,
  MedicalCondition.STROKE,
]

const currentConditions = [
  MedicalCondition.CHRONIC_KIDNEY_DISEASE,
  MedicalCondition.TYPE_1_DIABETES,
  MedicalCondition.TYPE_2_DIABETES,
  MedicalCondition.FAMILIAL_HYPERCHOLESTEROLEMIA,
  MedicalCondition.HEART_DISEASE,
  MedicalCondition.HIGH_CHOLESTEROL,
  MedicalCondition.HIGH_BLOOD_PRESSURE,
  MedicalCondition.PERIPHERAL_ARTERY_DISEASE
]

const familyHistoryConditions = [
  MedicalCondition.BLOCKED_ARTERY,
  MedicalCondition.DIABETES,
  MedicalCondition.HEART_ATTACK,
  MedicalCondition.HEART_DISEASE,
  MedicalCondition.HIGH_BLOOD_PRESSURE,
  MedicalCondition.HIGH_CHOLESTEROL,
  MedicalCondition.PERIPHERAL_ARTERY_DISEASE,
  MedicalCondition.STROKE,
]


export const Step = {
  INTRO: 'intro',
  REASON: 'reason',
  ACCOUNT: 'account',
  NAME: 'name',
  PHONE: 'phone',
  GENDER: 'gender',
  NATURAL: 'natural',
  PREGNANCY: 'pregnancy',
  CONFIRM_CONSULT: 'confirm-consult',
  LIVER_DISEASE: 'liver-disease',
  BREASTFEEDING: 'breastfeeding',
  DOB: 'dob',
  MEDICATIONS: 'medications',
  LIPID_TEST: 'lipid-test',
  TEST_DATE: 'test-date',
  TEST_VALUES: 'test-values',
  MEDICAL_CONDITIONS: 'medical-conditions',
  PREVIOUS_MEDICAL_CONDITIONS: 'previous-medical-conditions',
  SMOKING: 'smoking',
  ALCOHOL: 'alcohol',
  FAMILY_HISTORY: 'family-history',
  SELECT_MEDICATION: 'select-medication',
  PRESCRIPTION_DELIVERY: 'prescription-delivery',
  PAY_CHOLESTEROL_THERAPY: 'pay-cholesterol-therapy',
  GOV_ID: 'gov-id',
  PENDING_APPROVAL: 'pending-approval',
  DISQUALIFIED_PREGNANT: 'disqualified-pregnant',
  DISQUALIFIED_LIVER: 'disqualified-liver',
  SELECT_TEST: 'select-test',
  TEST_LOCATION: 'test-location',
  LAB_LOCATION: 'lab-location',
  PAY_TEST: 'pay-test',
  PAY_LAB: 'pay-lab',
  CONFIRM_TEST: 'confirm-test',
  CONFIRM_LAB: 'confirm-lab',
  DR_LIPIDS: 'dr-consult',

  // dyanic
  CURRENT: 'current',
  DOSAGE: 'dosage',
  ADVERSE_REACTION: 'adverse-reaction',
}

export const PrescriptionFlow = () => {
  const { products, token, longevityMembership } = useContext(UserContext)
  const [openPanel, setOpenPanel] = useState()
  const [openHHPanel, setOpenHHPanel] = useState()
  const [openTasso, setOpenTasso] = useState()
  const [skeleton, setSkeleton] = useState()
  const [flow, setFlow] = useState()
  const [hasPass, setHasPass] = useState()

  const [tests, setTests] = useState()
  const [results, setResults] = useState()

  const [recommendedPrescription, setRecommendedPrescription] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [productTypes, setProductTypes] = useState()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  useEffect(() => {
    fetchProductTypes()
    fetchMembershipTypes()
  }, [])

  useEffect(() => {
    fetchPatientData()
  }, [token])

  const fetchPatientData = async () => {
    if (token) {
      setTests(await listAnnotatedTests())
      setResults(await listAnnotatedResults())
    } else {
      setTests(null)
      setResults(null)
    }
  }

  const fetchMembershipTypes = async () => {
    setMembershipTypes(await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.LIPIDS,
            MembershipTypeCode.LONGEVITY,
          ]
        }
      }
    }))
  }
  
  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.HEART_HEALTH_TEST_KIT,
            ProductTypeCode.HEART_HEALTH_LAB_VISIT,
          ]
        }
      }
    }))
  }

  useEffect(() => {
    setRecommendedPrescription(getRecommendedPrescription())
  }, [flow])

  useEffect(() => {
    fetchSkeleton()
  }, [hasPass, products, membershipTypes, productTypes, recommendedPrescription, longevityMembership, flow])

  useEffect(() => {
    fetchSkeleton()
  }, [])

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }
  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const getLatestTestValue = (testId) => {
    const latestValue = getLatestResult(testId)?.values.find(({ test }) => {
      return test === testId
    })?.value
    return typeof latestValue === 'string' ? Number(latestValue) : latestValue
  }

  const getLatestResult = testId => {
    return results?.find(result => {
      const { values } = result
      return values?.some(({ value, test }) => {
        return value && test === testId
      })
    })
  }

  const hasRiskyTests = () => {
    const ldlCutoff = 50
    const apobCutoff = 40

    const ldl = getLdl()
    if (ldl >= ldlCutoff) return true

    const apob = getApob()
    if (apob >= apobCutoff) return true

    return false
  }

  const getLdl = () => {
    let ldlValue

    if (flow?.user?.hasLipidTest) {
      ldlValue = flow?.user?.ldl
      if (flow?.user?.ldlUnit === "mmol/L") ldlValue *= 38.67
      return ldlValue
    }

    const ldlId = getTestIdByCode(TestCode.DIRECT_LDL)
    ldlValue = getLatestTestValue(ldlId)
    return ldlValue
  }

  const getApob = () => {
    let apobValue

    if (flow?.user?.hasLipidTest) {
      apobValue = flow?.user?.apob
      if (flow?.user?.apobUnit === "mmol/L") apobValue *= 51.3
      return apobValue
    }

    const apobId = getTestIdByCode(TestCode.APO_B)
    apobValue = getLatestTestValue(apobId)
    return apobValue
  }

  const hasInstalabTest = () => {
    const apob = getTestIdByCode(TestCode.APO_B)
    const ldl = getTestIdByCode(TestCode.DIRECT_LDL)
    const testIds = [apob, ldl]
    
    return results?.some(result => {
      const { values } = result
      return values?.some(({ value, test }) => {
        return value && (testIds.includes(test))
      })
    })
  }

  const getTestIdByCode = (testCode) => {
    if (!tests?.length) return
    return tests.find(({ code }) => code === testCode)?._id
  }

  const getRecommendedPrescription = () => {
    const ldl = getLdl()
    const apob = getApob()

    if (
      flow?.user?.cholesterolMedications?.includes('Ezetimibe') && 
      flow?.user?.cholesterolAdverseReaction && 
      flow?.user?.cholesterolAdverseReaction['Ezetimibe'] === true
    ) {
      return LipidPrescription.ROSUVASTATIN_2500MCG
    } else if (ldl >= 160 || apob > 120) {
      return LipidPrescription.EZETIMIBE_10MG_AND_ROSUVASTATIN_2500MCG
    } else if (currentConditions.some(c => flow?.user?.currentMedicalConditions?.includes(c))) {
      return LipidPrescription.EZETIMIBE_10MG_AND_ROSUVASTATIN_2500MCG
    }
    return LipidPrescription.EZETIMIBE_10MG
  }

  const fetchSkeleton = () => {
    let medicationSteps = {}
    const cholesterolMedications = flow?.user?.cholesterolMedications

    if (cholesterolMedications?.length) {
      for (const cholesterolMedication of cholesterolMedications) {
        medicationSteps[`${Step.CURRENT}-${cholesterolMedication}`] = {
          type: StepType.SINGLE_SELECT,
          nextStep: dosages[cholesterolMedication].length === 1 ? `${Step.ADVERSE_REACTION}-${cholesterolMedication}` : `${Step.DOSAGE}-${cholesterolMedication}`,
          buildUser: true,
          title: `Are you still taking ${cholesterolMedication}?`,
          field: {
            name: `cholesterolCurrent.${cholesterolMedication}`,
            options: [{
              label: 'Yes',
              value: true,
            }, {
              label: 'No',
              value: false,
            }]
          }
        }

        medicationSteps[`${Step.DOSAGE}-${cholesterolMedication}`] = {
          type: StepType.SINGLE_SELECT,
          nextStep: `${Step.ADVERSE_REACTION}-${cholesterolMedication}`,
          buildUser: true,
          title: `What dosage of ${cholesterolMedication} did you take last?`,
          field: {
            name: `cholesterolDosage.${cholesterolMedication}`,
            required: true,
            options: dosages[cholesterolMedication].map(dosage => {
              return {
                label: dosage,
                value: dosage,
              }
            })
          },
        }

        medicationSteps[`${Step.ADVERSE_REACTION}-${cholesterolMedication}`] = {
          type: StepType.SINGLE_SELECT,
          onNextStep: async (patient) => {
            const { cholesterolMedications } = patient
            const index = cholesterolMedications.findIndex(c => c === cholesterolMedication)
            if (cholesterolMedications[index+1]) {
              return `${Step.CURRENT}-${cholesterolMedications[index+1]}`
            }
            return Step.LIPID_TEST
          },
          buildUser: true,
          title: `Did you have any adverse reactions to ${cholesterolMedication}?`,
          field: {
            name: `cholesterolAdverseReaction.${cholesterolMedication}`,
            options: [{
              label: 'Yes',
              value: true,
            }, {
              label: 'No',
              value: false,
            }]
          }
        }
      }
    }

    setSkeleton({
      [Step.INTRO]: {
        type: StepType.CUSTOM,
        nextStep: Step.REASON,
        content: ({ footer }) => <PrescriptionIntro footer={footer} />
      },
      [Step.REASON]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.ACCOUNT,
        buildUser: true,
        title: `Why are you interested in treating your cholesterol?`,
        description: 'Select all that apply.',
        field: {
          name: 'cholesterolReason',
          required: true,
          options: [{
            label: `I can't tolerate statins`,
            value: `I can't tolerate statins`,
          }, {
            label: `No doctor to help me`,
            value: `No doctor to help me`,
          }, {
            label: `Lower disease risk`,
            value: `Lower disease risk`,
          }, {
            label: 'Other',
            value: 'Other',
          }]
        },
      },
      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `We can help! First create your Instalab acccount.`,
        description: `This will help you manage your prescriptions and test results.`,
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.gender === Gender.FEMALE) {
            return Step.PREGNANCY
          } else {
            return Step.DOB
          }
        },
        buildUser: true,
        title: `What is your biological sex?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUserRef?.current?.gender
        }
      },
      [Step.PREGNANCY]: {
        type: StepType.SINGLE_SELECT,
        onNextStep: async (patient) => {
          if (patient?.isPregnant) {
            return Step.DOB
          } else {
            return Step.BREASTFEEDING
          }
        },
        buildUser: true,
        title: `Are you pregnant or planning to be in the next 12 months?`,
        field: {
          name: 'isPregnant',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.BREASTFEEDING]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `Are you currently breastfeeding?`,
        field: {
          name: 'isBreastfeeding',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.LIVER_DISEASE,
        buildUser: true,
        title: `What is your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.dob
        }
      },
      [Step.LIVER_DISEASE]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.NATURAL,
        buildUser: true,
        title: `Do you have cirrhosis or end-stage liver disease?`,
        field: {
          name: 'hasEndStageLiverDisease',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.DISQUALIFIED_LIVER]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `Sorry, you don't qualify now.`,
        description: `Unfortunately, taking cholesterol medication is not advisable if you have cirrhosis or end-stage liver disease, because your body will likely have a problem metabolizing it.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <LiverDisqualification />
      },
      [Step.NATURAL]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.MEDICATIONS,
        buildUser: true,
        title: `Which of the following natural remedies you have taken to lower your cholesterol?`,
        description: 'Select all that apply.',
        field: {
          name: 'naturalCholesterolRemedies',
          options: [{
            label: 'Berberine',
            value: 'Berberine',
          }, {
            label: 'Bergamot',
            value: 'Bergamot',
          }, {
            label: 'Fiber Supplements',
            value: 'Fiber Supplements',
          }, {
            label: 'Red Yeast Rice',
            value: 'Red Yeast Rice',
          }, {
            label: 'Other',
            value: 'Other',
          }]
        },
      },
      [Step.MEDICATIONS]: {
        type: StepType.MULTIPLE_SELECT,
        onNextStep: async (patient) => {
          const patientCholesterolMedications = patient?.cholesterolMedications
          if (patientCholesterolMedications?.length) {
            return `${Step.CURRENT}-${patientCholesterolMedications[0]}`
          }
          return Step.LIPID_TEST
        },
        buildUser: true,
        title: `What prescribed medications have you taken to lower your cholesterol?`,
        description: 'Select all that apply. Press OK if none apply.',
        field: {
          name: 'cholesterolMedications',
          options: [{
            label: 'Alirocumab (Praluent)',
            value: 'Alirocumab',
          }, {
            label: 'Atorvastatin (Lipitor)',
            value: 'Atorvastatin',
          }, {
            label: 'Evolocumab (Repatha)',
            value: 'Evolocumab',
          }, {
            label: 'Ezetimibe (Zetia)',
            value: 'Ezetimibe',
          },
          {
            label: 'Inclisirin (Leqvio)',
            value: 'Inclisirin'
          }, 
          {
            label: 'Lovastatin (Altoprev)',
            value: 'Lovastatin',
          }, {
            label: 'Pitavastatin (Livalo)',
            value: 'Pitavastatin',
          }, {
            label: 'Pravastatin (Pravachol)',
            value: 'Pravastatin',
          }, {
            label: 'Rosuvastatin (Crestor)',
            value: 'Rosuvastatin',
          }, {
            label: 'Simvastatin (Zocor)',
            value: 'Simvastatin',
          }]
        },
      },

      ...medicationSteps,

      [Step.DR_LIPIDS]: {
        type: StepType.CALENDLY,
        nextStep: Step.CONFIRM_CONSULT,
        addConsult: true,
        title: `Speak with a physician first.`,
        description: `Due to your history with cholesterol medication, we recommend you speak with a clinical lipidologist first. Schedule a free consultation to find your ideal prescription.`,
        showFooter: false,
        url: process.env.REACT_APP_ENV === "local" ? 'https://calendly.com/instalab/devtest' : `https://calendly.com/d/434-msx-2xd/cholesterol-therapy-consultation`,
      },

      [Step.CONFIRM_CONSULT]: {
        type: StepType.STATIC,
        title: `Your meeting is confirmed.`,
        description: `Check your email for confirmation or to update your appointment.`,
        showFooter: false,
        content: <ConfirnmConsult />,
      },
      [Step.LIPID_TEST]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        title: `Have you had blood work done in the past year that includes LDL cholesterol or ApoB?`,
        field: {
          name: 'hasLipidTest',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
        onNextStep: async (patient) => {
          if (patient?.hasLipidTest) {
            return Step.TEST_DATE
          } else {
            return Step.MEDICAL_CONDITIONS
          }
        },
        skipIf: () => hasInstalabTest()
      },
      [Step.TEST_DATE]: {
        type: StepType.SINGLE_INPUT,
        buildUser: true,
        nextStep: Step.TEST_VALUES,
        title: `Great, when was your blood work done?`,
        field: {
          name: 'lipidTestDate',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => hasInstalabTest()
      },
      [Step.TEST_VALUES]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.MEDICAL_CONDITIONS,
        buildUser: true,
        title: `Enter your lab values.`,
        description: `Fill in at least one value.`,
        requiresOneField: true,
        fields: [{
          name: 'ldl',
          label: 'LDL Cholesterol',
          number: true,
          inputMode: 'decimal',
          placeholder: '100',
          suffixField: {
            name: 'ldlUnit',
            default: 'mg/dL',
            options: [{
              label: 'mg/dL',
              value: 'mg/dL',
            }, {
              label: 'mmol/L',
              value: 'mmol/L',
            }]
          }
        }, {
          name: 'apob',
          label: 'ApoB',
          number: true,
          inputMode: 'decimal',
          placeholder: '90',
          suffixField: {
            name: 'apobUnit',
            default: 'mg/dL',
            options: [{
              label: 'mg/dL',
              value: 'mg/dL',
            }, {
              label: 'mmol/L',
              value: 'mmol/L',
            }]
          }
        }],
        skipIf: () => hasInstalabTest()
      },
      [Step.MEDICAL_CONDITIONS]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.PREVIOUS_MEDICAL_CONDITIONS,
        buildUser: true,
        title: `Which of the following conditions do you currently have?`,
        description: 'Select all that apply.',
        field: {
          name: 'currentMedicalConditions',
          options: currentConditions.map(value => ({label: value, value: value})).sort((a, b) => a.label.localeCompare(b.label))
        },
      },
      [Step.PREVIOUS_MEDICAL_CONDITIONS]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.SMOKING,
        buildUser: true,
        title: `Which of the following conditions have you been previously had?`,
        description: 'Select all that apply.',
        field: {
          name: 'allMedicalConditions',
          options: pastConditions.map(value => ({label: value, value: value})).sort((a, b) => a.label.localeCompare(b.label))
        }
      },
      [Step.SMOKING]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        nextStep: Step.ALCOHOL,
        title: `Do you smoke or vape nicotine?`,
        field: {
          name: 'hasSmoking',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        }
      },
      [Step.ALCOHOL]: {
        type: StepType.SINGLE_INPUT,
        buildUser: true,
        nextStep: Step.FAMILY_HISTORY,
        title: `How many alcoholic drinks do you usually consume per week?`,
        description: `1 alcoholic drink is equivalent to 1 medium glass of wine, 1 can of regular strength beer, or 1 shot of liquor.`,
        field: {
          name: 'alcoholQty',
          placeholder: 'Type quantity here ...',
        },
      },
      [Step.FAMILY_HISTORY]: {
        type: StepType.MULTIPLE_SELECT,
        buildUser: true,
        onNextStep: async (patient) => {
          if (patient?.gender === Gender.FEMALE && (patient?.isPregnant || patient?.isBreastfeeding)) {
            return Step.DISQUALIFIED_PREGNANT
          } else if (patient?.hasEndStageLiverDisease) {
            return Step.DISQUALIFIED_LIVER
          } else if (
            patient?.cholesterolCurrent && 
            Object.keys(patient.cholesterolCurrent).some(c => cholesterolMedications.includes(c) && patient.cholesterolCurrent[c] === true)
          ) {
            return Step.DR_LIPIDS
          } else if (
            patient?.cholesterolAdverseReaction && 
            patient?.cholesterolMedications?.includes('Ezetimibe') &&
            patient?.cholesterolAdverseReaction['Ezetimibe'] === true && 
            patient?.cholesterolMedications?.includes('Rosuvastatin') &&
            patient?.cholesterolAdverseReaction['Rosuvastatin'] === true
          ) {
            return Step.DR_LIPIDS
          } else if (
            !hasRiskyTests() &&
            !patient?.allMedicalConditions?.length && 
            !patient?.currentMedicalConditions?.length && 
            !patient?.familyHistory?.length &&
            !patient?.hasSmoking &&
            patient?.alcoholQty !== 'More than 14 drinks / week' &&
            patient?.alcoholQty !== 'Less than 14 drinks / week'
          ) {
            return Step.SELECT_TEST
          } else {
            return Step.SELECT_MEDICATION
          }
        },
        title: `Have any grandparents, parents, or siblings been diagnosed with any of the following conditions before age 60?`,
        description: 'Select all that apply.',
        field: {
          name: 'familyHistory',
          options: familyHistoryConditions.map(value => ({label: value, value: value})).sort((a, b) => a.label.localeCompare(b.label))
        },
      },
      [Step.SELECT_MEDICATION]: {
        type: StepType.PRODUCT_SELECT,
        nextStep: Step.PRESCRIPTION_DELIVERY,
        title: `We have your recommendation!`,
        description: `Based on your medical history, we recommend starting with ${prescriptionTitle[recommendedPrescription]}. Select and confirm your prescription.`,
        buildUser: true,
        buttonText: 'Confirm Prescription',
        field: {
          name: 'lipidPrescription',
          required: true,
          options: [{
            title: prescriptionTitle[LipidPrescription.EZETIMIBE_10MG],
            value: LipidPrescription.EZETIMIBE_10MG,
            description: `A typically well-tolerated medication that lowers your ApoB and LDL cholesterol by 20%. Side effects are rare, including muscle pain and diarrhea.`,
            tag: recommendedPrescription === LipidPrescription.EZETIMIBE_10MG ? 'recommended' : null,
          }, {
            title: prescriptionTitle[LipidPrescription.ROSUVASTATIN_2500MCG],
            value: LipidPrescription.ROSUVASTATIN_2500MCG,
            description: `A more potent medication than Ezetimibe that lowers ApoB and LDL cholesterol by 40%. Side effects like muscle pain and liver issues can occur.`,
            tag: recommendedPrescription === LipidPrescription.ROSUVASTATIN_2500MCG ? 'recommended' : null,
          }, {
            title: prescriptionTitle[LipidPrescription.EZETIMIBE_10MG_AND_ROSUVASTATIN_2500MCG],
            value: LipidPrescription.EZETIMIBE_10MG_AND_ROSUVASTATIN_2500MCG,
            description: `A powerful combination that can lower your ApoB and LDL cholesterol by more than 50%. Side effects like muscle pain and liver issues can occur.`,
            tag: recommendedPrescription === LipidPrescription.EZETIMIBE_10MG_AND_ROSUVASTATIN_2500MCG ? 'recommended' : null,
          }]
          .filter(({ value }) => {
            const noEzetimibe = flow?.user?.cholesterolMedications?.includes('Ezetimibe') && flow?.user?.cholesterolAdverseReaction &&  flow?.user?.cholesterolAdverseReaction['Ezetimibe'] === true
            const noRosuvastatin = flow?.user?.cholesterolMedications?.includes('Rosuvastatin') && flow?.user?.cholesterolAdverseReaction &&  flow?.user?.cholesterolAdverseReaction['Rosuvastatin'] === true
            switch (value) {
              case LipidPrescription.EZETIMIBE_10MG:
                return !noEzetimibe
              case LipidPrescription.ROSUVASTATIN_2500MCG:
                return !noRosuvastatin
              case LipidPrescription.EZETIMIBE_10MG_AND_ROSUVASTATIN_2500MCG:
                return !noEzetimibe && !noRosuvastatin
            }
          }).sort((a,b) => {
            // Recommended prescription first
            const aValue = recommendedPrescription === a.value ? 1 : 0
            const bValue = recommendedPrescription === b.value ? 1 : 0
            return bValue - aValue
          })
        }
      },
      [Step.PRESCRIPTION_DELIVERY]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.PAY_CHOLESTEROL_THERAPY,
        title: `What’s your delivery address?`,
        description: `We'll ship your medication here.`,
        excludeStates: ['MS'],
        field: {
          name: 'location'
        },
      },
      [Step.PAY_CHOLESTEROL_THERAPY]: {
        type: StepType.PAY,
        nextStep: Step.NAME,
        addSubscription: true,
        subscriptionType: MembershipTypeCode.LIPIDS,
        addLongevityMembership: true,
        description: `This includes all medication, testing and expert guidance from Instalab physicians. You may cancel anytime.`,
        title: (flow?.addLongevityMembership || longevityMembership) ?
          <>Pay <span className="true-price">${getMembershipType(MembershipTypeCode.LIPIDS)?.cost * DISCOUNT}/month</span> <span className="original-price">${getMembershipType(MembershipTypeCode.LIPIDS)?.cost}/month</span> for your cholesterol treatment program{longevityMembership ? '.' : <> and <span className="true-price">${getMembershipType(MembershipTypeCode.LONGEVITY)?.cost}/month</span> for your Instalab membership.</>}</> :
          <>Pay <span className="true-price">${getMembershipType(MembershipTypeCode.LIPIDS)?.cost}/month</span> for your cholesterol treatment program.</>,
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What's your full name?`,
        description: `We need this for your prescription and lab orders.`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.GOV_ID,
        buildUser: true,
        title: `What's your phone number?`,
        description: `We'll only text you urgent notifications about your medications.`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type your phone number here...',
          required: true,
          unique: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.phone
        }
      },
      [Step.GOV_ID]: {
        type: StepType.IMAGE_UPLOAD,
        buildUser: true,
        onNextStep: async () => {
          const hasTestKit = products?.some(({ productType }) => productType?.code === ProductTypeCode.HEART_HEALTH_TEST_KIT)
          const hasLabVisit = products?.some(({ productType }) => productType?.code === ProductTypeCode.HEART_HEALTH_LAB_VISIT)
          if (hasLabVisit) {
            return Step.CONFIRM_LAB
          } else if (hasTestKit) {
            return Step.CONFIRM_TEST
          }
          return Step.PENDING_APPROVAL
        },
        title: 'One more thing...',
        description: `Upload a government-issued ID to verify your identity. This is required by law to get medication online. A passport, driver's license, or state identification card qualifies.`,
        field: {
          name: 'govId'
        }
      },
      [Step.PENDING_APPROVAL]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `Almost Done: Awaiting Physician Approval`,
        description: `A licensed physician must review and approve your prescription for ${prescriptionTitle[flow?.user?.lipidPrescription]}, as mandated by law. This usually happens within 1 business day. We'll keep you updated and ensure your medication is sent immediately upon approval.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <PrescriptionApproval prescriptionTitle={prescriptionTitle} />,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
      [Step.DISQUALIFIED_PREGNANT]: {
        type: StepType.STATIC,
        showFooter: false,
        title: `Sorry, you don't qualify right now.`,
        description: `Unfortunately, taking cholesterol medication is not safe if you're pregnant or breastfeeding. Cholesterol is necessary for the proper development of your baby.`,
        enterStyle: { display: 'none' },
        buttonStyle: { display: 'none' },
        content: <PregnancyDisqualification />
      },
      [Step.SELECT_TEST]: {
        type: StepType.PRODUCT_SELECT,
        buildUser: true,
        onNextStep: async (patient) => {
          switch (patient?.testPreference) {
            case ProductTypeCode.HEART_HEALTH_TEST_KIT:
              return Step.TEST_LOCATION
            case ProductTypeCode.HEART_HEALTH_LAB_VISIT:
              return Step.LAB_LOCATION
            default:
              return Step.TEST_LOCATION
          }
        },
        title: `Take a blood test to get prescription.`,
        description: <>Currently, we don't have enough medical data to proceed. Please get a blood test for ApoB or LDL cholesterol to move forward. You can do that with our <a className="secondary-link" onClick={() => setOpenHHPanel(true)}>advanced heart health test</a> of 8 key biomarkers:</>,
        field: {
          name: 'testPreference',
          required: true,
          options: [{
            title: `At-home test`,
            value: ProductTypeCode.HEART_HEALTH_TEST_KIT,
            description: <>
              Get a self-collection kit mailed to your home. It includes a <a className="secondary-link" onClick={(e) => {
                e.stopPropagation(); 
                setOpenTasso(true);
              }} >Tasso device</a> vs the usual fingerprick, which ensures a painless process.
            </>,
            price: getProductType(ProductTypeCode.HEART_HEALTH_TEST_KIT)?.cost,
            tag: `recommended`,
          }, 
          {
            title: `Lab-based test`,
            value: ProductTypeCode.HEART_HEALTH_LAB_VISIT,
            description: `Get a lab order from us and visit any Quest Diagnostics location where an on-site phlebotomist will draw your blood.`,
            price: getProductType(ProductTypeCode.HEART_HEALTH_LAB_VISIT)?.cost,
          }],
        },
      },
      [Step.TEST_LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.PAY_TEST,
        excludeStates: ['MS'],
        title: `What is your delivery address?`,
        description: `This is where we'll send the test kit.`,
        field: {
          name: 'location'
        },
      },
      [Step.LAB_LOCATION]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.PAY_LAB,
        excludeStates: [
          'MS', 
          'RI', 
          'NJ', 
          'NY'
        ],
        title: `What is your address?`,
        description: `We'll find the closest location where an on-site phlebotomist will draw your blood.`,
        field: {
          name: 'location'
        },
      },
      [Step.PAY_TEST]: {
        type: StepType.PAY,
        addProduct: true,
        productType: ProductTypeCode.HEART_HEALTH_TEST_KIT,
        nextStep: Step.NAME,
        title: longevityMembership ? 
                <>Pay <span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.HEART_HEALTH_TEST_KIT))}</span> <span className="original-price">${getProductType(ProductTypeCode.HEART_HEALTH_TEST_KIT)?.cost}</span> to receive your at-home blood test kit.</>  : 
                <>Pay <span className="true-price">${getProductType(ProductTypeCode.HEART_HEALTH_TEST_KIT)?.cost}</span> to receive your at-home blood test kit.</>,
        description: `Test your blood so you can join our cholesterol treatment program.`,
        benefits: [
          `We'll mail a blood test kit to your home.`,
          `Once collected, just drop it in the mail.`,
          <>Our lab tests your blood for <a onClick={() => setOpenHHPanel(true)} className="secondary-link">8 biomarkers</a>.</>,
          `A physician will review results, recommend and approve your prescription.`,
        ],
      },
      [Step.PAY_LAB]: {
        type: StepType.PAY,
        addProduct: true,
        productType: ProductTypeCode.HEART_HEALTH_LAB_VISIT,
        nextStep: Step.NAME,
        title: longevityMembership ? 
                <>Pay <span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.HEART_HEALTH_LAB_VISIT))}</span> <span className="original-price">${getProductType(ProductTypeCode.HEART_HEALTH_LAB_VISIT)?.cost}</span> for your blood test at a local lab.</>  : 
                <>Pay <span className="true-price">${getProductType(ProductTypeCode.HEART_HEALTH_LAB_VISIT)?.cost}</span> for your blood test at a local lab.</>,
        description: `Test your blood so you can join our cholesterol treatment program.`,
        benefits: [
          `Get lab order via email and directions how to schedule your blood draw.`,
          <>Our lab tests your blood for <a onClick={() => setOpenHHPanel(true)}>8 biomarkers</a>.</>,
          `A physician will review results, recommend and approve your prescription.`,
        ],
      },
      [Step.CONFIRM_TEST]: {
        type: StepType.STATIC,
        title: `Your blood test kit is on the way!`,
        description: `Your at-home blood test kit will arrive within 1 week. We'll send you notifications to keep you updated.`,
        showFooter: false,
        content: <TestConfirmed />
      },
      [Step.CONFIRM_LAB]: {
        type: StepType.STATIC,
        title: `Your lab-based test is confirmed!`,
        description: `We'll send you an email with instructions for your lab order and how to schedule your appointment soon.`,
        showFooter: false,
        content: <LabVisitConfirmed />,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  return <>
    {skeleton && (
      <Flow 
        skeleton={skeleton} 
        flow={flow} 
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
      />
    )}

    <TassoModal
      open={openTasso}
      setOpen={setOpenTasso}
    />
    <PanelModal
      panel={cholesterolTherapyPanel}
      title="Cholesterol Treatment Panel"
      open={openPanel}
      setOpen={setOpenPanel}
    />

    <PanelModal
      panel={heartHealthPanel}
      title="Advanced Heart Health Test"
      open={openHHPanel}
      setOpen={setOpenHHPanel}
    />
  </>
}
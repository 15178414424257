const ProductTypeCode = Object.freeze({
  ELITE_PANEL: 'elite panel',
  LONGEVITY_PANEL: 'longevity panel',
  ATHLETE_PANEL: 'athlete panel',
  TEST_KIT: 'test kit',
  LAB_VISIT: 'lab visit',
  ALLERGY_PANEL: 'allergy panel',

  CONSULT_LONGEVITY: 'consult - longevity',

  // Testing
  HEART_BASELINE: 'heart heatlh baseline',
  GALLERI: 'galleri test',
  PRENUVO: 'prenuvo',
  TRUAGE_COMPLETE: 'truage - complete',
  TRUAGE_PACE: 'truage - pace',
  TOXIC_HAIR_TEST: 'toxic hair test',
  TOXIC_METALS_URINE_TEST: 'toxic metals - urine test',
  HEART_HEALTH_TEST_KIT: 'heart health - test kit',
  HEART_HEALTH_LAB_VISIT: 'heart health - lab visit', 
  CTCALCIUM_REFERRAL: 'ct calcium - referral',
  CTCALCIUM: 'ct calcium scan',
  CHOLESTEROL_TREATMENT_TEST_KIT: 'cholesterol treatment - test kit',
  OMEGAQUANT_COMPLETE: 'omegaquant - complete',
  OMEGAQUANT_BASIC: 'omegaquant - basic',
  DEXA_BODYCOMP: 'dexa - body composition',
  DEXA_BONE: 'dexa - bone density',
  DEXA_BOTH: 'dexa - body composition + bone density',
  DEXA_BODYCOMP_REFERRAL: 'dexa - body composition - referral',
  DEXA_BONE_REFERRAL: 'dexa - bone density - referral',
  FERTILITY: 'fertility',
  MYCOTOX_PROFILE: 'mycotox profile',
  APOE_SALIVA: 'apoe - swab kit',
  WATCHPAT_ONE: 'watchpat one',
  BIOBEAT: 'biobeat',
  BIOBEAT_BP_MONITOR: 'biobeat + bp monitor',
  CLEERLY: 'cleerly',
  TOTAL_TESTOSTERONE_KIT: 'total testosterone - test kit',
  TESTOSTERONE_PANEL_KIT: 'testosterone panel - test kit',
  CGM: 'cgm',
  BP_MONITOR: 'bp monitor',
  FIT_STOOL: 'fit stool test',
  
  // blood draw add ons 
  OMEGA_3_ADDON: 'omega 3',
  APOE_ADDON: 'apoe',
  NMR_LIPOPROFILE: 'npm lipoprofile',
  CPEPTIDE: 'c-peptide',
  FRUCTOSAMINE: 'fructosamine',
  
  // SUPPLEMENTS
  SUPPLEMENT_PACK: 'supplement pack',

  // TREATMENTS
  LEQVIO: 'leqvio',

  // MISC
  TEST_MATRIX: 'testing matrx',

  // INACTIVE
  CALCIFEDIOL: 'Calcifediol',
  VITAMIN_B12: 'Vitamin B12',
  VITAMIN_B9_B12: 'Vitamin B9 + B12',
  CONSULT_LONGEVITY_60: 'consult - longevity - 60 minutes',
  CONSULT_HEART_HEALTH: 'consult - heart health',
  CONSULT_WEIGHT_MANAGEMENT: 'consult - weight management',
  CONSULT_METABOLIC_HEALTH: 'consult - metabolic health',
  CONSULT_METABOLIC_HEALTH_60: 'consult - metabolic health - 60 minutes',
  CONSULT_LIPIDOLOGIST: 'consult - lipidologist',


})

export default ProductTypeCode;
import React, { useContext, useEffect, useState } from 'react';
import { Table, Button, Dropdown, Typography } from 'antd'
import { SettingOutlined, EditOutlined } from '@ant-design/icons'
import './productTypes.scss';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { listProductTypes } from '../../services/productType.service.js';
import { ProductTypeForm } from '../productTypeForm/productTypeForm.component.js';
import ProductHelper from '../../helpers/product.helper.js';
import { UserContext } from '../../contexts/user.context.js';

const { Column } = Table
const { Text } = Typography

export const ProductTypes = () => {
  const [productTypes, setProductTypes] = useState([])
  const [productTypeId, setProductTypeId] = useState()
  const [openModal, setOpenModal] = useState()
  const { setCounts } = useContext(UserContext)

  useEffect(() => {
    document.title = 'Instalab | Product Types'
  }, [])

  useEffect(() => {
    fetchProductTypes()
  }, [])

  const fetchProductTypes = async () => {
    setProductTypes(sortProductTypes(await listProductTypes()));
  };

  const onEdit = (_id) => {
    setProductTypeId(_id)
    setOpenModal(true)
  }

  const sortProductTypes = (unsortedProductTypes) => {
    return unsortedProductTypes.sort((a, b) => {
      const titleA = a.title?.toLowerCase();
      const titleB = b.title?.toLowerCase();
  
      if (titleA < titleB) {
          return -1;
      }
      if (titleA > titleB) {
          return 1;
      }
      return 0;
    })
  }

  const onSuccess = (productType) => {
    if (productTypes.some(p => p._id === productType._id)) {
      setProductTypes(cachedProductTypes => {
        return sortProductTypes(cachedProductTypes.map(p => {
          return p._id === productType._id ? productType : p
        }))
      })
      
    } else {
      setProductTypes(cachedProductTypes => {
        return sortProductTypes([
          ...cachedProductTypes,
          productType
        ])
      })
      setCounts(cachedCounts => {
        return {
          ...cachedCounts,
          productTypes: cachedCounts.productTypes + 1
        }
      })
    }
  }

  return (
    <div className="product-types">
      <PageHeader
        title='Product Types'        
        count={productTypes?.length}
        actions={(
          <div className="page-header-actions">
            <Button
              type='primary'
              onClick={() => {
                setProductTypeId(null)
                setOpenModal(true)
              }}
            >
              + Add Product Type
            </Button>
          </div>
        )}
      />

      <ProductTypeForm
        open={openModal}
        setOpen={setOpenModal}
        onSuccess={onSuccess}
        productTypeId={productTypeId}
        setProductTypeId={setProductTypeId}
      />

      <Table
        loading={!productTypes?.length}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={productTypes}
      >
        <Column
          title='Title'
          dataIndex='title'
        />

        <Column
          title='Code'
          dataIndex='code'
        />

        <Column
          title='Normal Cost'
          dataIndex='cost'
          render={(_, productType) => (
            <Text className="product-type-cost">
              {ProductHelper.formatCost(productType)}
            </Text>
          )}
        />

        <Column
          title='Member Cost'
          dataIndex='memberCost'
          render={(_, productType) => (
            <Text className="product-type-cost">
              {ProductHelper.formatDiscountCost(productType)}
            </Text>
          )}
        />
        
        <Column
          dataIndex='remove'
          className="action-column"
          render={(_, { _id }) => (
            <Dropdown 
              menu={{ 
                items: [{
                  key: 'edit',
                  label: (
                    <a onClick={() => onEdit(_id)}>
                      <EditOutlined /> Edit
                    </a>
                  ),
                }]
              }}
              placement='bottomRight'
            >
              <Button>
                <SettingOutlined />
              </Button>
            </Dropdown>
          )}
        />
      </Table>
    </div>
  )
}
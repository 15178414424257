import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { posthog } from "posthog-js";

const initTracking = () => {
  // if (window.location.hostname !== "localhost") {

    posthog.init("dFcc12453JhWlT-iDYk6YMPdJkdWiTl6gNu6s26JNv8", {
      api_host: "https://app.posthog.com",
    });
    posthog.capture("$pageview");
  // }
};

initTracking();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useContext, useEffect, useState } from "react";
import { Typography, Switch, Tooltip, Button, message } from "antd";
import { listReports, updateReport } from "../../services/report.service";
import "./adminReportsTable.scss";
import { CheckOutlined, EyeOutlined } from '@ant-design/icons'
import moment from 'moment'
import { useParams, useNavigate } from 'react-router-dom'
import { PageHeader } from "../pageHeader/pageHeader.component";
import UrlHelper from '../../helpers/url.helper.js';
import ReportSection from "../../enums/reportSection.enum";
import Condition from "../../enums/condition.enum";
import ReportStatus from "../../enums/reportStatus.enum";
import { FlexibleTable } from "../flexibleTable/flexibleTable.component";
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import Role from "../../enums/role.enum.js";
import { UserContext } from "../../contexts/user.context.js";

const { Text } = Typography;
const defaultSections = [ReportSection.HEALTH_SCORE, ReportSection.NEXT_STEPS];

export const AdminReportsTable = ({ hasPatient=false }) => {
  const { patientId } = useParams()
  const navigate = useNavigate()
  const [reports, setReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filteredCount, setFilteredCount] = useState();
  const [panels, setPanels] = useState([])
  const [conditionKeys, setConditionKeys] = useState([])
  const [sectionCodes, setSectionCodes] = useState([])
  const [select, setSelect] = useState()
  const [populate, setPopulate] = useState()
  const { currentUser } = useContext(UserContext)
  const booleanOptions = [{
    label: 'Yes',
    value: true
  }, {
    label: 'No',
    value: false
  }]

  useEffect(() => {
    document.title = 'Instalab | Reports'
  }, []);

  useEffect(() => {
    fetchSelect()
    fetchPopulate()
  }, [hasPatient, patientId])

  useEffect(() => {
    fetchReports()
  }, [populate, select])

  const fetchSelect = () => {
    if (hasPatient && !patientId) return
    let reportSelect = '_id status providerStatus result healthScore healthPercentile sections lockedAt'
    if (!hasPatient) {
      reportSelect = `${reportSelect} patient`
    }
    setSelect(reportSelect)
  }

  const fetchPopulate = () => {
    if (hasPatient && !patientId) return
    let reportPopulate = [{
      path: 'result',
      select: 'collectedAt conditions patient',
      populate: {
        path: 'order',
        select: 'panel'
      }
    }]
    if (!hasPatient) {
      reportPopulate.push({
        path: 'patient',
        select: '_id firstName lastName',
      })
    }
    setPopulate(reportPopulate)
  }

  const fetchReports = async () => {
    setIsLoading(true)
    if (!populate || !select) return

    let filter = {}
    if (patientId) {
      filter.patient = patientId
    }

    let params = {
      select,
      filter,
      populate,
      sort: '-createdAt'
    }

    if (hasPatient) {
      params.filter = {
        patient: patientId
      }
    }

    const fetchedReports = await listReports(params)
    setPanels([...new Set(fetchedReports.filter(({ result }) => result?.order?.panel).map(({ result }) => result.order.panel))])
    setConditionKeys([...new Set(fetchedReports.reduce((acc, { result }) => {
      if (result?.conditions) {
        acc = [
          ...acc,
          ...result.conditions.map(code => getKeyByValue(Condition, code))
        ]
      }
      return acc
    }, []))].sort())
    setSectionCodes([...new Set(fetchedReports.reduce((acc, { sections }) => {
      if (sections?.length) {
        acc = [
          ...acc,
          ...sections.map(({ code }) => code)
        ]
      }
      return acc
    }, []))].sort())
    setReports(fetchedReports)
    setFilteredCount(fetchedReports.length)
    setIsLoading(false) 
  }


  const getKeyByValue = (object, value) => {
    return Object.keys(object).find((key) => object[key] === value);
  };

  const getCustomFilter = (report, value) => {
    return {
      patient: () => report.patient && `${report.patient.firstName} ${report.patient.lastName}`.toLowerCase().includes(value.toLowerCase()),
      panel: () => report.result?.order?.panel === value,
      conditions: () => report.result?.conditions?.some(condition => value === getKeyByValue(Condition, condition)),
      sections: () => report.sections?.some(section => section.code === value),
      lockedAt: () => value === (report.lockedAt ? true : false),
    }
  }

  const getActionItems = (report) => {
    let menuItems = [{
      key: 'view',
      label: (
        <Button onClick={() => {
          window.open(`/patients/${patientId || report.patient?._id}/reports/${report._id}`, '_blank')
        }}>
          <EyeOutlined /> View
        </Button>
      )
    }]
    return menuItems
  }

  return (
    <div className="admin-reports-table">
      <PageHeader 
        title='Reports' 
        count={filteredCount}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={reports}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        // getActionItems={getActionItems}
        columns={[currentUser?.role === Role.ADMIN && {
          title: "Approved",
          dataIndex: "status",
          render: (status, { _id }) => (
            <Switch
              defaultChecked={status === ReportStatus.APPROVED}
              onChange={async (checked) => {
                const report = await updateReport(_id, {
                  fields: {
                    status: checked ? ReportStatus.APPROVED : ReportStatus.PENDING,
                  },
                  select,
                  populate,
                });
                setReports(
                  reports.map((r) => (r._id === report._id ? report : r))
                );
                message.info(
                  report.status === ReportStatus.PENDING
                    ? "Approval removed"
                    : "Report approved"
                );
              }}
            />
          ),
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(ReportStatus)
        }, 
        currentUser?.role === Role.PROVIDER && {
          title: "Approved",
          dataIndex: "providerStatus",
          render: (providerStatus, { _id }) => (
            <Switch
              defaultChecked={providerStatus === ReportStatus.APPROVED}
              onChange={async (checked) => {
                const report = await updateReport(_id, {
                  fields: {
                    providerStatus: checked ? ReportStatus.APPROVED : ReportStatus.PENDING,
                  },
                  select,
                  populate,
                });

                setReports(
                  reports.map((r) => (r._id === report._id ? report : r))
                );
                message.info(
                  report.providerStatus === ReportStatus.PENDING
                    ? "Approval removed"
                    : "Report approved"
                );
              }}
            />
          ),
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(ReportStatus)
        }, !hasPatient && {
          title: 'Patient',
          dataIndex: 'patient',
          render: patient => {
            if (patient) {
              return (
                <a 
                  onClick={() => navigate(UrlHelper.getPatientProfile(patient._id, 'Reports'))}
                >
                  {patient.firstName} {patient.lastName}
                </a>
              )
            }
          },
          filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: "Collected",
          dataIndex: "result",
          render: (result) => result?.collectedAt && <>
            <Tooltip title={`${Math.round(moment().diff(moment(result.collectedAt), 'months', true)*10)/10} months ago`}>
              {moment(result.collectedAt).format('MM/DD/YYYY')}
            </Tooltip>
          </>
        }, 
        {
          title: "Panel",
          dataIndex: "panel",
          render: (_, { result }) => result?.order?.panel,
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: panels
        }, currentUser?.role === Role.ADMIN && {
          title: "Score",
          dataIndex: "healthScore",
          render: (healthScore, { healthPercentile }) => {
            return (
              healthScore &&  <>{healthScore} ({healthPercentile}%)</>
            );
          }
        }, currentUser?.role === Role.ADMIN && {
          title: "Conditions",
          dataIndex: "conditions",
          render: (_, { result }) => {
            return (result?.conditions?.length > 0 && 
            <ol>
              {result?.conditions.map((conditionCode) => (
                <li key={`section-${conditionCode}-${result?.patient}`}>
                  {getKeyByValue(Condition, conditionCode)?.replace(/_/g, " ")}
                </li>
              ))}
            </ol>
          )},
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: conditionKeys
        }, currentUser?.role === Role.ADMIN && {
          title: "Sections",
          dataIndex: "sections",
          render: (sections, { _id }) => (
            <ol>
              {sections
                .filter((section) => {
                  return !defaultSections.includes(section.code) && section.show;
                })
                .map(({ code, status }) => (
                  <li key={`section-${code}-${_id}`}>
                    {code.replace(/_/g, " ")} {status && <>({status.toLowerCase()})</>}
                  </li>
                ))}
            </ol>
          ),
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: sectionCodes
        }, currentUser?.role === Role.ADMIN && {
          title: "Locked",
          dataIndex: "lockedAt",
          width: 90,
          render: (lockedAt) => (
            <>
              {lockedAt ? <Text className="locked-status"><CheckOutlined /> Locked</Text> : <></>}
            </>
          ),
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: booleanOptions
        },
      {
        dataIndex: "_id",
        render: (_id, {patient}) =>         <Button onClick={() => {
          window.open(`/patients/${patientId || patient?._id}/reports/${_id}`, '_blank')
        }}>
          <EyeOutlined /> View
        </Button>
      }]}
      />
    </div>
  )
};

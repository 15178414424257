import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { useState, useContext, useRef, useEffect } from "react"
import { UserContext } from "../../contexts/user.context"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import { listProductTypes } from '../../services/productType.service'
import { completeFlow } from "../../services/flow.service"
import ProductHelper from "../../helpers/product.helper"
import Gender from "../../enums/gender.enum"
import { WarningFilled } from '@ant-design/icons'
import { invitePatient } from "../../services/patient.service"
import { listUserCounts } from "../../services/user.service"
import Role from "../../enums/role.enum"
import { listMemberships } from "../../services/membership.service"
import MembershipHelper from "../../helpers/membership.helper"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"

export const Step = {
  SELECT: 'select',
  NAME: 'name',
  EMAIL: 'email',
  PHONE: 'phone',
  GENDER: 'gender',
  DOB: 'dob',
  PAY: 'pay',
  PAY_60: 'pay-60',
  CONSULT: 'schedule',
  CONSULT_60: 'schedule-60',
  CONFIRM: 'confirm',
}

export const ProConsultFlow = () => {
  const { setCounts, currentUser } = useContext(UserContext)
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [productTypes, setProductTypes] = useState()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  const [longevityMembership, setLongevityMembereship] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [productTypes, flow, longevityMembership])

  useEffect(() => {    
    fetchProductTypes()
    }, [])

    useEffect(() => {
      fetchLongevityMembership()
    }, [flow])

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.CONSULT_LONGEVITY,
            ProductTypeCode.CONSULT_LONGEVITY_60
          ]
        }
      }
    }))
  }

  const fetchLongevityMembership = async () => {
    if (!flow?.user) return
    const memberships = await listMemberships({
      filter: {
        patient: flow.user._id
      },
      select: 'status endAt',
      populate: {
        path: 'membershipType',
        satus: 'code'
      }
    })
    setLongevityMembereship(MembershipHelper.getActiveMembership(memberships, MembershipTypeCode.LONGEVITY))
  }

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  const fetchSkeleton = () => {
    setSkeleton({
      [Step.SELECT]: {
        type: StepType.PRODUCT_SELECT,
        buildUser: true,
        nextStep: Step.NAME,
        title: `How long would you like your client's session to be?`,
        field: {
          name: 'sessionPreference',
          required: true,
          options: [{
            title: `30 minutes`,
            value: ProductTypeCode.CONSULT_LONGEVITY,
            description: <>Recommended if this is a follow up consult or {flow?.user?.firstName} has just a couple questions or topics you want to talk through.</>,
            price: (currentUser?.role === Role.PROVIDER || longevityMembership) ?
              <><span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.CONSULT_LONGEVITY), false)}</span> <span className="original-price">${getProductType(ProductTypeCode.CONSULT_LONGEVITY)?.cost}</span></> :
              <><span className="true-price">{getProductType(ProductTypeCode.CONSULT_LONGEVITY)?.cost}</span></>,
          }, 
          {
            title: `60 minutes`,
            value: ProductTypeCode.CONSULT_LONGEVITY_60,
            description: <>Perfect for a deep dive into health or if {flow?.user?.firstName} has a number of questions or topics to talk through.</>,
            price: (currentUser?.role === Role.PROVIDER || longevityMembership) ?
              <><span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.CONSULT_LONGEVITY_60), false)}</span> <span className="original-price">${getProductType(ProductTypeCode.CONSULT_LONGEVITY)?.cost}</span></> :
              <><span className="true-price">{getProductType(ProductTypeCode.CONSULT_LONGEVITY_60)?.cost}</span></>,
          }],
        },
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.EMAIL,
        buildUser: true,
        title: `What is your client's full name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return initialUserRef?.current?.firstName && initialUserRef?.current?.lastName
        }
      },
      [Step.EMAIL]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.PHONE,
        buildUser: true,
        title: `What is ${flow?.user?.firstName}'s email address?`,
        field: {
          name: 'email',
          placeholder: 'Type email address here...',
          email: true,
          required: true,
          uniqueError: <>
            <WarningFilled />&nbsp; Email already taken.
            &nbsp;
            <a className="error-link" onClick={onChat}>Message us</a> to add this client to your account.
          </>
        },
        skipIf: () => {
          return initialUserRef?.current?.email
        }
      },
      [Step.PHONE]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What is ${flow?.user?.firstName}'s phone number?`,
        field: {
          name: 'phone',
          phone: true,
          inputMode: 'numeric',
          placeholder: 'Type phone number here...',
          required: true,
          unique: true,
          uniqueError: <>
            <WarningFilled />&nbsp; Phone number already taken.
            &nbsp;
            <a className="error-link" onClick={onChat}>Message us</a> to add this client to your account.
          </>
        },
        skipIf: () => {
          return initialUserRef?.current?.phone
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What is ${flow?.user?.firstName}'s biological sex?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUserRef?.current?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        onNextStep: async (patient) => {
          switch (patient?.sessionPreference) {
            case ProductTypeCode.CONSULT_LONGEVITY:
              return Step.PAY
            case ProductTypeCode.CONSULT_LONGEVITY_60:
              return Step.PAY_60
            default:
              return Step.PAY
          }
        },
        buildUser: true,
        title: `What's ${flow?.user?.gender === Gender.MALE ? 'his' : 'her'} date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUserRef?.current?.dob
        }
      },
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.CONSULT,
        addProduct: true,
        productType: ProductTypeCode.CONSULT_LONGEVITY,
        title: (currentUser?.role === Role.PROVIDER || longevityMembership) ? 
          <>Pay <span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.CONSULT_LONGEVITY))}</span> <span className="original-price">${getProductType(ProductTypeCode.CONSULT_LONGEVITY)?.cost}</span> for your client's consultation.</> :
          <>Pay <span className="true-price">${getProductType(ProductTypeCode.CONSULT_LONGEVITY)?.cost}</span> for your client's consultation.</>,
        description: `This is for a 30-minute video consultation with a longevity physician. You'll schedule your client's appointment in the next step.`,
        onSuccess: async () => {
          setCounts(await listUserCounts())
        }
      },
      [Step.PAY_60]: {
        type: StepType.PAY,
        nextStep: Step.CONSULT_60,
        addProduct: true,
        productType: ProductTypeCode.CONSULT_LONGEVITY_60,
        title: (currentUser?.role === Role.PROVIDER || longevityMembership) ? 
          <>Pay <span className="true-price">{ProductHelper.formatDiscountCost(getProductType(ProductTypeCode.CONSULT_LONGEVITY_60))}</span> <span className="original-price">${getProductType(ProductTypeCode.CONSULT_LONGEVITY_60)?.cost}</span> for your client's consultation.</> :
          <>Pay <span className="true-price">${getProductType(ProductTypeCode.CONSULT_LONGEVITY_60)?.cost}</span> for your client's consultation.</>,
        description: `This is for a 60-minute video consultation with a longevity physician. You'll schedule your client's appointment in the next step.`,
        onSuccess: async () => {
          setCounts(await listUserCounts())
        }
      },
      [Step.CONSULT_60]: {
        type: StepType.CALENDLY,
        nextStep: Step.CONFIRM,
        addConsult: true,
        title: `Schedule ${flow?.user?.firstName}'s longevity consultation.`,
        description: `This session will be with Dr. Steven Winiarski who specializes in longevity medicine. It'll be a 60 minute video call where ${flow?.user?.gender === Gender.MALE ? 'he' : 'she'} can ask any questions.`,
        showFooter: false,
        url: process.env.REACT_APP_ENV === "local" ? 'https://calendly.com/instalab/devtest-clone' : 'https://calendly.com/d/cpv4-fmb-rj6',
        onSuccess: async () => {
          await invitePatient(flow?.user?._id)
          setCounts(await listUserCounts())
        }
      },
      [Step.CONSULT]: {
        type: StepType.CALENDLY,
        nextStep: Step.CONFIRM,
        addConsult: true,
        title: `Schedule ${flow?.user?.firstName}'s longevity consultation.`,
        description: `This session will be with Dr. Steven Winiarski who specializes in longevity medicine. It'll be a 30 minute video call where ${flow?.user?.gender === Gender.MALE ? 'he' : 'she'} can ask any questions.`,
        showFooter: false,
        url: process.env.REACT_APP_ENV === "local" ? 'https://calendly.com/instalab/devtest' : 'https://calendly.com/d/z2x-3dv-qxk',
        onSuccess: async () => {
          await invitePatient(flow?.user?._id)
          setCounts(await listUserCounts())
        }
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        title: `Appointment confirmed!`,
        description: `${flow?.user?.firstName} will receive an email with a calendar invite and a Zoom link for ${flow?.user?.gender === Gender.MALE ? 'his' : 'her'} session.`,
        buttonText: 'Back to Patient Profile',
        path: `/patients/${flow?.user?._id}?key=Consults`,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      },
    })
  }

  return <>
    {skeleton && (
      <Flow 
        skeleton={skeleton} 
        flow={flow} 
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        startIndex={1}
      />
    )}
  </>
}
import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, Typography, message } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import validator from "validator"
import "./forgotPasswordModal.scss"
import { requestPasswordReset } from '../../services/password.service'

const { Item } = Form
const { Text } = Typography

export const ForgotPasswordModal = ({ open, setOpen }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onCancel = () => {
    setOpen(false)
  }
  const onOk = async () => {
    setIsSubmitting(true)
    try {
      const { email } = form.getFieldsValue()

      if (!email || !validator.isEmail(email)) {
        message.error('Enter a valid email address')
        setIsSubmitting(false)
        return
      }
      await requestPasswordReset({ email })
      message.success('Email sent')
      setOpen(false)
      form.resetFields()
    } catch (err) {
      message.error('Failed to send email')
    }
    setIsSubmitting(false)
  }

  return (
    <Modal 
      open={open} 
      title={'Forgot Your Password'}
      onCancel={onCancel}
      className="forgot-password-modal"
      onOk={onOk}
      okText='Save'
      footer={[
        <ContinueButton
          onClick={onOk}
          text='Send Email'
          isSubmitting={isSubmitting}
        />
      ]}
    >
      <Form
        form={form}
        onFinish={onOk}
        layout='vertical'
      >
        <Text className="directions-text">
          We'll send you an email with a link to reset your password.
        </Text>

        <Item 
          name="email"
          onInput={e => e.target.value = e.target.value.toLowerCase()}
          className="email-item"
        >
          <Input placeholder="Email Address" />
        </Item>
      </Form>
    </Modal>
  )
}
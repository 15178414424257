import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, Select, Tooltip, message } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import { addProvider, getProvider, updateProvider } from '../../services/provider.service';
import { PickerInline } from 'filestack-react';
import { DeleteOutlined } from '@ant-design/icons'
import "./providerForm.scss"
import Permission from '../../enums/permission.enum';

const { Item } = Form

export const ProviderForm = ({ open, setOpen, onSuccess, providerId, select, populate=[] }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [photo, setPhoto] = useState(null)

  useEffect(() => {
    if (open) {
      if (providerId) setProviderFields()
    } else {
      form.resetFields()
      setPhoto(null)
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
    setPhoto(null)
  }

  const setProviderFields = async () => {
    const {
      firstName,
      lastName,
      email,
      phone,
      npi,
      slack,
      permissions,
      calendlyLink30min,
      calendlyLink60min,
      photo: selectedPhoto,
    } = await getProvider(providerId, {
      select: 'firstName lastName email phone npi slack calendlyLink30min calendlyLink60min photo',
      populate: []
    })

    if (selectedPhoto) {
      setPhoto(selectedPhoto)
    }

    form.setFieldsValue({
      firstName,
      lastName,
      email,
      phone,
      npi,
      slack,
      permissions,
      calendlyLink30min,
      calendlyLink60min,
    })
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {
      const {
        firstName,
        lastName,
        email,
        phone,
        npi,
        slack,
        calendlyLink30min,
        calendlyLink60min,
        permissions,
        } = form.getFieldsValue()
      
      const params = {
        fields: {
          firstName,
          lastName,
          email,
          phone,
          npi,
          photo,
          slack,
          calendlyLink30min,
          calendlyLink60min,
          permissions,
        },
        select,
        populate
      }
      const response = providerId ? await updateProvider(providerId, params) : await addProvider(params)
      if (onSuccess) {
        onSuccess(response)
      }
      message.success(providerId ? 'Provider updated' : 'Provider added')
      setOpen(false)
      if (!providerId) {
        form.resetFields()
      }
    } catch (err) {
      let msg = providerId ? 'Failed to update provider' : 'Failed to add provider'
      if (err.response?.data?.code === 11000) {
        msg = 'Account already exists with this email'
      }
      message.error(msg)
    }
    setIsSubmitting(false)
  }

  return (
    <div className="provider-form">
      <Modal 
        open={open} 
        title={providerId ? 'Edit Provider' : 'Add Provider'}
        onCancel={onCancel}
        onOk={onOk}
        okText='Save'
        footer={[
          <ContinueButton
            onClick={onOk}
            text='Save Provider'
            isSubmitting={isSubmitting}
          />
        ]}
      >
        <Form
          form={form}
          onFinish={onOk}
          layout='vertical'
        >
          <Item name="picker">
            {photo && (
              <Tooltip title="Click to remove photo" zIndex={2000} placement="right">
                <div 
                  className="provider-form-photo"
                  onClick={() => {
                    setPhoto(null)
                  }}
                >
                  <img 
                    src={`${photo}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`} 
                  />
                    <div className="provider-photo-overlay">
                      <DeleteOutlined />
                    </div>
                </div>
              </Tooltip>
            )}
            <div id="picker-container" style={{ display: photo ? 'none' : 'block' }}>
              <PickerInline
                apikey={process.env.REACT_APP_FILESTACK_API_KEY}
                onUploadDone={({ filesUploaded }) => {
                  if (filesUploaded?.length) {
                    const { url } = filesUploaded[0]
                    setPhoto(url)
                  }
                }}
                pickerOptions={{
                  accept: ['image/*'],
                  fromSources: ['local_file_system'],
                  container: 'picker-container'
                }}
                clientOptions={{
                  security: {
                    policy: process.env.REACT_APP_FILESTACK_POLICY,
                    signature: process.env.REACT_APP_FILESTACK_SIGNATURE
                  }
                }}
              >
                <div className="picker-container"></div>
              </PickerInline>
            </div>
          </Item>

          <Item 
            label="First Name"
            name="firstName"
            rules={[{ 
              required: true, 
              message: 'Enter first name'
            }]}
          >
            <Input placeholder="First Name" />
          </Item>


          <Item 
            label="Last Name"
            name="lastName"
            rules={[{ 
              required: true, 
              message: 'Enter last name'
            }]}
          >
            <Input placeholder="Last Name" />
          </Item>

          <Item 
            label="Email Address"
            name="email"
            onInput={e => e.target.value = e.target.value.toLowerCase()}
            rules={[{ 
              required: true, 
              message: 'Enter email address'
            }, {
              type: 'email',
              message: 'Enter a valid email address',
            }]}
          >
            <Input placeholder="Email Address" />
          </Item>

          <Item 
            label="Phone Number"
            name="phone"
            rules={[{ 
              required: true, 
              message: 'Enter phone number'
            }]}
          >
            <Input placeholder="Phone Number" />
          </Item>

          <Item 
            label="Permissions"
            name='permissions'
          >
            <Select
              mode="tags"
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
              options={Object.values(Permission).map(value => {
                return {
                  label: value,
                  value,
                }
              })}
            />
          </Item>

          <Item 
            label="NPI"
            name="npi"
          >
            <Input placeholder="NPI" />
          </Item>

          <Item 
            label="Slack"
            name="slack"
          >
            <Input placeholder="Slack" />
          </Item>

          <Item 
            label="Calendly (60 min)"
            name="calendlyLink60min"
          >
            <Input placeholder="" />
          </Item>
          <Item 
            label="Calendly (30 min)"
            name="calendlyLink30min"
          >
            <Input placeholder="" />
          </Item>

        </Form>
      </Modal>
    </div>
  )
}
import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, message } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import { addTest, getTest, updateTest } from '../../services/test.service';

const { Item } = Form

export const TestForm = ({ open, setOpen, onSuccess, testId }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (open) {
      if (testId) setTestFields()
    } else {
      form.resetFields()
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
  }

  const setTestFields = async () => {
    const {
      name
    } = await getTest(testId)

    form.setFieldsValue({
      name
    })
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {
      const {
        name
      } = form.getFieldsValue()
      
      const params = {
        name
      }
      const response = testId ? await updateTest(testId, params) : await addTest(params)
      if (onSuccess) {
        onSuccess(response)
      }
      message.success(testId ? 'Test updated' : 'Test added')
      setOpen(false)
      if (!testId) {
        form.resetFields()
      }
    } catch (err) {
      message.error(testId ? 'Failed to udpate test' : 'Failed to add test')
    }
    setIsSubmitting(false)
  }

  return (
    <div className="test-form">
      <Modal 
        open={open} 
        title={testId ? 'Edit Test' : 'Add Test'}
        onCancel={onCancel}
        onOk={onOk}
        okText='Save'
        footer={[
          <ContinueButton
            onClick={onOk}
            text='Save Test'
            isSubmitting={isSubmitting}
          />
        ]}
      >
        <Form
          form={form}
          onFinish={onOk}
          layout='vertical'
        >
          <Item 
            label="Name"
            name="name"
            rules={[{ 
              required: true, 
              message: 'Enter name'
            }]}
          >
            <Input placeholder="Name" />
          </Item>
        </Form>
      </Modal>
    </div>
  )
}
import React, { useContext, useEffect, useState } from 'react';
import { Table, Typography, Tooltip, Row, Col } from 'antd'
import { IntakeModal } from '../intakeModal/intakeModal.component';
import "./medicalInfo.scss";
import { UserContext } from '../../contexts/user.context.js';
import moment from 'moment'
import QuestionTemplateCode from '../../enums/questionTemplateCode.enum.js';
import { LockOutlined } from '@ant-design/icons';
import { PageHeader } from '../pageHeader/pageHeader.component.js';
import { useParams } from 'react-router-dom';
import Role from '../../enums/role.enum.js';
import { listQuestions } from '../../services/question.service.js';

const { Column } = Table
const { Text } = Typography

const WHITELIST = [
  QuestionTemplateCode.GENDER,
  QuestionTemplateCode.DOB,
  QuestionTemplateCode.ETHNICITY,

  // Medical History
  QuestionTemplateCode.CURRENT_MEDICAL_CONDITIONS,
  QuestionTemplateCode.ALL_MEDICAL_CONDITIONS,
  QuestionTemplateCode.ALLERGIES,
  QuestionTemplateCode.SURGERIES,
  QuestionTemplateCode.FREEFORM_MEDICATIONS,
  QuestionTemplateCode.FREEFORM_SUPPLEMENTS,
  QuestionTemplateCode.FAMILY_HISTORY,

  // Lifestyle
  QuestionTemplateCode.DIET,
  QuestionTemplateCode.PREVIOUS_SMOKING,
  QuestionTemplateCode.SMOKING,
  QuestionTemplateCode.EXERCISE,
  QuestionTemplateCode.STRENGTH,
  QuestionTemplateCode.SLEEP_HOURS,
  QuestionTemplateCode.SLEEP_QUALITY,
  QuestionTemplateCode.STRESS,
  QuestionTemplateCode.TRACKERS,
  QuestionTemplateCode.ALCOHOL_QTY,
  QuestionTemplateCode.MEDITATE,

  // Female only
  QuestionTemplateCode.PREGNANCY,
  QuestionTemplateCode.MENOPAUSE,
  QuestionTemplateCode.MENUSTRAL_CYCLE,

  // STAFF ONLY
  QuestionTemplateCode.BIRTH_CONTROL,
  QuestionTemplateCode.VERY_MUSCULAR,
  QuestionTemplateCode.RECENT_INJURY,
  QuestionTemplateCode.ALCOHOL,
  QuestionTemplateCode.LAST_PERIOD_DATE,
  QuestionTemplateCode.RECENT_VIRAL_INFECTION,
  QuestionTemplateCode.CURRENT_SUPPLEMENTS, 
  QuestionTemplateCode.CURRENT_MEDICATIONS,

]

export const MedicalInfo = () => {
  const { patientId } = useParams()
  const { currentUser, token } = useContext(UserContext)
  const [questions, setQuestions] = useState()
  const [questionId, setQuestionId] = useState()
  const [hardcodedAnswers, setHardcodedAnswers] = useState([])
  const [questionsForEveryone, setQuestionsForEveryone] = useState([])
  const [questionsForStaffOnly, setQuestionsForStaffOnly] = useState([])

  useEffect(() => {
    setHardcodedAnswers(getHardcodedAnswers())
  }, [currentUser, patientId])

  useEffect(() => {
    document.title = 'Instalab | Medical Information'
  }, [])

  useEffect(() => {
    fetchQuestions()
  }, [patientId, currentUser])

  useEffect(() => {
    if (!questions) return;

    // Filter questions for everyone
    const filteredQuestionsForEveryone = questions.filter(
      ({ questionTemplate }) => !questionTemplate.isStaffOnly && WHITELIST.includes(questionTemplate.code)
    );
    setQuestionsForEveryone(filteredQuestionsForEveryone);

    // // Filter questions for staff only
    const filteredQuestionsForStaffOnly = questions.filter(
      ({ questionTemplate }) => questionTemplate.isStaffOnly && WHITELIST.includes(questionTemplate.code)
    );
    setQuestionsForStaffOnly(filteredQuestionsForStaffOnly);

  }, [questions]);

  const fetchQuestions = async () => {
    if (!currentUser) return
    const userId = currentUser.role === Role.PATIENT ? currentUser._id : patientId
    setQuestions(await listQuestions({
      filter: {
        patient: userId
      }
    }))
  }

  const formatPatientValue = (question, value) => {
    const ConditionalList = ({ lines }) =>{

      if (!lines || lines.length===0 || lines[0]==="" || lines==="") return <>None</>
    
      // If it's a single line, return just the text
      if (lines.length === 1) { return <>{lines[0]}</> }
    
      const listItems = lines.map((item, index) => <li key={index}>{item}</li>);
      
      return <ul>{listItems}</ul>;
    }

    switch (typeof value) {
      case 'string':
        return <ConditionalList lines={value.split('\n')}/>
      case 'object':
        if (value?.length) {
          return <ConditionalList lines={value}/>
        } else {
          return 'None'
        }
      case 'boolean':
        if (value === true) return 'Yes'
        if (value === false) return 'No'
        return <Text className="not-available-text">N/A</Text>
      default:
        return <>[Fill In]</>
    }
  }

  const getHardcodedAnswers = () => {
    let answers = null

    // Alex Kurlin
    if (patientId === "64fdd453623fbbcbf1e417f1" || currentUser?._id === '64fdd453623fbbcbf1e417f1') {

      answers = [
        {
          question: "What is your date of birth?",
          answer: "03/28/1985"
        },
        {
          question: "What is your biological sex?",
          answer: "Male"
        },
        {
          question: "What is your race/ethnicity?",
          answer: "White or European American"
        },
        {
          question: "What diets do you currently follow?",
          answer: "Vegetarian"
        },
        {
          question: "Do you smoke or vape?",
          answer: "No"
        },
        {
          question: "How many alcoholic drinks do you consume per week?",
          answer: 0
        },
        {
          question: "How many hours do you typically sleep per night?",
          answer: "8-9"
        },
        {
          question: "How would you characterize your sleep quality?",
          answer: "Very good"
        },
        {
          question: "Would you consider yourself a stressed person?",
          answer: null
        },
        {
          question: "Do you meditate?",
          answer: "No"
        },
        {
          question: "How many hours of aerobic exercise per week?",
          answer: null
        },
        {
          question: "How many hours of anaerobic exercise per week?",
          answer: null
        },
        {
          question: "Describe your exercise routine.",
          answer: <>10 hours of exercise per week.<br/> ~3 days per week of cycling either at home or out at the park<br/>~1 day a week a hike for a couple of hours<br/>3-4 days a week of gym, mostly calisthenics and weights</>
        },
        {
          question: "Do you have any of the following medical conditions?",
          answer: <>High cholesterol<br/>Hypothyroidism</>
        },
        {
          question: "Do you deal with any chronic physical issues? ",
          answer: "No"
        },
        {
          question: "Have you had any surgeries?",
          answer: "No"
        },
        {
          question: "Do you currently take any medications or supplements?",
          answer: <>Levothyroxine<br/>Atorvastatin</>
        },
        {
          question: "Do you have any drug or food allergies?",
          answer: "No"
        },
        {
          question: "What conditions has anyone in your immediate family been diagnosed with?",
          answer: <>Cancer<br/>Diabetes<br/>Heart Disease or Stroke<br/>High Blood Pressure<br/>High Cholesterol</>
        },
        {
          question: "What are your current body measurements",
          answer: <>5' 11" / 153 lbs / 13% body fat</>
        },
        {
          question: "What trackers do you currently wear?",
          answer: "Used a few for a while, no longer really bothering with them as my sleep is pretty great and their calorie tracking is usually super off."
        }
      ]

    }

    // Andrew Jiang
    else if (patientId === "645d116a682ca38630d139a6" || currentUser?._id === '645d116a682ca38630d139a6' ) {
      answers = [
        {
          question: "What is your date of birth?",
          answer: "01/15/1988"
        },
        {
          question: "What is your biological sex?",
          answer: "Male"
        },
        {
          question: "What is your race/ethnicity?",
          answer: "Asian, East or Central"
        },
        {
          question: "What diets do you currently follow?",
          answer: "Intermittent Fasting"
        },
        {
          question: "Do you smoke or vape?",
          answer: "No"
        },
        {
          question: "How many alcoholic drinks do you consume per week?",
          answer: 0
        },
        {
          question: "How many hours do you typically sleep per night?",
          answer: 7
        },
        {
          question: "How would you characterize your sleep quality?",
          answer: "Good"
        },
        {
          question: "Would you consider yourself a stressed person?",
          answer: "Yes"
        },
        {
          question: "What are your main sources of stress",
          answer: "Work"
        },
        {
          question: "What are you coping mechanisms to deal with stress?",
          answer: "Exercise, light meditation, journaling"
        },
        {
          question: "Do you meditate?",
          answer: "No"
        },
        {
          question: "How many hours of aerobic exercise per week?",
          answer: 1
        },
        {
          question: "How many hours of anaerobic exercise per week?",
          answer: 3
        },
        {
          question: "Describe your exercise routine.",
          answer: <>I climb 1-2 times per week<br/>I strength train once per 1-2 weeks<br/>I play pickleball once per 1-2 weeks<br/>I kayak once per 1-2 weeks<br/>Outside of that, I do pull ups in my yard.</>
        },
        {
          question: "Do you have any of the following medical conditions?",
          answer: <>Anxiety/Depression</>
        },
        {
          question: "Do you deal with any chronic physical issues? ",
          answer: "No"
        },
        {
          question: "Have you had any surgeries?",
          answer: "No"
        },
        {
          question: "Do you currently take any medications or supplements?",
          answer: "No"
        },
        {
          question: "Do you have any drug or food allergies?",
          answer: "No"
        },
        {
          question: "What conditions has anyone in your immediate family been diagnosed with?",
          answer: <>Dementia / Alzheimer's<br/>Diabetes<br/>High Blood Pressure</>
        },
        {
          question: "What are your current body measurements",
          answer: <>5' 8" / 161 lbs</>
        },
        {
          question: "What trackers do you currently wear?",
          answer: "None"
        }        
      ]
    }

    // Dalton Caldwell
    else if (patientId === "651200a09c25476ab8774ebc" || currentUser?._id === "651200a09c25476ab8774ebc") {
      answers = [
        {
          question: "What is your date of birth?",
          answer: "02/27/1980"
        },
        {
          question: "What is your biological sex?",
          answer: "Male"
        },
        {
          question: "What is your race/ethnicity?",
          answer: "White or European American"
        },
        {
          question: "What diets do you currently follow?",
          answer: <>Intermittent Fasting</>
        },
        {
          question: "Do you smoke or vape?",
          answer: "No"
        },
        {
          question: "How many alcoholic drinks do you consume per week?",
          answer: 0
        },
        {
          question: "How many hours do you typically sleep per night?",
          answer: 8
        },
        {
          question: "How would you characterize your sleep quality?",
          answer: "Good"
        },
        {
          question: "Do any of the following pertain to your sleep habits",
          answer: "Difficulty staying asleep"
        },
        {
          question: "Would you consider yourself a stressed person?",
          answer: "No"
        },
        {
          question: "Do you meditate?",
          answer: "No"
        },
        {
          question: "How many hours of aerobic exercise per week?",
          answer: 2
        },
        {
          question: "How many hours of anaerobic exercise per week?",
          answer: 1
        },
        {
          question: "Describe your exercise routine.",
          answer: <>Over the past 18 months I have been doing 3 20 minute strength training classes per week with the Peloton Guide.<br/>I would also cross train with Peloton bike rides once or twice a week that has fallen off over the past 9 months.<br/>Over the past 3 months I have been running 3 times per week to build my aerobic exercise base to cross train with my weight training. I also try to do a fair amount of walking.</>
        },
        {
          question: "Do you have any of the following medical conditions?",
          answer: "No"
        },
        {
          question: "Do you deal with any chronic physical issues? ",
          answer: "No"
        },
        {
          question: "Have you had any surgeries?",
          answer: "No"
        },
        {
          question: "Do you currently take any medications or supplements?",
          answer: "Athletic Greens, Fatty15"
        },
        {
          question: "Do you have any drug or food allergies?",
          answer: "No"
        },
        {
          question: "What conditions has anyone in your immediate family been diagnosed with?",
          answer: <>Cancer<br/>Dementia/Alzheimer's<br/>Type 2 Diabetes<br/>Heart Disease<br/>High Blood Pressure<br/>High Cholesterol</>
        },
        {
          question: "What are your current body measurements",
          answer: <>6' 2" / 185 lbs / 18.5% body fat</>
        },
        {
          question: "What trackers do you currently wear?",
          answer: "Apple Watch, Oura"
        }        
      ]     
    }

    // Leo Chen
    else if (patientId === "6582f132d96249d1f7a15ed0" || currentUser?._id ==="6582f132d96249d1f7a15ed0") {
      answers = [
        {
          question: "What is your date of birth?",
          answer: "02/18/1983"
        },
        {
          question: "What is your biological sex?",
          answer: "Male"
        },
        {
          question: "What is your race/ethnicity?",
          answer: "Asian, Central or East"
        },
        {
          question: "What diets do you currently follow?",
          answer: "None"
        },
        {
          question: "Do you smoke or vape?",
          answer: "No"
        },
        {
          question: "How many alcoholic drinks do you consume per week?",
          answer: 0
        },
        {
          question: "How many hours do you typically sleep per night?",
          answer: 7
        },
        {
          question: "How would you characterize your sleep quality?",
          answer: "Good"
        },
        {
          question: "Would you consider yourself a stressed person?",
          answer: "No"
        },
        {
          question: "Do you meditate?",
          answer: "No"
        },
        {
          question: "How many hours of exercise per week?",
          answer: 7
        },
        {
          question: "Describe your exercise routine.",
          answer: <>5-6x weight training each week; 1x kickboxing</>
        },
        {
          question: "Do you have any of the following medical conditions?",
          answer: "No"
        },
        {
          question: "Do you deal with any chronic physical issues?",
          answer: "No"
        },
        {
          question: "Do you currently take any medications?",
          answer: "No"
        },
        {
          question: "Do you currently take any supplements?",
          answer: <>Vitamin d (5000IU)
          <br/>Creatine (2.5g)
          <br/>Magnesium L-threonate (48mg)
          <br/>Zinc (15mg)
          <br/>Fish oil (EPA 800mg; DHA 600mg)
          <br/>Glucosamine & chondroitin (500mg)
          <br/>Coq10 (100mg)
          <br/>Cocoa flavanols (500mg)
          <br/>Alpha-lipoic acid (300mg)
          <br/>DHEA (25mg)
          <br/>Hyaluronic acid (300mg)
          <br/>Turmeric (1.4g)</>
        },
        {
          question: "Do you have any drug or food allergies?",
          answer: "None"
        },
        {
          question: "What conditions has anyone in your immediate family been diagnosed with?",
          answer: <>High blood pressure<br/>Dementia/Alzheimer's</>
        },
        {
          question: "What trackers do you currently wear?",
          answer: "Whoop"
        }        
      ]      
    }
    
    if (answers) {
      return  (
        <Table
          size='small'
          className='primary-table'
          pagination={false}
          rowKey='code'
          dataSource={answers}
          showHeader={false}
          rowClassName={token ? 'pointer-row' : 'default-row'}>
            <Column dataIndex='question' />
            <Column 
              dataIndex='answer' 
              render={(answer)=> answer === null ? "did not answer" : answer}
            />
        </Table>
      )
    }

    return null
  }

  const sortAnswers = (answers) => {
    return answers?.sort((a,b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })
  }

  const getLatestAnswer = (answers) => {
    if (!answers?.length) return
    return sortAnswers(answers)[0].content
  }

  const getTooltipHistory = (hoverQuestionId) => {
    const hoverQuestion = questions.find(({ _id }) => _id === hoverQuestionId)
    if (!hoverQuestion) return
    const sortedAnswers = sortAnswers(hoverQuestion.answers)
    if (!sortedAnswers?.length) return
    return (
      <div className="history-container">
        {sortedAnswers.map(answer => (
          <Row 
            key={`tooltip-answer-${answer._id}`}
            className="history-tooltip-row"
          >
            <Col 
              style={{ width: 100 }}
              className="history-tooltip-col"
            >
              <b>{moment(answer.createdAt).format('MMM D, YYYY')}</b>
            </Col>
            <Col 
              style={{ width: 80 }}
              className="history-tooltip-col"
            >
              {moment(answer.createdAt).format('h:mm a')}
            </Col>
            <Col 
              style={{ width: 500 }}
              className="history-tooltip-col"
            >
              {formatPatientValue(hoverQuestion, answer.content)}
            </Col>
          </Row>
        ))}
      </div>
    )
  }

  const CustomTableRow = (props) => {
    
    const hoverQuestionId = props['data-row-key']
    const history = getTooltipHistory(hoverQuestionId)

    return (
      <Tooltip 
        title={(currentUser?.role === Role.ADMIN || currentUser?.role === Role.PROVIDER) && history}
        overlayStyle={{ maxWidth: 800 }}
        className="tooltip-style"
      >
        <tr {...props} />
      </Tooltip>
    );
  }

  const onIntakeSuccess = () => {
    fetchQuestions()
  }
  
  return questionsForEveryone && 
    (<>
    <div className="medical-info">
      <PageHeader title='Medical Info' />

      {token && (<> 
        {/* Edit specific medical question */}
        <IntakeModal 
          questionId={questionId}
          setQuestionId={setQuestionId}
          autoOpen={false}
          hasFlow={questions?.some(({ isAnswered }) => !isAnswered)}
          onSuccess={onIntakeSuccess}
        />
        
        {/* Open automatically if there are unanswered questions  */}
        {/* <IntakeModal 
          autoOpen={true} 
          hasFlow={true}
        /> */}
      </>)}
      
      {hardcodedAnswers ? hardcodedAnswers :

      questionsForEveryone && questionsForEveryone?.length > 0 && 
      <Table
        loading={!questionsForEveryone?.length}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={questionsForEveryone}
        showHeader={false}
        rowClassName={token ? 'pointer-row' : 'default-row'}
        onRow={({ _id }) => {
          return {
            onClick: () => {
              setQuestionId(_id)
            }
          };
        }}
        components={{body: { row: CustomTableRow }}}
      >
        <Column dataIndex='content' />

        <Column
          dataIndex='answers'
          render={(answers, question) => formatPatientValue(question, getLatestAnswer(answers))}
          width={250} 
        />
      </Table>
      }

      {currentUser?.role === Role.ADMIN && questionsForStaffOnly && questionsForStaffOnly?.length > 0 && 
      <div className="staff-only-box">
        <div className="staff-only-box-header"><LockOutlined />{" "}For Staff Only</div>

        <Table
          loading={!questionsForStaffOnly?.length}
          size='small'
          className='primary-table '
          pagination={false}
          rowKey='_id'
          dataSource={questionsForStaffOnly}
          showHeader={false}
          rowClassName={token ? 'pointer-row' : 'default-row'}
          onRow={({ _id }) => {
            return {
              onClick: () => {
                setQuestionId(_id)
              }
            };
          }}
          components={{body: { row: CustomTableRow }}}
        >
          <Column dataIndex='content' />

          <Column
            dataIndex='answers'
            render={(answers, question) => formatPatientValue(question, getLatestAnswer(answers))}
            width={250} 
          />
        </Table>
      </div>
      }
  

      
      
    </div>
  </>)
}
import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Form, Select, message, Tooltip, Input, Button } from 'antd'
import "./blogForm.scss"
import { DeleteOutlined } from '@ant-design/icons'
import { addBlog, getBlog, updateBlog } from '../../services/blog.service';
import Subject from '../../enums/subject.enum';
import { PickerOverlay } from 'filestack-react';
import { listAuthors } from '../../services/author.service'

import ReactQuill, { Quill } from "react-quill-with-table";
import QuillBetterTable from "quill-better-table";
import katex from "katex";
import "react-quill-with-table/dist/quill.snow.css";
import "quill-better-table/dist/quill-better-table.css";
import "katex/dist/katex.min.css";
import { useNavigate, useParams } from 'react-router-dom'
import { PageHeader } from '../pageHeader/pageHeader.component'
import { ContinueButton } from '../continueButton/continueButton.component';
// Quill.register({ "modules/better-table": QuillBetterTable });
window.katex = katex;

const { Item } = Form

export const BlogForm = () => {
  const { blogId } = useParams()
  const reactQuillRef = useRef(null);
  const [editorState, setEditorState] = useState("");
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [authors, setAuthors] = useState()
  const [isSubmitting, setIsSubmitting] = useState()
  const [image, setImage] = useState(null)
  const [showPicker, setShowPicker] = useState()

  useEffect(() => {
    if (blogId) {
      setBlogFields()
    } else {
      form.resetFields()
    }
  }, [blogId])

  useEffect(() => {
    fetchAuthors()
  }, [])
  
  const insertTable = () => {
    const editor = reactQuillRef.current.getEditor();
    const tableModule = editor.getModule("better-table");
    tableModule.insertTable(3, 3);
  };

  useEffect(() => {
    const editor = reactQuillRef.current.getEditor();
    const toolbar = editor.getModule("toolbar");
    toolbar.addHandler("table", () => {
      insertTable();
    });
  }, []);

  const handleEditorStateChange = (val) => {
    setEditorState(val);
  };

  const modules = useMemo(
    () => ({
      table: false,
      "better-table": {
        operationMenu: {
          items: {
            unmergeCells: {
              text: "Another unmerge cells name"
            }
          }
        }
      },
      keyboard: {
        bindings: QuillBetterTable.keyboardBindings
      },
      toolbar: [
        [
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          { list: "ordered" },
          { list: "bullet" },
        ], // toggled buttons
      ],
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  const fetchAuthors = async () => {
    setAuthors(await listAuthors())
  }

  const setBlogFields = async () => {
    const blogFields = await getBlog(blogId)

    const {
      title,
      subject,
      content,
      author,
      slug,
      image: selectedImage
    } = blogFields

    if (content) setEditorState(content)
    if (selectedImage) setImage(selectedImage) 

    form.setFieldsValue({
      title,
      subject,
      slug,
      author: author?._id
    })
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {
      const {
        title,
        subject,
        slug,
        author,
      } = form.getFieldsValue()
      
      const params = {
        title,
        subject,
        slug,
        content: editorState,
        author,
        image,
      }

      blogId ? await updateBlog(blogId, params) : await addBlog(params)
      message.success(blogId ? 'Blog post updated' : 'Blog post added')
      navigate('/admin/blogs')
    } catch (err) {
      let msg = blogId ? 'Failed to update blog post' : 'Failed to add blog post'
      message.error(msg)
    }
    setIsSubmitting(false)
  }

  return (
    <div className="blog-form">
      <PageHeader
        title={blogId ? 'Edit Blog Post' : 'Add Blog Post'}
      />

      {image && (
        <Tooltip title="Click to remove image" zIndex={2000} placement="right">
          <div 
            className="blog-form-image"
            onClick={() => {
              setImage(null)
            }}
          >
            <img 
              src={`${image}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`} 
            />
              <div className="blog-image-overlay">
                <DeleteOutlined />
              </div>
          </div>
        </Tooltip>
      )}

      {showPicker && (
        <PickerOverlay
          apikey={process.env.REACT_APP_FILESTACK_API_KEY}
          onUploadDone={({ filesUploaded }) => {
            if (filesUploaded?.length) {
              const { url } = filesUploaded[0]
              setImage(url)
            }
          }}
          pickerOptions={{
            onClose: () => {
              setShowPicker(false)
            }
          }}
          clientOptions={{
            security: {
              policy: process.env.REACT_APP_FILESTACK_POLICY,
              signature: process.env.REACT_APP_FILESTACK_SIGNATURE
            }
          }}
        />
      )}

      {!image && (
        <Button
          onClick={() => setShowPicker(true)}
          className="add-image-btn"
        >
          + Add Image
        </Button>
      )}
    
      <Form
        form={form}
        onFinish={onOk}
        layout='vertical'
        className="form-container"
      >
        <Item name="author">
          <Select 
            placeholder='Author'
            optionFilterProp="children"
            options={authors?.map(author => {
              return {
                label: author.name,
                value: author._id
              }
            })}
            className="author-select"
          />
        </Item>

        <Item name="title">
          <Input placeholder="Title" />
        </Item>

        <Item name="subject">
          <Select
            placeholder="Select subject"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => 
              option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
            }
            options={Object.values(Subject).map(subject => {
              return {
                label: subject,
                value: subject,
              }
            })}
          />
        </Item>

        <Item name="slug">
          <Input placeholder="Slug" />
        </Item>
      </Form>

      <ReactQuill
        ref={reactQuillRef}
        modules={modules}
        theme="snow"
        onChange={handleEditorStateChange}
        value={editorState}
      />

      <div className="save-btn-container">
        <ContinueButton
          onClick={onOk}
          text='Save Post'
          isSubmitting={isSubmitting}
        />
      </div>
    </div>
  )
}
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import { Form, Input, Button, Typography, message } from 'antd'
import { UserContext } from '../../contexts/user.context.js';
import './proInvite.scss'
import { acceptProviderInvite } from '../../services/provider.service.js';
import RuleHelper from '../../helpers/rule.helper.js';
import { isUserPending } from '../../services/user.service.js';
import StorageKey from '../../enums/storageKey.enum.js';

const { Item } = Form
const { Paragraph } = Typography

export const ProInvite = () => {
  const [form] = Form.useForm()
  const { id } = useParams()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState()
  const { token, setToken, currentUser } = useContext(UserContext)

  useEffect(() => {
    if (!id) return
    fetchIsUserPending()
  }, [id])

  useEffect(() => {
    if (token) {
      navigate('/')
    }
  }, [token])

  const fetchIsUserPending = async () => {
    const isPending = await isUserPending(id)
    if (!isPending) {
      message.error('Invalid invite')
      navigate('/pro')
    }
  }

  const onFinish = async () => {
    setIsLoading(true)
    try {
      const { password } = form.getFieldsValue()
      const response = await acceptProviderInvite(id, {
        password
      })
      localStorage.setItem(StorageKey.TOKEN, response.token)
      setToken(response.token)
      message.info('Accepted invite')
    } catch (err) {
      console.error(err)
      message.error('Failed to accept invite')
    }
    setIsLoading(false)
  };

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  return (
    <div className="pro-invite">
      <Paragraph className="pro-invite-header">
        Welcome to Instalab<span>Pro</span>
      </Paragraph>
      <Paragraph className="pro-invite-subtitle">
        Set your account password to accept your invite. If you have any questions, please <a onClick={onChat}>send us a message</a>
      </Paragraph>

      <Form
        form={form}
        onFinish={onFinish}
        layout='vertical'
      >
        <Item 
          name="password"
          rules={[RuleHelper.isRequired]}
        >
          <Input placeholder="Enter password..." type="password" />
        </Item>

        <Item>
          <Button 
            htmlType='submit'
            type="primary" 
            loading={isLoading}
          >
            Accept Invite
          </Button>
        </Item>
      </Form>
    </div> 
  )
}
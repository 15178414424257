import React, { useState, useEffect } from 'react'
import { Modal, Form, message, Tooltip, Input } from 'antd'
import { ContinueButton } from '../continueButton/continueButton.component'
import "./authorForm.scss"
import { DeleteOutlined } from '@ant-design/icons'
import 'react-quill/dist/quill.snow.css';
import { addAuthor, getAuthor, updateAuthor } from '../../services/author.service';
import { PickerInline } from 'filestack-react';

const { Item } = Form

export const AuthorForm = ({ open, setOpen, onSuccess, authorId }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()
  const [image, setImage] = useState()

  useEffect(() => {
    if (open) {
      if (authorId) setAuthorFields()
    } else {
      form.resetFields()
    }
  }, [open])

  const onCancel = () => {
    setOpen(false)
    setImage(null)
  }

  const setAuthorFields = async () => {
    const authorFields = await getAuthor(authorId)

    const {
      title,
      name,
      twitter,
      image: selectedImage
    } = authorFields

    setImage(selectedImage) 

    form.setFieldsValue({
      title,
      name,
      twitter,
    })
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {
      const {
        title,
        name,
        twitter,
      } = form.getFieldsValue()
      
      const params = {
        title,
        name,
        twitter,
        image,
      }

      const response = authorId ? await updateAuthor(authorId, params) : await addAuthor(params)
      if (onSuccess) {
        onSuccess(response)
      }
      message.success(authorId ? 'Author post updated' : 'Author post added')
      onCancel()
      if (!authorId) {
        form.resetFields()
      }
    } catch (err) {
      let msg = authorId ? 'Failed to update author post' : 'Failed to add author post'
      message.error(msg)
    }
    setIsSubmitting(false)
  }

  return (
    <div className="author-form">
      <Modal 
        open={open} 
        title={authorId ? 'Edit Author' : 'Add Author'}
        onCancel={onCancel}
        onOk={onOk}
        okText='Save'
        width={600}
        footer={[
          <ContinueButton
            onClick={onOk}
            text='Save Author'
            isSubmitting={isSubmitting}
          />
        ]}
      >
        <Form
          form={form}
          onFinish={onOk}
          layout='vertical'
        >
          <Item 
            name="picker"
            label="Avatar"
          >
            {image ? (
              <Tooltip title="Click to remove image" zIndex={2000} placement="right">
                <div 
                  className="author-image"
                  onClick={() => {
                    setImage(null)
                  }}
                >
                  <img 
                    src={`${image}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`} 
                  />
                    <div className="author-image-overlay">
                      <DeleteOutlined />
                    </div>
                </div>
              </Tooltip>
            ) : (
              <PickerInline
                apikey={process.env.REACT_APP_FILESTACK_API_KEY}
                onUploadDone={({ filesUploaded }) => {
                  if (filesUploaded?.length) {
                    const { url } = filesUploaded[0]
                    setImage(url)
                  }
                }}
                pickerOptions={{
                  accept: ['image/*'],
                  fromSources: ['local_file_system']
                }}
                clientOptions={{
                  security: {
                    policy: process.env.REACT_APP_FILESTACK_POLICY,
                    signature: process.env.REACT_APP_FILESTACK_SIGNATURE
                  }
                }}
              >
                <div className="picker-container"></div>
              </PickerInline>
            )}
          </Item>

          <Item label="Name" name="name">
            <Input placeholder="Name" />
          </Item>

          <Item label="Title" name="title">
            <Input placeholder="Title" />
          </Item>

          <Item label="Twitter" name="twitter">
            <Input placeholder="Twitter" />
          </Item>
        </Form>
      </Modal>
    </div>
  )
}
import React, { useContext, useEffect, useState } from 'react';
import { Button, message, Avatar, Modal } from 'antd'
import { inviteProvider, listProviders, removeProvider } from "../../services/provider.service"
import { KeyOutlined, SendOutlined, GiftOutlined, DeleteOutlined, EditOutlined, LockOutlined } from '@ant-design/icons'
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import './providers.scss'
import { PageHeader } from '../pageHeader/pageHeader.component';
import { ProviderForm } from '../providerForm/providerForm.component';
import { ChangePasswordModal } from '../changePasswordModal/changePasswordModal.component';
import { AdminLocationModal } from '../adminLocationModal/adminLocationModal.component';
import { addImpersonation } from '../../services/impersonation.service';
import { TransferModal } from '../transferModal/transferModal.component';
import copy from 'copy-to-clipboard';
import ObjectType from '../../enums/objectType.enum';
import Org from '../../enums/org.enum';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component';
import { UserContext } from '../../contexts/user.context.js';

const select = '_id firstName lastName email phone photo npi credits'
const populate = []

export const Providers = () => {
  const [providers, setProviders] = useState([])
  const [providerId, setProviderId] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [openTransferModal, setOpenTransferModal] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState()
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState()
  const [isAdminLocationModalOpen, setIsLocalModalOpen] = useState()
  const [userId, setUserId] = useState()
  const [filteredCount, setFilteredCount] = useState();
  const { setCounts } = useContext(UserContext)

  useEffect(() => {
    document.title = 'Instalab | Providers'
    fetchProviders()
  }, [])

  const fetchProviders = async () => {
    setIsLoading(true)
    const fetchedProviders = await listProviders({
      select,
      filter: {
        org: Org.INSTALAB,
        email: {
          $ne: null
        },
        firstName: {
          $ne: null
        }
      },
      populate,
      sort: '-createdAt'
    })
    setProviders(fetchedProviders)
    setFilteredCount(fetchedProviders.length)
    setIsLoading(false)
  }

  const onImpersonate = async (_id) => {
    try {
      const impersonate = await addImpersonation({ user: _id })
      copy(`${process.env.REACT_APP_CLIENT_URL}/impersonate/${impersonate._id}`)
      message.success('Copied login link')
    } catch (err) {
      message.error('Failed to generate login link')   
    }
  }

  const onTransferModal = (_id) => {
    setUserId(_id)
    setOpenTransferModal(true);
  }

  const onTransferSuccess = (transfer) => {
    setProviders(providers.map(provider => {
      if (provider._id === transfer.user) {
        provider.credits = provider.credits?.length > 0 ? [...provider.credits, transfer.plan || transfer.productTypeCode] : [transfer.plan || transfer.productTypeCode]
      }
      return provider
    }))
  }

  const onInvite = async (_id) => {
    try {
      const provider = await inviteProvider(_id, {
        select,
        populate
      })
      if (provider) {
        setProviders(providers.map(p => p._id === provider._id ? provider : p))
        message.info('Provider invited')
      }
    } catch (err) {
      console.log('err', err)
      message.error('Failed to invite provider')
    }
  }

  const onRemove = async (_id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this provider?',
      content: 'This action cannot be undone and will permanently remove the provider from your system.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: async () => {
        try {
          await removeProvider(_id);
          setProviders(providers.filter(provider => provider._id !== _id));
          message.info('Provider removed');
          setCounts(cachedCounts => {
            return {
              ...cachedCounts,
              providers: cachedCounts.providers - 1
            }
          })
          setFilteredCount(cachedFilterCount => cachedFilterCount-1)
        } catch (err) {
          message.error('Failed to remove provider');
        }
      }
    });
  }

  const getActionItems = (provider) => {
    const { _id, email, hasInvited } = provider

    let menuItems = [{
      key: 'impersonate',
      label: (
        <Button onClick={() => onImpersonate(_id)}>
          <KeyOutlined /> Impersonate
        </Button>
      ),
    },{
      key: '1',
      label: (
        <Button onClick={() => {
          setProviderId(_id)
          setIsEditModalOpen(true)
        }}>
          <EditOutlined /> Edit
        </Button>
      )
    }, {
      key: '3',
      label: (
        <Button onClick={() => {
          setProviderId(_id)
          setIsPasswordModalOpen(true)
        }}>
          <LockOutlined /> Change Password
        </Button>
      )
    }, 
    {
      key: 'transfer',
      label: (
        <Button onClick={() => onTransferModal(_id)}>
          <GiftOutlined /> Gift Credit
        </Button>
      ),
    },  
    {
      type: 'divider'
    }, {
      key: '4',
      label: (
      <Button
          onClick={() => onRemove(_id)}
          className="remove-item"
        >
          <DeleteOutlined /> Remove
        </Button>
      )
    }]

    if (email && !hasInvited) {
      menuItems.unshift({
        key: 'invite',
        label: (
          <Button 
            onClick={() => onInvite(_id)}
          >
            <SendOutlined /> Send Invite
          </Button>
        )
      })
    }

    return menuItems
  }

  const getCustomFilter = (provider, value) => {
    return {
      firstName: () => `${provider.firstName} ${provider.lastName}`.toLowerCase().includes(value.toLowerCase()),
      phone: () => provider.phone && provider.phone.replace(/\D/g,'').includes(value.replace(/\D/g,'')),
      npi: () => provider.npi && provider.npi.replace(/\D/g,'').includes(value.replace(/\D/g,'')),
    }
  }

  return (
    <div className="providers">
      <PageHeader
        title='Providers'
        count={filteredCount}
        actions={(
          <Button
            type='primary'
            onClick={() => {
              setProviderId(null)
              setIsEditModalOpen(true)
            }}
          >
            + Add New Provider
          </Button>
        )}
      />

      <TransferModal
        userId={userId}
        open={openTransferModal}
        setOpen={setOpenTransferModal}
        onSuccess={onTransferSuccess}
      />

      <ProviderForm
        providerId={providerId}
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        select={select}
        populate={populate}
        onSuccess={provider => {
          if (providerId) {
            setProviders(providers.map(p => p._id === providerId ? provider : p))
          } else {
            setProviders([
              provider,
              ...providers
            ])
            setCounts(cachedCounts => {
              return {
                ...cachedCounts,
                providers: cachedCounts.providers + 1
              }
            })
            setFilteredCount(cachedFilterCount => cachedFilterCount+1)
          }
        }}
      />

      <AdminLocationModal
        objectId={providerId}
        objectType={ObjectType.PROVIDER}
        open={isAdminLocationModalOpen}
        setOpen={setIsLocalModalOpen}
        select={select}
        populate={populate}
        onSuccess={provider => {
          setProviders(providers.map(p => p._id === providerId ? provider : p))
        }}
      />

      <ChangePasswordModal
        objectId={providerId}
        objectType={ObjectType.PROVIDER}
        open={isPasswordModalOpen}
        setOpen={setIsPasswordModalOpen}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={providers}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={[{
          dataIndex: 'photo',
          className: "photo-col",
          width: 50,
          render: photo => photo && <Avatar className="user-photo" src={`${photo}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`} />
        }, {
          title: 'Name',
          dataIndex: 'firstName',
          render: (firstName, {lastName }) => `${firstName} ${lastName}`,
          filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: 'Email Address',
          dataIndex: 'email',
          filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: 'Phone Number',
          dataIndex: 'phone',
          filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: 'NPI',
          dataIndex: 'npi',
          filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: 'Credits',
          dataIndex: 'credits',
          render: credits => {
            if (credits?.length > 0) {
              const groupedCredits = credits.reduce((acc, credit)  => {
                if (acc[credit]) {
                  acc[credit] = acc[credit] + 1
                } else {
                  acc[credit] = 1
                }
                return acc
              }, {})
              
              return Object.keys(groupedCredits).map(credit => {
                return `${credit} x ${groupedCredits[credit]}`
              }).join(', ')
            }
          }
        }]}
      />
    </div>
  )
}
import './healthScore.scss'
import { Button, Modal, Progress, Typography } from 'antd';
import { useEffect, useState } from 'react';
import Color from '../../../colors.scss'
import ReportHelper from '../../../helpers/report.helper';
import QuestionTemplateCode from '../../../enums/questionTemplateCode.enum';
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography

export const HealthScore = ({ report, setSectionMeta, section, user }) => {
  const [title, setTitle] = useState()
  const [open, setOpen] = useState()
  const [color, setColor] = useState()

  useEffect(() => {
    setTitle(getTitle())
  }, [report, user])

  useEffect(() => {
    setColor(getColor())
  }, [report])

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title)
        }
      }
    })
  }, [title])

  const GREAT = 90
  const GOOD = 75

  const getColor = () => {
    if (!report) return null
    const { healthScore } = report
    if (healthScore >= GREAT) {
      return Color.success
    } else if (healthScore>=GOOD) {
      return Color.warning
    }
    return Color.error
  }

  const getTitle = () => {
    if (!report || !user) return null

    const { healthPercentile, healthScore, prevReport, lockedAt } = report
    
    if (lockedAt) return parse(section.meta.title)

    if (prevReport && prevReport.healthScore  && healthScore !== prevReport.healthScore) {
      if (healthScore - prevReport.healthScore >= 10) {
        return <><b>Great news!</b> Your longevity score has improved by +{healthScore - prevReport.healthScore} {healthScore - prevReport.healthScore ===1 ? 'point' : 'points'}. This is a major accomplishment.</>
      } else if (healthScore > prevReport.healthScore) {
        return <><b>Good news!</b> Your longevity score has improved by +{healthScore - prevReport.healthScore} {healthScore - prevReport.healthScore === 1 ? 'point' : 'points'} since your last blood draw.</>
      } else {
        return <><b>Heads up!</b> Your longevity score has dropped by {prevReport.healthScore - healthScore} {prevReport.healthScore - healthScore === 1 ? 'point' : 'points'} since your last blood draw.</>
      }
    }

    const isPregnant = ReportHelper.getAnswer(report, QuestionTemplateCode.PREGNANCY)
    if (isPregnant === "true" || isPregnant === true) {
      return <>Don’t stress if your longevity score seems concerning now, as it’s probably due to pregnancy.</>
    } else if (healthScore >=90) {
      return <><b>Great news!</b> Your longevity score is better than {healthPercentile}% of Instalab patients in your age range. Let's see how we can get even better.</>
    } else if (healthPercentile>=50) {
      return <><b>Good news!</b>  Your longevity score is better than {healthPercentile}% of Instalab patients in your age range. Let's see how we can get even better.</>
    } else {
      return <><b>Heads up!</b> Your longevity score is in the {formatPercentileSuffix(healthPercentile)} percentile of Instalab patients in your age range. Let's work on improving that score.</>
    }
  }

  const formatPercentileSuffix = (value) => {
    if (!value) return null
    let j = value % 10,
        k = value % 100;
    if (j === 1 && k != 11) {
        return value + "st";
    }
    if (j === 2 && k != 12) {
        return value + "nd";
    }
    if (j === 3 && k != 13) {
        return value + "rd";
    }
    return value + "th";
  }

  const gapDegree = 150
  const width = window.innerWidth - 90 > 350 ? 350 : window.innerWidth - 90
  const strokeWidth = 5

  return user && report && (

    <div className="health-score">
      <div 
        className="health-score-dashboard"
        style={{ 
          height: width / 2 + 10,
          width,
        }}
      >

        <div className="health-score-value">
          {Math.round(report.healthScore)}
        </div>
        <div className="health-score-footer">
          of 100 points
        </div>

        <div 
          className="dotted-border-container"
          style={{
            height: `${(width + 10)/2}px`,
          }}
        >
          <div 
            className="dotted-border" 
            style={{
              height: `${width - 60}px`,
              width: `${width - 60}px`,
            }}
          />
        </div>
        
        <div className="progress-container">
          <Progress 
            type="dashboard" 
            percent={report.healthScore}
            gapDegree={gapDegree}
            strokeColor={color}
            trailColor={Color.secondary_bg}
            width={width}
            strokeWidth={strokeWidth}
            showInfo={false}
          />
        </div>
      </div>

      <div className="health-score-content">
        <Paragraph className="health-score-title">
          {title}
        </Paragraph>

        <Button
          className='health-score-btn'
          onClick={() => setOpen(true)}
        >
          What does your longevity score mean?
        </Button>
      </div>

      <Modal
        title='Your longevity score'
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        className="health-score-modal"
      >
        <Paragraph>
          The longevity score, a scale from 0 to 100, is a comprehensive measure of your overall health and its potential impact on both your lifespan and healthspan. The higher your score, the closer you are to optimal health and the more potential you may have for disease-free years.
          <br/><br/>The score is calculated using a statistical algorithm that considers factors such as your biomarker levels, family and medical history, and current lifestyle. It asks the question: if everything stayed the same from today onward, how much are you at risk for aging-related diseases, including cardiovascular disease, diabetes, hypertension, dementia, chronic kidney disease, and more. By making changes to improve these factors, you can change the trajectory of your longevity score significantly.
          <br/><br/>Your percentile is determined by comparing scores with other Instalab users of the same age and biological sex.
        </Paragraph>
      </Modal>
    </div>
  )
}
import React, { useState, useEffect, useContext } from 'react'
import { Row, Col, Form, Input, Typography, Button, Select, message } from 'antd'
import { useNavigate, useParams } from 'react-router-dom'
import "./join.scss";
import {  ArrowRightOutlined } from '@ant-design/icons'
import MaskedInput from 'antd-mask-input'
import { UserContext } from '../../contexts/user.context';
import { getJoinableAppointment, joinAppointment } from "../../services/appointment.service";
import ValidateHelper from "../../helpers/validate.helper";
import { acceptInvite, getInvite } from '../../services/invite.service';
import dayjs from 'dayjs'
import { Step as InviteStep } from '../../components/inviteFlow/inviteFlow.component'
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";
import classNames from 'classnames';
import Breakpoint from "../../enums/breakpoint.enum";
import FlowType from '../../enums/flowType.enum';
import Gender from '../../enums/gender.enum';
import tzLookup from 'tz-lookup';
import useWidth from '../../hooks/useWidth.hook';
import StorageKey from '../../enums/storageKey.enum';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(weekday);
dayjs.extend(localeData);

const { Item } = Form
const { Paragraph } = Typography

export const Join = () => {
  const navigate = useNavigate()
  const { appointmentId, inviteId } = useParams()
  const { currentUser,token, setToken } = useContext(UserContext)
  const width = useWidth()
  const [form] = Form.useForm()
  const [appointment, setAppointment] = useState()
  const [isSubmitting, setIsSubmitting] = useState()
  const [locationTimezone, setLocationTimezone] = useState('UTC'); 

  useEffect(() => {
    fetchAppointment()
  }, [appointmentId])

  useEffect(() => {
    fetchInvite()
  }, [inviteId])

  useEffect(() => {
    if (appointment && appointment.location) {
      fetchTimezoneForLocation(appointment.location);
    }
  }, [appointment]);

  const fetchAppointment = async () => {
    if (!appointmentId) return
    setAppointment(await getJoinableAppointment(appointmentId, {
      populate: [{
        path: 'patients',
        select: '_id firstName'
      }]
    }))
  }

  const fetchInvite = async () => {
    if (!inviteId) return
    const {
      phone,
      appointment
    } = await getInvite(inviteId)

    form.setFieldsValue({
      phone,
    })

    setAppointment(appointment)
  }

  const hasFormError = async () => {
    try {
      await form.validateFields();
    } catch (errors) {
      return true
    }
    return false
  }

  const onAccept = async () => {
    setIsSubmitting(true)
    try {
      if (await hasFormError()) {
        message.error('Enter valid account information')
        setIsSubmitting(false)
        return 
      }

      let response;
      
      if (token) {
        const params = { userId: currentUser._id }
        response = await joinAppointment(appointmentId, params);
        message.success('Joined event!');
      } else {
        const {
          email,
          phone,
          firstName,
          lastName,
          password,
          gender,
          dob,
        } = form.getFieldsValue()
  
        const params = {
          firstName,
          lastName,
          email,
          phone,
          password,
          gender,
          dob,
        }
  
        if (inviteId) {
          response = await acceptInvite(inviteId, params)
        } else {
          response = await joinAppointment(appointmentId, params)
        }
        localStorage.setItem(StorageKey.TOKEN, response.token)
        setToken(response.token)
        message.success('Instalab account created')
      }

      const url = `/flow/${FlowType.INVITE}/${InviteStep.CONFIRM}/${response.flow._id}`
      navigate(url)
    } catch (err) {
      let msg = 'Failed to join appointment'
      if (err.response?.data?.code === 11000) {
        msg = 'Account already exists with this email'
      }
      message.error(msg)
    }
    setIsSubmitting(false)
  }

  
  const onLogin = () => {
    navigate(`/login`);
  };

  
  const fetchTimezoneForLocation = (location) => {
    if (location && location.latitude && location.longitude) {
      try {
        const timezone = tzLookup(location.latitude, location.longitude);
        setLocationTimezone(timezone);
      } catch (error) {
        console.log('Failed to fetch timezone information.');
      }
    }
  };

  const convertTimeZone = (time) => {
    const formattedDate = dayjs(time).tz(locationTimezone);
    return formattedDate;
  };

  return appointment && (
    <div 
      className={classNames("checkout-v2", "join")}
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100svh - 120px)' : 'calc(100svh - 94px)'
      }}
    >
      <div className="grey-bg" />

      <Paragraph className="join-title">
        Accept Invite
      </Paragraph>
      <Paragraph className="join-description">
        {appointment.patients[0].firstName} invited you to a blood draw appointment on {convertTimeZone(appointment.start).format('MMM D, YYYY h:mm a z')}. Location is {appointment.location.streetAddress} in {appointment.location.city}.
        
        {!token && <> Create your Instalab account to confirm you will join. If you already have an account, <a onClick={onLogin}>log in</a>.</>}
      </Paragraph>

      {!token && 
      <Form
          form={form}
          layout='vertical'
          className='account-form'
        >
          <Row gutter={12}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="First Name"
                name="firstName"
                rules={[{ 
                  required: true, 
                  message: 'Enter first name'
                }]}
              >
                <Input placeholder="Enter first name" />
              </Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Last Name"
                name="lastName"
                rules={[{ 
                  required: true, 
                  message: 'Enter last name'
                }]}
              >
                <Input placeholder="Enter last name" />
              </Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Email Address"
                name="email"
                rules={[{ 
                  required: true, 
                  message: 'Enter email address'
                }, {
                  message: 'Enter valid email address',
                  validator: (_, value) => {
                    if (!value || ValidateHelper.email(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject('Enter valid email address');
                    }
                  }
                }]}
              >
                <Input placeholder="Enter email address" />
              </Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Phone Number"
                name="phone"
                rules={[{ 
                  required: true, 
                  message: 'Enter phone number'
                }]}
              >
                <MaskedInput 
                  placeholder={'(XXX) XXX-XXXX'}
                  mask="(000) 000-0000"
                  inputMode="numeric"
                />
              </Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Sex at Birth"
                name="gender"
                rules={[{ 
                  required: true, 
                  message: 'Enter sex'
                }]}
              >
                <Select
                  placeholder="Select sex"
                  showSearch
                  options={Object.values(Gender).map(gender => {
                    return {
                      label: gender,
                      value: gender,
                    }
                  })}
                />
              </Item>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Date of Birth"
                name="dob"
                rules={[{ 
                  required: true, 
                  message: 'Enter date of birth'
                }]}
              >
                <MaskedInput 
                  placeholder={'MM/DD/YYYY'}
                  mask="00/00/0000"
                  inputMode="decimal"
                />
              </Item>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <Item 
                label="Password"
                name="password"
                rules={[{ 
                  required: true, 
                  message: 'Enter password'
                }]}
              >
                <Input 
                  placeholder="Enter password" 
                  type="password"
                />
              </Item>
            </Col>
          </Row>
      </Form>}

      <div className="checkout-btn-container">
        <div className="checkout-btn-list">
          <Button 
            className="join-btn"
            type='primary'
            onClick={onAccept}
            loading={isSubmitting}
          >
            Join Appointment
            {!isSubmitting && <ArrowRightOutlined />}
          </Button>
        </div>
      </div>
    </div>
  )
}
import { useState, useEffect, useRef, useContext } from "react"
import StepType from "../flow/enums/stepType.enum"
import { Flow } from "../flow/flow.component"
import { useNavigate, useParams } from 'react-router-dom'
import { listProductTypes } from "../../services/productType.service"
import ProductTypeCode from "../../enums/productTypeCode.enum"
import Color from '../../colors.scss';
import Gender from "../../enums/gender.enum"
import MedicalCondition from "../../enums/medicalCondition.enum"
import Ethnicity from "../../enums/ethnicity.enum"
import { UserContext } from "../../contexts/user.context"
import { PopupModal } from "react-calendly";
import FlowType from "../../enums/flowType.enum"
import { Typography } from "antd"
import "./heartHealthIntake.scss";

const { Text } = Typography

export const Step = {
  ACCOUNT: 'account',
  PAY: 'pay',
  NAME: 'name',
  GENDER: 'gender',
  DOB: 'dob',
  ETHNICITY: 'ethnicity',
  FAMILY_HISTORY: 'family-history',
  MEDICAL_CONDITIONS: 'medical-conditions',
  BP_MONITOR: 'bp',
  SHIPPING: 'shipping',
  SCHEDULE: 'schedule',
  CONFIRM: 'confirm'
}

const heartHealthConditions = [
  MedicalCondition.HIGH_BLOOD_PRESSURE,
  MedicalCondition.TYPE_1_DIABETES,
  MedicalCondition.TYPE_2_DIABETES,
  MedicalCondition.HEART_ATTACK,
  MedicalCondition.STROKE,
  MedicalCondition.HEART_DISEASE,
  MedicalCondition.HIGH_CHOLESTEROL,
]

export const HeartHealthIntakeFlow = () => {
  const { flowId } = useParams()
  const [flow, setFlow] = useState()
  const [skeleton, setSkeleton] = useState()
  const [hasPass, setHasPass] = useState()
  const [productTypes, setProductTypes] = useState()
  const { currentUser } = useContext(UserContext)
  const [open, setOpen] = useState()
  const navigate = useNavigate()

  const [initialUser, setInitialUser] = useState()
  const initialUserRef = useRef()
  initialUserRef.current = initialUser

  useEffect(() => {
    fetchSkeleton()
  }, [initialUser, hasPass, productTypes, flow])

  useEffect(() => {
    fetchProductTypes()
  }, [])

  useEffect(() => {
    resetFlowId()
  }, [flowId])

  const getProductType = (code) => {
    return productTypes?.find(productType => productType.code === code)
  }

  const onChat = () => {
    if (currentUser) {
      window.FrontChat("identity", {
        email: `${currentUser.email}`,
      });
    }
    window.FrontChat("show");
  };

  const fetchProductTypes = async () => {
    setProductTypes(await listProductTypes({
      filter: {
        code: {
          $in: [
            ProductTypeCode.HEART_BASELINE,
          ]
        }
      }
    }))
  }

  const resetFlowId = () => {
    if (!flowId) return 
  }

  const fetchSkeleton = () => {
    setSkeleton({

      [Step.ACCOUNT]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.PAY,
        buildUser: true,
        title: `First, let's create your Instalab acccount.`,
        description: <>Already have an account? <a className="secondary-link" onClick={() => navigate(`/login?redirect=/flow/${FlowType.HEART_HEALTH_INTAKE}`)}>Log in</a>.</>, 
        fields: [{
          name: 'email',
          label: 'Email Address',
          placeholder: 'Type your email here...',
          email: true,
          required: true,
        }, {
          name: 'password',
          label: 'Password',
          placeholder: 'Type your password here...',
          password: true,
          required: true,
        }],
        skipIf: () => {
          return hasPass
        }
      },
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.SHIPPING,
        addProduct: true,
        productType: ProductTypeCode.HEART_BASELINE,
        title: <>Pay <span className="true-price">${getProductType(ProductTypeCode.HEART_BASELINE)?.cost}</span> for the Heart Health Baseline Assessment.</>,
        benefits: [
          <><Text className="bullet-highlight">At-home testing:</Text> Receive a painless blood test kit and blood pressure monitor.</>,
          <><Text className="bullet-highlight">Comprehensive results:</Text> Key heart health markers like ApoB, Lp(a), and HbA1c tested.</>,
          <><Text className="bullet-highlight">Expert guidance:</Text> Consult with a physician to create a personalized care plan.</>,
        ],
      },
      [Step.SHIPPING]: {
        type: StepType.LOCATION,
        buildUser: true,
        nextStep: Step.NAME,
        title: `What is your shipping address?`,
        description: `You will receive your blood test kit, blood pressure monitor, and medication here.`,
        field: {
          name: 'shippingLocation'
        },
      },
      [Step.NAME]: {
        type: StepType.MULTIPLE_INPUT,
        nextStep: Step.GENDER,
        buildUser: true,
        title: `What's your name?`,
        fields: [{
          name: 'firstName',
          placeholder: 'First Name',
          required: true,
        }, {
          name: 'lastName',
          placeholder: 'Last Name',
          required: true,
        }],
        skipIf: () => {
          return (initialUser?.firstName && initialUser?.lastName)
        }
      },
      [Step.GENDER]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.DOB,
        buildUser: true,
        title: `What's your sex assigned at birth?`,
        field: {
          name: 'gender',
          options: [{
            label: 'Male',
            value: Gender.MALE,
          }, {
            label: 'Female',
            value: Gender.FEMALE,
          }]
        },
        skipIf: () => {
          return initialUser?.gender
        }
      },
      [Step.DOB]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.ETHNICITY,
        buildUser: true,
        title: `What's your date of birth?`,
        description: `Enter in the format of MM/DD/YYYY.`,
        field: {
          name: 'dob',
          placeholder: 'MM/DD/YYYY',
          date: true,
          inputMode: 'numeric',
          required: true,
        },
        skipIf: () => {
          return initialUser?.dob
        }
      },
      [Step.ETHNICITY]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.FAMILY_HISTORY,
        buildUser: true,
        title: `What is your ethnicity?`,
        description: 'Select all that apply.',
        field: {
          name: 'ethnicity',
          options: Object.values(Ethnicity).map(value => ({label: value, value: value}))
        },
        skipIf: () => {
          return initialUser?.ethnicity
        }
      },
      [Step.FAMILY_HISTORY]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.MEDICAL_CONDITIONS,
        buildUser: true,
        title: <>Which medical conditions has your immediately family been diagnosed with <u>before age 60</u>?</>,
        description: 'This includes any grandparents, parents, or siblings. Select all that apply.',
        field: {
          name: 'familyHistory',
          options: Object.values(MedicalCondition)
                          .filter(value => heartHealthConditions.includes(value))
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label))
        },
      },
      [Step.MEDICAL_CONDITIONS]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.BP_MONITOR,
        buildUser: true,
        title: <>Which medical conditions have you been diagnosed with?</>,
        field: {
          name: 'heartHealthConditions',
          options: Object.values(MedicalCondition)
                          .filter(value => heartHealthConditions.includes(value))
                          .map(value => ({label: value, value: value}))
                          .sort((a, b) => a.label.localeCompare(b.label))
        },
      },
      [Step.BP_MONITOR]: {
        type: StepType.SINGLE_SELECT,
        nextStep: Step.CONFIRM,
        buildUser: true,
        title: `Do you have a blood pressure monitor?`,
        field: {
          name: 'hasBloodPressureMonitor',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        },
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        title: `Next steps in your Heart Health Baseline Assessment:`,
        description: <>
          Your at-home blood test kit will arrive in less than a week. Once you complete the tests, your physician will review the results and work with you to create a personalized care plan.
          <br/><br/>
          If you have any questions, please <a onClick={onChat}>send us a message.</a>
        </>,
        content: null,
        buttonText: 'Back to Dashboard',
        path: `/`,
      }
    })
  }

  return skeleton && (
    <div className="heart-health-intake">
      <PopupModal
        open={open}
        url="https://calendly.com/d/cp8q-hch-fvg/heart-health-intro-call"
        onModalClose={() => setOpen(false)}
        rootElement={document.getElementById("root")}
        pageSettings={{
          hideEventTypeDetails: true,
          hideLandingPageDetails: true,
          primaryColor: Color.success,
        }}
        prefill={{
          firstName: currentUser?.firstName ? currentUser.firstName : '',
          lastName: currentUser?.lastName ? currentUser.lastName : '',
          name: currentUser?.firstName ? `${currentUser.firstName} ${currentUser.lastName}` : '',
          email: currentUser?.email ? currentUser.email : '',
        }}
      />

      <Flow 
        skeleton={skeleton} 
        flow={flow}
        setFlow={setFlow}
        startIndex={1}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
        setHasPass={setHasPass}
      />
    </div>
  )
}
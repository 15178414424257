import React, { useState, useEffect } from "react";
import { Typography } from "antd";
import "./generalExerciseAdvice.scss";
import { PlayCircleFilled } from "@ant-design/icons";
import ReactPlayer from "react-player";
import MedicalCondition from "../../../enums/medicalCondition.enum";
import { renderToString } from 'react-dom/server'
import parse from 'html-react-parser';

const { Paragraph } = Typography;

export const GeneralExerciseAdvice = ({ report, setSectionMeta, section, user }) => {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();

  useEffect(() => {
    if (!user) return;
    setDescription(getDescription());
  }, [report, user]);

  useEffect(() => {
    setTitle(getTitle());
  }, [report]);

  useEffect(() => {
    setSectionMeta(sectionMeta => {
      return {
        ...sectionMeta,
        [section.code]: {
          title: renderToString(title),
          description: renderToString(description),
        }
      }
    })
  }, [title, description])

  const getTitle = () => {
    if (report?.lockedAt) return parse(section.meta.title)
    return <>It's recommended you exercise at least <span>3 hours per week</span> to further lower your risk for cardiovascular disease.</>
  }

  const getDescription = () => {
    if (report?.lockedAt) return parse(section.meta.description)

    const { familyHistory } = user;
    const cardioConditions = [
      MedicalCondition.HEART_DISEASE,
      MedicalCondition.STROKE,
      MedicalCondition.BLOCKED_ARTERY,
    ];

    if (
      familyHistory &&
      familyHistory.some((condition) => cardioConditions.includes(condition))
    ) {
      return `Even though you're in good health, it's worth noting your family history puts you at a higher risk for heart disease and stroke. Maintaining an active lifestyle is one of the most effective ways to reduce this risk.`;
    }
    return `It's amazing how much of a difference regular exercise can make. This relatively small time commitment can lead to significant improvements to your cholesterol levels and overall heart function.`;
  };

  return (
    <div className="general-exercise-advice">
      <Paragraph className="report-template-title">
        {title}
      </Paragraph>
      <Paragraph className="report-template-description">
        {description}
      </Paragraph>

      <div className="video-container">
        <ReactPlayer
          url="https://vimeo.com/823803748"
          className="video-player"
          light={(
            <picture>
              <source srcSet='/img/steven_2.webp' type="image/webp" />
              <source srcSet='/img/steven_2.jpg' type="image/jpeg" />
              <img src='/img/steven_2.jpg' alt="advice giver" style={{ height: '100%', width: '100%', objectFit: 'cover', position: 'absolute', top: 0, left: 0 }} />
            </picture>
          )}
          controls={true}
          playing
          width="100%"
          height="100%"
          playIcon={<PlayCircleFilled className="play-icon" />}
        />
      </div>
    </div>
  );
};

import axios from 'axios'
import ServiceHelper from '../helpers/service.helper'

export const addProduct = async (params) => {
  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/v1/products`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const updateProduct = async (id, params) => {
  const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/v1/products/${id}`, ServiceHelper.getParams(params), {
    headers: ServiceHelper.getHeaders()
  })
  return response.data
}

export const listProducts = async (params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/products`, {
    params: ServiceHelper.getParams(params),
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const getProduct = async (id, params={}) => {
  const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/v1/products/${id}`, {
    params: ServiceHelper.getParams(params),
    headers: ServiceHelper.getHeaders(),
  })
  return response.data
}

export const removeProduct = async (id) => {
  const response = await axios.delete(`${process.env.REACT_APP_API_URL}/api/v1/products/${id}`, {
    headers: ServiceHelper.getHeaders(),
    params: ServiceHelper.getParams(),
  })
  return response.data
}
import React, { useContext, useEffect, useState } from 'react';
import { Button, Typography, Tooltip, message, Modal } from 'antd'
import { addAppointmentCharge, addAppointmentPaymentLink, listAppointments, removeAppointment } from "../../services/appointment.service"
import { CalendarOutlined, CreditCardOutlined, CopyOutlined, LoadingOutlined, EnvironmentOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { PageHeader } from '../pageHeader/pageHeader.component';
import ObjectType from '../../enums/objectType.enum';
import { AdminLocationModal } from '../adminLocationModal/adminLocationModal.component.js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import "./adminAppointmentsTable.scss";
import AppointmentStatus from '../../enums/appointmentStatus.enum';
import classNames from 'classnames';
import { Step } from '../proDrawFlow/proDrawFlow.component.js'
import UrlHelper from '../../helpers/url.helper.js';
import { ModalForm } from '../modalForm/modalForm.component.js';
import FieldType from '../modalForm/enums/fieldType.enum.js';
import RuleHelper from '../../helpers/rule.helper.js';
import FlowType from '../../enums/flowType.enum.js';
import { useNavigate, useParams } from 'react-router-dom';
import { addProxyFlow } from '../../services/flow.service.js';
import Role from '../../enums/role.enum.js';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { UserContext } from '../../contexts/user.context.js';
import PanelName from '../../enums/panelName.enum.js';
import ChargeStatus from '../../enums/chargeStatus.enum.js';
import { AdminAppointmentForm } from '../adminAppointmentForm/adminAppointmentForm.component.js';

dayjs.extend(utc)
dayjs.extend(timezone)

const { Text } = Typography

const select = 'status patients location phlebotomist start flow'
const populate = [{
  path: 'patients',
  select: '_id firstName lastName'
}, {
  path: 'phlebotomist',
  select: 'firstName'
}, {
  path: 'orders',
  select: 'panel'
}, {
  path: 'products',
  select: '_id',
  populate: {
    path: 'charge',
    select: 'status'
  }
}]

export const AdminAppointmentsTable = ({ hasPatient=false }) => {
  const { patientId } = useParams()
  const navigate = useNavigate()
  const [appointments, setAppointments] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [appointmentId, setAppointmentId] = useState()
  const [isCharging, setIsCharging] = useState()
  const [openModal, setOpenModal] = useState()
  const [isLocationModalOpen, setIsLocationModalOpen] = useState()
  const [fields, setFields] = useState()
  const { currentUser, setCounts } = useContext(UserContext)
  const [openAdminAppointmentForm, setOpenAdminAppointmentForm] = useState()
  const [filteredCount, setFilteredCount] = useState();

  useEffect(() => {
    fetchAppointments()
  }, [hasPatient, patientId])

  const fetchAppointments = async () => {
    setIsLoading(true)
    if (hasPatient && !patientId) return

    let params = {
      select,
      filter: {
        status: {
          $in: [
            AppointmentStatus.PENDING,
            AppointmentStatus.COLLECTED,
            AppointmentStatus.COMPLETE,
            AppointmentStatus.CONFIRMED,
          ]
        }
      },
      populate,
      sort: '-start'
    }

    if (hasPatient) {
      params.filter = {
        patients: {
          $in: [patientId]
        }
      }
    }
    const fetchedAppointments = await listAppointments(params)
    setAppointments(fetchedAppointments)
    setFilteredCount(fetchedAppointments.length)
    setIsLoading(false)
  }

  const onRemove = async (_id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this appointment?',
      content: 'Deleting this appointment cannot be undone.',
      okText: 'Yes, delete it',
      okType: 'danger',
      cancelText: 'No, keep it',
      onOk: async () => {
        try {
          await removeAppointment(_id);
          setAppointments(appointments.filter(appointment => appointment._id !== _id));
          message.info('Appointment removed');
          setCounts(cachedCounts => {
            return {
              ...cachedCounts,
              draws: cachedCounts.draws - 1
            }
          })
          setFilteredCount(cachedFilterCount => cachedFilterCount-1)
        } catch (err) {
          message.error('Failed to remove appointment');
        }
      }
    });
  }

  const onChargeAppointment = async (_id) => {
    setIsCharging(_id)
    try {
      const appointment = await addAppointmentCharge(_id, {
        select,
        populate
      })
      setAppointments(appointments.map(a => a._id === appointment._id ? appointment : a))
      message.success('Card charged')
    } catch (err) {
      message.error('Failed to charge card')
    }
    setIsCharging(null)
  }

  const getActionItems = (appointment) => {
    const { _id, flow, start } = appointment

    let menuItems = [];

    const isFuture = AppointmentStatus.CONFIRMED && new Date(start) > new Date()
    
    if (flow && isFuture) {
      if (currentUser?.role === Role.PROVIDER) {
        menuItems.push({  
          key: 'reschedule',
          label: (
            <Button onClick={() => {
              navigate(`/pro-flow/${FlowType.PRO_DRAW}/${Step.SCHEDULE}/${flow}`)
            }}>
              <CalendarOutlined /> Reschedule
            </Button>
          )
        }) 
      } else {
        menuItems.push({  
          key: 'edit',
          label: (
            <Button onClick={() => {
              setAppointmentId(_id);
              setOpenAdminAppointmentForm(true)
            }}>
              <CalendarOutlined /> Reschedule
            </Button>
          )
        }) 
      }
    }
    
    if (appointment.products?.some(product => !product.charge || product.charge.status === ChargeStatus.PENDING)) {
      menuItems.push({  
        key: 'payment-link',
        label: (
          <Button onClick={async () => {
            const response = await addAppointmentPaymentLink(_id, {
              seelct: '_id'
            })
            navigator.clipboard.writeText(response.url)
            message.info('Copied payment link')
          }}>
            <CopyOutlined /> Copy Payment Link
          </Button>
        )
      })
    }

    if (currentUser?.role === Role.ADMIN) {
      menuItems.push({
        key: 'status',
        label: (
          <Button onClick={() => {
            setFields([{
              label: 'Status',
              name: 'status',
              fieldType: FieldType.SELECT,
              rules: [RuleHelper.isRequired],
              options: Object.values(AppointmentStatus).map(value => {
                return {
                  label: value,
                  value,
                }
              })
            }])
            setAppointmentId(_id)
            setOpenModal(true)
          }}>
            <EditOutlined /> Change Status
          </Button>
        )
      })

      switch (appointment.status) {
        case AppointmentStatus.CONFIRMED:
          menuItems.push({
            key: 'location',
            label: (
              <Button onClick={() => {
                setAppointmentId(_id)
                setIsLocationModalOpen(true)
              }}>
                <EnvironmentOutlined /> Set Location
              </Button>
            )
          })
          menuItems.push({
            type: 'divider'
          })
          menuItems.push({
            key: '5',
            label: (
              <Button
                onClick={() => onRemove(_id)}
                className="remove-item"
              >
                <DeleteOutlined /> Remove
              </Button>
            )
          })
          break
        case AppointmentStatus.COLLECTED:
          menuItems.push({
            key: 'charge',
            label: (
              <Button onClick={(e) => {
                e.stopPropagation()
                if (isCharging !== _id) {
                  onChargeAppointment(_id)
                }
              }}>
                {isCharging === _id ? (
                  <Text className="charging-text">
                    <LoadingOutlined style={{marginRight:5}} /> Charging...
                  </Text>
                ) : <>
                  <CreditCardOutlined style={{marginRight:5}} /> Charge Card
                </>}
              </Button>
            )
          })
          break
      }
    }
  
    return menuItems;
  }

  const getCustomFilter = (appointment, value) => {
    return {
      patients: () => appointment.patients?.some(patient => `${patient.firstName} ${patient.lastName}`.toLowerCase().includes(value.toLowerCase())),
      phlebotomist: () => appointment.phlebotomist && appointment.phlebotomist.firstName.toLowerCase().includes(value.toLowerCase()),
      location: () => appointment.location.city.toLowerCase().includes(value.toLowerCase())
    }
  }

  const onAppointmentUpdate = updatedAppointment => {
    if (appointments.some(({ _id }) => _id === updatedAppointment._id)) {
      setAppointments(appointments.map(a => a._id === updatedAppointment._id ? updatedAppointment : a))
    } else {
      setAppointments([
        updatedAppointment,
        ...appointments,
      ])
    }
  }

  const onAddAppointment = async () => {
    switch (currentUser?.role) {
      case Role.ADMIN:
        setOpenAdminAppointmentForm(true)
        break
      case Role.PROVIDER:
        openProFlow()
        break
    }
  }

  const openProFlow = async () => {
    let params = {
      type: FlowType.PRO_DRAW
    }
    if (hasPatient) {
      params.user = patientId
    }
    const response = await addProxyFlow(params)
    navigate(`/pro-flow/${FlowType.PRO_DRAW}/${Object.values(Step)[0]}/${response._id}`)
  }

  return (
    <div className="admin-appointments-table">
      <ModalForm
        open={openModal}
        setOpen={setOpenModal}
        fields={fields}
        populate={populate}
        select={select}
        objectType={ObjectType.APPOINTMENT}
        objectId={appointmentId}
        onSuccess={onAppointmentUpdate}
      />

      {currentUser?.role === Role.ADMIN && (
        <AdminAppointmentForm
          open={openAdminAppointmentForm}
          setOpen={setOpenAdminAppointmentForm}
          appointmentId={appointmentId}
          setAppointmentId={setAppointmentId}
          populate={populate}
          select={select}
          onSuccess={onAppointmentUpdate}
        />
      )}

      <AdminLocationModal
        objectId={appointmentId}
        objectType={ObjectType.APPOINTMENT}
        open={isLocationModalOpen}
        populate={populate}
        select={select}
        setOpen={setIsLocationModalOpen}
        onSuccess={onAppointmentUpdate}
      />

      <PageHeader
        title='Appointments'
        count={filteredCount}
        actions={
          <Button
            type="primary"
            onClick={onAddAppointment}
          >
            + Schedule Blood Draw
          </Button>
        }
      />

      <FlexibleTable
        isLoading={isLoading}
        records={appointments}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={[{
          title: 'Status',
          dataIndex: 'status',
          width: 110,
          render: status => <Text className={classNames(`${status}-status`, "appointment-status")}>{status}</Text>,
          filterDropdownType: FilterDropdownType.CHECKBOX,
          filterOptions: Object.values(AppointmentStatus)
        }, 
        {
          title: 'Panel',
          dataIndex: 'orders',
          render: orders => {
            const panels = [...new Set(orders.map(({ panel }) => PanelName[panel] || panel))]
            return panels.reduce((acc, panel) => {
              const count = orders.filter(order => {
                const panelName = PanelName[order.panel] || order.panel
                return panelName === panel
              }).length
              acc.push(<><Text className="order-panel">{panel}</Text>{count > 1 && <Text className="panel-count"> (x{count})</Text>}</>)
              return acc
            }, []).map((item, index) => <>
              {index > 0 && ', '}{item}
            </>)
          }
        },
        !hasPatient && {
          title: 'Patients',
          dataIndex: 'patients',
          filterDropdownType: FilterDropdownType.INPUT,
          render: (patients) => {
            return patients.map((patient, index) => {
              return (
                <span key={patient._id}>
                  {index !== 0 && ', '} 
                    <a 
                      className="patient-name"
                      onClick={() => navigate(UrlHelper.getPatientProfile(patient._id, 'Appointments'))}
                    >
                      {patient.firstName} {patient.lastName}
                    </a>
                </span>
              )
            })
          }
        }, {
          title: 'Location',
          dataIndex: 'location',
          render: (location) => {
            if (!location) return null
            const { streetAddress, streetAddress2, city, state, postalCode } = location
            const fullAddress = `${streetAddress}${streetAddress2 ? ' ' + streetAddress2 : ''}, ${city}, ${state} ${postalCode}`
            return <Tooltip title={fullAddress}>{city}</Tooltip>
          },
          filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: 'Phlebotomist',
          dataIndex: 'phlebotomist',
          render: phlebotomist => phlebotomist && phlebotomist.firstName,
          filterDropdownType: FilterDropdownType.INPUT
        }, {
          title: 'Start',
          dataIndex: 'start',
          render: (start, { location }) => {
            start = dayjs(start).tz(location.timeZoneId)
            return <>
              {start.format('MMM D, YYYY')} &nbsp; {start.format('h:mm a z')}
            </>
          }
        }]}
      />
    </div>
  )
}
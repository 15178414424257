import React, { useState, useEffect, useRef, useMemo } from 'react'
import { Form, Select, message, Tooltip, Input, Button } from 'antd'
import "./contentForm.scss"
import { addContent, getContent, updateContent } from '../../services/content.service';
import { listAuthors } from '../../services/author.service'

import ReactQuill, { Quill } from "react-quill-with-table";
import QuillBetterTable from "quill-better-table";
import katex from "katex";
import "react-quill-with-table/dist/quill.snow.css";
import "quill-better-table/dist/quill-better-table.css";
import "katex/dist/katex.min.css";
import { useNavigate, useParams } from 'react-router-dom'
import { PageHeader } from '../pageHeader/pageHeader.component'
import { ContinueButton } from '../continueButton/continueButton.component';
// Quill.register({ "modules/better-table": QuillBetterTable });
window.katex = katex;

const { Item } = Form

export const ContentForm = () => {
  const { contentId } = useParams()
  const reactQuillRef = useRef(null);
  const [editorState, setEditorState] = useState("");
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [authors, setAuthors] = useState()
  const [isSubmitting, setIsSubmitting] = useState()
  const [image, setImage] = useState(null)
  const [showPicker, setShowPicker] = useState()

  useEffect(() => {
    if (contentId) {
      setContentFields()
    } else {
      form.resetFields()
    }
  }, [contentId])

  useEffect(() => {
    fetchAuthors()
  }, [])
  
  const insertTable = () => {
    const editor = reactQuillRef.current.getEditor();
    const tableModule = editor.getModule("better-table");
    tableModule.insertTable(3, 3);
  };

  useEffect(() => {
    const editor = reactQuillRef.current.getEditor();
    const toolbar = editor.getModule("toolbar");
    toolbar.addHandler("table", () => {
      insertTable();
    });
  }, []);

  const handleEditorStateChange = (val) => {
    setEditorState(val);
  };

  const modules = useMemo(
    () => ({
      table: false,
      "better-table": {
        operationMenu: {
          items: {
            unmergeCells: {
              text: "Another unmerge cells name"
            }
          }
        }
      },
      keyboard: {
        bindings: QuillBetterTable.keyboardBindings
      },
      toolbar: [
        [
          "bold",
          "italic",
          "underline",
          "strike",
          "blockquote",
          { list: "ordered" },
          { list: "bullet" },
        ], // toggled buttons
      ],
      clipboard: {
        matchVisual: false,
      },
    }),
    []
  );

  const fetchAuthors = async () => {
    setAuthors(await listAuthors())
  }

  const setContentFields = async () => {
    const contentFields = await getContent(contentId)

    const {
      publishedAt,
      askedBy,
      answeredBy,
      video,
      title,
      body,
    } = contentFields

    if (body) setEditorState(body)

    form.setFieldsValue({
      publishedAt,
      askedBy: askedBy?._id,
      answeredBy: answeredBy?._id,
      video,
      title,
    })
  }

  const onOk = async () => {
    setIsSubmitting(true)
    try {
      const {
        publishedAt,
        askedBy,
        answeredBy,
        video,
        title,
      } = form.getFieldsValue()
      
      const params = {
        publishedAt,
        askedBy,
        answeredBy,
        video,
        title,
        body: editorState,
      }

      contentId ? await updateContent(contentId, params) : await addContent(params)
      message.success(contentId ? 'Content post updated' : 'Content post added')
      navigate('/admin/contents')
    } catch (err) {
      let msg = contentId ? 'Failed to update content post' : 'Failed to add content post'
      message.error(msg)
    }
    setIsSubmitting(false)
  }

  return (
    <div className="content-form">
      <PageHeader
        title={contentId ? 'Edit Content' : 'Add Content'}
      />
    
      <Form
        form={form}
        onFinish={onOk}
        layout='vertical'
        className="form-container"
      >
        <Item label="Answered" name="answeredBy">
          <Select 
            optionFilterProp="children"
            options={authors?.map(author => {
              return {
                label: author.name,
                value: author._id
              }
            })}
          />
        </Item>

        <Item label="Asked" name="askedBy">
          <Select 
            optionFilterProp="children"
            options={authors?.map(author => {
              return {
                label: author.name,
                value: author._id
              }
            })}
          />
        </Item>

        <Item label="Title" name="title">
          <Input />
        </Item>

        <Item label="Video" name="video">
          <Input placeholder="Youtube URL" />
        </Item>
      </Form>

      <label className="body-label">Body</label>
      <ReactQuill
        ref={reactQuillRef}
        modules={modules}
        theme="snow"
        onChange={handleEditorStateChange}
        value={editorState}
      />

      <div className="save-btn-container">
        <ContinueButton
          onClick={onOk}
          text='Save Content'
          isSubmitting={isSubmitting}
        />
      </div>
    </div>
  )
}
import React, { useContext, useEffect, useState } from 'react';
import FilterDropdownType from '../../enums/filterDropdownType.enum.js';
import { EditOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Modal, Form, message, Input, Button } from 'antd';
import { listFacilities, updateFacility, addFacility, getFacility } from "../../services/facility.service.js";
import './adminFacilities.scss';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { ContinueButton } from '../continueButton/continueButton.component';
import { AdminLocationModal } from '../adminLocationModal/adminLocationModal.component';
import ObjectType from '../../enums/objectType.enum';
import { FlexibleTable } from '../flexibleTable/flexibleTable.component.js';
import { UserContext } from '../../contexts/user.context.js';

const { Item } = Form;
const select = '_id name location'

const FacilityForm = ({ open, setOpen, facilityId, onSuccess }) => {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  useEffect(() => {
    if (open && facilityId) {
      setFacilityFields();
    } else {
      form.resetFields();
    }
  }, [open, facilityId]);

  const setFacilityFields = async () => {
    const { name } = await getFacility(facilityId, { select })
    form.setFieldsValue({ name })
  }

  const onCancel = () => {
    setOpen(false);
  };

  const onOk = async () => {
    setIsSubmitting(true);
    try {
      const { name } = form.getFieldsValue();
      const params = { 
        fields: {
          name
        },
        select
      };
      const response = facilityId ? await updateFacility(facilityId, params) : await addFacility(params);
      if (onSuccess) {
        onSuccess(response);
      }
      message.success(facilityId ? 'Facility updated' : 'Facility added');
      setOpen(false);
      form.resetFields(); // Resetting form fields in both cases
    } catch (err) {
      let msg = facilityId ? 'Failed to update facility' : 'Failed to add facility';
      if (err.response?.data?.code === 11000) {
        msg = 'Account already exists with this email';
      }
      message.error(msg);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal 
      open={open}
      title={facilityId ? 'Edit Facility' : 'Add Facility'}
      onCancel={onCancel}
      onOk={onOk}
      okText='Save'
      footer={[
        <ContinueButton
          key="submit"
          onClick={onOk}
          text='Save'
          isSubmitting={isSubmitting}
        />
      ]}
    >
      <Form
        form={form}
        onFinish={onOk}
        layout='vertical'
      >
        <Item
          label="Name"
          name="name"
          rules={[{
            required: true,
            message: 'Enter facility name',
          }]}
        >
          <Input placeholder="Facility Name" />
        </Item>
      </Form>
    </Modal>
  );
};

export const AdminFacilities = () => {
  const [facilities, setFacilities] = useState([]);
  const [facilityId, setFacilityId] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // Explicitly set to false
  const [isAdminLocationModalOpen, setIsAdminLocationModalOpen] = useState()
  const [filteredCount, setFilteredCount] = useState();
  const { setCounts } = useContext(UserContext)

  useEffect(() => {
    fetchFacilities();
  }, []);

  const fetchFacilities = async () => {
    setIsLoading(true)
    let params = {
      select,
      filter: {},
      populate: [],
      sort: 'name'
    }

    const fetchedFacilities = await listFacilities(params)
    setFacilities(fetchedFacilities)
    setFilteredCount(fetchedFacilities.length)
    setIsLoading(false)
  }

  const stripLocation = location => {
    return location.replace(/[^a-zA-Z0-9]/g, '')
  }

  const getActionItems = ({ _id }) => [
    {
      key: '1',
      label: (
        <Button onClick={() => {
          setFacilityId(_id)
          setIsEditModalOpen(true)
        }}>
          <EditOutlined /> Edit
        </Button>
      )
    },
    {
      key: '2',
      label: (
        <Button onClick={() => {
          setFacilityId(_id)
          setIsAdminLocationModalOpen(true)
        }}>
          <EnvironmentOutlined /> Set Location
        </Button>
      )
    } 
  ]


  const getCustomFilter = (facility, value) => {
    return {
      location: () => facility.location && stripLocation(formatLocation(facility.location)).toLowerCase().includes(stripLocation(value).toLowerCase()),
    }
  }
  const formatLocation = location => {
    return `${location.streetAddress}${location.streetAddress2 ? ' ' + location.streetAddress2 : ''}, ${location.city}, ${location.state} ${location.postalCode}`
  }

  return (
    <div className="facility-facilities">
      <PageHeader
        title='Facilities'
        count={filteredCount}
        actions={(
          <Button
            type='primary'
            onClick={() => {
              setFacilityId(null)
              setIsEditModalOpen(true)
            }}
          >
            + Add New Facility
          </Button>
        )}
      />

      <AdminLocationModal
        objectId={facilityId}
        objectType={ObjectType.FACILITY}
        open={isAdminLocationModalOpen}
        setOpen={setIsAdminLocationModalOpen}
        select={select}
        onSuccess={facility => {
          setFacilities(facilities.map(p => p._id === facilityId ? facility : p))
        }}
      />

      <FacilityForm
        facilityId={facilityId}
        open={isEditModalOpen}
        setOpen={setIsEditModalOpen}
        onSuccess={facility => {
          if (facilityId) {
            setFacilities(facilities.map(p => p._id === facilityId ? facility : p))
          } 
          else {
            setFacilities([facility, ...facilities])
            setFilteredCount(cachedFilterCount => cachedFilterCount+1)
            setCounts(cachedCounts => {
              return {
                ...cachedCounts,
                facilities: cachedCounts.facilities + 1
              }
            })
          }
        }}
      />

      <FlexibleTable
        isLoading={isLoading}
        records={facilities}
        setFilteredCount={setFilteredCount}
        getCustomFilter={getCustomFilter}
        getActionItems={getActionItems}
        columns={[{
          title: 'Facility',
          dataIndex: 'name',
          filterDropdownType: FilterDropdownType.INPUT,
        }, {
          title: 'Address',
          dataIndex: 'location',
          filterDropdownType: FilterDropdownType.INPUT,
          render: location => location && formatLocation(location)
        }]}
      />
    </div>
  )
}
import { Typography, Button, Row, Col }  from 'antd'
import LatestPosts from '../latestPosts/latestPosts.component.js'
import Breakpoint from '../../enums/breakpoint.enum.js'
import useWidth from '../../hooks/useWidth.hook.js'

const { Text, Paragraph } = Typography

export const PrenuvoApproval = () => {
  const width = useWidth()

  const instructions = [
    {
      sections: [{
        title: 'Book Scan',
        description: <>Instalab Concierge will reach out to schedule your scan at the closest Prenuvo location to you.</>
      }]
    }, 
    {
      sections: [{
        title: 'Get Scan Done',
        description: <>Prenuvo's clinics are calm and welcoming. Sit back, relax and watch a show or listen to beats while Prenuvo's MRIs do their magic.</>
      }]
    }, 
    {
      sections: [{
        title: 'Review Your Results',
        description: <>Prenuvo provides a specialist to walk you through your results. You can also book an appointment with one of our longevity physicians.</>
      }]
    }
  ]

  return (
    <div className="prescription-approval">


      {instructions?.length > 0 && (
        <div className="instruction-list">
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row>
                <Col
                  style={{
                    width: width > Breakpoint.SM ? 80 : '100%',
                    marginBottom: width > Breakpoint.SM ? 0 : 10
                  }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  style={{
                    width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                  }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>
      )}

      <Button
        className="question-btn"
        onClick={() => window.FrontChat("show")}
      >
        I have a question?
      </Button>

      <LatestPosts />
    </div>
  )
}

import React, { useEffect, useState } from 'react';
import { Table, Button, Dropdown, message, Tooltip } from 'antd'
import { listBlogs, removeBlog } from "../../services/blog.service"
import { SettingOutlined, EyeOutlined, LockOutlined, UnlockOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons'
import './blogs.scss';
import moment from 'moment';
import { PageHeader } from '../pageHeader/pageHeader.component';
import { updateBlog } from '../../services/blog.service';
import { publishBlog } from '../../services/blog.service';
import { useNavigate } from 'react-router-dom';

const { Column } = Table

export const Blogs = () => {
  const [blogs, setBlogs] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'Instalab | Newsletter Posts'
    fetchBlogs()
  }, [])

  const fetchBlogs = async () => {
    setBlogs(await listBlogs())
    setIsLoading(false)
  }

  const onRemove = async (_id) => {
    try {
      await removeBlog(_id)
      setBlogs(blogs.filter(blog => blog._id !== _id))
      message.info('Post removed')
    } catch (err) {
      message.error('Failed to remove post')
    }
  }

  const onMakePrivate = async (_id) => {
    try {
      const blog = await updateBlog(_id, { publishedAt: null })
      setBlogs(blogs.map(b => b._id === blog._id ? blog : b))
      message.success('Post made private')
    } catch (err) {
      message.error('Failed to make post private')
    }
  }
  
  const onPublish = async (_id) => {
    try {
      const blog = await publishBlog(_id)
      setBlogs(blogs.map(b => b._id === blog._id ? blog : b))
      message.success('Post published')
    } catch (err) {
      message.error('Failed to publish post')
    }
  }

  const items = (blog) => {
    let itemList = [{
      key: 'view',
      label: (
        <a onClick={() => window.open(`/newsletter/${blog.slug}`, '_blank')}>
          <EyeOutlined /> View
        </a>
      )
    }, {
      key: 'edit',
      label: (
        <a onClick={() => {
          navigate(`/admin/blogs/${blog._id}`)
        }}>
          <EditOutlined /> Edit
        </a>
      )
    }, {
      type: 'divider'
    }, {
      key: 'remove',
      label: (
        <a 
          onClick={() => onRemove(blog._id)}
          className="error"
        >
          <DeleteOutlined /> Remove
        </a>
      )
    }]
    if (blog.publishedAt) {
      itemList = [
        {
          key: 'make-private',
          label: (
            <a onClick={() => onMakePrivate(blog._id)}>
              <LockOutlined /> Make private
            </a>
          )
        },
        ...itemList
      ]
    } else {
      itemList = [
        {
          key: 'publish',
          label: (
            <a onClick={() => onPublish(blog._id)}>
              <UnlockOutlined /> Publish
            </a>
          )
        },
        ...itemList
      ]
    }
    return itemList
  }

  return (
    <div className="blogs">
      <PageHeader
        title='Newsletter Posts'
        count={blogs.length}
        actions={(
          <Button
            type='primary'
            onClick={() => {
              navigate('/admin/blogs/new')
            }}
          >
            + Add New Post
          </Button>
        )}
      />

      <Table
        loading={isLoading}
        size='small'
        className='primary-table'
        pagination={false}
        rowKey='_id'
        dataSource={blogs}
      >
        <Column
          title='Title'
          dataIndex='title'
        />

        <Column
          title='Subject'
          dataIndex='subject'
        />

        <Column
          title='Author'
          dataIndex='author'
          render={({ name }) => name}
        />

        <Column
          title='Subscriptions'
          dataIndex='subscriptions'
          render={(subscriptions) => subscriptions?.length}
        />

        <Column
          title='Created'
          dataIndex='createdAt'
          render={createdAt => createdAt && moment(createdAt).format('MMM D. YYYY')}
        />

        <Column
          title='Published'
          dataIndex='publishedAt'
          render={publishedAt => publishedAt ? moment(publishedAt).format('MMM D. YYYY') : 'Not published'}
        />
        
        <Column
          dataIndex='remove'
          width={50}
          className="action-column"
          render={(_, blog) => (
            <Dropdown 
              menu={{ items: items(blog) }}
              placement='bottomRight'
            >
              <Button>
                <SettingOutlined />
              </Button>
            </Dropdown>
          )}
        />
      </Table>
    </div>
  )
}
import React, { useState, useEffect, useRef } from 'react'
import { Modal, Form, Button, message, Select } from 'antd'
import "./adminAppointmentForm.scss"
import { getAppointment, updateAppointment, addAppointment } from '../../services/appointment.service';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import FlowType from '../../enums/flowType.enum';
import RuleHelper from '../../helpers/rule.helper';
import FormHelper from '../flow/helpers/form.helper';
import { addProxyFlow, updateFlow } from '../../services/flow.service';
import { addProduct } from '../../services/product.service';
import ProductTypeCode from '../../enums/productTypeCode.enum';
import { listPatients } from '../../services/patient.service';
import Org from '../../enums/org.enum';
import TimeInput from '../timeInput/timeInput.component';
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { listPhlebotomists } from '../../services/phlebotomist.service';
import DateInput from '../dateInput/dateInput.component';
import PhlebType from '../../enums/phlebType.enum';
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(customParseFormat)

const { Item } = Form

const flowTypes = {
  [FlowType.LONGEVITY_TEST]: {
    title: 'Longevity Panel',
    productTypeCode: ProductTypeCode.LONGEVITY_PANEL
  },
  [FlowType.ATHLETE_TEST]: {
    title: 'Athlete Panel',
    productTypeCode: ProductTypeCode.ATHLETE_PANEL
  },
}

export const AdminAppointmentForm = ({ open, setOpen, onSuccess, appointmentId, setAppointmentId, populate=[], select }) => {
  const [form] = Form.useForm()
  const [isSubmitting, setIsSubmitting] = useState()
  const [hasAttempt, setHasAttempt] = useState()
  const [appointment, setAppointment] = useState()
  const [patients, setPatients] = useState()
  const [phlebotomists, setPhlebotomists] = useState()
  const hasAttemptRef = useRef(null)
  hasAttemptRef.current = hasAttempt

  useEffect(() => {
    if (open) {
      if (appointmentId) setFormFields()
    } else {
      setAppointmentId(null)
      form.resetFields()
      setHasAttempt(false)
    }
  }, [open])

  useEffect(() => {
    fetchPatients()
    fetchPhlebotomists()
  }, [])

  const fetchPatients = async () => {
    setPatients(sortUsers(await listPatients({
      select: '_id firstName lastName location',
      filter: {
        org: Org.INSTALAB,
        firstName: {
          $ne: null
        },
        email: {
          $ne: null
        },
        location: {
          $ne: null
        }
      },
      sort: '-createdAt'
    })))
  }

  const fetchPhlebotomists = async () => {
    setPhlebotomists(await listPhlebotomists({
      select: '_id email',
      filter: {
        firstName: {
          $ne: null
        },
        email: {
          $ne: null
        },
        location: {
          $ne: null
        }
      },
      sort: 'email'
    }))
  }

  const onCancel = () => {
    setOpen(false)
  }

  const hasFormError = async () => {
    try {
      await form.validateFields();
    } catch (errors) {
      return true
    }
    return false
  }

  const setFormFields = async () => {
    if (appointmentId) {
      const fetchedAppointment = await getAppointment(appointmentId, {
        select: 'start phlebotomist patients location',
        populate: [{
          path: 'flow',
          select: 'type'
        }]
      })
      setAppointment(fetchedAppointment)
  
      form.setFieldsValue({
        patient: fetchedAppointment.patients[0],
        startDate: dayjs(fetchedAppointment.start).tz(fetchedAppointment.location.timeZoneId).format('MM/DD/YYYY'),
        startTime: dayjs(fetchedAppointment.start).tz(fetchedAppointment.location.timeZoneId).format('hh:mm A'),
        phlebotomist: fetchedAppointment.phlebotomist,
        flowType: fetchedAppointment.flow?.type
      })
    } else {
      form.resetFields()
    }
  }

  const sortUsers = (users) => {
    return users.sort((a, b) => {
      const nameA = a.firstName.toLowerCase();
      const nameB = b.firstName.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
    
      // If first names are equal, compare last names
      const lastNameA = a.lastName.toLowerCase();
      const lastNameB = b.lastName.toLowerCase();
      if (lastNameA < lastNameB) return -1;
      if (lastNameA > lastNameB) return 1;
    
      return 0; // Names are equal
    })
  }

  const onOk = async () => {
    setHasAttempt(true)
    setIsSubmitting(true)

    try {
      if (await hasFormError()) {
        message.error('Enter valid appointment data')
        setIsSubmitting(false)
        return 
      }

      let fetchedAppointment
      if (appointmentId) {
        fetchedAppointment = await onUpdateAppointment()
      } else {
        fetchedAppointment = await onAddAppointment()
      }

      if (onSuccess) {
        onSuccess(fetchedAppointment)
      }
      message.success(appointmentId ? `Updated appointment` : `Added appointment`)
      setOpen(false)
      if (!appointmentId) {
        form.resetFields()
      }
    } catch (err) {
      console.log(err)
      message.error(appointmentId ? `Failed to update appointment` : `Failed to add appointment`)
    }
    setIsSubmitting(false)
  }

  const onAddAppointment = async () => {
    const {
      patient,
      flowType,
      startDate,
      startTime,
      phlebotomist,
    } = form.getFieldsValue()

    console.log('form values: ', form.getFieldsValue())

    // Create flow
    const flowFields = {
      type: flowType,
      user: patient,
    }

    let flow = await addProxyFlow(flowFields)
    console.log('Create flow: ', flow)

    // Add products to proxy flow
    const product = await addProduct({
      fields: {
        flowId: flow._id,
        patient,
        type: flowTypes[flowType].productTypeCode,
        free: true,
        hasKit: false,
        hasAppointment: true,
      }
    })
    console.log('Add products to proxy flow: ', product)

    // Append new product to flow
    flow = await updateFlow(flow._id, {
      products: [product._id]
    })
    console.log('Append new product to flow: ', flow)

    // Create appointment
    const { timeZoneId } = patients.find(({ _id }) => _id === patient).location
    const start = dayjs.tz(`${startDate} ${startTime}`, 'MM/DD/YYYY hh:mm A', timeZoneId)

    const newAppointment = await addAppointment({
      flow: flow._id,
      fields: {
        start,
        type: flowType,
        phlebotomist,
        phlebType: PhlebType.INSTALAB,
      },
      select,
      populate,
    })
    console.log('Create appointment: ', newAppointment)

    return newAppointment
  }

  const onUpdateAppointment = async () => {
    const {
      startDate,
      startTime,
    } = form.getFieldsValue()

    // Update appointment
    const { timeZoneId } = appointment.location
    const start = dayjs.tz(`${startDate} ${startTime}`, 'MM/DD/YYYY hh:mm A', timeZoneId)

    const updatedAppointment = await updateAppointment(appointmentId, {
      fields: {
        start,
      },
      select,
      populate,
    })
    console.log('Update appointment: ', updatedAppointment)

    return updatedAppointment
  }

  return (
    <Modal 
      open={open} 
      title={appointmentId ? `Reschedule Appointment` : `Add Appointment`}
      onCancel={onCancel}
      onOk={onOk}
      okText='Save'
      footer={null}
    >
      <Form
        form={form}
        onFinish={onOk}
        layout='vertical'
        className="admin-appointment-form"
      >
        {!appointmentId && (
          <Item 
            label="Patient" 
            name="patient"
            rules={[
              RuleHelper.isRequired
            ]}
            validateTrigger='onSubmit'
          >
            <Select 
              placeholder="Select patient..."
              options={patients?.map(patient => {
                return {
                  label: `${patient.firstName} ${patient.lastName}`,
                  value: patient._id
                }
              })}
              optionFilterProp="children"
              filterOption={(input, option) => 
                option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
              }
              className="single-select"
              showSearch
            />
          </Item>
        )}

        {!appointmentId && (
          <Item 
            label="Phlebotomist" 
            name="phlebotomist"
            rules={[
              RuleHelper.isRequired
            ]}
            validateTrigger='onSubmit'
          >
            <Select 
              placeholder="Select phlebotomist..."
              options={phlebotomists?.map(phlebotomist => {
                return {
                  label: phlebotomist.email,
                  value: phlebotomist._id
                }
              })}
              optionFilterProp="children"
              filterOption={(input, option) => 
                option.label.replace(/\s/g, '').toLowerCase().indexOf(input.replace(/\s/g, '').toLowerCase()) >= 0
              }
              className="single-select"
              showSearch
            />
          </Item>
        )}

        {!appointmentId && (
          <Item 
            label="Type"
            name="flowType"
            rules={[
              RuleHelper.isRequired
            ]}
            validateTrigger='onSubmit'
          >
            <Select 
              placeholder="Select appointment type..."
              onChange={() => {
                if (hasAttemptRef.current) {
                  FormHelper.fetchHasError(form)
                }
              }}
              options={Object.entries(flowTypes).map(([flowType, values]) => {
                return {
                  label: values.title,
                  value: flowType
                }
              })}
            />
          </Item>
        )}

        <Item 
          label="Appointment Date"
          name="startDate"
          rules={[
            RuleHelper.isRequired,
            RuleHelper.isDate,
          ]}
          validateTrigger='onSubmit'
        >
          <DateInput />
        </Item>

        <Item 
          label="Appointment Time"
          name="startTime"
          rules={[
            RuleHelper.isRequired,
          ]}
          validateTrigger='onSubmit'
        >
          <TimeInput />
        </Item>

        <Button
          className="submit-btn"
          type='primary'
          onClick={onOk}
          loading={isSubmitting}
        >
          {appointmentId ? 'Update Appointment' : 'Create Appointment'}
        </Button>
      </Form>
    </Modal>
  )
}
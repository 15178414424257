import TestCode from "../enums/testCode.enum";

// this is a repetitive file with panel.data.js - the differene is this is categorizing it for display onto the marketing page

export const advancedPanel = {
    Lipoproteins: [TestCode.APO_B, TestCode.LIPOPROTEIN_A, TestCode.APO_E],
    Lipids: [
        TestCode.TC,
        TestCode.DIRECT_LDL,
        TestCode.HDL,
        TestCode.VLDL,
        TestCode.TG,
    ],
    Inflammation: [
        TestCode.HS_CRP,
        TestCode.HOMOCYSTEINE,
        TestCode.URIC_ACID,
        // TestCode.LP_PLA2,
    ],
    "Glucose Metabolism": [
        TestCode.INSULIN,
        TestCode.GLUCOSE,
        TestCode.HBA1C,
        TestCode.HOMAIR,
    ],
    Thyroid: [TestCode.TSH, TestCode.FREET4],
    Nutrients: [
        TestCode.VITAMIN_D,
        TestCode.FERRITIN,
        TestCode.VITAMIN_B12,
        TestCode.FOLATE
    ],
    "Sex Hormones": [
        TestCode.TOTAL_TESTOSTERONE,
        TestCode.FREE_TESTOSTERONE,
        TestCode.SHBG,
        TestCode.LH,
        TestCode.FSH,
    ],
    Liver: [
        TestCode.ALBUMIN,
        TestCode.ALP,
        TestCode.ALT,
        TestCode.AST,
        TestCode.TOTAL_BILIRUBIN,
        TestCode.DIRECT_BILIRUBIN,
        TestCode.TOTAL_PROTEIN,
        TestCode.GGT
    ],
    Renal: [
        TestCode.BUN,
        TestCode.CREATININE,
        TestCode.CYSTATIN_C,
        TestCode.EGFR,
    ],
    Electrolytes: [
        TestCode.CALCIUM,
        TestCode.CHLORIDE,
        TestCode.SODIUM,
        TestCode.POTASSIUM,
        TestCode.BICARBONATE,
    ],
    "Blood Cells": [
        TestCode.RBC,
        TestCode.HEMOGLOBIN,
        TestCode.HEMATOCRIT,
        TestCode.MCV,
        TestCode.MCH,
        TestCode.MCHC,
        TestCode.RDW,
        TestCode.WBC,
        TestCode.NEUTROPHILS,
        TestCode.MONOCYTES,
        TestCode.LYMPHOCYTES,
        TestCode.EOSINOPHILS,
        TestCode.BASOPHILS,
        TestCode.PLATELET,
        TestCode.MPV
    ],
    // Biometrics: [
    //     TestCode.BMI,
    //     TestCode.BP_S,
    //     TestCode.PULSE,
    //     TestCode.WAIST,
    //     TestCode.GRIP_RIGHT,
    // ],
    Prostate: [
        TestCode.PSA_TOTAL
    ],
};
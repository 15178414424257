const BlogHelper = {
  getMinsToRead: (blog) => {
    const text = blog.content.replace(/<[^>]*>?/gm, '').trim();
    const wordCount = text.split(/\s+/).length; // Split on any whitespace
    const wordsPerMinute = 150; // Average between 200 and 250
    const readingTime = Math.ceil(wordCount / wordsPerMinute);
    return readingTime;
  },
}

export default BlogHelper

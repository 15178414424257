import { Button, Typography } from "antd"
import { useState } from 'react'
import { ArrowRightOutlined, CheckCircleFilled } from '@ant-design/icons'
import classNames from 'classnames'
import { SubscribeModal } from "../subscribeModal/subscribeModal.component";
import "./subscribeSection.scss"

const { Paragraph } = Typography

const SubscribeSection = ({ darkbg, isSubscribed, setIsSubscribed }) => {
  const [openNewsletter, setOpenNewsletter] = useState()

  const onSubscribe = () => {
    setOpenNewsletter(true)
  }
  
  return (
    <div className={classNames("subscribe-section", "section", darkbg ? "dark-bg" : "not-dark-bg")}>
      <SubscribeModal
        open={openNewsletter}
        setOpen={setOpenNewsletter}
        setIsSubscribed={setIsSubscribed}
      />  

      <div className="section-container subscribe-container">
        <Paragraph className="section-title">
          Subscribe to the <span>Peak Health Newsletter</span>
        </Paragraph>
        <Paragraph className="subscribe-section-subtitle section-subtitle">
          Insights for high-achieving people, striving to keep up with the demands of your busy life while prioritizing your long-term health.
        </Paragraph>

        <Button 
          type='primary'
          className={classNames(
            "subscribe-btn", 
            "main-cta-btn",
            isSubscribed ? 'subscribed-btn' : 'not-subscribed-btn'
          )}
          onClick={onSubscribe}
        >
          {isSubscribed ? <><CheckCircleFilled /> Subscribed</> : <>Subscribe Now <ArrowRightOutlined /></>}
          
        </Button>
      </div>
    </div>
  )
}

export default SubscribeSection
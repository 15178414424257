import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { posthog } from "posthog-js";
import { message } from 'antd'
import StorageKey from "../../enums/storageKey.enum.js";
import { UserContext } from "../../contexts/user.context.js";
import { v4 as uuidv4 } from 'uuid';

export const Logout = () => {
  const navigate = useNavigate();
  const { setCurrentUser, setToken, setTracker, setLongevityMembership, setMemberships } = useContext(UserContext)

  useEffect(() => {
    const newTracker = uuidv4()
    localStorage.setItem(StorageKey.TRACKER, newTracker)
    setTracker(newTracker)
    localStorage.removeItem(StorageKey.TOKEN)
    setToken(null)
    setCurrentUser(null)
    setLongevityMembership(null)
    setMemberships(null)
    message.info('Logged out')
    resetPostHog()
    navigate('/')
  }, [])

  const resetPostHog = () => {
    if (window.location.hostname !== "localhost") {
      posthog.init("dFcc12453JhWlT-iDYk6YMPdJkdWiTl6gNu6s26JNv8", {
        api_host: "https://app.posthog.com",
      });
      posthog.reset(true);
    }
  };
  return null;
};

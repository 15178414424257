import { useEffect, useState, useRef } from "react"
import { Flow } from "../flow/flow.component"
import { cholesterolTherapyPanel } from "../../data/cholesterolTherapyPanel.data"
import StepType from "../flow/enums/stepType.enum"
import MembershipTypeCode from "../../enums/membershipTypeCode.enum"
import { listMembershipTypes } from '../../services/membershipType.service'
import { useContext } from "react"
import { dosages } from "../../data/dosage.data"
import UpgradeConfirmed from "./static/upgradeConfirmed/upgradeConfirmed.component"
import { PanelModal } from "../panelModal/panelModal.component"
import { TassoModal } from "../tassoModal/tassoModal.component"
import { UserContext } from "../../contexts/user.context"
import { completeFlow } from "../../services/flow.service"
import ProductHelper from "../../helpers/product.helper"
import { getMe } from "../../services/user.service"

export const Step = {
  PAY: 'pay',
  NATURAL: 'natural',
  MEDICATIONS: 'medications',
  OTHER_MEDICATIONS: 'other-medications',
  MEDICAL_CONDITIONS: 'medical-conditions',
  SMOKING: 'smoking',
  ALCOHOL: 'alcohol',
  FAMILY_HISTORY: 'family-history',
  CONFIRM: 'confirm',

  // dynamic
  CURRENT: 'current',
  DOSAGE: 'dosage',
  ADVERSE_REACTION: 'adverse-reaction',
}

export const UpgradeFlow = () => {
  const { token, longevityMembership } = useContext(UserContext)
  const [skeleton, setSkeleton] = useState()
  const [flow, setFlow] = useState()
  const [membershipTypes, setMembershipTypes] = useState()
  const [openPanel, setOpenPanel] = useState()
  const [openTasso, setOpenTasso] = useState()
  const [currentUser, setCurrentUser] = useState()

  const [initialUser, setInitialUser] = useState()

  useEffect(() => {
    fetchSkeleton()
  }, [flow, membershipTypes, longevityMembership, currentUser])

  useEffect(() => {
    fetchMembershipTypes()
  }, [])

  useEffect(() => {
    fetchCurrentUser()
  }, [token])

  const fetchCurrentUser = async () => {
    if (token) {
      setCurrentUser(await getMe({
        select: 'cholesterolMedications'
      }))
    } else {
      setCurrentUser(null)
    }
  }

  const fetchMembershipTypes = async () => {
    setMembershipTypes(await listMembershipTypes({
      filter: {
        code: {
          $in: [
            MembershipTypeCode.LIPIDS_UPGRADE,
            MembershipTypeCode.LONGEVITY,
          ]
        }
      }
    }))
  }
  
  const getMembershipType = (code) => {
    return membershipTypes?.find(membershipType => membershipType.code === code)
  }

  const fetchSkeleton = () => {
    let medicationSteps = {}
    const cholesterolMedications = currentUser?.cholesterolMedications?.filter(c => c !== 'Other')

    if (cholesterolMedications?.length) {
      for (const cholesterolMedication of cholesterolMedications) {
        medicationSteps[`${Step.CURRENT}-${cholesterolMedication}`] = {
          type: StepType.SINGLE_SELECT,
          nextStep: dosages[cholesterolMedication].length === 1 ? `${Step.ADVERSE_REACTION}-${cholesterolMedication}` : `${Step.DOSAGE}-${cholesterolMedication}`,
          buildUser: true,
          title: `Are you still taking ${cholesterolMedication}?`,
          field: {
            name: `cholesterolCurrent.${cholesterolMedication}`,
            options: [{
              label: 'Yes',
              value: true,
            }, {
              label: 'No',
              value: false,
            }]
          }
        }

        medicationSteps[`${Step.DOSAGE}-${cholesterolMedication}`] = {
          type: StepType.SINGLE_SELECT,
          nextStep: `${Step.ADVERSE_REACTION}-${cholesterolMedication}`,
          buildUser: true,
          title: `What dosage of ${cholesterolMedication} did you take last?`,
          field: {
            name: `cholesterolDosage.${cholesterolMedication}`,
            required: true,
            options: [...dosages[cholesterolMedication],'Other'].map(dosage => {
              return {
                label: dosage,
                value: dosage,
              }
            })
          },
        }

        medicationSteps[`${Step.ADVERSE_REACTION}-${cholesterolMedication}`] = {
          type: StepType.SINGLE_SELECT,
          onNextStep: async (patient) => {
            const { cholesterolMedications } = patient
            const index = cholesterolMedications.findIndex(c => c === cholesterolMedication)
            if (cholesterolMedications[index+1]) {
              return `${Step.CURRENT}-${cholesterolMedications[index+1]}`
            } else if (cholesterolMedications.includes('Other')) {
              return Step.OTHER_MEDICATIONS
            }
            return Step.MEDICAL_CONDITIONS
          },
          buildUser: true,
          title: `Did you have any adverse reactions to ${cholesterolMedication}?`,
          field: {
            name: `cholesterolAdverseReaction.${cholesterolMedication}`,
            options: [{
              label: 'Yes',
              value: true,
            }, {
              label: 'No',
              value: false,
            }]
          }
        }
      }
    }

    setSkeleton({
      [Step.PAY]: {
        type: StepType.PAY,
        nextStep: Step.NATURAL,
        addSubscription: true,
        subscriptionType: MembershipTypeCode.LIPIDS_UPGRADE,
        description: <>
          This includes <a onClick={() => setOpenTasso(true)}>painless at-home blood tests</a> for <a onClick={() => setOpenPanel(true)}>7 key biomarkers</a> to monitor the efficacy of your medication.
          <br/><br/>
          An Instalab physician will continuously adjust your prescription until you hit your target health goals without side effects.
        </>,
        title: longevityMembership ?
          <>Pay <span className="true-price">{ProductHelper.formatDiscountCost(getMembershipType(MembershipTypeCode.LIPIDS_UPGRADE))}/month</span> for your cholesterol treatment program</> :
          <>Pay <span className="true-price">${getMembershipType(MembershipTypeCode.LIPIDS_UPGRADE)?.cost}/month</span> for your cholesterol treatment program.</>,
      },
      [Step.NATURAL]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.MEDICATIONS,
        buildUser: true,
        title: `Select any of the following natural remedies you have taken to lower your cholesterol.`,
        description: 'Select all that apply.',
        field: {
          name: 'naturalCholesterolRemedies',
          options: [{
            label: 'Berberine',
            value: 'Berberine',
          }, {
            label: 'Bergamot',
            value: 'Bergamot',
          }, {
            label: 'Fiber Supplements',
            value: 'Fiber Supplements',
          }, {
            label: 'Red Yeast Rice',
            value: 'Red Yeast Rice',
          }, {
            label: 'Other',
            value: 'Other',
          }]
        },
      },
      [Step.MEDICATIONS]: {
        type: StepType.MULTIPLE_SELECT,
        onNextStep: async (patient) => {
          const patientCholesterolMedications = patient?.cholesterolMedications?.filter(c => c !== 'Other')
          if (patientCholesterolMedications?.length) {
            return `${Step.CURRENT}-${patientCholesterolMedications[0]}`
          } else if (patient?.cholesterolMedications?.includes('Other')) {
            return Step.OTHER_MEDICATIONS
          }
          return Step.MEDICAL_CONDITIONS
        },
        buildUser: true,
        title: `Select any of the following medications you have taken to lower your cholesterol.`,
        description: 'Select all that apply.',
        field: {
          name: 'cholesterolMedications',
          options: [{
            label: 'Alirocumab (Praluent)',
            value: 'Alirocumab',
          }, {
            label: 'Atorvastatin (Lipitor)',
            value: 'Atorvastatin',
          }, {
            label: 'Evolocumab (Repatha)',
            value: 'Evolocumab',
          }, {
            label: 'Ezetimibe (Zetia)',
            value: 'Ezetimibe',
          }, {
            label: 'Lovastatin (Altoprev)',
            value: 'Lovastatin',
          }, {
            label: 'Pitavastatin (Livalo)',
            value: 'Pitavastatin',
          }, {
            label: 'Pravastatin (Pravachol)',
            value: 'Pravastatin',
          }, {
            label: 'Rosuvastatin (Crestor)',
            value: 'Rosuvastatin',
          }, {
            label: 'Simvastatin (Zocor)',
            value: 'Simvastatin',
          }, {
            label: 'Other',
            value: 'Other',
          }]
        },
      },

      ...medicationSteps,

      [Step.OTHER_MEDICATIONS]: {
        type: StepType.SINGLE_INPUT,
        nextStep: Step.MEDICAL_CONDITIONS,
        buildUser: true,
        title: `What other medications have you taken to lower your cholesterol?`,
        field: {
          name: 'otherCholesterolMedications',
          placeholder: 'Bempedoic Acid (Nexletol), Niacin (Niacor)',
        },
      },
      [Step.MEDICAL_CONDITIONS]: {
        type: StepType.MULTIPLE_SELECT,
        nextStep: Step.SMOKING,
        buildUser: true,
        title: `Select any of the following conditions you've been diagnosed with.`,
        description: 'Select all that apply.',
        field: {
          name: 'allMedicalConditions',
          options: [{
            label: 'Blocked Artery',
            value: 'Blocked Artery',
          }, {
            label: 'Chronic Kidney Disease',
            value: 'Chronic Kidney Disease',
          }, {
            label: 'Diabetes Type 1',
            value: 'Diabetes Type 1',
          }, {
            label: 'Diabetes Type 2',
            value: 'Diabetes Type 2',
          }, {
            label: 'Familial Hypercholesterolemia',
            value: 'Familial Hypercholesterolemia',
          }, {
            label: 'Heart Attack',
            value: 'Heart Attack',
          }, {
            label: 'Heart Disease',
            value: 'Heart Disease',
          }, {
            label: 'High Cholesterol',
            value: 'High Cholesterol',
          }, {
            label: 'Hypertension',
            value: 'Hypertension',
          }, {
            label: 'Peripheral Arterial Disease',
            value: 'Peripheral Arterial Disease',
          }, {
            label: 'Stroke',
            value: 'Stroke',
          }]
        },
      },
      [Step.SMOKING]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        nextStep: Step.ALCOHOL,
        title: `Do you smoke or vape nicotine?`,
        field: {
          name: 'hasSmoking',
          options: [{
            label: 'Yes',
            value: true,
          }, {
            label: 'No',
            value: false,
          }]
        }
      },
      [Step.ALCOHOL]: {
        type: StepType.SINGLE_SELECT,
        buildUser: true,
        nextStep: Step.FAMILY_HISTORY,
        title: `How much alcohol do you consume?`,
        description: `1 drink is a single alcoholic beverage.`,
        field: {
          name: 'alcoholQty',
          options: [{
            label: 'More than 14 drinks / week',
            value: 'More than 14 drinks / week',
          }, {
            label: 'Less than 14 drinks / week',
            value: 'Less than 14 drinks / week',
          }, {
            label: 'I do not consume alcohol',
            value: 'I do not consume alcohol',
          }]
        }
      },
      [Step.FAMILY_HISTORY]: {
        type: StepType.MULTIPLE_SELECT,
        buildUser: true,
        nextStep: Step.CONFIRM,
        title: `Have any grandparents, parents, or siblings been diagnosed with any of the following conditions before age 60?`,
        description: 'Select all that apply.',
        field: {
          name: 'familyHistory',
          options: [{
            label: 'Blocked Artery',
            value: 'Blocked Artery',
          }, {
            label: 'Familial Hypercholesterolemia',
            value: 'Familial Hypercholesterolemia',
          }, {
            label: 'Heart Attack',
            value: 'Heart Attack',
          }, {
            label: 'Heart Disease',
            value: 'Heart Disease',
          }, {
            label: 'High Cholesterol',
            value: 'High Cholesterol',
          }, {
            label: 'Peripheral Arterial Disease',
            value: 'Peripheral Arterial Disease',
          }, {
            label: 'Stroke',
            value: 'Stroke',
          }]
        },
      },
      [Step.CONFIRM]: {
        type: StepType.STATIC,
        title: `You're in!`,
        description: `We'll send you an email with instructions to get started with the cholesterol treatment program soon. This includes the delivery of your first blood test kit.`,
        showFooter: false,
        content: <UpgradeConfirmed />,
        onLoad: async () => {
          if (flow?._id) {
            await completeFlow(flow._id)
          }
        }
      }
    })
  }

  return <>
    {skeleton && (
      <Flow
        skeleton={skeleton} 
        flow={flow} 
        setFlow={setFlow}
        initialUser={initialUser}
        setInitialUser={setInitialUser}
      />
    )}

    <TassoModal
      open={openTasso}
      setOpen={setOpenTasso}
    />

    <PanelModal
      panel={cholesterolTherapyPanel}
      title="Cholesterol Treatment Panel"
      open={openPanel}
      setOpen={setOpenPanel}
    />
  </>
}
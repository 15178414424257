
const EventCode = Object.freeze({
  VISIT_HOME_V2: 'VISIT_HOME_V2',

  VISIT_HOME: 'VISIT_HOME',
  VISIT_CHECKOUT: 'VISIT_CHECKOUT',
  VISIT_SCHEDULE: 'VISIT_SCHEDULE',
  VISIT_INVITE: 'VISIT_INVITE',
  VISIT_CONFIRMATION: 'VISIT_CONFIRMATION',
  VISIT_POST: 'VISIT_POST',
  VISIT_NEWSLETTER: 'VISIT_NEWSLETTER',
  VISIT_GIFT: 'VISIT_GIFT',
  INVITE_SENT: 'INVITE_SENT',
  VISIT_FEEDBACK: 'VISIT_FEEDBACK',

  // Flow
  VISIT_GALLERI: '[galleri-test] marketing',
  VISIT_CHOLESTEROL_THERAPY: '[cholesterol] marketing',
  VISIT_BLOOD_TEST: '[blood-test] marketing',
  VISIT_ATHLETE_BLOOD_TEST:  '[athlete-blood-test] marketing',
  VISIT_CLEERLY: '[cleerly] marketing',
  VISIT_CAC: '[cac] marketing',
  VISIT_HEART_HEALTH_TEST: '[heart health test] marketing',
  VISIT_FERTILITY_TEST: '[fertility test] marketing',
  VISIT_TRUAGE_COMPLETE: '[truage complete] marketing',
  
  // Blog
  CLICK_BLOG_LINK: 'CLICK_BLOG_LINK',
  CLICK_BLOG_TWITTER: 'CLICK_BLOG_TWITTER',
  CLICK_BLOG_LINKEDIN: 'CLICK_BLOG_LINKEDIN',
  CLICK_BLOG_SUBSCRIBE: 'CLICK_BLOG_SUBSCRIBE',
  CLICK_BLOG_SCHEDULE: 'CLICK_BLOG_SCHEDULE',

  // V2 Checkout
  V2_INTRO: 'V2_INTRO',
  V2_LOCATION: 'V2_LOCATION',
  V2_PAYMENT: 'V2_PAYMENT',
  V2_ACCOUNT: 'V2_ACCOUNT',
  V2_SCHEDULE: 'V2_SCHEDULE',
  V2_INVITE: 'V2_INVITE',
  V2_CONFIRMATION: 'V2_CONFIRMATION',

  // V3 Checkout with membership
  V3_LOCATION: 'V3_LOCATION',
  V3_PAYMENT: 'V3_PAYMENT',
  V3_ACCOUNT: 'V3_ACCOUNT',
  V3_SCHEDULE: 'V3_SCHEDULE',
  V3_INVITE: 'V3_INVITE',
  V3_CONFIRMATION: 'V3_CONFIRMATION',

  // Success
  PAYMENT_SUCCESS: 'PAYMENT_SUCCESS',
  ACCOUNT_SUCCESS: 'ACCOUNT_SUCCESS',
  SCHEDULE_SUCCESS: 'SCHEDULE_SUCCESS',

  // Test Catalog
  TEST_CATALOG_VIEW: 'TEST_CATALOG_VIEW',
  TEST_CATALOG_CLICK: 'TEST_CATALOG_CLICK',
  
})

export default EventCode;

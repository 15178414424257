import { Typography, Row, Col }  from 'antd'
import Breakpoint from "../../enums/breakpoint.enum";
import useWidth from '../../hooks/useWidth.hook';
import { TassoModal } from '../tassoModal/tassoModal.component';
import { useState } from 'react'

const { Text, Paragraph } = Typography

const TotalTestosteroneTestIntro = ({ footer }) => {
  const width = useWidth()
  const [openTasso, setOpenTasso] = useState()

  const instructions = [{
    sections: [
      {
      title: 'Receive Test Kit',
      description: <>It'll come within one week of purchase. This includes everything you'll need to collect your blood and send to lab.</>
    }, 
  ]}, 
  {
    sections: [{
      title: 'Self Collect Blood + Mail Back',
      description: <>You'll use a <a className="cta-link" onClick={(e) => {
        e.stopPropagation(); 
        setOpenTasso(true);
      }} >Tasso device</a> vs the usual fingerprick, which ensures a painless process. This takes two minutes or less. Just drop in mail when done.</>
    }]
  }, 
  {
    sections: [{
      title: 'Review Test Results',
      description: <>Test results will be ready 1 week after your sample arrives at the lab..</>
    }]
  }]
  return (
    <div 
      className="flow-intro"
      style={{
        minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
      }}
    >

      <TassoModal
        open={openTasso}
        setOpen={setOpenTasso}
      />

      <div 
        className="flow-intro-body"
        style={{
          minHeight: width >= Breakpoint.XL ? 'calc(100vh - 86px)' : 'calc(100vh - 60px)',
        }}
      >
        <Paragraph 
          className="flow-title"
          style={{
            fontSize: width > Breakpoint.SM ? 32 : 28,
          }}
        >
          Total Testosterone Test
        </Paragraph>
        <Paragraph 
          className="flow-description"
          style={{
            fontSize: width > Breakpoint.SM ? 16 : 14,
          }}
        >
          By measuring the total level of testosterone in your body, you can identify any factors that might interfere with sex drive (libido), bone mass, fat distribution, muscle mass or production of red blood cells and sperm.
        </Paragraph>

        <div 
          className="instruction-list"
          style={{
            marginTop: width > Breakpoint.SM ? 50 : 30,
            marginBottom: width > Breakpoint.SM ? -15 : -25,
            paddingTop: width > Breakpoint.SM ? 25 : 15,
          }}
        >
          {instructions.map(({ sections }, instructionIndex) => (
            <div 
              key={`instruction-${instructionIndex}`} 
              className="instruction-item"
            >
              <Row>
                <Col
                  style={{
                    width: width > Breakpoint.SM ? 80 : '100%',
                    marginBottom: width > Breakpoint.SM ? 0 : 10
                  }}
                >
                  <Text className="instruction-index">
                    Step {instructionIndex+1}
                  </Text>
                </Col>

                <Col
                  style={{
                    width: width > Breakpoint.SM ? 'calc(100% - 80px)' : '100%'
                  }}
                >
                  {sections.map(({ title, description }, sectionIndex) => (
                    <div 
                      key={`section-${instructionIndex}-${sectionIndex}`} 
                      className="section-item"
                    >
                      <Text className="section-title">
                        {title}
                      </Text>
                      <Paragraph className="section-description">
                        {description}
                      </Paragraph>
                    </div>
                  ))}
                  </Col>
              </Row>
            </div>
          ))}
        </div>

        <div className="flow-intro-btn-container">
          <div className="flow-intro-btn">
            {footer({ 
              buttonText: "Let's go",
              buttonCheck: false,
              buttonStyle: {
                height: 50,
                fontSize: 18,
                width: '100%',
                display: 'block',
              },
              enterStyle: {
                display: 'none',
              }
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TotalTestosteroneTestIntro
import { useContext, useEffect, useState } from "react"
import { Typography, Avatar, Tooltip, Affix, Button, message } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import copy from 'copy-to-clipboard';
import { getBlogBySlug } from "../../services/blog.service"
import parse from 'html-react-parser';
import moment from 'moment'
import classNames from "classnames";
import "./blogView.scss"
import { LinkOutlined, CheckCircleFilled, TwitterSquareFilled, LinkedinFilled } from '@ant-design/icons'
import BlogHelper from "../../helpers/blog.helper";
import SubscribeSection from "../subscribeSection/subscribeSection.component";
import EventCode from "../../enums/eventCode.enum";
import PosthogHelper from '../../helpers/posthog.helper';
import { UserContext } from "../../contexts/user.context";
import { SubscribeModal } from "../subscribeModal/subscribeModal.component";
import { TwitterShareButton, LinkedinShareButton } from 'react-share'
import Breakpoint from "../../enums/breakpoint.enum";
import { PgFooter } from "../pgFooter/pgFooter.component";
import FlowType from "../../enums/flowType.enum";
import useWidth from "../../hooks/useWidth.hook";
import StorageKey from "../../enums/storageKey.enum";

const { Text, Paragraph } = Typography

export const BlogView = () => {
  const [blog, setBlog] = useState()
  const { slug } = useParams()
  const [isSubscribed, setIsSubscribed] = useState(localStorage.getItem(StorageKey.SUBSCRIBED))
  const { currentUser } = useContext(UserContext)
  const width = useWidth()
  const navigate = useNavigate()
  const [openSubscribe, setOpenSubscribe] = useState()

  useEffect(() => {
    fetchBlog()
  }, [slug])

  const fetchBlog = async () => {
    if (!slug) return
    setBlog(await getBlogBySlug(slug))
  }

  useEffect(() => {
    trackPosthog()
  }, [currentUser])

  const trackPosthog = () => {
    PosthogHelper.track(currentUser, EventCode.VISIT_POST)
  }

  return blog && <>
    <div className="blog-view">
      <Paragraph className="blog-title">
        {blog.title}
      </Paragraph>

      <div className="blog-details">
        <Text className="blog-author"> 
          <Avatar 
            src={`${blog.author.image}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`} 
            className="blog-avatar"
          />
          {blog.author.name}{blog.author.title ? `, ${blog.author.title}` : ''}
        </Text>
        &nbsp;&nbsp;•&nbsp;&nbsp;
        <Text className="mins-to-read">{BlogHelper.getMinsToRead(blog)} min read</Text>
        {width >= 420 && <>
          &nbsp;&nbsp;•&nbsp;&nbsp;
          {blog.publishedAt ? moment(blog.publishedAt).format('MMM D, YYYY') : 'Not published'}
        </>}
      </div>

      <Affix
        offsetTop={width >= Breakpoint.XL ? 0 : 0}
      >
        <div className="blog-bar">
          <SubscribeModal
            open={openSubscribe}
            setOpen={setOpenSubscribe}
            setIsSubscribed={setIsSubscribed}
          />

          <div className="social-btn-list">
            <Tooltip title="Copy Link" placement="bottom">
              <LinkOutlined 
                className={classNames("social-btn", "link-btn")}
                onClick={() => {
                  copy(`${process.env.REACT_APP_CLIENT_URL}/newsletter/${slug}`)
                  message.success('Link copied')
                  PosthogHelper.track(currentUser, EventCode.CLICK_BLOG_LINK)
                }}
              />
            </Tooltip>

            <Tooltip title="Share on Twitter" placement="bottom">
              <TwitterShareButton
                title={`"${blog.title}" by @${blog.author.twitter}`}
                url={window.location.href}
                onClick={() => {
                  PosthogHelper.track(currentUser, EventCode.CLICK_BLOG_TWITTER)
                }}
              >
                <TwitterSquareFilled className={classNames("social-btn", "twitter-btn")} />
              </TwitterShareButton>
            </Tooltip>

            <Tooltip title="Share on LinkedIn" placement="bottom">
              <LinkedinShareButton
                title={'test'}
                description={'test'}
                source={window.location.href}
                url={window.location.href}
                onClick={() => {
                  PosthogHelper.track(currentUser, EventCode.CLICK_BLOG_LINKEDIN)
                }}
              >
                <LinkedinFilled className={classNames("social-btn", "linkedin-btn")} />
              </LinkedinShareButton>
            </Tooltip>

            {/* <Tooltip title="Share on Facebook" placement="bottom">
              <FacebookShareButton
                url={window.location.href}
              >
                <FacebookFilled className={classNames("social-btn", "facebook-btn")} />
              </FacebookShareButton>
            </Tooltip> */}
          </div>

          <div className="blog-actions">
            <Tooltip title="Insights for high-achieving people, keeping up with the demands of your busy life while prioritizing your health." placement="bottom">
              <Button 
                className={classNames(
                  isSubscribed ? 'subscribed-btn' : 'not-subscribed-btn',
                  "subscribe-btn"
                )}
                onClick={() => {
                  setOpenSubscribe(true)
                  PosthogHelper.track(currentUser, EventCode.CLICK_BLOG_SUBSCRIBE)
                }}
              >
                {isSubscribed ? <>{width >= 350 ? <CheckCircleFilled /> : ''}Subscribed</> : 'Subscribe'}
              </Button>
            </Tooltip>

            <Tooltip title="An at-home blood testing service for 60+ biomarkers that teaches you how to optimize your health." placement="bottom">
              <Button 
                type="primary" 
                className="schedule-btn"
                onClick={() => {
                  navigate(`/flow/${FlowType.LONGEVITY_TEST}`)
                  PosthogHelper.track(currentUser, EventCode.CLICK_BLOG_SCHEDULE)
                }}
              >
                {width >= 350 ? 'Schedule Blood Draw' : 'Schedule Draw'}
              </Button>
            </Tooltip>
          </div>
        </div>
      </Affix>

      {blog.image && 
      <img 
        src={`${blog.image}?policy=${process.env.REACT_APP_FILESTACK_POLICY}&signature=${process.env.REACT_APP_FILESTACK_SIGNATURE}`} 
        className="blog-image"
      />}

      <Paragraph className="blog-content">
        {blog.content && parse(blog.content.replace(/<p><br><\/p>/g, ''))}
      </Paragraph>
    </div>
    

    <SubscribeSection 
      darkbg={true}
      isSubscribed={isSubscribed}
      setIsSubscribed={setIsSubscribed}
    />

    <PgFooter />
  </>
}